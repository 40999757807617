import { eventChannel } from '@redux-saga/core';
import { call, delay, fork, put, take, select, retry } from 'redux-saga/effects';
import { actionCreators as sessionActionCreators, actionTypes, NetworkStatus } from './session.reducer';
import { actionCreators, actionCreators as authActionCreators, actionTypes as authActionTypes } from '../auth/auth.reducer';
import PartnerApi from '@app/api/partner-api/partner.actions';
import { push } from 'connected-react-router';
import { selectPartnerId } from './session.selectors';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { API_RETRY_ATTEMPTS, API_RETRY_INTERVAL } from '@app/lib/constants';
import { GQLHelpers } from '@app/api/hasura/ts';
import { apolloClient } from '@app/api/graphql/client';

const createNetworkStatusChannel = () =>
  eventChannel((emitter) => {
    window.addEventListener(NetworkStatus.ONLINE, emitter);
    window.addEventListener(NetworkStatus.OFFLINE, emitter);

    return () => {
      window.removeEventListener(NetworkStatus.ONLINE, emitter);
      window.removeEventListener(NetworkStatus.OFFLINE, emitter);
    };
  });

function* watchNetworkStatusChannel() {
  try {
    const networkStatusChannel = yield call(createNetworkStatusChannel);
    for (;;) {
      yield take(networkStatusChannel);
      const networkStatus = navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
      yield put(sessionActionCreators.notificationEnqueue({
        message: `Network: ${capitalizeFirstLetter(networkStatus)}`, 
        options: {
            key: new Date().getTime() + Math.random(),
        },
      }))
      yield put(sessionActionCreators.networkStatusChanged(networkStatus));
    }
  } catch (e) {
    yield put(sessionActionCreators.networkStatusChanged(NetworkStatus.UNKNOWN));
  }
}

const createFocusChannel = () =>
  eventChannel((emitter) => {
    window.addEventListener('focusin', emitter);

    return () => {
      window.removeEventListener('focusin', emitter);
    }
   });


function* watchFocusChannel() {
  try {
    const focusChannel = yield call(createFocusChannel);
    for (;;) {
      yield take(focusChannel);
      yield put(sessionActionCreators.focusChanged());
    }
  } catch (e) {}
}

function* watchAppInitialize() {
  for (;;) {
    try {
      yield take([authActionTypes.SIGNIN_SUCCESS, authActionTypes.TOKEN_REFRESH_SUCCESS]);

      yield put(authActionCreators.loadPreferencesRequest());

      const partnerId = yield select(selectPartnerId);
      const preferences = yield retry(API_RETRY_ATTEMPTS, API_RETRY_INTERVAL, PartnerApi.getPreferences, partnerId);

      if (preferences?.partner_id) {
        yield put(actionCreators.loadPreferencesSuccess({
          partnerId: preferences.partner_id,
          logoFilename: preferences.logo_filename,
          faviconFilename: preferences.favicon_filename,
          headerColor: preferences.header_color,
          pageTitle: preferences.page_title,
          logoFileUrl: preferences.logo_filename_url,
          faviconFileUrl: preferences.favicon_filename_url,
          id: preferences.id,
        }));
      }
    } catch (error) {
      console.log('Error initializing app')
      console.log(error)
      yield put(push('/auth/login'));
    }
  }
}

function* watchLogout() {
  for (;;) {
    yield take(authActionTypes.LOGOUT_REQUEST);
    yield put(sessionActionCreators.appInitializeReset());
  }
}

export function* sessionSaga() {
  yield fork(watchAppInitialize);
  yield fork(watchLogout);
  yield fork(watchNetworkStatusChannel);
  yield fork(watchFocusChannel);
}
