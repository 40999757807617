import React from 'react';
import loginVideo from '@app/assets/video/ethica-bg.mp4';
import defaultLogo from '@app/assets/images/logo/cloud-access-logo-white.png'
import { selectPageTitle } from '@app/common/session/session.selectors';
import { useSelector } from 'react-redux';

const VideoBackground = () => {
  const pageTitle = useSelector(selectPageTitle);
  const memoizedTitle = React.useMemo(() => pageTitle ?? 'Orchestrator', [pageTitle]);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  return (
    <div className="video-container">
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        playsInline 
        className="video-content"
      >
        <source src={loginVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">
        <h1 className="video-text mt-3">Welcome to {memoizedTitle}!</h1>
      </div>
      <div className="video-bottom">
          <img
            src={defaultLogo}
            width="150"
            alt=""
          />
      </div>
    </div>
  );
};
  
  export default VideoBackground;