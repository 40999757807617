import ApiGateway from '../api-gateway/api-gateway';
import { getPortsFromRange, getPortRangeFromStartEnd, aclFromState } from '@app/pages/aclprofile/ACLProfileHelper';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const acls = await ApiGateway.get('/access-control-lists');
      if (!acls?.data?.access_control_lists) {
        return [];
      }
      return acls.data.access_control_lists;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const acl = await ApiGateway.get(`/access-control-lists/${id}`);
      return acl.data.access_control_list;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const aclParams = aclFromState(payload);
      const acl = await ApiGateway.post('/access-control-lists', aclParams);
      return acl.data.access_control_list;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const aclParams = aclFromState(payload);
      const acl = await ApiGateway.patch(`/access-control-lists/${id}`, aclParams);
      return acl.data.access_control_list;
    }
    else {
      cognitoUser.signOut();
      window.location.href = '/auth/login';
    }
  },

  async delete(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      return await ApiGateway.delete(`/access-control-lists/${id}`, {});
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async deleteRule(aclId, ruleId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      return await ApiGateway.delete(`/access-control-lists/${aclId}/rules/${ruleId}`, {});
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async setSiteAssignments(assignments, siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      await this.setAssignments(assignments, siteId, 'site');
    }
    else {
      cognitoUser.signOut();
    }
  },

  async setWanAssignments(assignments, wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      await this.setAssignments(assignments, wanId, 'wan');
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getAssignedACLs(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const allACLs = await this.getAll();

      if (allACLs) {
        let acls: Promise<any>[] = [];
  
        allACLs.map(acl => {
          if (acl.assignments) {
            let sites = acl.assignments.filter(a => a.resource_id === id);
            if (sites?.length > 0) {
              acls.push(acl);
            }
          }
        });
        return acls;
      }
      else {
        return [];
      }
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async setAssignments(assignments, resourceId, resourceType) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const allACLs = await this.getAll();

      if (allACLs) {
        const namesOnly = allACLs.map(acl => { return acl.name });
        let acls: any[] = [];
    
        allACLs.map(acl => {
          if (assignments.includes(acl.name)) {
            acls.push(acl);
          }
        }); 
    
        // Add ACL assignments
        for (let i = 0; i < acls.length; i++) {
          const aclId = acls[i].id;
          const prop = resourceType + '_id';
          const payload = { [prop]: resourceId };
          await ApiGateway.post(`/access-control-lists/${aclId}/assignments`, payload);
        }
    
        // Remove ACL assignments
        let removed = namesOnly.filter(a => !assignments.includes(a));
        const aclsToRemove = allACLs.map(acl => { return removed.includes(acl.name) ? acl : false });
        await this.deleteAssignments(aclsToRemove, resourceId);
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async deleteAssignments(aclsToRemove, resourceId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      for (let i = 0; i < aclsToRemove.length; i++) {
        const acl = aclsToRemove[i];
  
        if (acl) {
          const aclId = acl.id;
          if (acl.assignments) {
            const assignment = acl.assignments.filter(a => a.resource_id === resourceId);
            if (assignment[0]) {
              await ApiGateway.delete(`/access-control-lists/${aclId}/assignments/${assignment[0].id}`, {});
            }
          }
        }
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateSiteAssignmentsForWan(sites, wanAclProfiles) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      if (sites && wanAclProfiles) {
        for (let i = 0; i < sites.length; i++) {
          const siteId = sites[i].cells[4].props.value;
          const siteAcls = await this.getAssignedACLs(siteId);
          await this.deleteAssignments(siteAcls, siteId);
          await this.setSiteAssignments(wanAclProfiles, siteId);
        }
      }
    }
    else {
      cognitoUser.signOut();
    }
  }
}
