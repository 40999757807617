import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

const MetricChart = ({ metricData, metricName }) => {
  // Temporarily disabled - return null to not render anything
  return null;

  /*
  const chartRef = useRef(null);
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;

  const allData = metricData && Array.isArray(metricData) ? metricData.flatMap((entry) => entry.data || []) : [];

  const labels =
    allData && Array.isArray(allData)
      ? allData.map((entry) =>
          entry && entry.time_stamp ? new Date(entry.time_stamp * 1000).toLocaleTimeString() : ''
        )
      : [];

  const values =
    allData && Array.isArray(allData) ? allData.map((entry) => (entry && entry.value ? entry.value : null)) : [];

  if (values.length < 1) {
    return '';
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: metricName,
        data: values,
        borderColor: '#45b649',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = (theme) => ({
    scales: {
      x: {
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
      y: {
        min: 0,
        max: 20,
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
          },
          color: theme === 'dark' ? '#fff' : '#000',
        },
      },
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        ctx.save();
        ctx.fillStyle = theme === 'dark' ? '#000' : '#fff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    },
  });

  return (
    // eslint-disable-next-line react/jsx-key
    <Row>
      <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
        <Card key={metricName}>
          <CardHeader className="p-2 border-bottom">
            <strong>{metricName}</strong>
          </CardHeader>
          <CardBody className="p-2">
            <Line ref={chartRef} data={data} options={options} height={70} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
  */
};

export default MetricChart;
