import React, { createContext, useState, useEffect } from 'react';
//@ts-ignore
const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [sidebarStatus, setSidebarStatus] = useState(localStorage.getItem('sidebarStatus') || 'open');

  useEffect(() => {
    localStorage.setItem('sidebarStatus', sidebarStatus);
  }, [sidebarStatus]);

  const toggleSidebar = () => {
    setSidebarStatus((prevSidebar) => (prevSidebar === 'open' ? 'close' : 'open'));
  };

  return (
    <SidebarContext.Provider value={{ sidebarStatus, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;