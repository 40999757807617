import * as React from 'react';
import '../../components/InfoSection.css';
import './Product.css';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Page from '@app/components/Page';
import ProductApi from '@app/api/product-api/product.actions';
import { useCart } from '@app/contexts/cart.context';
import ThemeContext from '@app/providers/contexts/ThemeContext';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ProductView: React.FC = () => {
  const { dispatch } = useCart();
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [ currentProduct, setCurrentProduct] = React.useState([]);
  const { addToCart } = useCart();

  const getIdFromUrl = () => {
    const url = window.location.pathname;
    const matches = url.match(/\/products\/([^/]+)/);
    return matches ? matches[1] : null;
  };

  const productId = getIdFromUrl();

  const fetchProduct = async () => {
    try {
      const productTest = await ProductApi.get(productId);
      setCurrentProduct(productTest);
    }
    catch (error) {
      console.log(error);
    }
  };
  
  React.useEffect(() => {
    fetchProduct();
  }, []); 

  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [totalPrice, setTotalPrice] = React.useState(() => {
    //@ts-ignore
    if (currentProduct && currentProduct.prices && currentProduct.prices.length > 0) {
      //@ts-ignore
      return currentProduct.prices[0].price;
    }
    return 0;
  });

  const handleOptionChange = (optionId, name, currency, price) => {
    setSelectedOptions((prev) => {
      const newOptions = {
        ...prev,
        [optionId]: { price, name, currency }
      };
      calculateTotalPrice(newOptions);
      return newOptions;
    });
  };

  const calculateTotalPrice = (options) => {
    //@ts-ignore
    const optionsTotal = Object.values(options).reduce((sum, option) => sum + option.price, 0);
    //@ts-ignore
    setTotalPrice(currentProduct.prices[0].price + optionsTotal);
  };

  const addToCartAction = async () => {
    const productToCart = {
      //@ts-ignore
      id: currentProduct.id,
      selectedOptions: Object.keys(selectedOptions || {}),
      totalPrice: totalPrice,
    };
  
    try {
      addToCart(productToCart);
      console.log("Item added to cart successfully");
    } catch (error) { 
      console.error("Failed to add item to cart:", error);
    }
  };

  return (
    <Page
      tag="div"
      title=""
      className={`cr-page px-3 pt-2 products-view ${theme}`}
      breadcrumbs={
        [
          { name: <a href="/products">Products</a>, active: true }, 
          //@ts-ignore
          { name: currentProduct.name, active: true }

        ]}
    >
      <div className="products-card-wrapper user-product">
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col lg={6} md={6} sm={6} xs={12} className="mb-3">
                  <div className="user-products">
                    <div className="p-3">
                      <div className="product-image-view mb-3">
                        <img src={
                          //@ts-ignore
                          currentProduct.image_url
                        } alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6} xs={12} className="mb-3">
                  <div className="summary entry-summary">
                    <h1 className="product_title entry-title">{
                      //@ts-ignore
                      currentProduct.name
                    }</h1>
                    <div className="product-sku">
                      SKU: <span>N/A</span>
                    </div>
                    <div className="product-category mb-3">
                      Category: <span>hardware</span>
                    </div>
                    <div className="product-price rounded">
                      <h4>
                        Base Price: <span>{
                        //@ts-ignore
                        currentProduct.prices?.[0]?.price || 'N/A'}</span> {currentProduct.prices?.[0]?.currency || ''}
                      </h4>
                      <h4>Total Price: <span>{totalPrice}</span> {
                      //@ts-ignore
                      currentProduct.prices?.[0]?.currency}</h4>
                    </div>
                    <div className="product-description">
                      <p>{
                      //@ts-ignore
                      currentProduct.description}</p>
                    </div>
                    <div>
                    <div className="product-options">
                      {
                      //@ts-ignore
                      currentProduct.options && currentProduct.options.length > 0 && (
                        <>
                          <span><strong>Options:</strong></span>
                          {
                          //@ts-ignore
                          currentProduct?.options?.map((option) => (
                            <div key={option.id}>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  id={option.name + '_' + option.id}
                                  className="form-check-input"
                                  value={option.prices[0].price}
                                  onChange={(e) =>
                                    handleOptionChange(
                                      option.id,
                                      option.name,
                                      option.prices[0].currency,
                                      e.target.checked ? option.prices[0].price : 0
                                    )
                                  }
                                />
                                <label className="form-check-label">{option.name} (+${option.prices[0].price} {option.prices[0].currency})</label>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>  
                  </div>
                    <div className="w-25 mt-3">                
                      <Button className="btn w-100 ethica-button-green" onClick={addToCartAction}>Add to cart</Button>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={12} md={12} sm={12} xs={12} className="mb-3 mt-3">
                  <div className="product-info p-3 rounded">
                    <table>
                      <tbody>
                      <tr>
                        <td width="202" className="product-info-title">CPU</td>
                        <td className="product-info-text">Intel Celeron Bay Trail SOC Processor&nbsp; J1900 Quad Core Processor
                          2.0GHz&ZeroWidthSpace;, 2MB Cache
                        </td>
                      </tr>
                      <tr>
                        <td className="product-info-title">AES-NI</td>
                        <td className="product-info-text">No</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">RAM</td>
                        <td className="product-info-text">4 GB DDR3 RAM</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Graphics</td>
                        <td className="product-info-text">Intel® HD Graphics</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Video Port</td>
                        <td className="product-info-text">VGA</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Hard Drive</td>
                        <td className="product-info-text">16G MSATA SSD</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Ethernet Ports</td>
                        <td className="product-info-text">4 x Intel I211-AT Gigabit LAN</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">USB Ports</td>
                        <td className="product-info-text">1 x USB 3.0, 3 x USB 2.0</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Serial Ports</td>
                        <td className="product-info-text">None</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">WiFi</td>
                        <td className="product-info-text">None</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Mobile Broadband</td>
                        <td className="product-info-text">None</td>
                      </tr>
                      <tr>
                        <td className="product-info-title">Antenna Ports</td>
                        <td className="product-info-text">None</td>
                      </tr>
                      </tbody>
                    </table>
                    {product.description}
                  </div>
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>
    </Page>
);
};