import axios from 'axios';
import UserSession from '@app/common/user-session';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://qa.api.ngx.ethica.partners/v2';

// Command types as specified by the manager
export enum LinkCommandType {
  Shutdown = 'shutdown',
  Reboot = 'reboot',
  Stow = 'stow',
  Unstow = 'unstow'
}

// Mapping from string command types to properly capitalized API values
const commandValueMap = {
  [LinkCommandType.Shutdown]: 'Shutdown',
  [LinkCommandType.Reboot]: 'Reboot',
  [LinkCommandType.Stow]: 'Stow',
  [LinkCommandType.Unstow]: 'Unstow'
};

export interface LinkCommandResponse {
  request_id: string;
  status: number;
}

export const LinkService = {
  /**
   * Send a command to a link
   * @param siteId - The ID of the site
   * @param linkId - The ID of the link
   * @param command - The command to send (shutdown, reboot, stow, unstow)
   * @returns Promise with the command response
   */
  sendLinkCommand: async (
    siteId: string, 
    linkId: string, 
    command: LinkCommandType
  ): Promise<LinkCommandResponse> => {
    try {
      // Get authentication token
      const token = UserSession.getToken();
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }
      
      // Get properly capitalized command value for the API
      const commandValue = commandValueMap[command];
      
      // Use the correct endpoint format with site ID
      const url = `${API_BASE_URL}/sites/${siteId}/links/${linkId}/commands`;
      
      // Send the command with the proper capitalized string value
      const response = await axios.post(
        url,
        { command: commandValue },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      return response.data;
    } catch (error: any) {
      // Just throw the original error without trying alternative endpoints
      throw error;
    }
  }
}; 