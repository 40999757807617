import * as React from 'react';
import axios from 'axios';
import replace from "buffer-replace";
import { saveAs } from "file-saver";
import { DownloadIcon } from '@patternfly/react-icons';
import { Spinner, Modal, ModalVariant, Button } from '@patternfly/react-core';
import './DownloadImage.css';
import { MdCloudDownload } from 'react-icons/md';
import UserSession from '@app/common/user-session';
import ApiGateway from '@app/api/api-gateway/api-gateway';
import '../app.css';

class DownloadImage extends React.Component<any, any> {
  private _isMounted: boolean = false;

  constructor(props) {
    super(props);
    
    this.state = {
      imageType: this.props.imageType,
      downloading: false,
      error: "",
      isModalOpen: false,
      downloadType: null,
      pollInterval: null,
      downloadStatus: ''
    }
  }

  handleDownload = async () => {
    if (this.props.imageType === "site") {
      this.setState({ isModalOpen: true });
      return;
    }

    await this.startDownload("CAImage");
  }

  startDownload = async (type) => {
    const { imageId, imageType } = this.props;
    
    try {
      this.setState({ downloadType: type, isModalOpen: false });

      if (type === "CAFlex") {
        await new Promise<void>(resolve => {
          this.setState({ downloading: true }, resolve);
        });
        try {
          await this.startCAFlexDownload(imageId);
        } catch(error: any) {
          if (this._isMounted) {
            this.setState({ 
              downloading: false, 
              error: `Unable to start CAFlex download: ${error?.response?.data?.message || error?.message || 'Network error'}`
            });
          }
        }
        return;
      }

      switch (imageType) {
        case "server": 
          await new Promise<void>(resolve => {
            this.setState({ downloading: true }, resolve);
          });
          try {
            await this.generateServerImage(imageId);
            if (this._isMounted) {
              this.setState({ downloading: false });
            }
          } catch(error) {
            if (this._isMounted) {
              this.setState({ downloading: false, error: "Unable to download image" });
            }
          }
          break;
        case "site": 
          await new Promise<void>(resolve => {
            this.setState({ downloading: true }, resolve);
          });
          try {
            await this.generateSiteImage(imageId);
            if (this._isMounted) {
              this.setState({ downloading: false });
            }
          } catch(error) {
            if (this._isMounted) {
              this.setState({ downloading: false, error: "Unable to download image" });
            }
          }
          break;
        case "controller": 
          await new Promise<void>(resolve => {
            this.setState({ downloading: true }, resolve);
          });
          try {
            await this.downloadControllerPackage(imageId);
            if (this._isMounted) {
              this.setState({ downloading: false });
            }
          } catch(error) {
            console.log(error);
            if (this._isMounted) {
              this.setState({ downloading: false, error: "No image available" });
            }
          }
          break;
        default: 
          if (this._isMounted) {
            this.setState({ error: "Unsupported image type for download" });
          }
      }
    } catch (error: any) {
      if (this._isMounted) {
        this.setState({ 
          downloading: false, 
          error: error?.message || "An unexpected error occurred"
        });
      }
    }
  }

  startCAFlexDownload = async (siteId) => {
    try {
      // Set downloading state first
      if (!this._isMounted) return;
      await new Promise<void>(resolve => {
        this.setState({ downloading: true }, resolve);
      });

      // Initial API call
      const response = await ApiGateway.get(`/sites/${siteId}/packages`);
      
      // Set initial status
      if (!this._isMounted) return;
      await new Promise<void>(resolve => {
        this.setState({ downloadStatus: response.data.status }, resolve);
      });
      
      const pollInterval = setInterval(async () => {
        if (!this._isMounted) {
          clearInterval(pollInterval);
          return;
        }

        try {
          const statusResponse = await ApiGateway.get(`/sites/${siteId}/packages`);
          const status = statusResponse.data.status;
          
          if (!this._isMounted) {
            clearInterval(pollInterval);
            return;
          }

          // Update status
          await new Promise<void>(resolve => {
            this.setState({ downloadStatus: status }, resolve);
          });

          if (status === "Completed") {
            clearInterval(pollInterval);
            const packageUrl = statusResponse.data.package?.url;
            if (!packageUrl) {
              throw new Error('Package URL not found in response');
            }
            
            // Handle the download
            const link = document.createElement('a');
            link.href = packageUrl;
            link.download = `caflex-${siteId.replace(/-/g, "")}.deb`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            // Clear the status
            if (this._isMounted) {
              await new Promise<void>(resolve => {
                this.setState({ 
                  downloading: false, 
                  downloadStatus: '',
                  pollInterval: null
                }, resolve);
              });
            }
          }
        } catch (error: any) {
          clearInterval(pollInterval);
          if (this._isMounted) {
            await new Promise<void>(resolve => {
              this.setState({ 
                downloading: false, 
                error: `Error downloading CAFlex package: ${error?.response?.data?.message || error?.message || 'Network error'}`,
                pollInterval: null,
                downloadStatus: ''
              }, resolve);
            });
          }
        }
      }, 2000);

      if (this._isMounted) {
        await new Promise<void>(resolve => {
          this.setState({ pollInterval: pollInterval }, resolve);
        });
      }
    } catch (error: any) {
      if (this._isMounted) {
        await new Promise<void>(resolve => {
          this.setState({ 
            downloading: false, 
            error: `Error starting CAFlex download: ${error?.response?.data?.message || error?.message || 'Network error'}`,
            downloadStatus: ''
          }, resolve);
        });
      }
      throw error;
    }
  }

  downloadControllerPackage = async (controllerId: string) => {
    try {
      const token = UserSession.getParam('token');
      
      if (!token) {
        throw new Error('No authentication token available');
      }
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_USER_MGMT_API_BASE_URL}/controllers/${controllerId}/packages`,
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      // Get filename from content-disposition header if available
      const contentDisposition = response.headers['content-disposition'];
      let filename = `controller-${controllerId.replace(/-/g, "")}.deb`;
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }
      const blob = new Blob([response.data], { 
        type: response.headers['content-type'] || 'application/x-deb' 
      });
      saveAs(blob, filename);
      
      return Promise.resolve();
    } catch (error) {
      console.error('Error downloading controller package:', error);
      throw error;
    }
  };

  generateServerImage = async (id) => {
    const { data: raw } = await axios.get(process.env.REACT_APP_SERVER_IMAGE_URL!, {
      responseType: "arraybuffer",
    });
    const theId = id.replace(/-/g, "");
    const replaced = replace(raw, "ethica_machine_id_placeholder___", theId);
    const blob = new Blob([replaced], { type: "charset=binary" });
    saveAs(blob, `server-${theId}.img`);
    return Promise.resolve();
  };

  generateSiteImage = async (id) => {
    const { data: raw } = await axios.get(process.env.REACT_APP_CPE_IMAGE_URL!, {
      responseType: "arraybuffer",
    });
    const theId = id.replace(/-/g, "");
    const replaced = replace(raw, "ethica_machine_id_placeholder___", theId);
    const blob = new Blob([replaced], { type: "charset=binary" });
    saveAs(blob, `cpe-${theId}.img`);
    return Promise.resolve();
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Clear any polling interval when component unmounts
    if (this.state.pollInterval) {
      clearInterval(this.state.pollInterval);
    }
  }

  render() {
    const { imageType, downloading, error, isModalOpen, downloadStatus } = this.state;
    const controllerImageUrl = process.env.REACT_APP_CONTROLLER_IMAGE_URL;
    const DownloadIconComponent = MdCloudDownload as unknown as React.FC;
    const controllerIcon = (
      <div>
        <a onClick={this.handleDownload} data-testid="download-link" style={{ cursor: 'pointer' }}>
          <DownloadIconComponent />
        </a>
      </div>
    );
    const serverIcon = (
      <div>
        <a onClick={this.handleDownload} data-testid="download-link" style={{ cursor: 'pointer' }}>
          <DownloadIconComponent />
        </a>
      </div>
    );
    const spinner = <Spinner size="md" />;

    return (
      <div>
        {error === "" ?
          imageType === "controller" ? 
            downloading ? spinner : controllerIcon
          : 
            downloading ? (
              <div>
                <Spinner size="md" />
                {downloadStatus && <div data-testid="download-status" className="download-status">{downloadStatus}</div>}
              </div>
            ) : serverIcon
        : 
          <div>{error}</div>
        }

        <Modal
          variant={ModalVariant.small}
          title="Select Download Type"
          isOpen={isModalOpen}
          onClose={this.handleModalClose}
          className="modal-fade-in"
          actions={[
            <Button 
              key="caimage" 
              variant="primary" 
              onClick={() => this.startDownload("CAImage")}
              className="ethica-button-green"
              data-testid="caimage-button"
            >
              CAImage
            </Button>,
            <Button 
              key="caflex" 
              variant="primary" 
              onClick={() => this.startDownload("CAFlex")}
              className="ethica-button-green"
              data-testid="caflex-button"
            >
              CAFlex
            </Button>,
            <Button 
              key="cancel" 
              variant="primary" 
              onClick={this.handleModalClose}
              className="ethica-button-cancel"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          ]}
        >
          Please select the type of image to download
        </Modal>
      </div>
    );
  }
}

export { DownloadImage };

