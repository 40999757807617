import React from 'react';
import { useCart } from '@app/contexts/cart.context';
import Page from '@app/components/Page';
import { Card, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import demoImage from '@app/assets/images/Edge1002.png';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

const CartPage = () => {
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const { cart, removeItemFromCart, removeCart } = useCart();

  const isCartEmpty = !cart || !cart.items || cart.items.length === 0;
  console.log('CartPage.tsx isCartEmpty',isCartEmpty);
  console.log('CartPage.tsx cart',cart);

  const removeFromCart = async (id) => {
    try {
      removeItemFromCart(id);
      //@ts-ignore
      console.log("Item added to cart successfully:", response);
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  const clearCart = async () => {
    try {
      removeCart(cart.id);
      //@ts-ignore
      console.log("Item added to cart successfully:", response);
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  const getCartTotalPrice = () => {
    // let cartTotalPrice = 0;
    // if (cart.length > 0) {
    //   return cart.reduce((total, item) => total + item.totalPrice, 0);
    // }
    // return cartTotalPrice;
  }

  return (
    <React.Fragment>
      <Page
        tag="div"
        className={`cr-page cart-page px-3 pt-2 ${theme}`} 
        title="" 
        breadcrumbs={[{ name: 'Cart', active: true }]}
      >
        <div className={`disable-wrapper`}></div>
          {isCartEmpty ? (
            <p>Cart is empty</p>
          ) : (
            <>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="cart-card-wrapper">
                      <h3 className="w-100">Cart items</h3>
                      {cart.items.map((item, index) => (
                        <Row key={item.product.id + '_' + index} className="w-100">
                          <Col>
                            <div className="d-flex align-items-center justify-content-between w-100 border-bottom">
                              <Col lg={1} md={1} sm={1} xs={1} className="mb-3">
                                <img src={demoImage} alt={item.product.name} />
                              </Col>
                              <Col lg={5} md={5} sm={12} xs={12} className="mb-3">
                                <div>
                                  <Link to={`/products/${item.product.id}`}>
                                    <strong>{item.product.name}</strong>
                                  </Link>
                                </div>
                                <div>
                                  <div><b>Product price:</b> {item.product.prices ? item.product.prices[0].price + ' ' + item.product.prices[0].currency : item.product.prices[0].price ? item.product.prices[0].price + ' ' + item.product.prices[0].currency : 0}</div>

                                  {item.product.selectedOptions && (
                                    <>
                                      {Object.entries(item.product.selectedOptions).map(([key, option], index) => (
                                        <div key={key}>
                                          <b>{
                                          //@ts-ignore
                                          option.name}:</b> +{option.price} {option.currency}
                                        </div>
                                      ))}
                                    </>                        
                                  )}
                                </div>
                              </Col>
                              <Col lg={3} md={3} sm={12} xs={12} className="mb-3">
                              <b>Total product price:</b> {item.product.totalPrice} {item.product.prices ? item.product.prices[0].currency : item.product.price[0].currency}
                              </Col>
                              <Col lg={2} md={2} sm={12} xs={12} className="mb-3 text-end">
                                <button className="btn ethica-button-black" onClick={() => removeFromCart(item.id)}>Remove</button>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      ))}
                      <div className="w-100 text-end me-4">
                        <h4>Total cart price: {getCartTotalPrice()} CND</h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="h-100">
                    <Row className="mb-3">
                      <Col>
                        <div className="cart-card-wrapper">
                          <h3 className="w-100">Payment</h3>                        
                          <select className="form-select" aria-label="Default select payment">
                            <option value="3">Process with Stripe</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="cart-card-wrapper">
                          <h3 className="w-100">Delivery</h3>                        
                          <select className="form-select" aria-label="Default select delivery">
                            <option value="1">Express (UPS)</option>
                            <option value="2">Expedited (UPS)</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="w-100 text-end">
              <button onClick={clearCart} className="btn ethica-button-green ml-auto">
                Clear Cart
              </button>
            </div>
          </>
        )}
      </Page>
    </React.Fragment>
  );
};

export default CartPage;
