import * as React from 'react';
import { textInputRequiredOnly } from '@app/lib/validator';
import { IconHeading } from '@app/components/IconHeading';
import { Spacer } from '@app/components/Spacer';
import { ClusterIcon, CogIcon, ConnectedIcon, ExclamationCircleIcon, NetworkIcon, PlusIcon, TopologyIcon, TrashIcon } from '@patternfly/react-icons';
import { Checkbox, Flex, FlexItem, FormGroup, Radio, Select, SelectVariant, Spinner, TextInput } from '@patternfly/react-core';
import { textInputRequiredOnly, textInputValidate, isValidNumber, isValidVlan, isValidIP, ipInSubnet, isWithinRange, isLengthWithinRange } from '@app/lib/validator';
import { FormGroupSpacer } from '../../../../components/forms/pf/form-group-spacer.component';
import { nextIp, lastIp, isUsableAddress } from '@app/lib/functions';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';

const LanSettings = ({
  dataLoading,
  deleteLan,
  dmzCount,
  handleChange,
  handleCheckboxChange,
  handleLanIpChange,
  handleLanEnabled,
  handleWifiRadioChange,
  lanConfig,
  linkInterfaces,
  netmaskOptions,
  wifiChannelOptions,
  onLanInterfaceSelectClick,
  onSelectClick,
  wanEnabled,
  wanName
}) => {
  const isSiteWan = wanName !== '' && wanEnabled;
  const [selectState, setSelectState] = React.useState({
    isLanInterfaceSelectOpen: false,
    isNetmaskOpen: false,
    isWifiChannelOpen: false
  });

  // LAN Config validations
  const lanSegmentIp4IsValid =
    isValidIP(lanConfig.lanSegmentIp4) && isUsableAddress(lanConfig.lanSegmentIp4, lanConfig.lanCidrIp4);
  const dnsServer1IsValid = isValidIP(lanConfig.dnsServer1);
  const dnsServer2IsValid = isValidIP(lanConfig.dnsServer2);
  const lanSegmentIp4ErrorState = textInputValidate(
    lanConfig.lanSegmentIp4,
    true,
    lanSegmentIp4IsValid,
    'Invalid IP Address'
  );
  const dnsServer1ErrorState = textInputValidate(lanConfig.dnsServer1, true, dnsServer1IsValid, 'Invalid IP Address');
  const dnsServer2ErrorState = textInputValidate(lanConfig.dnsServer2, true, dnsServer2IsValid, 'Invalid IP Address');

  // Misc field validations
  let vlanErrorState = lanConfig?.vlanId === '' || isValidVlan(lanConfig?.vlanId) ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a positive integer between 2-4094' }
  
  const ip4DMZIsValid = isValidIP(lanConfig.ip4DMZ);
  let lanSubnet = lanConfig.lanSegmentIp4 + lanConfig.lanCidrIp4;
  let ip4DMZErrorState = textInputValidate(lanConfig.ip4DMZ, lanConfig.isDMZ, ip4DMZIsValid, 'Invalid IP Address');
  let dmzExampleIp = '';

  if (lanConfig.isDMZ) {
    dmzExampleIp = nextIp(lanSubnet, 2);
  }

  // TODO: Refactor Validator to better handle multi check validations for the PF4 components
  if (ip4DMZErrorState.validated === 'default') {
    const ip4DMZIsInSubnet = ipInSubnet(lanConfig.ip4DMZ, lanSubnet);
    ip4DMZErrorState = textInputValidate(
      lanConfig.ip4DMZ,
      lanConfig.isDMZ,
      ip4DMZIsInSubnet,
      'Address not in LAN Subnet'
    );

    if (ip4DMZErrorState.validated === 'default') {
      const dmzIpSameAsLanIp = lanConfig.lanSegmentIp4 === lanConfig.ip4DMZ;
      ip4DMZErrorState = textInputValidate(
        lanConfig.ip4DMZ,
        lanConfig.isDMZ,
        !dmzIpSameAsLanIp,
        'Cannot be same as LAN IP'
      );
    }
  }

  const dhcpStartIsValid = lanConfig.isDhcpRequired && isValidIP(lanConfig.dhcpRangeStart);
  const dhcpEndIsValid = lanConfig.isDhcpRequired && isValidIP(lanConfig.dhcpRangeEnd);
  const dhcpDefaultLeaseIsValid = lanConfig.isDhcpRequired && isValidNumber(lanConfig.dhcpDefaultLease) && isWithinRange(lanConfig.dhcpDefaultLease, 1, 31535999);
  const dhcpMaxLeaseIsValid = lanConfig.isDhcpRequired && isValidNumber(lanConfig.dhcpMaxLease) && isWithinRange(lanConfig.dhcpMaxLease, 1, 31535999);
  let dhcpStartErrorState = textInputValidate(
    lanConfig.dhcpRangeStart,
    lanConfig.isDhcpRequired,
    dhcpStartIsValid,
    'Invalid IP Address'
  );
  let dhcpEndErrorState = textInputValidate(
    lanConfig.dhcpRangeEnd,
    lanConfig.isDhcpRequired,
    dhcpEndIsValid,
    'Invalid IP Address'
  );
  const dhcpDefaultLeaseErrorState = textInputValidate(
    lanConfig.dhcpDefaultLease,
    lanConfig.isDhcpRequired,
    dhcpDefaultLeaseIsValid,
    'Must be a number between 1 and 31535999'
  );
  const dhcpMaxLeaseErrorState = textInputValidate(
    lanConfig.dhcpMaxLease,
    lanConfig.isDhcpRequired,
    dhcpMaxLeaseIsValid,
    'Must be a number between 1 and 31535999'
  );

  if (dhcpStartErrorState.validated === 'default') {
    const dhcpStartInSubnet = ipInSubnet(lanConfig.dhcpRangeStart, lanSubnet);
    dhcpStartErrorState = textInputValidate(
      lanConfig.dhcpRangeStart,
      lanConfig.isDhcpRequired,
      dhcpStartInSubnet,
      'Address not in LAN Subnet'
    );
  }
  if (dhcpEndErrorState.validated === 'default') {
    const dhcpEndInSubnet = ipInSubnet(lanConfig.dhcpRangeEnd, lanSubnet);
    dhcpEndErrorState = textInputValidate(
      lanConfig.dhcpRangeEnd,
      lanConfig.isDhcpRequired,
      dhcpEndInSubnet,
      'Address not in LAN Subnet'
    );
  }

  // WiFi Settings validations
  const apSSIDIsValid = isLengthWithinRange(lanConfig.apSSID, 1, 32);
  const apPasswordIsValid = isLengthWithinRange(lanConfig.apPassword, 8, 63);
  const apChannelIsValid = isValidNumber(lanConfig.apChannel);
  const apSSIDErrorState = textInputValidate(
    lanConfig.apSSID,
    lanConfig.isAP,
    apSSIDIsValid,
    'Length must be between 1-32 characters'
  );
  const apPasswordErrorState = textInputValidate(
    lanConfig.apPassword,
    lanConfig.isAP,
    apPasswordIsValid,
    'Length must be between 8-63 characters'
  );
  const apChannelErrorState = lanConfig.isAP && apChannelIsValid ? { errorText: '', validated: 'default' } : { errorText: 'Must be a number(0 = Auto)', validated: 'error' };

  const ap5SSIDIsValid = isLengthWithinRange(lanConfig.ap5SSID, 1, 32);
  const ap5PasswordIsValid = isLengthWithinRange(lanConfig.ap5Password, 8, 63);
  const ap5ChannelIsValid = isValidNumber(lanConfig.ap5Channel);
  const ap5SSIDErrorState = textInputValidate(
    lanConfig.ap5SSID,
    lanConfig.isAP,
    ap5SSIDIsValid,
    'Length must be between 1-32 characters'
  );
  const ap5PasswordErrorState = textInputValidate(
    lanConfig.ap5Password,
    lanConfig.isAP,
    ap5PasswordIsValid,
    'Length must be between 8-63 characters'
  );
  const ap5ChannelErrorState = lanConfig.isAP && ap5ChannelIsValid ? { errorText: '', validated: 'default' } : { errorText: 'Must be a number(0 = Auto)', validated: 'error' };

  return (
    <>
      <Row className="lan-item">
        <Col>
          <Card isCompact className="lan-settings-form-row">
            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
              <div className="w-100 d-flex justify-content-between">
                <IconHeading 
                  icon={<NetworkIcon className="icon-medium" />} 
                  heading={lanConfig.interfaceName?.toString()}
                />
              </div>
            </CardHeader>
            <CardBody>
              {dataLoading ? (
                <Spinner size="lg" />
              ) : (
                <div>
                  <FormGroup
                    label="LAN Interface(s)"
                    className="site-form-label form-item"
                    fieldId="lanInterface"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={textInputRequiredOnly(lanConfig.interfaceName)}
                  >
                    <Select
                      maxHeight={300}
                      variant={SelectVariant.typeaheadMulti}
                      aria-label="Select Interface"
                      validated={textInputRequiredOnly(lanConfig.interfaceName)}
                      onToggle={(e) => setSelectState({ ...selectState, isLanInterfaceSelectOpen: e })}
                      onSelect={(event, selection, isPlaceholder) => {
                        onLanInterfaceSelectClick(event, selection, isPlaceholder, lanConfig.id);
                        setSelectState({
                          ...selectState,
                          isLanInterfaceSelectOpen: !selectState.isLanInterfaceSelectOpen,
                        });
                      }}
                      selections={lanConfig.interfaceName}
                      isOpen={selectState.isLanInterfaceSelectOpen}
                      isDisabled={false}
                      className="form-control"
                    >
                      {linkInterfaces}
                    </Select>
                  </FormGroup>

                  <FormGroup
                    label="Netmask"
                    isRequired
                    fieldId="netmask-toggle"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={textInputRequiredOnly(lanConfig.lanCidrIp4)}
                    className="form-item"
                  >
                    <Input
                      type="select" 
                      value={lanConfig.lanCidrIp4 || ''}
                      onChange={(event, selection, isPlaceholder) => {
                        onSelectClick(event, event.target.value, isPlaceholder, lanConfig.id, 'lanCidrIp4', 'isNetmaskOpen');
                        setSelectState({ ...selectState, isNetmaskOpen: false });
                      }}
                      onClick={(e) => setSelectState({ ...selectState, isNetmaskOpen: !selectState.isNetmaskOpen })}
                      open={selectState.isNetmaskOpen} 
                    >
                      {netmaskOptions &&
                      <>
                        <option key="default" value="">Select Netmask...</option>
                        {netmaskOptions.map((netmaskOption, index) => (
                            <option key={netmaskOption.key || index} value={netmaskOption.props.value}>
                                {netmaskOption.props.value}
                            </option>
                        ))}
                      </>
                      }
                    </Input>
                  </FormGroup>
                    
                  <FormGroup
                    label="LAN IP Address"
                    isRequired
                    fieldId="lanSegmentIp4"
                    helperTextInvalid={lanSegmentIp4ErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lanSegmentIp4ErrorState.validated}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      validated={lanSegmentIp4ErrorState.validated}
                      isRequired
                      type="text"
                      id="lanSegmentIp4"
                      name="lanSegmentIp4"
                      placeholder="192.168.0.1"
                      value={lanConfig.lanSegmentIp4}
                      onChange={(e) => handleLanIpChange(lanConfig.id, e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>
                  
                  <FormGroup
                    label="Primary DNS Server"
                    isRequired
                    fieldId="dnsServer1"
                    helperTextInvalid={dnsServer1ErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={dnsServer1ErrorState.validated}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      validated={dnsServer1ErrorState.validated}
                      isRequired
                      type="text"
                      id="dnsServer1"
                      name="dnsServer1"
                      placeholder="1.1.1.1"
                      value={lanConfig.dnsServer1}
                      onChange={(e) => handleChange(lanConfig.id, 'dnsServer1', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>
                  
                  <FormGroup
                    label="Secondary DNS Server"
                    isRequired
                    fieldId="dnsServer2"
                    helperTextInvalid={dnsServer2ErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={dnsServer2ErrorState.validated}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      validated={dnsServer2ErrorState.validated}
                      isRequired
                      type="text"
                      id="dnsServer2"
                      name="dnsServer2"
                      placeholder="8.8.8.8"
                      value={lanConfig.dnsServer2}
                      onChange={(e) => handleChange(lanConfig.id, 'dnsServer2', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>
                </div>
              )}
              </CardBody>
            </Card>
          </Col>

          <Col>
          <Card isCompact className="lan-settings-form-row">
            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
              <div className="w-100 d-flex justify-content-between">
                <div className="w-100">
                  <IconHeading
                    id={lanConfig.id}
                    icon={<ClusterIcon className="icon-medium" />}
                    heading="DHCP"
                    showEnable={true}
                    handleEnable={(e) => handleLanEnabled(lanConfig.id, lanConfig.isDhcpRequired, 'isDhcpRequired', e)}
                    enabled={lanConfig.isDhcpRequired}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {dataLoading ? (
                <Spinner size="lg" />
              ) : (
                <div>
                  <FormGroup
                    label="IP Range Start"
                    isRequired={lanConfig.isDhcpRequired}
                    fieldId="dhcpRangeStart"
                    helperTextInvalid={dhcpStartErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lanConfig.isDhcpRequired ? dhcpStartErrorState.validated : 'default'}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      isDisabled={!lanConfig.isDhcpRequired}
                      isRequired={lanConfig.isDhcpRequired}
                      type="text"
                      validated={lanConfig.isDhcpRequired ? dhcpStartErrorState.validated : 'default'}
                      id="dhcpRangeStart"
                      name="dhcpRangeStart"
                      placeholder="192.168.0.9"
                      value={lanConfig.dhcpRangeStart || ''}
                      onChange={(e) => handleChange(lanConfig.id, 'dhcpRangeStart', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>

                  <FormGroup
                    label="IP Range End"
                    isRequired={lanConfig.isDhcpRequired}
                    fieldId="dhcpRangeEnd"
                    helperTextInvalid={dhcpEndErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lanConfig.isDhcpRequired ? dhcpEndErrorState.validated : 'default'}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      isDisabled={!lanConfig.isDhcpRequired}
                      isRequired={lanConfig.isDhcpRequired}
                      type="text"
                      validated={lanConfig.isDhcpRequired ? dhcpEndErrorState.validated : 'default'}
                      id="dhcpRangeEnd"
                      name="dhcpRangeEnd"
                      placeholder="192.168.0.254"
                      value={lanConfig.dhcpRangeEnd || ''}
                      onChange={(e) => handleChange(lanConfig.id, 'dhcpRangeEnd', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Default Lease Time (s)"
                    isRequired={lanConfig.isDhcpRequired}
                    fieldId="dhcpDefaultLease"
                    helperTextInvalid={dhcpDefaultLeaseErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lanConfig.isDhcpRequired ? dhcpDefaultLeaseErrorState.validated : 'default'}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      isDisabled={!lanConfig.isDhcpRequired}
                      isRequired={lanConfig.isDhcpRequired}
                      type="text"
                      validated={lanConfig.isDhcpRequired ? dhcpDefaultLeaseErrorState.validated : 'default'}
                      id="dhcpDefaultLease"
                      name="dhcpDefaultLease"
                      placeholder=""
                      value={lanConfig.dhcpDefaultLease}
                      onChange={(e) => handleChange(lanConfig.id, 'dhcpDefaultLease', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Max. Lease Time (s)"
                    isRequired={lanConfig.isDhcpRequired}
                    fieldId="dhcpMaxLease"
                    helperTextInvalid={dhcpMaxLeaseErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lanConfig.isDhcpRequired ? dhcpMaxLeaseErrorState.validated : 'default'}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      isDisabled={!lanConfig.isDhcpRequired}
                      isRequired={lanConfig.isDhcpRequired}
                      type="text"
                      validated={lanConfig.isDhcpRequired ? dhcpMaxLeaseErrorState.validated : 'default'}
                      id="dhcpMaxLease"
                      name="dhcpMaxLease"
                      placeholder=""
                      value={lanConfig.dhcpMaxLease}
                      onChange={(e) => handleChange(lanConfig.id, 'dhcpMaxLease', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>
                </div>
              )}
            </CardBody>
          </Card>
          </Col>

          <Col>
          <Card isCompact className="lan-settings-form-row">
            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
              <div className="w-100 d-flex justify-content-between">
                <div className="w-100">
                  <IconHeading
                    id={lanConfig.id}
                    icon={<ConnectedIcon className="icon-medium" />}
                    heading="WiFi Settings"
                    showEnable={true}
                    handleEnable={(e) => handleLanEnabled(lanConfig.id, lanConfig.isAP, 'isAP', e)}
                    enabled={lanConfig.isAP}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {dataLoading ? (
                <Spinner size="lg" />
              ) : (
                <div>
                  {lanConfig.apMode === 'mode_5' ? (
                    <div>
                      <FormGroup
                        label="5 GHz SSID"
                        isRequired={lanConfig.isAP}
                        fieldId="ap5SSID"
                        helperTextInvalid={ap5SSIDErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? ap5SSIDErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          isRequired={lanConfig.isAP}
                          type="text"
                          id="ap5SSID"
                          name="ap5SSID"
                          placeholder="Guest WiFi"
                          isDisabled={!lanConfig.isAP}
                          value={lanConfig.ap5SSID || ''}
                          onChange={(e) => handleChange(lanConfig.id, 'ap5SSID', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Password"
                        isRequired={lanConfig.isAP}
                        fieldId="ap5Password"
                        helperTextInvalid={ap5PasswordErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? ap5PasswordErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          isRequired={lanConfig.isAP}
                          type="text"
                          id="ap5Password"
                          name="ap5Password"
                          placeholder=""
                          isDisabled={!lanConfig.isAP}
                          value={lanConfig.ap5Password || ''}
                          onChange={(e) => handleChange(lanConfig.id, 'ap5Password', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>
                      <FormGroup
                        label="Channel"
                        isRequired={lanConfig.isAP}
                        fieldId="ap5Channel"
                        helperTextInvalid={ap5ChannelErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? ap5ChannelErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          isRequired={lanConfig.isAP}
                          type="text"
                          id="ap5Channel"
                          name="ap5Channel"
                          placeholder=""
                          isDisabled={!lanConfig.isAP}
                          value={lanConfig.ap5Channel}
                          onChange={(e) => handleChange(lanConfig.id, 'ap5Channel', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <FormGroup
                        label="2.4 GHz SSID"
                        isRequired={lanConfig.isAP}
                        fieldId="apSSID"
                        helperTextInvalid={apSSIDErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? apSSIDErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          isRequired={lanConfig.isAP}
                          type="text"
                          id="apSSID"
                          name="apSSID"
                          placeholder="Guest WiFi"
                          isDisabled={!lanConfig.isAP}
                          value={lanConfig.apSSID || ''}
                          onChange={(e) => handleChange(lanConfig.id, 'apSSID', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>

                      <FormGroup
                        label="Password"
                        isRequired={lanConfig.isAP}
                        fieldId="apPassword"
                        helperTextInvalid={apPasswordErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? apPasswordErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          isRequired={lanConfig.isAP}
                          type="text"
                          id="apPassword"
                          name="apPassword"
                          placeholder=""
                          isDisabled={!lanConfig.isAP}
                          value={lanConfig.apPassword || ''}
                          onChange={(e) => handleChange(lanConfig.id, 'apPassword', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>

                      <FormGroup
                        label="Channel"
                        isRequired={lanConfig.isAP}
                        fieldId="apChannel"
                        helperTextInvalid={apChannelErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={lanConfig.isAP ? apChannelErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <Input
                          type="select" 
                          value={lanConfig.apChannel || ''}
                          onChange={(event, selection, isPlaceholder) => {
                            onSelectClick(event, event.target.value, isPlaceholder, lanConfig.id, 'apChannel', 'isWifiChannelOpen');
                            setSelectState({ ...selectState, isWifiChannelOpen: false });
                          }}
                          onClick={(e) => setSelectState({ ...selectState, isWifiChannelOpen: !selectState.isWifiChannelOpen })}
                          open={selectState.isWifiChannelOpen}
                          disabled={!lanConfig.isAP}
                        >
                          {wifiChannelOptions &&
                          <>
                            <option key="default" value="">Select Channel...</option>
                            {wifiChannelOptions.map((wifiChannelOption, index) => (
                                <option key={wifiChannelOption.key || index} value={wifiChannelOption.props.value}>
                                    {wifiChannelOption.props.value}
                                </option>
                            ))}
                          </>
                          }
                        </Input>

                      </FormGroup>
                    </div>
                  )}

                  <br />
                  {/* TODO: Unhide this table and move to the top of <div>
                    when 5 Ghz and or other wifi options become supported */}
                  <table style={{ visibility: 'hidden' }}>
                    <tbody>
                      <tr>
                        <td>
                          <Radio
                            className="form-input"
                            isChecked={lanConfig.apMode === "mode_24" ? true : false}
                            name={"wifi24-" + lanConfig.id}
                            id={"wifi24-" + lanConfig.id}
                            isDisabled={!lanConfig.isAP}
                            onChange={(checked, event) => handleWifiRadioChange(checked, event, lanConfig.id)}
                            label="2.4 GHz"
                            value={"mode_24-" + lanConfig.id}
                          />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <Radio
                            className="form-input"
                            isChecked={lanConfig.apMode === "mode_5" ? true : false}
                            name={"wifi5-" + lanConfig.id}
                            id={"wifi5-" + lanConfig.id}
                            isDisabled={!lanConfig.isAP}
                            onChange={(checked, event) => handleWifiRadioChange(checked, event, lanConfig.id)}
                            label="5 GHz"
                            value={"mode_5-" + lanConfig.id}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </CardBody>
          </Card>
          </Col>

          <Col>
          <Card isCompact className="lan-settings-form-row">
            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
              <div className="w-100 d-flex justify-content-between">
                <IconHeading
                  id={lanConfig.id}
                  icon={<CogIcon className="icon-medium" />}
                  heading="Misc"
                  handleEnable={(e) => handleLanEnabled(lanConfig.id, lanConfig.isDMZ, 'isDMZ', e)}
                />
                <div className="sub-heading">
                  <FormGroup fieldId="emailAlert" className="form-item">
                    <Checkbox
                      label="Enable DMZ"
                      aria-label="Enable DMZ"
                      name="isDMZ"
                      id={"isDMZ" + lanConfig.id}
                      key={"isDMZ" + lanConfig.id}
                      isChecked={lanConfig.isDMZ}
                      isDisabled={lanConfig.lanSegmentIp4 === '' || lanConfig.lanCidrIp4 === '' || isSiteWan || lanConfig.isDMZDisabled}
                      onChange={(checked, event) => handleCheckboxChange(lanConfig.id, 'isDMZ', checked, event)}
                    />
                  </FormGroup>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {dataLoading ? (
                <Spinner size="lg" />
              ) : (
                <React.Fragment>
                  <FormGroup
                    label="VLAN"
                    fieldId="vlanId"
                    helperTextInvalid={vlanErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={vlanErrorState.validated}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      type="text"
                      id="vlanId"
                      name="vlanId"
                      placeholder=""
                      value={lanConfig.vlanId}
                      onChange={(e) => handleChange(lanConfig.id, 'vlanId', e)}
                      autoComplete="new-password"
                    />
                  </FormGroup>

                  {lanConfig.isDMZ ?
                      <FormGroup
                        label="DMZ IP Address"
                        fieldId="ip4DMZ"
                        helperTextInvalid={ip4DMZErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={isSiteWan ? 'default' : ip4DMZErrorState.validated}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          validated={isSiteWan ? 'default' : ip4DMZErrorState.validated}
                          type="text"
                          id="ip4DMZ"
                          name="ip4DMZ"
                          placeholder={dmzExampleIp}
                          isDisabled={isSiteWan}
                          value={lanConfig.ip4DMZ || ''}
                          onChange={(e) => handleChange(lanConfig.id, 'ip4DMZ', e)}
                          autoComplete="new-password"
                        />
                      </FormGroup>
                  : <></> }
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>

          <div align="center" className="lan-delete" onClick={(e) => deleteLan(e, lanConfig.id)}>
            <TrashIcon className="pointer" />
          </div>
      </Row>
    </>
  );
};

export default LanSettings;
