import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll(allLevels) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const path = allLevels ? '/products?level=all' : '/products';
      const result = await ApiGateway.get(path);
      return result.data.products;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/products/${id}`);
      return result.data.products;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getOptions(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/products/${id}/options`);
      return result.data.options;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getPreferences(id) {
    const result = await ApiGateway.get(`/products/${id}/preferences`);
    return result.data.preferences;
  },

  // TODO: Refactor these headers etc. into ApiGateway functions
  async updatePreferences(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      let formData = new FormData();
      const fileHeaders = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${UserSession.getToken()}`,
      }
  
      formData.append('pageTitle', payload.pageTitle);
      formData.append('headerColor', payload.headerColor);
  
      if (payload.logo instanceof File) {
        formData.append('logo', payload.logo);
      }
      if (payload.favicon instanceof File) {
        formData.append('favicon', payload.favicon);
      }
  
      const result = await axios.post(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + `/products/${id}/preferences`,
        formData, { headers: fileHeaders }
      );
  
      return result.data.preferences;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
