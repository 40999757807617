import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const acls = await ApiGateway.get('/application-profiles');
      return acls.data.profiles;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getApplicationGroups() {
    const appProfiles = await this.getAll();
    const validAppProfiles = appProfiles.filter(appProfile => appProfile.category !== "");
    const unique = Array.from(new Set(validAppProfiles.map(profile => profile.category)));
    return unique.map((profile) => ({ name: profile }));
  },

  async getApplications() {
    const appProfiles = await this.getAll();
    return appProfiles.map((profile) => ({ 
      id: profile.id, name: profile.protocol, category: profile.category 
    }));
  }
}