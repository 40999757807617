import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import '@patternfly/react-core/dist/styles/base.css';
import Login from '@app/pages/auth/Login';
import ForgotPassword from '@app/pages/auth/ForgotPassword';
import NewPasswordChallenge from '@app/pages/auth/NewPasswordChallenge';
import { AppLayout } from '@app/components/layout/layout.component';
import { AppRoutes } from '@app/routes';
import CssBaseline from '@mui/material/CssBaseline';

import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';

import { Html } from './components/layout/html.component';
import { SearchProvider } from './contexts/search.context';

import { history, persistor, store } from './store';

import '@app/app.css';
import './styles/reduction.scss';
// Import CSS to hide metrics temporarily
import './styles/metrics-hide.css';

const basePath = process.env.REACT_APP_BASE_PATH || '';

const App: React.FunctionComponent = () => (
  <React.Fragment>
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ConnectedRouter history={history}>
            <QueryParamProvider>
              <SnackbarProvider dense anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Html>
                  <Switch>
                    <Route exact path={`${basePath}/forgot-password`}>
                      <Redirect to={`${basePath}/auth/forgot-password`} />
                    </Route>
                    <Route exact path={`${basePath}/auth/forgot-password`}>
                      <ForgotPassword />
                    </Route>
                    <Route exact path={`${basePath}/new-password`}>
                      <Redirect to={`${basePath}/auth/new-password`} />
                    </Route>
                    <Route exact path={`${basePath}/auth/new-password`}>
                      <NewPasswordChallenge />
                    </Route>
                    <Route exact path={`${basePath}/login`}>
                      <Redirect to={`${basePath}/auth/login`} />
                    </Route>
                    <Route exact path={`${basePath}/`}>
                      <Redirect to={`${basePath}/dashboard`} />
                    </Route>
                    <Route exact path={`${basePath}/auth/login`}>
                      <Login />
                    </Route>
                    <SearchProvider>
                      <AppLayout>
                        <AppRoutes />
                      </AppLayout>
                    </SearchProvider>
                  </Switch>
                </Html>
              </SnackbarProvider>
            </QueryParamProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
  </React.Fragment>
);

export default App;
