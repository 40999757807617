import getAllGatewayIds from '@app/pages/wan/WanHelper';
import { createSelector } from 'reselect';

import { controllerAdapter, serverAdapter, siteAdapter, wanAdapter, tunnelAdapter } from './configuration.reducer';
import Tunnel from '../../api/tunnel/tunnel.actions';
import { State } from '../../store/root-reducer';
import { Server, Site } from '@app/types';

export const wanSelectors = wanAdapter.getSelectors((state: State) => state.configuration.wans);
export const controllerSelectors = controllerAdapter.getSelectors((state: State) => state.configuration.controllers);
export const serverSelectors = serverAdapter.getSelectors((state: State) => state.configuration.servers);
export const siteSelectors = siteAdapter.getSelectors((state: State) => state.configuration.sites);
export const tunnelSelectors = tunnelAdapter.getSelectors((state: State) => state.configuration.tunnels);

export const selectSiteEntities = (state: State) => state.configuration?.sites.entities;

export const selectSiteIds = (state: State) => state.configuration?.sites?.ids;

export const selectWanIds = (state: State) => state.configuration?.wans?.ids;

export const selectWanEntities = (state: State) => state.configuration?.wans.entities;

export const selectControllers = (state: State) => state.configuration?.controllers;

export const selectControllerById = (controllerId: string | undefined) => {
  return createSelector(selectControllers, serverSelectors.selectAll, tunnelSelectors.selectAll,
    (controllers, servers, tunnels) => {
      const controller = controllers?.entities[controllerId as string];
      let gateways = servers.filter(server => server.gatewayId === controllerId);

      for (let i=0; i<gateways.length; i++) {
        gateways[i] = { ...gateways[i], Tunnels: tunnels.filter(tunnel => tunnel.serverId === gateways[i].id) };
      }

      return {
        ...controller, 
        Servers: gateways
      };
    }
  )
};

export const selectSites = (state: State) => state.configuration?.sites;

export const selectSiteById = (siteId: string | undefined) => {
  return createSelector(selectSites, (sites) => sites?.entities[siteId as string]);
};

export const selectWans = (state: State) => state.configuration.wans;
export const selectWanById = (wanId: string | undefined) => {
  return createSelector(selectWans, (wans) => wans.entities[wanId as string]);
};

export const selectWanOptions = createSelector(wanSelectors.selectAll, (wans) => {
  return wans.reduce<string[]>((wanOptions, { name }) => {
    return wanOptions.concat(name);
  }, []);
});

export const selectClientCompanies = (state: State) => state.configuration.clientCompany;
export const selectClientCompanyById = (clientCompanyId: string) => {
  return createSelector(selectClientCompanies, (clientCompanies) => clientCompanies.entities[clientCompanyId]);
};

export const selectServers = (state: State) => state.configuration.servers;
export const selectServerById = (serverId: string) => {
  return createSelector(selectServers, (servers) => servers.entities[serverId]);
};

export const selectGatewayIds = createSelector(wanSelectors.selectAll, (wans) => {
  return wans.reduce<string[]>((gatewayIds, { activeServerId, standbyServerId }) => {
    return gatewayIds.concat([activeServerId, standbyServerId]).filter((x) => x !== undefined);
  }, []);
});

export const selectControllerServers = (controllerId: string | undefined) => {
  return createSelector(
    selectControllerById(controllerId),
    siteSelectors.selectAll,
    wanSelectors.selectAll,
    (controller, sites, wans) => {
      let controllerSites: Site[] = [];
      let assignedGateways: Server[] = [];
      let availableGateways: Server[] = [];

      let wanGateways: string[] = getAllGatewayIds(Object.values(wans));

      controller?.Servers.forEach((gateway) => {
        if (gateway.Tunnels?.length > 0) {
          assignedGateways.push(gateway);

          let foundSite: Site;

          gateway.Tunnels?.forEach((tunnel) => {
            if (sites && sites.find) {
              // @ts-ignore
              foundSite = sites.find((site) => site.Tunnels.find((siteTunnel) => tunnel.id === siteTunnel.id));
              // @ts-ignore
              controllerSites.push({ ...foundSite, server: gateway, name: foundSite?.ClientCompany?.name });
            }
          });
        } else if (wanGateways.includes(gateway.id)) {
          assignedGateways.push(gateway);
        } else {
          availableGateways.push(gateway);
        }
      });

      return {
        sites: controllerSites,
        assigned: assignedGateways,
        available: availableGateways,
      };
    }
  );
};
