import { createStore, applyMiddleware, Middleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from '@redux-saga/core';
import persistStore from 'redux-persist/lib/persistStore';

import { createRootReducer } from './root-reducer';
import { rootSaga } from './root-saga';

import { jwt } from './middleware/jwt';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

let middleware: Middleware[] = [sagaMiddleware, jwt, routerMiddleware(history)];

if (!['production', 'development', 'test'].includes(process.env.NODE_ENV!)) {
  const { logger } = require('redux-logger');
  middleware = middleware.concat(logger);
}

export const store = createStore(createRootReducer(history), applyMiddleware.apply(null, middleware));

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
