import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import PartnerApi from '@app/api/partner-api/partner.actions';
import { Spacer } from '@app/components/Spacer';
import UserSession from '@app/common/user-session';
import ClientCompany from '@app/api/client-company/client-company.actions';
import { getNamesFromFullname } from './PartnerHelper';
import { getParamFromQueryString, formatDate, reverseSortBy } from '@app/lib/functions';
import { Flex, FlexItem, TreeView, ToggleGroup, ToggleGroupItem, PageSection, Title, Modal, ModalVariant, Spinner } from '@patternfly/react-core';
import { BullhornIcon, PencilAltIcon, ShareSquareIcon } from '@patternfly/react-icons';
import './Partner.css';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Card, CardBody, Col, Row, Button, CardHeader } from 'reactstrap';
import PartnersList from '@app/components/PartnersList';
import {
  MdClose,
  MdAddCircle,
  MdOutlinePeople
} from 'react-icons/md';
import { PartnerForm } from './PartnerForm';
import Loader from '@app/components/Loader';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const PartnerList: React.FunctionComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [partners, setPartners] = React.useState([]);
  const [activeItems, setActiveItems] = React.useState({});
  const [activePartner, setActivePartner] = React.useState({});
  const [enteringPartner, setEnteringPartner] = React.useState(false);
  const [partnerForm, setPartnerForm] = React.useState(false);
  const partnerName = UserSession.getParam('partnerName');
  const id = getParamFromQueryString('id');
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [activePartnerId, setActivePartnerId] = React.useState(null);
  const [forceRender, setForceRender] = React.useState(false);

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  React.useEffect(() => {
    const fetchPartners = async () => {
      try {
        const partners = await PartnerApi.getAll(true);
        const partnerId = UserSession.getPartnerId();
        const sortedPartners = reverseSortBy(partners, 'updated');
        const partnerPromises = [];

        if (sortedPartners) {
          //@ts-ignore          
          partnerPromises = sortedPartners.map(item => {
            return partnerFromItem(item);
          });
        }

        const partnerRows = await Promise.all(partnerPromises);

        setPartners(partnerRows);
        setLoading(false);

        // If no partner id is passed, just expand the first partner 
        if (!id && partnerRows?.length) {
          partnerRows[0].defaultExpanded = true;
          setActivePartner(partnerRows[0]);
          setActiveItems([partnerRows[0]]);
        }
        setForceRender((prev) => !prev);
      }
      catch (error) {
        console.log(error);
      }
    };
  
    fetchPartners();
  }, [partnerForm]);

  const enterPartnerSpace = async (e) => {
    e.preventDefault();
    setLoading(true);
    const flattened = flattenPartners(partners);
    const currentPartnerId = UserSession.getPartnerId();
    //@ts-ignore
    const partnerChain = getPartnerAuthChain(flattened, activePartner.id, currentPartnerId, activePartner);

    setEnteringPartner(true);

    // If the active selected partner is nested, add it to the end of the partner chain
    //@ts-ignore
    if (currentPartnerId !== activePartner.parent_id) {
      //@ts-ignore
      partnerChain.push({ id: activePartner.id, parent_id: activePartner.parent_id, name: activePartner.name });
    }

    for (let i = 0; i < partnerChain.length; i++) {
      const partner = partnerChain[i];
      const assumedRole = await PartnerApi.getAssumedRole(partner.id);
      const preferences = await PartnerApi.getPreferences(partner.id);

      UserSession.setParam('token', assumedRole.assumed_token);
      UserSession.setParam('partnerId', partner.id);
      UserSession.setParam('partnerName', partner.name);

      // Override partner preferences
      UserSession.setParam('logoFileUrl', preferences?.logo_filename_url || false);
      UserSession.setParam('faviconFileUrl', preferences?.favicon_filename_url || false);
      UserSession.setParam('pageTitle', preferences?.page_title || false);
      UserSession.setParam('headerColor', preferences?.header_color || false);
      UserSession.setParam('logoFilename', preferences?.logo_filename || false);
      UserSession.setParam('faviconFilename', preferences?.favicon_filename || false);

      const clientCompanies = await ClientCompany.getAll(partner.id);
      UserSession.setParam('clientCompanyIds', clientCompanies.map((c) => c.id));
    }

    // When exiting the partner space from components/layout/sidebar.component.tsx, this will 
    // get set to false and app will be reloaded, and original session attributes from redux 
    // store will get loaded
    UserSession.setParam('isImpersonating', true);

    window.location.href = `${basePath}/dashboard`;
  }

  const getPartnerAuthChain = (flattened, partnerId, currentPartnerId, activePartner) => {
    const partner = flattened.find(p => p.id === partnerId);
    let partnerChain = [];

    if (currentPartnerId === partner.parent_id) {
      partnerChain = partnerChain.concat({ id: partnerId, parent_id: partner.parent_id, name: partner.name });
    }
    else {
      //@ts-ignore
      partnerChain = partnerChain.concat(getPartnerAuthChain(flattened, partner.parent_id, currentPartnerId));
    }

    return partnerChain;
  }

  const flattenPartners = (partners) => {
    var result = [];

    partners.forEach(function (partner) {
      result.push(partner);

      if (Array.isArray(partner.children)) {
        result = result.concat(flattenPartners(partner.children));
      }
    });

    return result;
  }

  const editPartner = () => {
    //@ts-ignore
    history.push(`/partner/edit/${activePartner.id}`);
  }

  const partnerFromItem = async (item) => {
    const subPartnerCount = item.partners ? item.partners.length : 0;
    const names = getNamesFromFullname(item.billing_contact);
    const partner = {
      name: item.name,
      id: item.id,
      key: item.id,
      parent_id: item.parent_id,
      created: item.created,
      updated: item.updated,
      acknowledgedTerms: item.acknowledgedTerms,
      contactFirstName: names.first_name,
      contactLastName: names.last_name,
      contactEmail: item.billing_email,
      contactPhone: item.billing_phone,
      controllers: (item.controllers ?? '').toString(),
      sites: (item.sites ?? '').toString(),
    }

    // If id is passed via query string, make that the active node in the TreeView
    if (id === item.id) {
      //@ts-ignore
      partner.defaultExpanded = true;
      setActiveItems([partner]);
      setActivePartner(partner);
    }

    // Only show the badge containing the sub partner count if > 0
    if (subPartnerCount > 0) {
      const badgeText = subPartnerCount === 1 ? `${subPartnerCount} partner` : `${subPartnerCount} partners`;
      //@ts-ignore
      partner.hasBadge = true;
      //@ts-ignore
      partner.customBadgeContent = badgeText;
      item.partners = item.partners.map(item => {
        return partnerFromItem(item);
      });
      //@ts-ignore
      partner.children = await Promise.all(item.partners);
    }
    else {
      //@ts-ignore
      partner.hasBadge = false;
    }

    return partner;
  }

  const onSelect = async (evt, treeViewItem) => {
    if (treeViewItem) {
      setActiveItems([treeViewItem]);
      setActivePartner(treeViewItem);
    }
  }

  const findById = (id, partnersList) => {
    for (const partner of partnersList) {
        if (partner.id === id) {
            return partner;
        }
        if (partner.children && partner.children.length > 0) {
            const foundInChildren = findById(id, partner.children);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
    }
    return null;
  };
  
  const selectedPartner = activePartnerId ? findById(activePartnerId, partners) : null;

  return (
    <React.Fragment>
      <Helmet>
        <title>Partners</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Page
            tag="div"
            className={`cr-page px-3 pt-2 partners-list ${theme}`}
            title=""
            breadcrumbs={[{ name: 'Partners', active: true }]}
          >
          <div className={`disable-wrapper ${partnerForm ? 'active' : ''}`}></div>  
          <Row>
            <Col md={activePartnerId ? "9" : "12"} sm="12" xs="12" className="partners-col">
              <div className="partners-card-wrapper">
                <Card>
                  <div className="d-flex justify-content-between border-bottom">
                    <h3 className="col-9"><strong>{partnerName}</strong></h3>
                    <div className="d-flex col-3 p-2 justify-content-end">
                      <Button size="sm me-2" className="ethica-button-green" onClick={() => {setActivePartnerId(null), setPartnerForm(true)}}><MdAddCircle className="me-1"/>New Partner</Button>
                    </div>
                  </div>
                  <CardBody>
                    <PartnersList
                      headers={[
                        <MdOutlinePeople/>,
                        'name',
                        'ID',
                        'Email',
                        'Controllers',
                        'Sites',
                        'Actions'
                      ]}
                      partnersData={partners}
                      activePartnerId={activePartnerId}
                      setActivePartnerId={setActivePartnerId}
                      enterPartnerSpace={enterPartnerSpace}
                      setPartnerForm={setPartnerForm}
                    />
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col md="3" sm="12" xs="12" className="partners-col-details">
              <div className="partners-card-wrapper">
                {selectedPartner ?
              (
                <>
                  <Card className="mb-2">
                    <h3 className="border-bottom"><strong>Partner Details</strong></h3>
                    <CardBody>
                      <p><b>Name:</b>	{selectedPartner.name}</p>
                      <p><b>Partner ID:</b>	{selectedPartner.id}</p>
                      <p><b>Created:</b>	{formatDate(selectedPartner.created, false)}</p>
                      <p><b>Last updated:</b>	{formatDate(selectedPartner.updated, false)}</p>
                      <p><b>Controllers:</b> {selectedPartner.controllers || 'No data'}	</p>
                      <p><b>Sites:</b> {selectedPartner.sites || 'No data'}	</p>
                    </CardBody>
                  </Card>
                  <Card className="mb-2">
                    <h3 className="border-bottom"><strong>Primary Contact</strong></h3>
                    <CardBody>
                      <p><b>Name:</b>	{selectedPartner.name || 'No data'}</p>
                      <p><b>Phone:</b>	{selectedPartner.contactPhone || 'No data'}</p>
                      <p><b>Email:</b>	{selectedPartner.contactEmail || 'No data'}</p>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody className="d-flex justify-content-between">
                      <div>
                        <Button className="ethica-button-green" size="sm me-2" onClick={() => {setActivePartnerId(selectedPartner.id), setPartnerForm(true)}}>Edit</Button>
                        <Button className="ethica-button-black" size="sm me-2" onClick={enterPartnerSpace}>Partner Space</Button>
                      </div>
                      <div>
                        <Button className="ethica-button-black" size="sm" onClick={() => setActivePartnerId(null)}><MdClose /></Button>
                      </div>
                    </CardBody>
                  </Card>
                </>
              )
              : 
              (<>No data</>)
              }
              </div>
            </Col>
          </Row>
          {partnerForm && (
            <div className={`partner-action ${partnerForm ? 'active' : ''}`}>
              <PartnerForm setPartnerForm={setPartnerForm} activePartnerId={activePartnerId}/>
            </div>
          )}
        </Page>
      )}
    </React.Fragment>
  );
}
