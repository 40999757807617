import * as React from 'react';
import { useHistory } from "react-router-dom";
import ProductApi from '@app/api/product-api/product.actions';
import UserSession from '@app/common/user-session';
import { getNamesFromFullname } from './ProductHelper';
import { getParamFromQueryString, reverseSortBy } from '@app/lib/functions';
import './Product.css';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ProductsList from '@app/components/ProductsList';
import Loader from '@app/components/Loader';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ProductList: React.FunctionComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [activeItems, setActiveItems] = React.useState({});
  const [activeProduct, setActiveProduct] = React.useState({});
  const [enteringProduct, setEnteringProduct] = React.useState(false);
  const [productForm, setProductForm] = React.useState(false);
  const productName = UserSession.getParam('productName');
  const id = getParamFromQueryString('id');
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [activeProductId, setActiveProductId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async function() {
      try {
        const products = await ProductApi.getAll(true);
        console.log('products',products);

        const groupedProducts = groupProductsByCategory(products);

        // const sortedProducts = reverseSortBy(groupedProducts, 'updated');
        // let productPromises = [];
        //
        // if (sortedProducts) {
        //   //@ts-ignore
        //   productPromises = sortedProducts.map(item => {
        //     return productFromItem(item);
        //   });
        // }
        //
        // const productRows = await Promise.all(productPromises);

        setProducts(groupedProducts);

        // if (!id && productRows?.length) {
        //   productRows[0].defaultExpanded = true;
        //   setActiveProduct(productRows[0]);
        //   setActiveItems([productRows[0]]);
        // }
        setIsLoading(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const groupProductsByCategory = (products) => {
    return products.reduce((acc, product) => {
      const category = product.category;

      let categoryName;
      switch (category) {
        case 1:
          categoryName = "Edge Devices";
          break;
        case 2:
          categoryName = "Controllers";
          break;
        case 3:
          categoryName = "Licenses";
          break;
        default:
          categoryName = "Other";
      }

      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }

      acc[categoryName].push(product);
      return acc;
    }, {});
  };

  const enterProductSpace = async (e) => {
    e.preventDefault();
    const flattened = flattenProducts(products);
    //@ts-ignore
    const currentProductId = UserSession.getProductId();
    //@ts-ignore
    const productChain = getProductAuthChain(flattened, activeProduct.id, currentProductId, activeProduct);

    setEnteringProduct(true);

    // If the active selected product is nested, add it to the end of the product chain
          //@ts-ignore
    if (currentProductId !== activeProduct.parent_id) {
      //@ts-ignore
      productChain.push({ id: activeProduct.id, parent_id: activeProduct.parent_id, name: activeProduct.name });
    }

    for (let i = 0; i < productChain.length; i++) {
      const product = productChain[i];
      //@ts-ignore
      const assumedRole = await ProductApi.getAssumedRole(product.id);
      const preferences = await ProductApi.getPreferences(product.id);

      UserSession.setParam('token', assumedRole.assumed_token);
      UserSession.setParam('productId', product.id);
      UserSession.setParam('productName', product.name);

      // Override product preferences
      UserSession.setParam('logoFileUrl', preferences?.logo_filename_url || false);
      UserSession.setParam('faviconFileUrl', preferences?.favicon_filename_url || false);
      UserSession.setParam('pageTitle', preferences?.page_title || false);
      UserSession.setParam('headerColor', preferences?.header_color || false);
      UserSession.setParam('logoFilename', preferences?.logo_filename || false);
      UserSession.setParam('faviconFilename', preferences?.favicon_filename || false);
    }

    // When exiting the product space from components/layout/sidebar.component.tsx, this will
    // get set to false and app will be reloaded, and original session attributes from redux
    // store will get loaded
    UserSession.setParam('isImpersonating', true);

    window.location.href = '/dashboard';
  }

  const getProductAuthChain = (flattened, productId, currentProductId, activeProduct) => {
    const product = flattened.find(p => p.id === productId);
    let productChain = [];

    if (currentProductId === product.parent_id) {
      productChain = productChain.concat({ id: productId, parent_id: product.parent_id, name: product.name });
    }
    else {
      //@ts-ignore
      productChain = productChain.concat(getProductAuthChain(flattened, product.parent_id, currentProductId));
    }

    return productChain;
  }

  const flattenProducts = (products) => {
    var result = [];

    products.forEach(function (product) {
      result.push(product);

      if (Array.isArray(product.lessons)) {
        console.log(product.lessons);
        result = result.concat(flattenProducts(product.lessons));
      }
    });

    return result;
  }

  const productFromItem = async (item) => {
    const subProductCount = item.products ? item.products.length : 0;
    const names = getNamesFromFullname(item.billing_contact);
    const product = {
      name: item.name,
      image_url: item.image_url,
      description: item.description,
      id: item.id,
      price: item.prices,
      created: item.created_at,
      updated: item.updated_at,
    }

    if (id === item.id) {
      //@ts-ignore
      product.defaultExpanded = true;
      setActiveItems([product]);
      setActiveProduct(product);
    }

    if (subProductCount > 0) {
      const badgeText = subProductCount === 1 ? `${subProductCount} product` : `${subProductCount} products`;
      //@ts-ignore
      product.hasBadge = true;
      //@ts-ignore
      product.customBadgeContent = badgeText;
      item.products = item.products.map(item => {
        return productFromItem(item);
      });
      //@ts-ignore
      product.lessons = await Promise.all(item.products);
    }
    else {
      //@ts-ignore
      product.hasBadge = false;
    }

    return product;
  }

  const findById = (id, productsList) => {
    for (const product of productsList) {
        if (product.id === id) {
            return product;
        }
        if (product.lessons && product.lessons.length > 0) {
            const foundInChildren = findById(id, product.lessons);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
    }
    return null;
  };

  const selectedProduct = activeProductId ? findById(activeProductId, products) : null;

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Page
            tag="div"
            className={`cr-page px-3 pt-2 products-list ${theme}`}
            title=""
            breadcrumbs={[{ name: 'Products', active: true }]}
          >
          <div className={`disable-wrapper ${productForm ? 'active' : ''}`}></div>
          <Row>
            <div className="products-card-wrapper">
              <Col md={activeProductId ? "9" : "12"} sm="12" xs="12" className="products-col">
                <Card>
                  <CardBody>
                    <ProductsList
                      headers={[
                        'ID',
                        'name',
                        'price',
                        'image_url',
                        'Description',
                      ]}
                      productsData={products}
                      activeProductId={activeProductId}
                      setActiveProductId={setActiveProductId}
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
        </Page>
      )}
    </React.Fragment>
  );
}
