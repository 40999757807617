import React from 'react';
import PropTypes from '@app/utils/propTypes';

import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useCart } from '@app/contexts/cart.context';
import demoImage from '@app/assets/images/Edge1002.png';
import CartAPI from '@app/api/cart-api/cart.actions';

const ProductsList = ({ headers, productsData, activeProductId, setActiveProductId, ...restProps }) => {
  const { dispatch } = useCart();
  const { addToCart } = useCart();

  console.log('productsData',productsData);

  const [activePartnerExpand, setActivePartnerExpand] = React.useState(null);
  const [cartId, setCartId] = React.useState(null);

  const handleRowClick = (id) => {
    setActiveProductId(id);
  };

  const addToCartAction = async (product) => {
    const productToCart = {
      id: product.id,
      selectedOptions: Object.keys(product.selectedOptions || {}),
      totalPrice: product.totalPrice,
    };

    try {
      addToCart(productToCart);
      //@ts-ignore
      console.log("Item added to cart successfully:", response);
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  const getPrice = (currentPrice) => {
    if (currentPrice && currentPrice[0].price) {
      return currentPrice[0].billing_cycle === "OneTime" ? currentPrice[0].price + ' ' + currentPrice[0].currency : currentPrice[0].price + ' ' + currentPrice[0].currency + ' (' + currentPrice[0].billing_cycle + ')';
    }
    return 0;
  }

  return (
    <Row>
      <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
        <div className="user-products">
          {/* Проходим по каждой категории */}
          {Object.entries(productsData).map(([category, products]) => (
            <div key={category}>
              {/* Заголовок категории */}
              <h3 className="category-title">{category}</h3>
              <Row>
                {/* Проходим по каждому продукту в категории */}
                {products.map(({ id, name, prices, image_url, description }, index) => (
                  <Col key={index} lg={4} md={4} sm={6} xs={12} className="mb-3">
                    <Link to={`/products/${id}`} className="product-item-link">
                      <div className="product-item">
                        <div className="product-name p-3 mb-3">
                          <strong>{name}</strong>
                        </div>
                        <div className="product-image ps-3 pe-3 mb-3">
                          <img src={image_url} alt={name} />
                        </div>
                        <div className="product-description ps-3 pe-3 mb-3">
                          {description}
                        </div>
                        <div className="product-actions">
                          <div className="product-price ps-3 pe-3 mb-3">
                            <strong>{getPrice(prices)}</strong>
                          </div>
                          <div className="ld_product_grid_button ps-3 pe-3 mb-3">
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                addToCartAction({ id, name, prices, image_url, description });
                              }}
                              className="btn w-100 ethica-button-green"
                            >
                              Add to cart
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

ProductsList.propTypes = {
  headers: PropTypes.node,
  productsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  activeProductId: PropTypes.string,
  setActiveProductId: PropTypes.func,
};

ProductsList.defaultProps = {
  headers: [],
  productsData: [],
};

export default ProductsList;
