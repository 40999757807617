import _map from 'lodash-es/map';
import _omit from 'lodash-es/omit';
import {
  CREATE_SITE,
  UPDATE_SITE,
  GET_SITE,
  DELETE_SITE,
  CREATE_SITE_ROUTE,
  DELETE_SITE_ROUTE,
  GET_SITE_LANS,
  CREATE_SITE_LAN,
  UPDATE_SITE_LAN,
  UPDATE_SITE_ROUTE,
  DELETE_SITE_LAN,
  DELETE_TUNNEL,
  CREATE_LINK,
  UPDATE_LINK,
  DELETE_LINK,
  UPDATE_SITE_CONTACT,
  DELETE_SITE_CONTACT,
} from './gql.queries';
import { GET_TUNNEL, CREATE_TUNNEL, UPDATE_TUNNEL } from '../tunnel/gql.queries';
import UserSession from '@app/common/user-session';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import Server from '@app/api/gateway/gateway.actions';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import Wan from '@app/api/wan/wan.actions';
import SiteApi from '@app/api/site-api/site.actions';
import { shouldUbondRestart } from '@app/common/config-helper';
import { routeFromObj, linkFromObj, arraysEqual } from '@app/lib/functions';
import { GQLHelpers } from '../hasura/ts';
import { apolloClient } from '../graphql/client';
import { isUUID } from '@app/lib/validator';
import { Order_By } from '../hasura';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const { objects: Sites } = await GQLHelpers.Fragments.Site.queryObjects({
        apolloClient,
        options: {
          variables: {
            where: { ClientCompany: { partnerId: { _eq: partnerId } } },
            order_by: { address1: Order_By.Asc },
          },
        },
      });
      return Sites;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async getDashboardAll() {
    console.log('getDashboardAll');
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const { objects: Sites } = await GQLHelpers.Fragments.Site.queryObjects({
        apolloClient,
        options: {
          variables: {
            where: {
              ClientCompany: { partnerId: { _eq: partnerId } },
              _or: [
                { status: { _eq: 1 } },
                { status: { _eq: 2 } }
              ]
            },
            order_by: { address1: Order_By.Asc },
          },
        },
      });
      return Sites;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async getSiteLansBySiteId(siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { data: { SiteLan } } = await apolloClient.query({
        query: GET_SITE_LANS,
        variables: { siteId: siteId }
      });
      return SiteLan;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async getSitesByWan(wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { objects: Sites } = await GQLHelpers.Fragments.Site.queryObjects({
        apolloClient,
        options: { variables: { where: { wanId: { _eq: wanId } } } },
      });

      return Sites;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async update(payload, originalData) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const siteId = payload.siteId;
      const primaryContact = payload.SiteContacts.data[0];
      const primaryLans = payload.SiteLans.data;
      const primaryTunnel = payload.Tunnels.data[0];
      const siteContactId = primaryContact.id;
      const activeTunnelId = primaryTunnel.id;
      const { id: siteContactId_, ...siteContact } = primaryContact;
      const { id: activeTunnelId_, ...activeTunnel } = primaryTunnel;

      let anyLinksChanged = false;

      // Update the main Site record
      const { SiteContacts, SiteLans, SiteRoutes, Tunnels, ...siteDetails } = payload;
      siteDetails.isServerless = siteDetails.wanId ? false : siteDetails.isServerless;
      delete siteDetails.siteId;

      const {
        data: { updatedSite },
      } = await apolloClient.mutate({
        mutation: UPDATE_SITE,
        variables: { id: siteId, changes: siteDetails },
      });

      // Tunnels CRUD
      var tunnelPromises: Promise<any>[] = [];
      let tunnelAddressPairSeed = -1;
      let nextPort = await this.getServerLinkPort(primaryTunnel);
      let ubondNeedsRestart = false;

      for (var i = 0; i < payload.Tunnels.data.length; i++) {
        let tunnel = payload.Tunnels.data[i];

        if (tunnel !== undefined) {
          let activeTunnel = payload.Tunnels.data[0];

          // Create Tunnel
          if (tunnel?.id === undefined) {
            const { id, Links, Server, ...newTunnel } = activeTunnel;
            newTunnel.isPrimary = i === 0 ? true : false; // Primary Tunnel must be index 0
            newTunnel.serverId = tunnel.serverId;
            newTunnel.Links = { data: activeTunnel.Links.data.map(({ id, tunnelId, ...link }) => link) };
            const updatedTunnel = await this.updateTunnelPortAndSeed(newTunnel);

            tunnelPromises.push(
              apolloClient.mutate({
                mutation: CREATE_TUNNEL,
                variables: { input: updatedTunnel },
              })
            );
          }

          // Delete Tunnel
          else if (tunnel?.crudState === 'delete') {
            // Get a fresh Tunnel from the db
            const {
              data: { Tunnel },
            } = await apolloClient.query({
              query: GET_TUNNEL,
              variables: { id: tunnel.id },
            });
            let linkPromises = Tunnel[0].Links.map(({ id }) => {
              return apolloClient.mutate({
                mutation: DELETE_LINK,
                variables: { id },
              });
            });
            await Promise.all(linkPromises);

            tunnelPromises.push(
              apolloClient.mutate({
                mutation: DELETE_TUNNEL,
                variables: { id: tunnel.id },
              })
            );
          }

          // Update Tunnel
          else if (isUUID(tunnel?.id)) {
            // Get a fresh Tunnel from the db
            const {
              data: { Tunnel },
            } = await apolloClient.query({
              query: GET_TUNNEL,
              variables: { id: tunnel.id },
            });
            const tunnelServerChanged = Tunnel[0].serverId !== tunnel.serverId;
            let { Server, Links, ...tunnelChanges } = tunnel;

            if (tunnelServerChanged) {
              const { Server, ...tempTunnel } = tunnel;
              const updatedTunnel = await this.updateTunnelPortAndSeed(tempTunnel);

              if (i === 0) {
                tunnelAddressPairSeed = updatedTunnel.tunnelAddressPairSeed;
              } else if (tunnelAddressPairSeed !== -1) {
                updatedTunnel.tunnelAddressPairSeed = tunnelAddressPairSeed;
              }

              const { Links, ...tunnelOnly } = updatedTunnel;
              tunnelChanges = tunnelOnly;
            }

            tunnelPromises.push(
              apolloClient.mutate({
                mutation: UPDATE_TUNNEL,
                variables: { id: tunnel.id, changes: tunnelChanges },
              })
            );
          }
        }
      }

      await Promise.all(tunnelPromises);

      // Links CRUD
      let linkPromises: Promise<any>[] = [];

      for (var i = 0; i < primaryTunnel.Links.data.length; i++) {
        let link = primaryTunnel.Links.data[i];

        // Delete Link
        if (link?.crudState === 'delete') {
          linkPromises.push(
            apolloClient.mutate({
              mutation: DELETE_LINK,
              variables: { id: link.id },
            })
          );
        }
        // Update Link
        else if (isUUID(link?.id)) {
          const notDeletedLinks = primaryTunnel.Links.data.filter((l) => l?.crudState !== 'delete');
          var linkCountChanged = notDeletedLinks.length !== originalData.links.length;
          var noTunnelToTunnel = originalData.isServerless === true && payload.isServerless === false;
          var updatedLink = link;

          if (shouldUbondRestart(originalData, primaryLans, link, primaryTunnel) || linkCountChanged || noTunnelToTunnel) {
            ubondNeedsRestart = true;
            if (!payload.wanId) {
              updatedLink = linkFromObj(link, nextPort++);
            }
          }

          linkPromises.push(
            apolloClient.mutate({
              mutation: UPDATE_LINK,
              variables: { id: link.id, changes: updatedLink },
            })
          );
        }

        // Create Link
        else if (link.id.startsWith('link-')) {
          const newLink = linkFromObj(link, nextPort++);
          newLink.tunnelId = link.tunnelId;
          linkPromises.push(
            apolloClient.mutate({
              mutation: CREATE_LINK,
              variables: { input: newLink },
            })
          );
        }
      }

      await Promise.all(linkPromises);

      const standbyTunnel = payload.Tunnels.data[1];
      const addingFailover = originalData.standbyTunnel === undefined && standbyTunnel !== undefined ? true : false;

      const removingFailover =
        originalData.standbyTunnel !== undefined && standbyTunnel?.crudState === 'delete' ? true : false;

      const bothTunnelsChanged =
        originalData.activeTunnel.serverId !== primaryTunnel.serverId &&
        originalData.standbyTunnel &&
        standbyTunnel &&
        originalData.standbyTunnel.serverId !== standbyTunnel.serverId
          ? true
          : false;
      const failoverEnabled = standbyTunnel !== undefined && standbyTunnel?.crudState !== 'delete' ? true : false;
      const addingSiteToWan =
        !originalData.wanId && payload.wanId !== originalData.wanId && payload.wanId !== undefined ? true : false;
      const removingSiteFromWan =
        originalData.wanId && payload.wanId !== originalData.wanId && !payload.wanId ? true : false;
      const portRangeHasChanged =
        originalData.activeTunnel.portRangeStart !== primaryTunnel.portRangeStart ||
        originalData.activeTunnel.portRangeEnd !== primaryTunnel.portRangeEnd;

      linkPromises = [];

      if (failoverEnabled) {
        // Get a the latest from the db.
        const {
          data: { Site },
        } = await apolloClient.query({
          query: GET_SITE,
          variables: { id: siteId },
        });

        // Delete all standby tunnel links, and copy all Links from Tunnels[0] over
        // to Tunnels[1] (as new db records)
        const existingSite = Site[0];
        let existingActiveTunnel = existingSite.Tunnels.find(tunnel => tunnel.isPrimary === true);
        const existingStandbyTunnel = existingSite.Tunnels.find(tunnel => tunnel.isPrimary === false);

        if (addingFailover || removingFailover) {
          existingActiveTunnel.Links.data = existingActiveTunnel.Links;
          existingActiveTunnel = await this.updateTunnelPortAndSeed(existingActiveTunnel);
          existingActiveTunnel.Links = existingActiveTunnel.Links.data;
          let tunnelChanges = { tunnelAddressPairSeed: existingActiveTunnel.tunnelAddressPairSeed };

          await apolloClient.mutate({
            mutation: UPDATE_TUNNEL,
            variables: { id: existingActiveTunnel.id, changes: tunnelChanges },
          });
        } else if (bothTunnelsChanged) {
          let startPort = existingActiveTunnel.portRangeStart;

          for (var i = 0; i < existingActiveTunnel.Links.length; i++) {
            let port = bothTunnelsChanged ? startPort++ : existingActiveTunnel.Links[i].serverPort;
            let linkChanges = { serverPort: port };

            await apolloClient.mutate({
              mutation: UPDATE_LINK,
              variables: { id: existingActiveTunnel.Links[i].id, changes: linkChanges },
            });
          }
        }

        const activeLinks = existingActiveTunnel.Links.map(({ id, ...link }) => link);
        const updatingActiveControllerOnly =
          originalData.activeTunnel.serverId !== primaryTunnel.serverId &&
          originalData.standbyTunnel &&
          standbyTunnel &&
          originalData.standbyTunnel.serverId === standbyTunnel.serverId;
        let linkCountChanged = activeLinks.length !== originalData.links.length;
        let startPort = primaryTunnel.portRangeStart;

        nextPort = await this.getServerLinkPort(existingStandbyTunnel);

        // If links were added or deleted
        if (linkCountChanged) {
          // Delete all standby links
          for (var i = 0; i < existingStandbyTunnel.Links.length; i++) {
            var deleteLink = existingStandbyTunnel.Links[i];
            linkPromises.push(
              apolloClient.mutate({
                mutation: DELETE_LINK,
                variables: { id: deleteLink.id },
              })
            );
          }

          // Re-add standby links as copies of the active links
          for (var i = 0; i < activeLinks.length; i++) {
            var activeLink = linkFromObj(activeLinks[i], nextPort++);
            activeLink.tunnelId = existingStandbyTunnel.id;
            if (addingFailover || bothTunnelsChanged) {
              activeLink.serverPort = startPort++;
            }
            linkPromises.push(
              apolloClient.mutate({
                mutation: CREATE_LINK,
                variables: { input: activeLink },
              })
            );
          }
          await Promise.all(linkPromises);
        }
        // Recompute the ports for the standby links(active would already be computed at this point)
        else if (ubondNeedsRestart) {
          for (var i = 0; i < existingActiveTunnel.Links.length; i++) {
            let port = addingFailover || bothTunnelsChanged ? startPort++ : nextPort++;
            const { id, ...standbyLink } = activeLinks[i];
            let standbyLinkId = existingStandbyTunnel.Links[i].id;
            standbyLink.serverPort = port;

            linkPromises.push(
              apolloClient.mutate({
                mutation: UPDATE_LINK,
                variables: { id: standbyLinkId, changes: standbyLink },
              })
            );
          }
          await Promise.all(linkPromises);
        }
        // Update the standby links
        else {
          for (var i = 0; i < existingActiveTunnel.Links.length; i++) {
            let { id, serverPort, ...standbyLink } = activeLinks[i];
            let standbyLinkId = existingStandbyTunnel.Links[i].id;

            if (addingFailover) {
              standbyLink.serverPort = startPort++;
            }
            if (updatingActiveControllerOnly) {
              let standbyPort = await this.getServerLinkPort(existingStandbyTunnel);
              standbyLink.serverPort = standbyPort;
            }

            await apolloClient.mutate({
              mutation: UPDATE_LINK,
              variables: { id: standbyLinkId, changes: standbyLink },
            });
          }
        }
      }

      // Recalculate all Link serverPort's if the Tunnel's port range has changed
      if (portRangeHasChanged) {
        await this.updateLinkPortsFromNewRange(primaryTunnel);

        if (standbyTunnel) {
          const newPortRangeStart = payload.Tunnels.data[0].portRangeStart;
          const {
            data: { Tunnel },
          } = await apolloClient.query({
            query: GET_TUNNEL,
            variables: { id: standbyTunnel.id },
          });
          const tunnel = { portRangeStart: newPortRangeStart, Links: { data: Tunnel[0].Links } };
          await this.updateLinkPortsFromNewRange(tunnel);
        }
      }

      if (addingSiteToWan) {
        await SiteApi.addSiteToWan(siteId, payload.wanId);
      }

      if (removingSiteFromWan) {
        await SiteApi.removeSiteFromWan(siteId);
      }

      // Update Link ports for WAN
      if (portRangeHasChanged || addingSiteToWan || (payload.wanId && ubondNeedsRestart)) {
        await this.updatePortsForWanSite(siteId, payload.wanId);
      }

      // SiteRoutes CRUD
      if (payload.SiteRoutes !== undefined) {
        var routePromises: Promise<any>[] = [];

        for (var i = 0; i < payload.SiteRoutes.data.length; i++) {
          let route = payload.SiteRoutes.data[i];

          // Delete SiteRoute
          if (route?.crudState !== undefined && route?.crudState === 'delete') {
            routePromises.push(
              apolloClient.mutate({
                mutation: DELETE_SITE_ROUTE,
                variables: { id: route.id },
              })
            );
          }
          // Update SiteRoute
          else if (route.id && route.id.length === 36) {
            const updatedRoute = routeFromObj(route);
            routePromises.push(
              apolloClient.mutate({
                mutation: UPDATE_SITE_ROUTE,
                variables: { id: route.id, changes: updatedRoute },
              })
            );
          }
          // Create SiteRoute
          else if (route.id && route.id.startsWith('route-')) {
            const newRoute = { ...routeFromObj(route), siteId: route.siteId };
            newRoute.siteId = route.siteId;
            routePromises.push(
              apolloClient.mutate({
                mutation: CREATE_SITE_ROUTE,
                variables: { input: newRoute },
              })
            );
          }
        }
        await Promise.all(routePromises);
      }

      // Update SiteContact
      const {
        data: { updatedContact },
      } = await apolloClient.mutate({
        mutation: UPDATE_SITE_CONTACT,
        variables: { id: siteContactId, changes: siteContact },
      });

      // Update SiteLan
      // const {
      //   data: { updatedLan },
      // } = await apolloClient.mutate({
      //   mutation: UPDATE_SITE_LAN,
      //   variables: { id: siteLanId, changes: siteLan },
      // });

      // LANs CRUD
      let lanPromises: Promise<any>[] = [];

      for (var i = 0; i < primaryLans.length; i++) {
        let lan = primaryLans[i];

        // Delete LAN
        if (lan?.crudState === 'delete') {
          lanPromises.push(
            apolloClient.mutate({
              mutation: DELETE_SITE_LAN,
              variables: { id: lan.id },
            })
          );
        }
        // Update LAN
        else if (isUUID(lan?.id)) {
          lanPromises.push(
            apolloClient.mutate({
              mutation: UPDATE_SITE_LAN,
              variables: { id: lan.id, changes: lan },
            })
          );
        }
        // Create LAN
        else if (lan.id.startsWith('lan-')) {
          const { id, ...newLan } = lan;
          lanPromises.push(
            apolloClient.mutate({
              mutation: CREATE_SITE_LAN,
              variables: { input: newLan },
            })
          );
        }
      }

      await Promise.all(lanPromises);

      // Reboot gateway if Site is being added or removed from a WAN
      if (payload.wanId !== originalData.wanId) {
        await Server.reboot(payload.Tunnels.data[0].serverId);

        if (payload.Tunnels.data.length > 1) {
          await Server.reboot(payload.Tunnels.data[1].serverId);
        }
      }

      return updatedSite;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const activeLinks = { data: payload.Tunnels.data[0].Links.data.map(({ id, ...link }) => link) };
      payload.Tunnels.data[0].Links = activeLinks;
      payload.Tunnels.data[0] = await this.updateTunnelPortAndSeed(payload.Tunnels.data[0]);
      payload.Tunnels.data[0].isActive = true;
      payload.SiteLans = { data: payload.SiteLans.data.map(({ id, ...lan }) => lan) }

      if (payload.SiteRoutes && payload.SiteRoutes.data.length > 0) {
        var newRoutes = payload.SiteRoutes.data.map(({ id, ...item }) => item);
        payload.SiteRoutes = { data: newRoutes };
      }

      if (payload.Tunnels.data[1] !== undefined) {
        payload.Tunnels.data[1].Links = activeLinks;
        payload.Tunnels.data[1].tunnelAddressPairSeed = payload.Tunnels.data[0].tunnelAddressPairSeed;

        let startPort = payload.Tunnels.data[0].portRangeStart;
        for (var i = 0; i < payload.Tunnels.data[1].Links.data.length; i++) {
          payload.Tunnels.data[1].Links.data[i].serverPort = startPort++;
        }
      }

      const {
        data: {
          insert_Site: {
            returning: [site],
          },
        },
      } = await apolloClient.mutate({
        mutation: CREATE_SITE,
        variables: { input: [payload] },
      });

      // Add site to WAN, if one is selected
      if (payload.wanId) {
        await SiteApi.addSiteToWan(site.id, payload.wanId);
      }

      return site;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async delete(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      // Delete Site children in order:
      //   SiteContacts, Links, Tunnels, SiteLANs, SiteRoutes, then Site
      const {
        data: { Site },
      } = await apolloClient.query({
        query: GET_SITE,
        variables: { id },
      });

      let promises = _map(Site[0].SiteContacts, ({ id }) => {
        return apolloClient.mutate({
          mutation: DELETE_SITE_CONTACT,
          variables: { id },
        });
      });
      await Promise.all(promises);

      for (const tunnel of Site[0].Tunnels) {
        promises = _map(tunnel.Links, ({ id }) => {
          return apolloClient.mutate({
            mutation: DELETE_LINK,
            variables: { id },
          });
        });
        await Promise.all(promises);

        const id = tunnel.id;
        promises.push(
          apolloClient.mutate({
            mutation: DELETE_TUNNEL,
            variables: { id },
          })
        );
        await Promise.all(promises);
      }

      // TODO: Why is this commented out?
      // promises = _map(Site[0].Tunnels, ({ id }) => {
      //   return apolloClient.mutate({
      //     mutation: DELETE_TUNNEL,
      //     variables: { id }
      //   })
      // })
      // await Promise.all(promises);
      promises = _map(Site[0].SiteLans, ({ id }) => {
        return apolloClient.mutate({
          mutation: DELETE_SITE_LAN,
          variables: { id },
        });
      });
      await Promise.all(promises);

      promises = _map(Site[0].SiteRoutes, ({ id }) => {
        return apolloClient.mutate({
          mutation: DELETE_SITE_ROUTE,
          variables: { id },
        });
      });
      await Promise.all(promises);

      await apolloClient.mutate({
        mutation: DELETE_SITE,
        variables: { id },
      });

      let acls = await ACLProfile.getAssignedACLs(id);
      await ACLProfile.deleteAssignments(acls, id);
    }
    else {
      cognitoUser.signOut();
    }
  },

  async reboot(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      var now = new Date().toISOString();
      await apolloClient.mutate({
        mutation: UPDATE_SITE,
        variables: { id, changes: { rebootRequest: now } },
      });
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateTunnelPortAndSeed(tunnel) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      let startPort = tunnel.portRangeStart;
      let tunnelAddressPairSeed = 0;

      if (tunnel.serverId) {
        startPort = await Tunnel.nextPort(tunnel.serverId, startPort);
        tunnelAddressPairSeed = (await Tunnel.getTunnelAddressPairSeed()) ?? 0;
      }
      const links = tunnel.Links.data.map((link, index) => {
        return { ...link, serverPort: startPort + index };
      });

      tunnel = { ...tunnel, tunnelAddressPairSeed, Links: { data: links } };
      tunnel = _omit(tunnel, ['clientIp4', 'clientIp4Cidr']);

      return tunnel;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updateLinkPortsFromNewRange(tunnel) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      let startPort = tunnel.portRangeStart;

      for (var i = 0; i < tunnel.Links.data.length; i++) {
        let link = tunnel.Links.data[i];

        await apolloClient.mutate({
          mutation: UPDATE_LINK,
          variables: { id: link.id, changes: { serverPort: startPort++ } },
        });
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getServerLinkPort(tunnel) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      let startPort = tunnel.portRangeStart;

      if (tunnel.serverId) {
        startPort = await Tunnel.nextPort(tunnel.serverId, startPort);
      }

      return startPort;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updatePortsForWanSite(siteId, wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const wanSites = await Wan.getWanSites(wanId);
      const wanSite = wanSites?.Site?.find((s) => s.id === siteId);
      let portCounter = 0;

      for (var i = 0; i < wanSite?.Tunnels?.length; i++) {
        let tunnel = wanSite.Tunnels[i];
        let availablePorts = await this.getAvailablePorts(wanId, tunnel);

        for (var j = 0; j < tunnel.Links.length; j++) {
          let link = tunnel.Links[j];
          let nextPort = availablePorts[portCounter++];

          await apolloClient.mutate({
            mutation: UPDATE_LINK,
            variables: { id: link.id, changes: { serverPort: nextPort } },
          });
        }
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updatePortsForWan(wanId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const wanSites = await Wan.getWanSites(wanId);
      let portCounter;

      for (var i = 0; Array.isArray(wanSites.Site) && i < wanSites.Site.length; i++) {
        let wanSite = wanSites.Site[i];
        portCounter = 0;

        for (var j = 0; Array.isArray(wanSite.Tunnels) && j < wanSite.Tunnels.length; j++) {
          let tunnel = wanSite.Tunnels[j];
          let availablePorts = await this.getAvailablePorts(wanId, tunnel);

          for (var k = 0; Array.isArray(tunnel.Links) && k < tunnel.Links.length; k++) {
            let link = tunnel.Links[k];
            let nextPort = availablePorts[portCounter++];

            await apolloClient.mutate({
              mutation: UPDATE_LINK,
              variables: { id: link.id, changes: { serverPort: nextPort } },
            });
          }
        }
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getAvailablePorts(wanId, tunnel) {
    const wanPorts = await Wan.getWanPorts(wanId, tunnel.portRangeStart, tunnel.portRangeEnd);
    const numPossiblePorts = tunnel.portRangeEnd - tunnel.portRangeStart + 1;

    const possiblePorts = Array.from({ length: numPossiblePorts }, (_, k) => k + tunnel.portRangeStart);

    let min = wanPorts && wanPorts.length > 0 ? Math.min.apply(null, wanPorts) : Infinity;
    let max = possiblePorts.length > 0 ? Math.max.apply(null, possiblePorts) + 1 : -Infinity;

    if (!wanPorts || wanPorts.length === 0) {
        throw new Error("wanPorts is empty or undefined");
    }

    return Array.from({ length: max - min }, (_, i) => i + min).filter((p) => wanPorts.includes(p));
  }
};
