import * as React from 'react';
import { AppDispatch } from '@app/store';
import UserSession from '@app/common/user-session';
import ApiKeyApi from '@app/api/apikey/apikey.actions';
import { textInputRequiredOnly, textInputValidate, isValidNumber, isWithinRange } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import { ExclamationCircleIcon, PencilAltIcon, BullhornIcon, PlusIcon } from '@patternfly/react-icons';
import { Modal, ModalVariant, PageSection, Title, TextInput, Flex, FlexItem, Form, FormGroup, Spinner, TextArea, ToggleGroup, ToggleGroupItem, Radio } from '@patternfly/react-core';
import './Admin.css';
import { connect } from 'react-redux';
import { State } from '@app/store/root-reducer';
import { Card, CardBody, Button, CardHeader, Col } from 'reactstrap';

export const ApiKeys: React.FunctionComponent = ({}) => {
  const emptyApiKey = { name: '', days_valid_for: 90, scope: 'ReadOnly', status: 'Active' };
  const defaultScope = { ReadOnly: true, ReadWrite: false, All: false };
  const [apiKey, setApiKey] = React.useState(emptyApiKey);
  const [loading, setLoading] = React.useState(true);
  const [apiKeys, setApiKeys] = React.useState([]);
  const [scope, setScope] = React.useState(defaultScope);
  const [validUntil, setValidUntil] = React.useState('');
  const [secretKey, setSecretKey] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);
  const [secretKeyMessage, setSecretKeyMessage] = React.useState('');
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isConfirmKeyOpen, setIsConfirmKeyOpen] = React.useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = React.useState(false);
  const [updatedStatus, setUpdatedStatus] = React.useState('');
  const [actionId, setActionId] = React.useState("");
  const [deleteKeyName, setDeleteKeyName] = React.useState('');
  const [statusKeyName, setStatusKeyName] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [buttonText, setButtonText] = React.useState('Create');
  const [cancelText, setCancelText] = React.useState('Cancel');
  const [error, setError] = React.useState('');
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    (async function() {
      try {
        loadApiKeys();
        setLoading(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const loadApiKeys = async () => {
    const userId = UserSession.getParam('userId');
    const apiKeyList = await ApiKeyApi.getAll(userId);
    const apiKeyRows = apiKeyList.map(item => {
      return apiKeyFromItem(item);
    });
    setApiKeys(apiKeyRows);
  }

  const getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] }, 
      { title: 'Access Key ID' }, 
      { title: 'Status' },
      { title: 'Valid Until', transforms: [sortable] },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' }},
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  const apiKeyFromItem = (item) => {
    return {
      cells: [
        { title: item.name },
        { title: item.access_key },
        { title: item.status },
        { title: item.not_valid_after },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
      ]
    }
  }

  const deleteApiKey = async (event, rowId, rowData) => {
    const tokenId = rowData['id']['title'];
    const keyName = rowData['name']['title'];
    setDeleteKeyName(keyName);
    setIsConfirmOpen(!isConfirmOpen);
    setActionId(tokenId);
  }

  const deleteRow = async () => {
    const userId = UserSession.getParam('userId');

    try {
      const result = await ApiKeyApi.delete(userId, actionId);
      const tokenIdIndex = getColumnIndex('ID');
      const updated = apiKeys.filter(t => t.cells[tokenIdIndex].title !== actionId);
  
      setApiKeys([ ...updated ]);
      setLoading(false);
      setIsConfirmOpen(false);
      setActionId("");
      setPage(1);
      setError('');
    }
    catch(error) {
      setError('There was an error deleting the Personal Access Token')
      console.log(error)
    }
  }

  const handleChange = (name, value) => {
    if (name === "days_valid_for") {
      const numericValue = parseInt(value, 10);
      setApiKey(prev => ({ ...prev, [name]: isNaN(numericValue) ? value : numericValue }));
    } else {
      setApiKey(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleScopeChange = (e, value) => {
    let updatedScope = { ReadOnly: false, ReadWrite: false, All: false };
    updatedScope[value] = true;
    setScope({ ...updatedScope });
    setApiKey(prev => ({ ...prev, scope: value }));
  };

  const handleConfirmToggle = () => {
    setError('');
    setIsConfirmOpen(!isConfirmOpen);
  };

  const handleConfirmKeyToggle = () => {
    setIsConfirmKeyOpen(!isConfirmKeyOpen);
  };

  const handleAddApiKeyToggle = () => {
    setError('');
    setButtonText('Create');
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const handleUpdateStatusModalToggle = () => {
    setError('');
    if (isUpdateStatusModalOpen) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsUpdateStatusModalOpen(false);
        setIsAnimating(false);
      }, 300);
    } else {
      setIsUpdateStatusModalOpen(true);
    }
    //setIsUpdateStatusModalOpen(!isOpen);
  };

  const closeAllDialogs = () => {
    setIsCreateModalOpen(false);
    setIsConfirmKeyOpen(false);
    setButtonText('Create');
  }

  const addApiKey = () => {
    setSecretKey('');
    setSecretKeyMessage('');
    setApiKey(emptyApiKey);
    setScope(defaultScope);
    setIsCreateModalOpen(true);
  };

  const showUpdateStatusModal = (event, rowId, rowData) => {
    const apiKeyId = rowData['id']['title'];
    const apiKeyStatus = rowData['status']['title'];
    const apiKeyName = rowData['name']['title'];
    setStatusKeyName(apiKeyName);
    setIsUpdateStatusModalOpen(true);
    setUpdatedStatus(apiKeyStatus),
    setActionId(apiKeyId);
    setError('');
  }

  const getStatusAction = () => {
    return updatedStatus === 'Active' ? 'Disable' : 'Enable';
  }

  const updateStatus = async () => {
    const userId = UserSession.getParam('userId');
    const action = getStatusAction();
    const payload = { status: updatedStatus === 'Active' ? 'Disabled' : 'Active' };

    try {
      const result = await ApiKeyApi.updateStatus(userId, actionId, payload);
      const idIndex = getColumnIndex('ID');
      const statusIndex = getColumnIndex('Status');
      const updatedApiKeys = apiKeys.map(ak => {
        if (ak.cells[idIndex].title === actionId) {
          ak.cells[statusIndex] = { title: action === 'Enable' ? 'Active' : 'Disabled' };
          return ak;
        }
        return ak;
      });
  
      setIsUpdateStatusModalOpen(false);
      setApiKeys([ ...updatedApiKeys ]);
      setError('');
    }
    catch(error) {
      setError('There was an error updating the Personal Access Token')
      console.log()
    }
  }

  const handleCreate = async (event) => {
    if (buttonText === 'Close') {
      setIsConfirmKeyOpen(true);
      return;
    }

    setIsCreating(true);

    try {
      const userId = UserSession.getParam('userId');
      // Ensure days_valid_for is a number
      if (typeof apiKey.days_valid_for === 'string') {
        apiKey.days_valid_for = parseInt(apiKey.days_valid_for, 10);
      }
      const result = await ApiKeyApi.create(userId, apiKey);

      if (result?.data?.request_id && result?.data?.token) {
        setValidUntil(result.data.token.not_valid_after.replaceAll('\ ', '-'))
        setSecretKey(result.data.token.secret_key);
        setSecretKeyMessage('Copy this secret key and store it in a secure and safe place, as it will not be available once this window is closed');
        const { secret_key, ...newToken } = result.data.token;
        newToken.status = 'Active';
        setApiKeys([ ...apiKeys, apiKeyFromItem(newToken) ]);
      }
      else {
        console.log(result.data.error);
      }
      setIsCreating(false);
      setButtonText('Close');
      setCancelText('')
      setError('');
    }
    catch (error) {
      setIsCreating(false);
      setError('There was an error creating the Personal Access Token');
      console.log(error)
    }
  }
  
  const statusAction = getStatusAction();
  const nameIsValid = apiKey.name && apiKey.name.length < 256;
  const nameErrorState = nameIsValid ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Required field' }
  const daysIsValid = isValidNumber(apiKey.days_valid_for) && isWithinRange(apiKey.days_valid_for, 1, 365);
  const daysErrorState = daysIsValid ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a number between 1-365' }
  const formValid = nameErrorState.validated !== 'error' && daysErrorState.validated !== 'error';

  return (
    <Col>
      <Card className="h-100">
        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
          <div className="w-100 d-flex justify-content-between">
            <div className="icon-heading-container">
              <div className="icon-heading">
                Personal Access Tokens                  
              </div>
            </div>                            
            <div className="pointer add-section" onClick={addApiKey}>
              <PlusIcon /> &nbsp;Add PAT
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? 
              <Spinner size="lg" />
            : apiKeys?.length === 0 ? <div>No Personal Access Tokens found!</div> :
              <div>
                <PatternflyTable
                  columns={getColumns}
                  deleteRow={deleteApiKey}
                  updateStatus={showUpdateStatusModal}
                  data={apiKeys}
                  page={page}
                />
              </div>
            }
        </CardBody>
      </Card>

      <Modal
        title="Create Personal Access Token"
        titleIconVariant={BullhornIcon}
        variant={ModalVariant.small}
        isOpen={isCreateModalOpen}
        onClose={handleAddApiKeyToggle}
        actions={secretKey !== '' ? 
          [
            <Button 
              key="confirm" 
              variant="primary" 
              className="ethica-button-green" 
              onClick={handleCreate}
            >
              {buttonText}
            </Button>
          ] : 
          [
            <Button 
              key="confirm" 
              variant="primary" 
              className="ethica-button-green"
              isDisabled={!formValid} 
              isLoading={isCreating} 
              onClick={handleCreate}
            >
              {buttonText}
            </Button>,
            <Button 
              key="cancel" 
              variant="primary"
              className="ethica-button-cancel" 
              onClick={handleAddApiKeyToggle}
            >
              {cancelText}
            </Button>
          ]
        }
      >
        <Form isHorizontal>
          <Flex>
            <FlexItem>
              <FormGroup label="Name" isRequired fieldId="name" className="form-item"
                helperTextInvalid={nameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />}>
                <TextInput
                  isRequired type="text" id="name" name="name"
                  value={apiKey.name} onChange={(e) => handleChange("name", e)} className="form-control"
                />
              </FormGroup>
              <FormGroup label="Days valid for" isRequired fieldId="days_valid_for" className="form-item"
                helperTextInvalid={daysErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />}>
                <TextInput
                  isRequired type="text" id="days_valid_for" name="days_valid_for"
                  value={apiKey.days_valid_for} onChange={(e) => handleChange("days_valid_for", e)} className="form-control"
                />
              </FormGroup>
              <br />
              <FormGroup label="Scope" fieldId="scope" className="form-item">
                <Radio id="readOnly" label="Read Only" name="scope-group"
                  isChecked={scope.ReadOnly} onChange={(e) => handleScopeChange(e, 'ReadOnly')}
                />
                <Radio 
                  id="readWrite" label="Read Write" name="scope-group"
                  isChecked={scope.ReadWrite} onChange={(e) => handleScopeChange(e, 'ReadWrite')}
                />
                <Radio 
                  id="all" label="All" name="scope-group"
                  isChecked={scope.All} onChange={(e) => handleScopeChange(e, 'All')}
                />
              </FormGroup>

              { error !== '' ? <p><br />{ error }</p> : <></> }

              { secretKey !== '' ?
                <React.Fragment>
                  <br />
                  <h1><strong>Secret Key</strong></h1>
                  {secretKeyMessage}<br /><br />
                  <b>Valid Until:</b> {validUntil}<br /><br />
                  <Card isCompact className="secret-key">
                    <CardBody>
                      {secretKey}
                    </CardBody>
                  </Card>
                </React.Fragment>
              : <></> }
            </FlexItem>
          </Flex>
        </Form>
      </Modal>

      <Modal
        title="Confirm Action"
        titleIconVariant={BullhornIcon}
        variant={ModalVariant.small}
        isOpen={isConfirmOpen}
        onClose={handleConfirmToggle}
        actions={[
          <Button key="confirm" variant="primary" className="ethica-button-green" onClick={deleteRow}>Yes</Button>,
          <Button key="cancel" variant="primary" className="ethica-button-cancel" onClick={handleConfirmToggle}>Cancel</Button>
        ]}
      >
        <p>Are you sure you want to delete this Personal Access Token?</p>
        <p><b>{deleteKeyName}</b></p>

        { error !== '' ? <p><br />{ error }</p> : <></> }

      </Modal>

      <Modal
        title="Confirm Action"
        titleIconVariant={BullhornIcon}
        variant={ModalVariant.small}
        isOpen={isConfirmKeyOpen}
        onClose={handleConfirmKeyToggle}
        actions={[
          <Button key="confirm" variant="primary" className="ethica-button-green" onClick={closeAllDialogs}>Yes</Button>,
          <Button key="cancel" variant="primary" className="ethica-button-cancel" onClick={handleConfirmKeyToggle}>Cancel</Button>
        ]}
      >
        <p>Are you sure you want to close this window?</p>
      </Modal>

      <Modal
        title="Update Personal Access Token Status"
        titleIconVariant={BullhornIcon}
        variant={ModalVariant.small}
        isOpen={isUpdateStatusModalOpen || isAnimating}
        onClose={handleUpdateStatusModalToggle}
        className={isAnimating ? 'modal-fade-out' : isUpdateStatusModalOpen ? 'modal-fade-in' : ''}
        actions={[
          <Saver 
            key="update-status"
            submitButtonText={statusAction} 
            submitButtonisDisabled={false}
            submit={updateStatus}
            showConfirmationMessage={false}
            showCancel={false}
          />,
          <Button 
            key="cancel-update" 
            variant="primary" 
            className="ethica-button-cancel" 
            onClick={handleUpdateStatusModalToggle}
          >
            Cancel
          </Button>
        ]}
      >
        Are you sure you want to <strong>{statusAction}</strong> the {statusKeyName} Personal Access Token?

        { error !== '' ? <p><br />{ error }</p> : <></> }
      </Modal>
    </Col>
  );
}

export default ApiKeys;