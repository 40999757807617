import React from 'react';
import { Card, CardImg, CardImgOverlay, CardTitle, CardText } from 'reactstrap';
import Todos from '@app/components/Todos';
import backgroundImage from '@app/assets/images/bg/background_1920-2.jpg';

type TodosCardProps = {
  image?: string;
  title?: string;
  subtitle?: string;
  todos?: { id: number; title: string; done: boolean }[];
};

const TodosCard: React.FC<TodosCardProps> = ({
  image = backgroundImage,
  title = 'Tasks',
  subtitle = 'Due soon...',
  todos = [],
  ...restProps
}) => {
  return (
    <Card {...restProps}>
      <div className="position-relative">
        <CardImg src={image} />
        <CardImgOverlay className="bg-dark" style={{ opacity: 0.2 }}>
          <CardTitle className="text-white">{title}</CardTitle>
          <CardText className="text-white">{subtitle}</CardText>
        </CardImgOverlay>
      </div>
      <Todos todos={todos} />
    </Card>
  );
};

export default TodosCard;
