import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Page } from '@patternfly/react-core';
import { userIsAuthenticatedRedir } from './auth.component';
import { withSplashScreen } from './splash.screen';
import { Header } from './header.component';
import { Sidebar } from './sidebar.component';
import ThemeContext from '@app/providers/contexts/ThemeContext';

// const AppLayout = withRouter(withSplashScreen(userIsAuthenticatedRedir(({ children }) => {
//   return (
//     <Page
//       isManagedSidebar
//       mainContainerId="primary-app-container"
//       header={<Header />}
//       sidebar={<Sidebar />}
//     >
//       {children}
//     </Page>
//   );
// })));

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

const AppLayout = withRouter(withSplashScreen(userIsAuthenticatedRedir(({ children }) => {
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  return (
    <main className={`cr-app bg-${theme}`} id="primary-app-container">
      <Sidebar />
      <div className="cr-content container-fluid">
        <Header />
          {children}
      </div>
    </main>
  );
})));

export { AppLayout };