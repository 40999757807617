import React, { useState, useEffect, useContext } from 'react';
import SearchInput from '../../components/SearchInput';
import Avatar from '../../components/Avatar';
import { UserCard } from '../../components/card';
import UserApi from '@app/api/user-api/user.actions';
import UserSession from '@app/common/user-session';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdLightMode,
  MdDarkMode,
  MdAccountCircle, MdOutlineNaturePeople, MdPeople, MdPerson2
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { Link } from 'react-router-dom';
import { actionCreators } from '../../../app/common/auth/auth.reducer';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import SidebarContext from '@app/providers/contexts/SidebarContext';
import { useDispatch } from 'react-redux';
import CartHeader from './cart.header.component';

interface HeaderProps {}

const bem = bn.create('header');

export const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const { theme, toggleTheme } = useContext(ThemeContext);
  //@ts-ignore
  const { sidebarStatus, toggleSidebar } = useContext(SidebarContext);

  const [isNavOpen, setNavOpen] = useState(true);

  const [userState, setUserState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    isLoading: false,
  });

  const [uiState, setUiState] = useState({
    isOpenUserCardPopover: false,
  });

  const loadProfile = async () => {
    try {
      const userId = UserSession.getUserId();
      if (!userId) {
        throw new Error("User ID is undefined");
      }

      const user = await UserApi.get(userId);

      if (!user) {
        throw new Error("User data is undefined");
      }

      setUserState((prevState) => ({
        ...prevState,
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        phone: user.phone || '',
      }));
    } catch (error) {
      console.error('Failed to load user profile', error);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  const onNavToggle = () => setNavOpen(!isNavOpen);

  const toggleUserCardPopover = () => {
    setUiState((prevState) => ({
      ...prevState,
      isOpenUserCardPopover: !prevState.isOpenUserCardPopover,
    }));
  };

  const handleLogout = () => {
    dispatch(actionCreators.logoutRequest());
  };

  return (
    <Navbar light expand className={bem.b(theme === 'light' ? 'bg-white' : 'bg-black')}>
      <Nav navbar className="me-2 ps-1">
        <Button outline onClick={toggleSidebar} data-testid="sidebar_button" className="sidebar-button" id="sidebar_button">
          <MdClearAll size={25} />
        </Button>
      </Nav>
      {/* <Nav navbar>
        <SearchInput />
      </Nav> */}

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem>
          <CartHeader />
        </NavItem>
        <NavItem>
          <NavLink>
            <div className="pe-3" onClick={toggleTheme}>
              {theme === 'light' ? <MdLightMode /> : <MdDarkMode style={{ color: 'white' }} />}
            </div>

            <MdAccountCircle
              id="Popover2"
              fill="#85ba3b"
              size="25"
              onClick={toggleUserCardPopover}
              className="can-click"
            />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={uiState.isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light header-profile">
              <UserCard
                title={`${userState.firstName} ${userState.lastName}`}
                subtitle={userState.email}
                text={userState.phone}
                className="border-light"
              >
                <ListGroup flush>
                  <ListGroupItem tag="button" action className="border-light">
                    <Link to="/profile">
                      <MdPerson2 /> Profile
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light">
                    <Link to="/support">
                      <MdHelp /> Help
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light" onClick={handleLogout}>
                    <MdExitToApp /> Sign Out
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
};
