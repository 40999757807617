import * as React from 'react';
import { FormGroupSpacer } from '../../../../components/forms/pf/form-group-spacer.component';
import { IconHeading } from '@app/components/IconHeading';
import { textInputRequiredOnly } from '@app/lib/validator';
import {
  AutomationIcon,
  BuildIcon,
  CpanelIcon,
  ExclamationCircleIcon,
  InfoCircleIcon,
  InfrastructureIcon,
  ListIcon,
  LockIcon,
  NetworkIcon,
  OptimizeIcon,
  ServiceIcon,
  TachometerAltIcon,
} from '@patternfly/react-icons';
import {
  Checkbox,
  FormGroup,
  Popover,
  Select,
  SelectVariant,
  Spinner,
  TextInput,
} from '@patternfly/react-core';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';

interface TunnelSettingsProps {
  activeController: any;
  activeControllers: any;
  activeServer: any;
  activeServers: any;
  businessContinuity: any;
  businessContinuityOptions: any;
  businessContinuityCardClass: string;
  dataLoading: boolean;
  encryptionCardClass: string;
  failoverEnabled: boolean;
  flexDirection: any;
  handleChange: any;
  handleCheckboxChange: (checked: boolean, event: React.FormEvent<HTMLInputElement>) => void;
  handleEnabled: any;
  handleEnableFailoverChange: any;
  handleReorderBufferEnabled: any;
  handleServerlessChange: any;
  handleTunnelChange: any;
  handleTunnelEnabled: any;
  handleTunnelEnabledWithDefault: any;
  handleTunnelInverseEnabled: any;
  handleTunnelPortRange: any;
  isActiveControllerOpen: boolean;
  isActiveServerOpen: boolean;
  isBusinessContinuityOpen: boolean;
  isLbAlgoOpen: boolean;
  isServerless: boolean;
  isStandbyControllerOpen: boolean;
  isStandbyServerOpen: boolean;
  loadBalancingCardClass: string;
  lbAlgo: string;
  lbAlgoOptions: Array<{key: string, props: {value: string}}>;
  getLbAlgoDescription: (algo: string) => string;
  flowlet: boolean;
  lbAlgoErrorState: any;
  lossCardClass: string;
  lossManual: any;
  lossToleranceErrorState: any;
  mtuCardClass: string;
  mtuErrorState: any;
  mtuManual: any;
  onActiveControllerSelect: any;
  onActiveServerSelect: any;
  onObjectToggle: any;
  onSelectClick: (event: React.ChangeEvent<HTMLInputElement>, selection: string, isPlaceholder: boolean, stateVar: string, isOpenKey: string) => void;
  onSelectToggle: (isOpen: boolean, isOpenKey: string) => void;
  onStandbyControllerSelect: any;
  onStandbyServerSelect: any;
  portRangeCardClass: string;
  portRangeCustom: any;
  portRangeEndErrorState: any;
  portRangeStartErrorState: any;
  reorderBufferErrorState: any;
  reorderCardClass: any;
  siteTunnels: any;
  standbyController: any;
  standbyControllers: any;
  standbyServer: any;
  standbyServers: any;
  wanEnabled: boolean;
  linkLbParam?: string;
  isLinkLbParamOpen?: boolean;
  onLinkLbParamSelect?: any;
}

export const TunnelSettings: React.FC<TunnelSettingsProps> = ({
  activeController,
  activeControllers,
  activeServer,
  activeServers,
  businessContinuity,
  businessContinuityOptions,
  businessContinuityCardClass,
  dataLoading,
  encryptionCardClass,
  failoverEnabled,
  flexDirection,
  handleEnableFailoverChange,
  handleReorderBufferEnabled,
  handleCheckboxChange,
  handleServerlessChange,
  handleTunnelChange,
  handleTunnelEnabled,
  handleTunnelEnabledWithDefault,
  handleTunnelInverseEnabled,
  handleTunnelPortRange,
  isActiveControllerOpen,
  isActiveServerOpen,
  isBusinessContinuityOpen,
  isServerless,
  isStandbyControllerOpen,
  isStandbyServerOpen,
  isLbAlgoOpen,
  loadBalancingCardClass,
  lbAlgo,
  flowlet,
  lbAlgoErrorState,
  lbAlgoOptions,
  getLbAlgoDescription,
  lossCardClass,
  lossManual,
  lossToleranceErrorState,
  mtuCardClass,
  mtuErrorState,
  mtuManual,
  onActiveControllerSelect,
  onActiveServerSelect,
  onObjectToggle,
  onSelectClick,
  onSelectToggle,
  onStandbyControllerSelect,
  onStandbyServerSelect,
  portRangeCardClass,
  portRangeCustom,
  portRangeEndErrorState,
  portRangeStartErrorState,
  reorderBufferErrorState,
  reorderCardClass,
  siteTunnels,
  standbyController,
  standbyControllers,
  standbyServer,
  standbyServers,
  wanEnabled,
  linkLbParam,
  isLinkLbParamOpen,
  onLinkLbParamSelect,
}) => {
  // tunnelConfig: {
  //   activeController: activeController,
  //   activeServer: activeServer,
  //   standbyController: standbyController,
  //   standbyServer: standbyServer,
  //   lossTolerance: lossTolerance,
  //   reorderBufferTime: reorderBufferTime,
  //   shouldReorderBuffer: shouldReorderBuffer,
  //   isClearTextData: isClearTextData,
  //   mtu: mtu,
  //   businessContinuity: businessContinuity
  // }

  // TODO: Use appropriate event handlers, refactor the SiteForm state for tunnelConfig

  const flowletEnabled = lbAlgo !== "FastPath" ? true : false;

  const getLinkLbParamOptions = () => {
    return [
      { key: "jitter", value: "jitter", label: "Jitter" },
      { key: "loss", value: "loss", label: "Packet Loss" }
    ];
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <Card>
            <CardHeader>
              <div className="w-100 d-flex justify-content-between">
                <IconHeading
                  icon={<InfrastructureIcon className="icon-medium" />}
                  heading="Controller Configuration"
                />
              </div>
            </CardHeader>
            <CardBody>
              <Card>
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="pointer add-section sub-heading">
                      <Checkbox
                        label="No Tunnel"
                        aria-label="No Tunnel"
                        name="isServerless"
                        id="isServerless"
                        key="isServerless"
                        isChecked={isServerless}
                        isDisabled={wanEnabled}
                        onChange={handleServerlessChange}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup
                    label="Primary Controller"
                    isRequired={!isServerless}
                    fieldId="active-controller-toggle"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={isServerless || wanEnabled ? 'default' : textInputRequiredOnly(activeController.name)}
                    className="form-item"
                  >
                    <Input
                      type="select" 
                      value={activeController.name || ''}
                      onChange={(e) => onActiveControllerSelect(e, e.target.value)}
                      onClick={(e) => onObjectToggle(e, 'isActiveControllerOpen')}
                      open={isActiveControllerOpen} 
                      disabled={isServerless || wanEnabled}
                    >
                      {activeControllers &&
                      <>
                        <option key="default" value="">Select...</option>
                        {activeControllers.map((activeController, index) => (
                            <option key={activeController.key || index} value={activeController.props.value}>
                                {activeController.props.value}
                            </option>
                        ))}
                      </>
                      }
                    </Input>
                  </FormGroup>

                  <FormGroup
                    label="Primary Gateway"
                    isRequired={!isServerless}
                    fieldId="active-server-toggle"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={
                      isServerless || wanEnabled ? 'default' : textInputRequiredOnly(activeServer.ip4Address)
                    }
                    className="form-item"
                  >
                    <Input
                      type="select" 
                      value={activeServer.ip4Address || ''}
                      onChange={(e) => onActiveServerSelect(e, e.target.value)}
                      onClick={(e) => onObjectToggle(e, 'isActiveServerOpen')}
                      open={isActiveServerOpen} 
                      disabled={isServerless || wanEnabled}
                    >
                      {activeServers &&
                      <>
                        <option key="default" value="">Select...</option>
                        {activeServers.map((activeServer, index) => (
                            <option key={activeServer.key || index} value={activeServer.props.value}>
                                {activeServer.props.value}
                            </option>
                        ))}
                      </>
                      }
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>

              <Card className="mt-3">
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="pointer add-section sub-heading">
                      <Checkbox
                        label="Enable Failover"
                        aria-label="Enable Failover"
                        name="failoverEnabled"
                        id="failoverEnabled"
                        key="failoverEnabled"
                        isChecked={failoverEnabled}
                        isDisabled={isServerless || wanEnabled}
                        onChange={handleEnableFailoverChange}
                        className="form-item"
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup
                    label="Secondary Controller"
                    isRequired={failoverEnabled}
                    fieldId="standby-controller-toggle"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={failoverEnabled ? textInputRequiredOnly(standbyController.name) : 'default'}
                    className="form-item"
                  >
                    <Input
                      type="select" 
                      value={standbyController.name || ''}
                      onChange={(e) => onStandbyControllerSelect(e, e.target.value)}
                      onClick={(e) => onObjectToggle(e, 'isStandbyControllerOpen')}
                      open={isStandbyControllerOpen} 
                      disabled={!failoverEnabled || isServerless || wanEnabled}
                    >
                      {standbyControllers &&
                      <>
                        <option key="default" value="">Select...</option>
                        {standbyControllers.map((standbyController, index) => (
                            <option key={standbyController.key || index} value={standbyController.props.value}>
                                {standbyController.props.value}
                            </option>
                        ))}
                      </>
                      }
                    </Input>
                  </FormGroup>
                  
                  <FormGroup
                    label="Secondary Gateway"
                    isRequired={failoverEnabled}
                    fieldId="standby-server-toggle"
                    helperTextInvalid=" Required field"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={failoverEnabled && !isServerless && !wanEnabled ? textInputRequiredOnly(standbyServer.ip4Address) : 'default'}
                    className="form-item"
                  >
                    <Input
                      type="select" 
                      value={standbyServer.ip4Address || ''}
                      onChange={(e) => onStandbyServerSelect(e, e.target.value)}
                      onClick={(e) => onObjectToggle(e, 'isStandbyServerOpen')}
                      open={isStandbyServerOpen} 
                      disabled={!failoverEnabled || isServerless || wanEnabled}
                    >
                      {standbyServers &&
                      <>
                        <option key="default" value="">Select...</option>
                        {standbyServers.map((standbyServer, index) => (
                            <option key={standbyServer.key || index} value={standbyServer.props.value}>
                                {standbyServer.props.value}
                            </option>
                        ))}
                      </>
                      }
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>

        <Col lg={9} md={9} sm={12} xs={12}>
          <Row>
            <Col>
              <Card className="h-100">
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="w-100">
                      <IconHeading
                        icon={<TachometerAltIcon className="icon-medium" />}
                        heading="Loss Threshold"
                        enableText="Enabled"
                        showEnable={true}
                        handleEnable={(e) => handleTunnelEnabledWithDefault(e, '25', 'lossTolerance', 'lossManual')}
                        enabled={!isServerless && lossManual}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup
                    label="Percent"
                    isRequired={lossManual}
                    fieldId="lossTolerance"
                    helperTextInvalid={lossToleranceErrorState.errorText}
                    helperTextInvalidIcon={<ExclamationCircleIcon />}
                    validated={lossManual ? lossToleranceErrorState.validated : 'default'}
                    className="form-item"
                  >
                    <TextInput
                      className="form-input form-control"
                      isDisabled={isServerless || !lossManual}
                      isRequired={lossManual}
                      type="text"
                      validated={lossManual ? lossToleranceErrorState.validated : 'default'}
                      id="lossTolerance"
                      name="lossTolerance"
                      placeholder=""
                      value={siteTunnels[0].lossTolerance}
                      onChange={(e) => handleTunnelChange('lossTolerance', e)}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col>
            <Card className="h-100">
              <CardHeader>
                <div className="w-100 d-flex justify-content-between">
                  <div className="w-100">
                    <IconHeading
                      icon={<LockIcon className="icon-medium" />}
                      heading="Encryption"
                      checkboxDisabled={wanEnabled}
                      showEnable={true}
                      handleEnable={(e) => handleTunnelInverseEnabled('isClearTextData', e)}
                      enabled={!isServerless && !siteTunnels[0].isClearTextData}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="font-12">If enabled, all tunnel traffic will be encrypted at 128-bit using a 256-bit key</p>
              </CardBody>
            </Card>
            </Col>

            <Col>
            <Card className="h-100">
              <CardHeader>
                <div className="w-100 d-flex justify-content-between">
                  <div className="w-100">
                    <IconHeading
                      icon={<ServiceIcon className="icon-medium" />}
                      heading="MTU Setting"
                      enableText="Manual"
                      showEnable={true}
                      handleEnable={(e) => handleTunnelEnabledWithDefault(e, '1452', 'mtu', 'mtuManual')}
                      enabled={!isServerless && mtuManual}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {dataLoading ? (
                  <Spinner size="lg" />
                ) : (
                  <div>
                    <FormGroup
                      label="Size"
                      isRequired={mtuManual}
                      fieldId="mtu"
                      helperTextInvalid={mtuErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={mtuManual ? mtuErrorState.validated : 'default'}
                      className="form-item"
                    >
                      <TextInput
                        className="form-input form-control"
                        validated={mtuManual ? mtuErrorState.validated : 'default'}
                        isDisabled={isServerless || !mtuManual}
                        isRequired={mtuManual}
                        type="text"
                        id="mtu"
                        name="mtu"
                        placeholder=""
                        value={mtuManual ? siteTunnels[0].mtu : 'Auto'}
                        onChange={(e) => handleTunnelChange('mtu', e)}
                      />
                    </FormGroup>
                  </div>
                )}
              </CardBody>
            </Card>
            </Col>

            <Col>
              <Card className="h-100">
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                    <IconHeading icon={<AutomationIcon className="icon-medium" />} heading="Business Continuity" />
                  </div>
                </CardHeader>
                <CardBody>
                  <p className="font-12">Select an interface below through which to pass traffic in the event of a tunnel failure</p>
                    <FormGroup
                      label=""
                      className="form-item"
                    >
                      <Input
                        type="select" 
                        value={businessContinuity || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onSelectClick(e, e.target.value, false, 'businessContinuity', 'isBusinessContinuityOpen');
                        }}
                        onClick={() => onSelectToggle(true, 'isBusinessContinuityOpen')}
                        open={isBusinessContinuityOpen} 
                        disabled={isServerless}
                      >
                        {businessContinuityOptions &&
                        <>
                          <option key="default" value="">Select...</option>
                          {businessContinuityOptions.map((businessContinuityOption, index) => (
                              <option key={businessContinuityOption.key || index} value={businessContinuityOption.props.value}>
                                  {businessContinuityOption.props.value}
                              </option>
                          ))}
                        </>
                        }
                      </Input>
                    </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
            <Card className="h-100">
              <CardHeader>
                <div className="w-100 d-flex justify-content-between">
                  <div className="w-100">
                    <IconHeading
                      icon={<ListIcon className="icon-medium" />}
                      heading="Port Range"
                      enableText="Custom"
                      showEnable={true}
                      enabled={!isServerless && portRangeCustom}
                      handleEnable={(e) => handleTunnelPortRange(e, 'portRangeCustom')}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <FormGroup
                  label="Start"
                  isRequired={portRangeCustom}
                  fieldId="portRangeStart"
                  helperTextInvalid={portRangeStartErrorState.errorText}
                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                  validated={portRangeCustom ? portRangeStartErrorState.validated : 'default'}
                  className="form-item"
                >
                  <TextInput
                    className="form-input form-control"
                    isDisabled={isServerless || !portRangeCustom}
                    isRequired={portRangeCustom}
                    type="text"
                    validated={portRangeCustom ? portRangeStartErrorState.validated : 'default'}
                    id="portRangeStart"
                    name="portRangeStart"
                    placeholder=""
                    value={siteTunnels[0].portRangeStart}
                    onChange={(e) => handleTunnelChange('portRangeStart', e)}
                  />
                </FormGroup>

                <FormGroup
                  label="End"
                  isRequired={portRangeCustom}
                  fieldId="portRangeEnd"
                  helperTextInvalid={portRangeEndErrorState.errorText}
                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                  validated={portRangeCustom ? portRangeEndErrorState.validated : 'default'}
                  className="form-item"
                >
                  <TextInput
                    className="form-input form-control"
                    isDisabled={isServerless || !portRangeCustom}
                    isRequired={portRangeCustom}
                    type="text"
                    validated={portRangeCustom ? portRangeEndErrorState.validated : 'default'}
                    id="portRangeEnd"
                    name="portRangeEnd"
                    placeholder=""
                    value={siteTunnels[0].portRangeEnd}
                    onChange={(e) => handleTunnelChange('portRangeEnd', e)}
                  />
                </FormGroup>
              </CardBody>
            </Card>
            </Col>

            <Col>
              <Card className="h-100">
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="w-100">
                      <IconHeading
                        icon={<BuildIcon className="icon-medium" />}
                        heading="Reorder Buffer"
                        enableText="Enabled"
                        showEnable={true}
                        handleEnable={handleReorderBufferEnabled}
                        enabled={!isServerless && siteTunnels[0].shouldReorderBuffer}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {dataLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <div>
                      <FormGroup
                        label="ms"
                        isRequired={siteTunnels[0].shouldReorderBuffer}
                        fieldId="reorderBufferTime"
                        helperTextInvalid={reorderBufferErrorState.errorText}
                        helperTextInvalidIcon={<ExclamationCircleIcon />}
                        validated={siteTunnels[0].shouldReorderBuffer !== "0" ? reorderBufferErrorState.validated : 'default'}
                        className="form-item"
                      >
                        <TextInput
                          className="form-input form-control"
                          validated={siteTunnels[0].shouldReorderBuffer !== "0" ? reorderBufferErrorState.validated : 'default'}
                          isDisabled={isServerless || !siteTunnels[0].shouldReorderBuffer}
                          isRequired={siteTunnels[0].shouldReorderBuffer}
                          type="text"
                          id="reorderBufferTime"
                          name="reorderBufferTime"
                          placeholder=""
                          value={siteTunnels[0].reorderBufferTime}
                          onChange={(e) => handleTunnelChange('reorderBufferTime', e)}
                        />
                      </FormGroup>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} md={6} sm={12} xs={12}>
              <Card className="h-100">
                <CardHeader>
                  <div className="w-100 d-flex justify-content-between">
                        <IconHeading
                          icon={<NetworkIcon className="icon-medium" />}
                          heading="Bonding Algorithm"
                        />
                      <div className="sub-heading">
                        <Checkbox
                          label="Enable Flowlet" aria-label="Enable Flowlet"
                          name="flowlet" id="flowlet" key="flowlet" isDisabled={isServerless || !flowletEnabled}
                          onChange={handleCheckboxChange}
                          isChecked={flowlet}
                        />
                        <Popover
                              aria-label="Flowlet" footerContent=""
                              headerContent={
                                <div>Flowlet Description</div>
                              }
                              bodyContent={
                                <div>Burst of packets belong to same flow are split based on RTT
                                  difference of various links and sent to avoid traffic reordering.
                                  Use to reduce processing overhead in Gateway
                                </div>
                              }
                            >
                              <InfoCircleIcon className="simple-popover ms-2" />
                        </Popover>
                      </div>
                  </div>
                </CardHeader>
                <CardBody>
                {dataLoading ? (
                      <Spinner size="lg" />
                    ) : (
                      <div>
                        <FormGroup
                          label="" isRequired={false} fieldId="lbAlgo"
                          helperTextInvalid={lbAlgoErrorState.errorText}
                          helperTextInvalidIcon={<ExclamationCircleIcon />}
                          className="form-item"
                        >
                          <Input
                            type="select" 
                            value={lbAlgo || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const selection = event.target.value;
                              onSelectClick(event, selection, false, 'lbAlgo', 'isLbAlgoOpen');
                            }}
                            onClick={() => onSelectToggle(true, 'isLbAlgoOpen')}
                            open={isLbAlgoOpen} 
                          >
                            {lbAlgoOptions &&
                            <>
                              <option key="default" value="">Select...</option>
                              {lbAlgoOptions.map((lbAlgoOption, index) => (
                                  <option key={lbAlgoOption.key || index} value={lbAlgoOption.props.value}>
                                      {lbAlgoOption.props.value}
                                  </option>
                              ))}
                            </>
                            }
                          </Input>
                        </FormGroup>

                        {lbAlgo === "Dynamic Link Select" && (
                          <FormGroup
                            label="Network Parameter" isRequired={true} fieldId="linkLbParam"
                            helperTextInvalid="Required field"
                            helperTextInvalidIcon={<ExclamationCircleIcon />}
                            className="form-item mt-3"
                          >
                            <Input
                              type="select" 
                              value={linkLbParam || ''}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const selection = event.target.value;
                                if (onLinkLbParamSelect) {
                                  onLinkLbParamSelect(event, selection);
                                } else {
                                  onSelectClick(event, selection, false, 'linkLbParam', 'isLinkLbParamOpen');
                                }
                              }}
                              onClick={() => onSelectToggle(true, 'isLinkLbParamOpen')}
                              open={isLinkLbParamOpen}
                              disabled={isServerless}
                            >
                              <option key="default" value="">Select...</option>
                              {getLinkLbParamOptions().map(option => (
                                <option key={option.key} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        )}

                        <div className="mt-3">
                          <p className="font-12">{getLbAlgoDescription(lbAlgo)}</p>
                        </div>
                      </div>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TunnelSettings;