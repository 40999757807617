import _map from 'lodash-es/map';
import { ALL_GATEWAYS, CREATE_GATEWAY, UPDATE_GATEWAY, GET_GATEWAY, DELETE_GATEWAY } from './gql.queries';
import {
  CREATE_SERVER,
  UPDATE_SERVER,
  DELETE_SERVER,
  DELETE_PUBLIC_IP,
  UPDATE_PUBLIC_IP,
} from '@app/api/gateway/gql.queries';
import UserSession from '@app/common/user-session';
import { Timezones } from '@app/common/world';
import { GQLHelpers } from '../hasura/ts';
import { apolloClient } from '../graphql/client';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

// TODO: Controllers in this app are actually Gateways on the backend. This
//       should be refactored for consistency.
export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      const { objects: gateways } = await GQLHelpers.Fragments.Gateway.queryObjects({
        apolloClient,
        options: { variables: { where: { partnerId: { _eq: partnerId } } } },
      });
      return gateways;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { data: { gateway } } = await apolloClient.query({
        query: GET_GATEWAY,
        variables: { id },
      });
      return gateway;
    }
    else {
      cognitoUser.signOut();
      return false;
    }
  }
};
