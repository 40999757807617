import * as React from 'react';
import { Table, TableHeader, TableBody, SortByDirection, TableVariant } from '@patternfly/react-table';
import { Pagination, PaginationVariant } from "@patternfly/react-core";

class PatternflyTable extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const actions = []; 
    const actionColumn = this.props.columns().find(item => {
      return item.title.toLowerCase() === 'actions';
    });
    
    if (actionColumn) {
      if (this.props.editRow) {
        actions.push({ title: 'Edit', key: "action-1", onClick: (event, rowId, rowData, extra) => this.props.editRow(event, rowId, rowData)});
      }
      if (this.props.deleteRow) {
        actions.push({ title: 'Delete', key: "action-2", onClick: (event, rowId, rowData, extra) => this.props.deleteRow(event, rowId, rowData)});
      }
      if (this.props.updateRole) {
        actions.push({ title: 'Update Role', key: "action-3", onClick: (event, rowId, rowData, extra) => this.props.updateRole(event, rowId, rowData)});
      }
      if (this.props.updateStatus) {
        actions.push({ title: 'Change Status', key: "action-4", onClick: (event, rowId, rowData, extra) => this.props.updateStatus(event, rowId, rowData)});
      }
    }

    this.state = {
      page: 1,
      perPage: 10,
      columns: [],
      data: [],
      actions: actions,
      rows: [],
      sortBy: {}
    }

    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    const data = this.props.data;
    this.setState(() => ({
      rows: data.slice(0, this.state.perPage),
      columns: this.props.columns(),
      data: data
    }));
  }

  // Ensure that row deletes from parent components refresh to remove deleted rows
  UNSAFE_componentWillReceiveProps(nextProps) {
    var { data, rows, page, perPage } = this.state;

    if (nextProps.perPage !== perPage) {
      perPage = nextProps.perPage;
    }
    if (nextProps.data !== data) {
      data = nextProps.data;
      const currentPage = page;
      const start = page === 1 ? 0 : (perPage - 1) * perPage;
      const end = start + perPage;
      rows = nextProps.data.slice(0, perPage)
    }
    if (nextProps.page !== page) {
      page = nextProps.page;
    }

    this.setState(() => ({
      data: data,
      rows: rows,
      page: page,
      perPage: perPage
    }));
  }

  onSetPage = (event, pageNumber) => {
    this.setPage(pageNumber);
  };

  onPerPageSelect = (event, perPage) => {
    const { rows, data } = this.state;
    this.setState(() => ({
      rows: data.slice(0, perPage),
      perPage: perPage
    }));
  };

  onFirstClick = (event) => {
    this.setPage(1);
  };

  onLastClick = (event) => {
    const { page } = this.state;
    const totalPages = this.totalPages();
    if (page < totalPages) {
      this.setPage(totalPages);
    }
  };

  onNextClick = () => {
    const { page } = this.state;
    if (page < this.totalPages()) {
      this.setPage(page + 1);
    }
  };

  onPreviousClick = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setPage(page - 1);
    }
  };

  setPage = (value) => {
    const page = Number(value);

    if (!Number.isNaN(value) && value !== '' && page > 0 && page <= this.totalPages()) {
      const { data, perPage } = this.state;
      var start = (perPage * (page - 1));
      var end = start + perPage;
      const rows = data.slice(start, end);
      this.setState(() => ({
        rows: rows,
        page: page
      }));
    }
  };

  totalPages = () => {
    const { data, perPage } = this.state;
    const totalPages = Math.ceil(data.length / perPage);
    return totalPages;
  };

  onSort = (event, index, direction) => {
    const { data, perPage } = this.state;

    const allSortedRows = data.sort((a, b) => (
      typeof a.cells[index].title === 'object' ?
      a.cells[index].title.props?.children?.toLowerCase() < b.cells[index].title.props?.children?.toLowerCase() ? -1 
        : a.cells[index].title.props?.children?.toLowerCase() > b.cells[index].title.props?.children?.toLowerCase() ? 1 : 0 
        : a.cells[index].title < b.cells[index].title ? -1 
        : a.cells[index].title > b.cells[index].title ? 1 : 0)
      );

    const sortedRows = direction === SortByDirection.asc ? allSortedRows : allSortedRows.reverse();

    const pagedRows = sortedRows.slice(0, perPage);

    this.setState({
      sortBy: { index, direction },
      data: sortedRows,
      rows: pagedRows
    });

  }

  render() {
    const { columns, rows, actions, sortBy, isConfirmOpen } = this.state;

    return (
      <>
        <Table 
          aria-label="Actions Table" 
          onSort={this.onSort} 
          sortBy={sortBy} 
          variant={TableVariant.compact} 
          actions={actions} 
          cells={columns} 
          rows={rows}
        >
          <TableHeader />
          <TableBody />
        </Table>

        <Pagination
          itemCount={this.state.data.length}
          widgetId="pagination-options-menu-bottom"
          perPage={this.state.perPage}
          page={this.state.page}
          variant={PaginationVariant.bottom}
          onSetPage={this.onSetPage}
          onPerPageSelect={this.onPerPageSelect}
          onPreviousClick={this.onPreviousClick}
          onNextClick={this.onNextClick}
          onFirstClick={this.onFirstClick}
          onLastClick={this.onLastClick}
        />
      </>
    );
  }
}

export { PatternflyTable };