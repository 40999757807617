const getDocsStructure = () => {
  //@ts-ignore
  const context = require.context('@app/pages/documentation/docs', true, /\.md$/);
  const structure = {};

  context.keys().forEach((filePath) => {
    const parts = filePath.replace('./', '').split('/');
    let current = structure;

    parts.forEach((part, index) => {
      const cleanPart = part.replace(/\d+/g, '');

      if (index === parts.length - 1) {
        current[cleanPart] = filePath.replace('./', '');
      } else {
        current[cleanPart] = current[cleanPart] || {};
        current = current[cleanPart];
      }
    });
  });

  return structure;
};

export default getDocsStructure;
