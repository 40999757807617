import { Link, Link_Insert_Input } from '@app/types';
import { Netmask } from 'netmask';

const compare = (a, b, field) => {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
};

const compareStr = (a, b, field) => {
  if (a[field].toLowerCase() < b[field].toLowerCase()) {
    return -1;
  }
  if (a[field].toLowerCase() > b[field].toLowerCase()) {
    return 1;
  }
  return 0;
}

export const emptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
};

export const countChars = (str, char) => {
  return str.split(char).length - 1;
};

export const sortByCaseInsensitive = (objects, field) => {
  if (!objects) {
    return [];
  }
  return objects.sort((a, b) => compareStr(a, b, field));
};

export const sortBy = (objects, field) => {
  if (!objects) {
    return [];
  }
  return objects.sort((a, b) => compare(a, b, field));
};

export const reverseSortBy = (objects, field) => {
  if (!objects) {
    return [];
  }
  return objects.sort((a, b) => compare(b, a, field));
};

export const omit = (key, obj) => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
};

export const objectsEqual = (o1, o2) => {
  return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);
};

export const arraysEqual = (a1, a2) => {
  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
};

export const lastIp = (subnet): string => {
  try {
    const block = new Netmask(subnet);
    return block.last;
  } catch (error) {
    return '';
  }
};

export const networkCidrBlock = (subnet) => {
  try {
    const block = new Netmask(subnet);
    return block.base;
  } catch (error) {
    return ''
  }
};

export const getParamFromQueryString = (paramName) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop)
  });
  return params[paramName];
}

export const formatDate = (dbDate, excludeTime) => {
  if (dbDate?.toString() === '0001-01-01T00:00:00Z') {
    return 'N/A';
  }

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const newDate = new Date(dbDate);
  const minutes = newDate.getMinutes();
  const mins = minutes < 10 ? `0${minutes}` : minutes;

  if (!dbDate) {
    return "";
  }

  try {
    let formattedDate = days[newDate.getDay()] + ' ' +
      months[newDate.getMonth()] + ' ' +
      newDate.getDate() + ' ' +
      newDate.getFullYear();

    if (!excludeTime) {
      formattedDate += ' ' + newDate.getHours() + ':' + mins;
    }

    return formattedDate;
  }
  catch {
    return dbDate;
  }
}

export const isUsableAddress = (address: string, mask: string): boolean => {
  try {
    const block = new Netmask(`${address}${mask}`);
    return address !== block.base && address !== block.broadcast;
  } catch {
    return false;
  }
};

export const nextIp = (subnet, numSkipAddresses = 1) => {
  let BreakException = {};
  let nextIp = '';

  try {
    const block = new Netmask(subnet);

    block.forEach(function (ip, long, index) {
      if (index + 1 === numSkipAddresses) {
        nextIp = ip;
        throw BreakException;
      }
    });
    return nextIp;
  } catch {
    return nextIp;
  }
};

export const routeFromObj = (routeObj) => {
  const route = {
    isActive: routeObj.isActive,
    isInbound: routeObj.isInbound,
    metric: routeObj.metric,
    routerIP: routeObj.routerIP,
    routerInterface: routeObj.routerInterface,
    subnet: routeObj.subnet,
  };
  return route;
};

export const quickLinkFromObj = (quickLinkObj) => {
  const quickLink = {
    link: quickLinkObj.link,
    description: quickLinkObj.description,
    clientCompanyId: quickLinkObj.clientCompanyId,
  };
  return quickLink;
};

export const linkFromObj = (linkObj: Link, serverPort: number) => {
  const link: Link_Insert_Input = {
    carrierName: linkObj.carrierName,
    cpeBindIp: linkObj.cpeBindIp,
    dataLimit: linkObj.dataLimit,
    dataLimitReset: linkObj.dataLimitReset,
    dataLimitUsed: linkObj.dataLimitUsed,
    downloadThroughput: linkObj.downloadThroughput,
    downloadThroughputCap: linkObj.downloadThroughputCap,
    interfaceName: linkObj.interfaceName,
    isCapEnabled: linkObj.isCapEnabled,
    isEnabled: linkObj.isEnabled,
    isEncrypted: linkObj.isEncrypted,
    isMetered: linkObj.isMetered,
    isPassthrough: linkObj.isPassthrough,
    isStandby: linkObj.isStandby,
    isDHCP: linkObj.isDHCP,
    isWWAN: linkObj.isWWAN,
    isPPPoE: linkObj.isPPPoE,
    pppoeUsername: linkObj.pppoeUsername,
    pppoePassword: linkObj.pppoePassword,
    modemAPN: linkObj.modemAPN,
    name: linkObj.name,
    notes: linkObj.notes,
    serverBindIp: linkObj.serverBindIp,
    state: linkObj.state,
    type: linkObj.type,
    uploadThroughput: linkObj.uploadThroughput,
    uploadThroughputCap: linkObj.uploadThroughputCap,
    serverPort: serverPort,
    staticIp: linkObj.staticIp,
    staticDnsIp: linkObj.staticDnsIp,
    staticGatewayIp: linkObj.staticGatewayIp,
  };
  return link;
};

export const denormalize = <T>(obj) => {
  return Object.keys(obj).length > 0 ? Object.keys(obj).map((key) => ({ [key]: obj[key] })) as unknown as T[] : [];
};

export const getColumnIndex = <T>(columns: T[], columnKey: string, columnValue: any) => {
  return columns.map((column) => column[columnKey]).indexOf(columnValue);
}
