import React from 'react';

export const getLinkLbParams = () => {
  return [
    { id: "jitter", name: "Jitter" },
    { id: "loss", name: "Packet Loss" }
  ];
}

export const getAlgos = () => {
  return [
    { id: "dwrr", name: "HybridLink" },
    { id: "ca_dwrr", name: "HybridLink-CA" },
    { id: "wrr", name: "Velocity" },
    { id: "alb", name: "FastPath" },
    { id: "slb", name: "Link Select" },
    { id: "flow", name: "Load Balancer" },
    { id: "dlb", name: "Dynamic Link Select" }
  ];
}

export const getLbAlgoIdFromName = (name) => {
  const algos = getAlgos();
  const algo = algos.find(algo => algo.name === name);
  return algo?.id;
}

export const getLbAlgoNameFromId = (id) => {
  const algos = getAlgos();
  const algo = algos.find(algo => algo.id === id);
  return algo?.name || id;
}

export const getLinkLbParamNameFromId = (id) => {
  const params = getLinkLbParams();
  const param = params.find(param => param.id === id);
  return param?.name || id;
}

export const getLinkLbParamIdFromName = (name) => {
  const params = getLinkLbParams();
  const param = params.find(param => param.name === name);
  return param?.id;
}