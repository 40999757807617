import * as React from 'react';
import { connect } from 'react-redux';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import ApplicationProfile from '@app/api/aclprofile/application-profile.actions';
import { useACLProfileContext } from '../../contexts/aclprofile.context';
import { textInputRequiredOnly, isValidACLSubnet, isLengthWithinRange, isValidNumber, isWithinRange, isValidPortRange, isValidPriority, isUUID } from '@app/lib/validator';
import { IconHeading } from '@app/components/IconHeading';
import { netmaskOptions, trafficTypeOptions, ruleActionOptions, appRuleActionOptions, qosOptions } from '@app/common/constants';
import { getPortFromTrafficType, getPortRangeFromStartEnd, getAppRuleColumns, getRuleColumns, getSitesColumns } from './ACLProfileHelper';
import { getParamFromQueryString, sortByCaseInsensitive } from '@app/lib/functions';
import { Saver } from '@app/components/Saver';
import { capitalizeFirstLetter } from '@app/utils/utils';
import ACLProfileIcon from '@app/bgimages/service-catalog-black.svg';
import { ExclamationCircleIcon, TrashIcon, PlusIcon, BullhornIcon, PficonNetworkRangeIcon, WrenchIcon } from '@patternfly/react-icons';
import { PageSection, Title, FormGroup, Modal, ModalVariant, TextInput, Select, SelectOption, SelectVariant, Spinner, TextArea, Tabs, Tab, TabTitleText, TabTitleIcon } from "@patternfly/react-core";
import { Table, TableHeader, TableBody, TableVariant, cancelCellEdits, validateCellEdits, applyCellEdits, EditableSelectInputCell, EditableTextCell, cellWidth, sortable } from '@patternfly/react-table';
import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';
import '../../app.css';
import './ACLProfile.css';
import Page from '@app/components/Page';
import { Button, CardHeader, Col, Row, Card, CardBody } from 'reactstrap';
import { PatternflyTable } from '@app/components/PatternflyTable';
import Loader from '@app/components/Loader';

enum TabIndex {
  INBOUND,
  OUTBOUND,
  APPLICATION
}

const TitleMap = {
  [TabIndex.INBOUND]: 'Inbound Rules',
  [TabIndex.OUTBOUND]: 'Outbound Rules',
  [TabIndex.APPLICATION]: 'Application Rules'
};

export const ACLProfileView = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const id = this.props.computedMatch?.params?.id;
    const tabIndex = getParamFromQueryString('tab') || 0;

    this.state = {
      id: id,
      name: "",
      origName: "",
      description: "",
      inboundRules: [],
      outboundRules: [],
      applicationRules: [],
      sites: [],
      actions: {},
      ruleId: "",
      error: "",
      message: "",
      messageType: "",
      isDeleteOutboundRuleConfirmOpen: false,
      isDeleteInboundRuleConfirmOpen: false,
      isDeleteApplicationRuleConfirmOpen: false,
      isErrorConfirmOpen: false,
      newRuleTempId: -1,
      inboundRulesNumInEditMode: 0,
      outboundRulesNumInEditMode: 0,
      applicationRulesNumInEditMode: 0,
      activeTabIndex: parseInt(tabIndex),
      dataLoading: true,
      mode: "edit"
    }
  }

  componentDidMount = async () => {
    const { mode } = this.state;
    const applicationGroups = await ApplicationProfile.getApplicationGroups();
    const applications = await ApplicationProfile.getApplications();

    this.setState(() => ({
      applicationGroups: applicationGroups,
      applications: applications
    }));

    if (mode === "edit") {
      try {
        await this.loadACLProfile();
      }
      catch (error) {
        this.setState(() => ({
          message: "There was an error loading the ACL Profile"
        }));
      }
    }
    else {
      this.setState(() => ({
        dataLoading: false
      }));
    }
  }

  loadACLProfile = async () => {
    try {
      //@ts-ignore 
      let acl = await ACLProfile.get(id);
      let outboundRules = [];
      let inboundRules = [];
      let applicationRules = [];
      let sites = [];

      if (acl === undefined) {
        this.props.history.push("/notfound");
      }

      const tempApplicationRules = acl.application_rules?.map(r => {
        if (!r.hasOwnProperty('exit_via')) {
          return { ...r, exit_via: '' };
        }
        return r;
      });

      const inboundNumberedPriorities = acl.inbound_rules ? acl.inbound_rules.filter(r => r.priority !== "" && r.priority !== "*") : [];
      const inboundOtherPriorities = acl.inbound_rules ? acl.inbound_rules.filter(r => r.priority === "" || r.priority === "*") : [];
      const sortedInboundRules = sortByCaseInsensitive(inboundNumberedPriorities, 'priority');
      const outboundNumberedPriorities = acl.outbound_rules ? acl.outbound_rules.filter(r => r.priority !== "" && r.priority !== "*") : [];
      const outboundOtherPriorities = acl.outbound_rules ? acl.outbound_rules.filter(r => r.priority === "" || r.priority === "*") : [];
      const sortedOutboundRules = sortByCaseInsensitive(outboundNumberedPriorities, 'priority');
      const applicationNumberedPriorities = acl.application_rules ? tempApplicationRules.filter(r => r.priority !== "" && r.priority !== "*") : [];
      const applicationOtherPriorities = acl.application_rules ? tempApplicationRules.filter(r => r.priority === "" || r.priority === "*") : [];
      const sortedApplicationRules = sortByCaseInsensitive(applicationNumberedPriorities, 'priority');

      if (sortedInboundRules) {
        inboundRules = sortedInboundRules.map(item => { return this.getRuleRow(item, false, "inboundRules", false); });
        inboundRules = inboundRules.concat(inboundOtherPriorities.map(item => { return this.getRuleRow(item, false, "inboundRules", false); }));
      }
      if (sortedOutboundRules) {
        outboundRules = sortedOutboundRules.map(item => { return this.getRuleRow(item, false, "outboundRules", false); });
        outboundRules = outboundRules.concat(outboundOtherPriorities.map(item => { return this.getRuleRow(item, false, "outboundRules", false); }));
      }
      if (sortedApplicationRules) {
        applicationRules = sortedApplicationRules.map(item => { return this.getAppRuleRow(item, false, "applicationRules", false); });
        applicationRules = applicationRules.concat(applicationOtherPriorities.map(item => { return this.getAppRuleRow(item, false, "applicationRules", false); }));
      }

      sites = acl.assignments.map(item => {
        return this.apiKeyFromItem(item);
      });

      this.setState(() => ({
        name: acl.name,
        origName: acl.name,
        description: acl.description,
        outboundRules: outboundRules,
        inboundRules: inboundRules,
        applicationRules: applicationRules,
        sites: sites,
        dataLoading: false
      }));
    } 
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error loading the ACL Profile"
      }));
    }
  };

  getColumns = () => {
    return [
      { title: 'Access Control List ID', transforms: [sortable] }, 
      { title: 'ID' }, 
      { title: 'Resource ID' }
    ];
  }

  getColumnIndex = (columnName) => {
    const columns = this.getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  apiKeyFromItem = (item) => {
    return {
      cells: [
        { title: item.access_control_list_id },
        { title: item.id },
        { title: item.resource_id }
      ]
    }
  }

  getRuleRow = (rule, isEditable, rulesList, isNewRow) => {
    var cells = [];

    rule.action = capitalizeFirstLetter(rule.action);

    cells.push(this.getTextCell(rule, "priority", rulesList, false));
    cells.push(this.getSelectCell(rule, "type", false, trafficTypeOptions(), rulesList));
    cells.push(this.getTextCell(rule, "source_ip", rulesList, false));
    cells.push(this.getPortTextCell(rule, "source_port", rulesList));
    cells.push(this.getTextCell(rule, "destination_ip", rulesList, false));
    cells.push(this.getPortTextCell(rule, "destination_port", rulesList));
    cells.push(this.getSelectCell(rule, "action", false, ruleActionOptions(), rulesList));
    cells.push(this.getTextCell(rule, "comments", rulesList, false));
    cells.push(this.getTrashCanCell(rule, "delete", rulesList, isNewRow));
    cells.push(this.getTextCell(rule, "id", rulesList, false));

    const row = {
      rowEditValidationRules: [
        {
          name: 'invalidPriority',
          validator: value => value === "" || (value !== "" && isValidPriority(value)),
          errorText: 'Must be a unique number between 0-999, "*" or left blank'
        },{
          name: 'invalidPortRange',
          validator: value => value === "" || (value !== "" && isValidPortRange(value)),
          errorText: 'Must be a number or range (e.g. 100 or 100-200) up to 65535 max'
        },{
          name: 'invalidIp',
          validator: value => value === "" || (value !== "" && (value === "ALL" || value === "all" || isValidACLSubnet(value))),
          errorText: 'Invalid IP, must include netmask'
        }
      ],
      cells: cells,
    }

    //@ts-ignore 
    row.isEditable = isEditable;
 
    return row;
  }

  getAppRuleRow = (rule, isEditable, rulesList, isNewRow) => {
    var cells = [];

    rule.action = capitalizeFirstLetter(rule.action);
    
    let disableUpDownRates = rule.action !== 'Shape';
    let disableExitVia = rule.action !== 'Accept';

    if (disableUpDownRates) {
      rule.download_cap = '';
      rule.upload_cap = '';
    }
    if (disableExitVia) {
      rule.exit_via = '';
    }

    if (rule.type === 'All Applications') {
      rule.type = rule.match;
      rule.match = 'All';
    }
    else {
      const group = this.state.applications.find(app => app.name === rule.match);
      rule.type = group.category;
    }

    cells.push(this.getTextCell(rule, "priority", rulesList, false));
    cells.push(this.getSelectCell(rule, "type", false, this.applicationGroupOptions(), rulesList));
    cells.push(this.getSelectCell(rule, "match", false, this.applicationOptions('Chat'), rulesList));
    cells.push(this.getSelectCell(rule, "qos", false, qosOptions(), rulesList));
    cells.push(this.getTextCell(rule, "source_ip", rulesList, false));
    cells.push(this.getTextCell(rule, "download_cap", rulesList, disableUpDownRates));
    cells.push(this.getTextCell(rule, "upload_cap", rulesList, disableUpDownRates));
    cells.push(this.getTextCell(rule, "exit_via", rulesList, disableExitVia));
    cells.push(this.getSelectCell(rule, "action", false, appRuleActionOptions(), rulesList));
    cells.push(this.getTrashCanCell(rule, "delete", rulesList, isNewRow));
    cells.push(this.getTextCell(rule, "id", rulesList, false));

    const row = {
      rowEditValidationRules: [
        {
          name: 'invalidPriority',
          validator: value => value === "" || (value !== "" && isValidPriority(value)),
          errorText: 'Must be a unique number between 0-999, "*" or left blank'
        },{
          name: 'invalidRate',
          validator: value => isValidNumber(value) && isWithinRange(value, 1, Number.MAX_SAFE_INTEGER),
          errorText: 'Must be a positive number less than ' + Number.MAX_SAFE_INTEGER
        },{
          name: 'invalidIp',
          validator: value => value === "" || (value !== "" && (value === "ALL" || value === "all" || isValidACLSubnet(value))),
          errorText: 'Invalid IP, must include netmask'
        }
      ],
      cells: cells,
    }

    //@ts-ignore 
    row.isEditable = isEditable;
 
    return row;
  }

  getRules = (rulesList) => {
    const { inboundRules, outboundRules, applicationRules } = this.state;
    switch (rulesList) {
      case "inboundRules": return Array.from(inboundRules);
      case "outboundRules": return Array.from(outboundRules);
      case "applicationRules": return Array.from(applicationRules);
      default: return [];
    }
  }

  onToggle = (isOpen, rowIndex, cellIndex, rulesList) => {
    let newRows = this.getRules(rulesList);
    //@ts-ignore 
    newRows[rowIndex].cells[cellIndex].props.isSelectOpen = isOpen;

    this.setState(() => ({
      [rulesList]: [...newRows]
    }));
  }

  onSelect = (newValue, evt, rowIndex, cellIndex, isPlaceholder, rulesList) => {
    let newRows = this.getRules(rulesList);
    //@ts-ignore 
    const newCellProps = newRows[rowIndex].cells[cellIndex].props;

    if (isPlaceholder) {
      newCellProps.editableValue = [];
      newCellProps.selected = [];
    }
    else {
      if (newCellProps.editableValue === undefined) {
        newCellProps.editableValue = [];
      }

      let newSelected = Array.from(newCellProps.selected);
      newSelected = newValue;
      newCellProps.editableValue = newSelected;
      newCellProps.selected = newSelected;

      const trafficTypeIndex = getRuleColumns().indexOf('Type');
      const actionIndex = getAppRuleColumns().indexOf('Action');

      // If the Type dropdown was selected
      if (cellIndex === trafficTypeIndex) {
        if (rulesList === "inboundRules" || rulesList === "outboundRules") {
          // Compute source port based on selected traffic type
          const sourcePortIndex = getRuleColumns().indexOf('Source Port');
          const destPortIndex = getRuleColumns().indexOf('Dest Port');
          const port = getPortFromTrafficType(newValue);
          let portIndex = sourcePortIndex;

          // Auto set the port from 'Type'. For outbound rules, auto set the destination port. 
          // For inbound, auto set the source port
          if (rulesList === "outboundRules") {
            portIndex = destPortIndex;
          }

          // Set the computed port
    //@ts-ignore 
          newRows[rowIndex].cells[portIndex].props.editableValue = port;
        }
        else {
          //@ts-ignore 
          this.loadApps(newRows[rowIndex], rulesList, newValue);
        }
      }

      // If the Action dropdown was selected in application rules
      if (cellIndex === actionIndex && rulesList === "applicationRules") {
        const cols = getAppRuleColumns();
        const disableUpDownRates = newValue !== "Shape";
        const disableExitVia = newValue !== "Accept";

        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Download(Mbps)')].title = this.getInput("download_cap", disableUpDownRates, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Download(Mbps)')].props.editableValue = "";
        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Upload(Mbps)')].title = this.getInput("upload_cap", disableUpDownRates, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Upload(Mbps)')].props.editableValue = "";
        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Exit Via')].title = this.getInput("exit_via", disableExitVia, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[cols.indexOf('Exit Via')].props.editableValue = "";
      }
    }

    // Close the dropdown
    //@ts-ignore 
    newRows[rowIndex].cells[cellIndex].props.isSelectOpen = false;

    // Update selected action in state
    const actions = this.state.actions;
    const actionKey = rulesList + '-' + rowIndex;
    actions[actionKey] = newValue;

    this.setState(() => ({
      actions: { ...actions },
      [rulesList]: [...newRows]
    }));
  }
  
  getInput = (columnName, isDisabled, rulesList) => {
    return (value, rowIndex, cellIndex, props) => (
      <EditableTextCell
        value={value}
        rowIndex={rowIndex}
        cellIndex={cellIndex}
        props={props}
        isDisabled={isDisabled}
        handleTextInputChange={(newValue, evt, rowIndex, cellIndex) => this.handleTextInputChange(newValue, evt, rowIndex, cellIndex, rulesList)}
        inputAriaLabel={columnName}
        className="form-control"
      />
    )
  }

  loadApps = (row, rulesList, category, appGroup) => {
    const appColIndex = getAppRuleColumns().indexOf('App');
    let appCell = this.getSelectCell({ match: appGroup || 'All' }, "match", false, this.applicationOptions(category), rulesList);
    row.cells[appColIndex] = appCell;
  }

  getSelectCell = (rule, columnName, readOnly, options, rulesList) => {
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableSelectInputCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          onSelect={(newValue, evt, rowIndex, cellIndex, isPlaceholder) => this.onSelect(newValue, evt, rowIndex, cellIndex, isPlaceholder, rulesList)}
          isOpen={props.isSelectOpen}
          className="form-control"
          options={props.options.map((option, index) => {
            return (
              <SelectOption id={columnName + '-' + index} key={index} value={option.value} />
            );
          })}
          onToggle={isOpen => {
            this.onToggle(isOpen, rowIndex, cellIndex, rulesList);
          }}
          selections={props.selected}
        />
      ),
      props: {
        value: rule[columnName],
        name: columnName,
        //@ts-ignore 
        isSelectOpen: props && props.isSelectOpen || false,
        //@ts-ignore 
        selected: props && props.selected && props.selected.length ? props.selected : rule[columnName],
        options: [...options],
        editableSelectProps: {
          maxHeight: 300
        }
      }
    }

    return cell;
  }

  getPortTextCell = (rule, columnName, rulesList) => {
    const portRange = getPortRangeFromStartEnd(rule[columnName + '_start'], rule[columnName + '_end']);
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableTextCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          handleTextInputChange={(newValue, evt, rowIndex, cellIndex) => this.handleTextInputChange(newValue, evt, rowIndex, cellIndex, rulesList)}
          inputAriaLabel={columnName}
          className="form-control"
        />
      ),
      props: {
        value: portRange,
        name: columnName
      }
    }

    return cell;
  }

  getTrashCanIcon = (id, rulesList) => {
    return (
      <div className="trash-icon" onClick={(e) => this.deleteConfirmation(e, id, rulesList)}>
        <TrashIcon />
      </div>
    );
  }

  getTrashCanCell = (rule, columnName, rulesList, isNewRow) => {
    return { 
      title: isNewRow ? <></> : this.getTrashCanIcon(rule.id, rulesList),
      props: {
        value: "-1",
        name: columnName
      }
    };
  }

  getTextCell = (rule, columnName, rulesList, isDisabled) => {
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableTextCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          isDisabled={isDisabled}
          handleTextInputChange={(newValue, evt, rowIndex, cellIndex) => this.handleTextInputChange(newValue, evt, rowIndex, cellIndex, rulesList)}
          inputAriaLabel={columnName}
          className="form-control"
        />
      ),
      props: {
        value: rule[columnName],
        name: columnName
      }
    }

    return cell;
  }

  addAppRule = (rulesList) => {
    const { newRuleTempId } = this.state;

    let emptyRule = {
      id: newRuleTempId,
      priority: "",
      type: "Chat",
      qos: "Medium",
      match: "IRC",
      source_ip: "",
      download_cap: "",
      upload_cap: "",
      exit_via: "",
      action: "Accept"
    };

    let numRowsInEditModeProp = rulesList + "NumInEditMode";
    let newRules = this.getRules(rulesList);
    newRules.push(this.getAppRuleRow(emptyRule, true, rulesList, true));
    
    this.setState(() => ({
      [numRowsInEditModeProp]: this.state[numRowsInEditModeProp] + 1,
      newRuleTempId: newRuleTempId - 1,
      [rulesList]: [...newRules]
    }));
  }

  addRule = (rulesList) => {
    const { newRuleTempId } = this.state;

    let emptyRule = {
      id: newRuleTempId,
      priority: "",
      type: "HTTPS",
      destination_ip: "",
      destination_port_end: 0,
      source_ip: "",
      source_port_end: 0,
      action: "Accept",
      comments: ""
    };

    if (rulesList === "inboundRules") {
      //@ts-ignore 
      emptyRule.source_port_start = 443;
    }
    else {
      //@ts-ignore 
      emptyRule.destination_port_start = 443;
    }

    let numRowsInEditModeProp = rulesList + "NumInEditMode";
    let newRules = this.getRules(rulesList);
    newRules.push(this.getRuleRow(emptyRule, true, rulesList, true));
    
    this.setState(() => ({
      [numRowsInEditModeProp]: this.state[numRowsInEditModeProp] + 1,
      newRuleTempId: newRuleTempId - 1,
      [rulesList]: [...newRules]
    }));
  }

  applicationGroupOptions = () => {
    const { applicationGroups } = this.state;
    const sortedAppGroups = sortByCaseInsensitive(applicationGroups, 'name');
    return sortedAppGroups.map((appGroup) => ({ value: appGroup.name }));
  }
  
  applicationOptions = (category) => {
    const { applications } = this.state;
    const appsByCategory = applications.filter(app => app.category === category);
    const sortedApps = sortByCaseInsensitive(appsByCategory, 'name');
    const options = sortedApps.map((app) => ({ value: app.name }));
    options.unshift({ value: 'All' });
    return options;
  }

  getDeleteProp = (rulesList) => {
    switch (rulesList) {
      case "inboundRules": return "isDeleteInboundRuleConfirmOpen";
      case "outboundRules": return "isDeleteOutboundRuleConfirmOpen";
      case "applicationRules": return "isDeleteApplicationRuleConfirmOpen";
      default: return "";
    }
  }

  deleteConfirmation = (event, id, rulesList) => {
    let isDeleteOpenProp = this.getDeleteProp(rulesList);
    this.setState(() => ({
      [isDeleteOpenProp]: true,
      ruleId: id
    }));
  }

  deleteRule = async (rulesList) => {
    const { id, ruleId, mode } = this.state;
    const ruleIdIndex = rulesList === "applicationRules" ? getAppRuleColumns().length : getRuleColumns().length;
    let isDeleteOpenProp = this.getDeleteProp(rulesList);
    let rules = this.getRules(rulesList);
    //@ts-ignore 
    const updated = rules.filter(r => r.cells[ruleIdIndex].props.value !== ruleId);

    if (mode === 'edit' && isUUID(ruleId)) {
      let currentAcl = await ACLProfile.get(id);
      let currInboundCount = currentAcl.inbound_rules ? currentAcl.inbound_rules.length : 0;
      let currOutboundCount = currentAcl.outbound_rules ? currentAcl.outbound_rules.length : 0;
      let currApplicationCount = currentAcl.application_rules ? currentAcl.application_rules.length : 0;
      const savedRuleCount = currInboundCount + currOutboundCount + currApplicationCount;

      if (savedRuleCount <= 1) {
        this.setState(() => ({
          isErrorConfirmOpen: true,
          isDeleteInboundRuleConfirmOpen: false,
          isDeleteOutboundRuleConfirmOpen: false,
          isDeleteApplicationRuleConfirmOpen: false
        }));
        return;
      }
    }

    if (isUUID(ruleId)) {
      try {
        await ACLProfile.deleteRule(id, ruleId);

        this.setState(() => ({
          message: "Rule was deleted successfully!",
          [rulesList]: [...updated]
        }));
      }
      catch (error) {
        this.setState(() => ({
          message: "There was an error deleting the Rule"
        }));
      }
    }
    else {
      this.setState(() => ({
        [rulesList]: [...updated]
      }));
    }

    this.setState(() => ({
      [isDeleteOpenProp]: false,
      ruleId: "",
      error: "",
    }));
  }

  handleTextInputChange = (newValue, evt, rowIndex, cellIndex, rulesList) => {
    let newRows = this.getRules(rulesList);
    //@ts-ignore 
    newRows[rowIndex].cells[cellIndex].props.editableValue = newValue;
    this.setState(() => ({
      [rulesList]: [...newRows]
    }));
  }

  updateEditableRows = (evt, type, isEditable, rowIndex, validationErrors, rulesList) => {
    let newRows = this.getRules(rulesList);
    let numRowsInEditModeProp = rulesList + "NumInEditMode";
    var invalidIps = [];
    var invalidPortRanges = [];
    var invalidPriorities = [];
    var invalidRates = [];
    const appRuleCols = getAppRuleColumns();
    const ruleCols = getRuleColumns();
    const actionKey = rulesList + '-' + rowIndex;
    let action = this.state.actions[actionKey]; 

    if (!action) {
      //@ts-ignore 
      action = newRows[rowIndex].cells[appRuleCols.indexOf('Action')].props.value;
    }

    // Note about validation ... since row edit rules will be applied to every cell,
    // we need to check the appropriate array in validationErrors, then set only 
    // the fields we want to validate for a particular row edit rule, into a new array.
    // Then assign that array back to the cooresponding key in the validationErrors obj

    if (validationErrors.invalidPriority) {
      if (validationErrors.invalidPriority.includes("priority")) {
        invalidPriorities.push("priority")
      }    
    }

    if (validationErrors.invalidRate) {
      if (validationErrors.invalidRate.includes("download_cap")) {
        invalidRates.push("download_cap")
      }
      if (validationErrors.invalidRate.includes("upload_cap")) {
        invalidRates.push("upload_cap")
      }
    }

    if (validationErrors.invalidIp) {
      if (validationErrors.invalidIp.includes("source_ip")) {
        invalidIps.push("source_ip")
      }    
      if (validationErrors.invalidIp.includes("destination_ip")) {
        invalidIps.push("destination_ip")
      }
    }

    if (validationErrors.invalidPortRange) {
      if (validationErrors.invalidPortRange.includes("source_port")) {
        invalidPortRanges.push("source_port")
      }
      if (validationErrors.invalidPortRange.includes("destination_port")) {
        invalidPortRanges.push("destination_port")
      }
    }

    if (invalidPriorities.length > 0) {
      validationErrors.invalidPriority = [...invalidPriorities];
    }
    else {
      delete validationErrors.invalidPriority;
    }

    if (invalidRates.length > 0 && rulesList === "applicationRules" && action === 'Shape') {
      validationErrors.invalidRate = [...invalidRates];
    }
    else {
      delete validationErrors.invalidRate;
    }

    if (invalidIps.length > 0) {
      validationErrors.invalidIp = [...invalidIps];
    }
    else {
      delete validationErrors.invalidIp;
    }

    if (invalidPortRanges.length > 0 && rulesList !== "applicationRules") {
      validationErrors.invalidPortRange = [...invalidPortRanges];
    }
    else {
      delete validationErrors.invalidPortRange;
    }

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowIndex] = validateCellEdits(newRows[rowIndex], type, validationErrors);
      this.setState(() => ({
        [rulesList]: [...newRows]
      }));
      return;
    }
    
    // Show the Delete icon. This will be hidden downstream if row goes into edit mode
    if (rulesList === "applicationRules") {
      //@ts-ignore 
      const rowId = newRows[rowIndex].cells[10].props.value;
      //@ts-ignore 
      newRows[rowIndex].cells[appRuleCols.indexOf('')].title = this.getTrashCanIcon(rowId, rulesList);
    }
    else {
      //@ts-ignore 
      const rowId = newRows[rowIndex].cells[9].props.value;
      //@ts-ignore 
      newRows[rowIndex].cells[ruleCols.indexOf('')].title = this.getTrashCanIcon(rowId, rulesList);
    }
    

    if (type === 'edit' && Object.keys(validationErrors).length === 0) {
      this.setState(() => ({
        [numRowsInEditModeProp]: this.state[numRowsInEditModeProp] + 1
      }));

      if (rulesList === "applicationRules") {
        const disableUpDownRates = action !== "Shape";
        const disableExitVia = action !== "Accept";

        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Download(Mbps)')].title = this.getInput("download_cap", disableUpDownRates, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Download(Mbps)')].props.editableValue = "";
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Upload(Mbps)')].title = this.getInput("upload_cap", disableUpDownRates, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Upload(Mbps)')].props.editableValue = "";
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Exit Via')].title = this.getInput("exit_via", disableExitVia, rulesList);
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('Exit Via')].props.editableValue = "";
        //@ts-ignore 
        newRows[rowIndex].cells[appRuleCols.indexOf('')].title = <></>;
        //@ts-ignore 
        const type = newRows[rowIndex].cells[appRuleCols.indexOf('Group')].props.value;
        //@ts-ignore 
        const appGroup = newRows[rowIndex].cells[appRuleCols.indexOf('App')].props.value;

        this.loadApps(newRows[rowIndex], rulesList, type, appGroup);
      }
      else {
        //@ts-ignore 
        newRows[rowIndex].cells[ruleCols.indexOf('')].title = <></>;
      }

      // The below block fixes those annoying ! validation errors in the UI, even though the validation
      // has passed. The validationErrors object contains all of the attribute specific errors that may
      // or not be present. Even when all attributes are deemed valid, the 'isValid' prop is still set
      // to false. This is a workaround as it is likely a bug in the Patternfly component itself
      if (Object.keys(validationErrors).length === 0) {
        for (var i = 0; i < newRows.length; i++) {
          //@ts-ignore 
          newRows[i].cells[appRuleCols.indexOf('Download(Mbps)')].props.isValid = true;
          //@ts-ignore 
          newRows[i].cells[appRuleCols.indexOf('Upload(Mbps)')].props.isValid = true;
          //@ts-ignore 
          newRows[i].cells[appRuleCols.indexOf('Exit Via')].props.isValid = true;
          //@ts-ignore 
          newRows[i].cells[appRuleCols.indexOf('Src IP')].props.isValid = true;
          //@ts-ignore 
          newRows[i].cells[appRuleCols.indexOf('Priority')].props.isValid = true;
        }
      }

      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
      return;
    }

    if (type === 'cancel') {
      newRows[rowIndex] = cancelCellEdits(newRows[rowIndex]);
      //@ts-ignore 
      const prevAction = newRows[rowIndex].cells[appRuleCols.indexOf('Action')].props.value;
      const actions = this.state.actions;
      const actionKey = rulesList + '-' + rowIndex;
      actions[actionKey] = prevAction;

      this.setState(() => ({
        actions: { ...actions },
        [numRowsInEditModeProp]: this.state[numRowsInEditModeProp] - 1,
        [rulesList]: [...newRows]
      }));
      return;
    }
    
    if (type === 'save') {
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
      this.setState(() => ({
        [rulesList]: [...newRows],
        [numRowsInEditModeProp]: this.state[numRowsInEditModeProp] - 1,
        error: ""
      }));
    }
  }

  handleInboundConfirmToggle = () => {
    this.setState(({ isDeleteInboundRuleConfirmOpen }) => ({
      isDeleteInboundRuleConfirmOpen: !isDeleteInboundRuleConfirmOpen
    }));
  };

  handleOutboundConfirmToggle = () => {
    this.setState(({ isDeleteOutboundRuleConfirmOpen }) => ({
      isDeleteOutboundRuleConfirmOpen: !isDeleteOutboundRuleConfirmOpen
    }));
  };

  handleApplicationConfirmToggle = () => {
    this.setState(({ isDeleteApplicationRuleConfirmOpen }) => ({
      isDeleteApplicationRuleConfirmOpen: !isDeleteApplicationRuleConfirmOpen
    }));
  };

  handleErrorConfirmToggle = () => {
    this.setState(({ isErrorConfirmOpen }) => ({
      isErrorConfirmOpen: !isErrorConfirmOpen
    }));
  };

  handleChange = (name, value) => {
    this.setState(() => ({
      [name]: value
    }));
  }

  handleCancel = () => {
    const { id } = this.state;
     if (id) {
      this.props.history.push('/aclprofile/' + id);
    }
    else {
      this.props.history.push('/aclprofiles');
    }
  }

  handleSubmit = async (event) => {
    const { mode } = this.state;

    if (mode === "edit") {
      this.updateACLProfile();
    }
    else if (mode === "new") {
      this.createACLProfile();
    }
  }

  checkForDuplicates = (inboundRules, outboundRules, applicationRules) => {
    const inboundDuplicates = this.hasDuplicatePriorities(inboundRules);
    const outboundDuplicates = this.hasDuplicatePriorities(outboundRules);
    const applicationDuplicates = this.hasDuplicatePriorities(applicationRules);

    if (inboundDuplicates) {
      this.setState(() => ({
        message: "The 'Priority' in Inbound Rules must be unique. Modify the duplicate priority and try again."
      }));
      return true;
    }
    if (outboundDuplicates) {
      this.setState(() => ({
        message: "The 'Priority' in Outbound Rules must be unique. Modify the duplicate priority and try again."
      }));
      return true;
    }
    if (applicationDuplicates) {
      this.setState(() => ({
        message: "The 'Priority' in Application Rules must be unique. Modify the duplicate priority and try again."
      }));
      return true;
    }

    return false;
  }

  hasDuplicatePriorities = (rules) => {
    const priorities = rules.filter(r => r.cells[0].props.value !== "" && r.cells[0].props.value !== "*");
    const uniques = new Set(priorities.map(item => item.cells[0].props.value));
    return uniques.size !== priorities.length; 
  }

  handleTabClick = (e, index) => {
    this.setState(() => ({
      activeTabIndex: index
    }));
  };

  createACLProfile = async () => {
    const { name, description, inboundRules, outboundRules, applicationRules } = this.state;
    const ruleCount = inboundRules.length + outboundRules.length + applicationRules.length;
    const acls = await ACLProfile.getAll();
    const duplicates = acls.filter(acl => acl.name.toLowerCase() === name.toLowerCase());

    if (ruleCount === 0) {
      this.setState(() => ({
        message: "There must be at least one rule created"
      }));
      return;
    }

    if (this.checkForDuplicates(inboundRules, outboundRules, applicationRules)) {
      return;
    }

    if (duplicates.length) {
      this.setState(() => ({
        message: "An ACL Profile with the name '" + name + "' already exists. Please try another name."
      }));
      return;
    }

    try {
      let result = await ACLProfile.create({ name, description, inboundRules, outboundRules, applicationRules });
      this.props.history.push('/aclprofile/' + result.id);
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error creating the ACL Profile"
      }));
    }
  }

  updateACLProfile = async () => {
    const { id, name, origName, description, inboundRules, outboundRules, applicationRules } = this.state;
    const acls = await ACLProfile.getAll();
    const duplicates = acls?.filter(acl => acl.name.toLowerCase() === name.toLowerCase() && acl.name.toLowerCase() !== origName.toLowerCase());

    if (this.checkForDuplicates(inboundRules, outboundRules, applicationRules)) {
      return;
    }

    if (duplicates?.length) {
      this.setState(() => ({
        message: "An ACL Profile with the name '" + name + "' already exists. Please try another name."
      }));
      return;
    }

    try {
      let result = await ACLProfile.update(id, { name, description, inboundRules, outboundRules, applicationRules });
      let tabIndex = parseInt(this.state.activeTabIndex);
      
      if (result?.id) {
        this.props.history.push(`/aclprofile/${result.id}?tab=${tabIndex}`);
      }
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the ACL Profile"
      }));
    }
  }

  render() {
    const { name, description, inboundRules, outboundRules, applicationRules, sites, ruleId, inboundRulesEditRowMode, 
      inboundRulesNumInEditMode, outboundRulesNumInEditMode, applicationRulesNumInEditMode, 
      outboundRulesEditRowMode, applicationRulesEditRowMode, activeTabIndex, mode, isErrorConfirmOpen, isDeleteInboundRuleConfirmOpen, 
      isDeleteOutboundRuleConfirmOpen, isDeleteApplicationRuleConfirmOpen, error, message, messageType, dataLoading } = this.state;
    const descriptionErrorState = isLengthWithinRange(description, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: "Max 255 characters allowed" };
    const formInvalid = !name || inboundRulesEditRowMode || outboundRulesEditRowMode || applicationRulesEditRowMode || 
      inboundRulesNumInEditMode > 0 || outboundRulesNumInEditMode > 0 || applicationRulesNumInEditMode > 0 ||
      descriptionErrorState.validated === 'error';
    const { theme } = this.context;

    const editAclProfile = () => {
      this.props.history.push(`/aclprofile/edit/${this.state.id}?tab=${parseInt(this.state.activeTabIndex)}`);
    };

    return (
      <>
        {dataLoading ? (
          <Loader />
        ) : (
          <Page
            tag="div"
            className={`cr-page px-3 pt-2 acl-form position-relative ${theme}`}
            title=""
            breadcrumbs={[
              { name: <a href="/aclprofiles">ACL Profiles</a>, active: false },
              { name: name, active: true },
            ]}
          >
              <>       
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Row className="w-100">
                      <Col lg={9} md={9} sm={12} xs={12}>
                        <Title headingLevel="h1" size="lg">
                          {name}
                        </Title>
                        <div className="w-100 mt-2 mb-2 font-12">
                        {description}
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={12} xs={12}>         
                        <div className="d-flex flex-column">
                          <Button className="text-white w-100 mb-2 ethica-button-green" onClick={editAclProfile}>Edit</Button>
                        </div>         
                      </Col> 
                    </Row>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={12}>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Col>
                      <Card>
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                          <div className="w-100 d-flex justify-content-between">
                            <div className="icon-heading-container">
                              <div className="icon-heading">{TitleMap[TabIndex.INBOUND]}</div>
                            </div>                            
                            <div className="pointer add-section" onClick={
                              //@ts-ignore 
                              (e) => this.addRule("inboundRules", e)
                              }>
                              <PlusIcon /> &nbsp;Add Rule
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          {inboundRules.length > 0 ?
                            <div>
                              <Table
                                onRowEdit={(evt, type, isEditable, rowIndex, validationErrors) => (
                                  this.updateEditableRows(evt, type, isEditable, rowIndex, validationErrors, "inboundRules"), 
                                  this.handleSubmit(evt))
                                }
                                aria-label="Edit Inbound Rules"
                                variant={TableVariant.compact}
                                cells={getRuleColumns()}
                                rows={inboundRules}
                                className="acl-rule-table"
                              >
                                <TableHeader />
                                <TableBody />
                              </Table>
                              <br />
                              <span className="error">{error}</span>
                            </div>
                          : 
                            <div className="font-12 d-flex justify-content-center align-items-center mt-5">No Inbound Rules configured!</div>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Col>
                      <Card>
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                          <div className="w-100 d-flex justify-content-between">
                            <div className="icon-heading-container">
                              <div className="icon-heading">{TitleMap[TabIndex.OUTBOUND]}</div>
                            </div>                            
                            <div className="pointer add-section" onClick={
                              //@ts-ignore 
                              (e) => this.addRule("outboundRules", e)
                              }>
                              <PlusIcon /> &nbsp;Add Rule
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          {outboundRules.length > 0 ?
                            <div>
                              <Table
                                onRowEdit={(evt, type, isEditable, rowIndex, validationErrors) => (
                                  this.updateEditableRows(evt, type, isEditable, rowIndex, validationErrors, "outboundRules"), 
                                  this.handleSubmit(evt)
                                )}
                                aria-label="Edit Outbound Rules"
                                variant={TableVariant.compact}
                                cells={getRuleColumns()}
                                rows={outboundRules}
                                className="acl-rule-table"
                              >
                                <TableHeader />
                                <TableBody />
                              </Table>
                              <br />
                              <span className="error">{error}</span>
                            </div>
                          : 
                            <div className="font-12 d-flex justify-content-center align-items-center mt-5">No Outbound Rules configured!</div> 
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Col>

                <Col>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Col>
                      <Card>
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                          <div className="w-100 d-flex justify-content-between">
                            <div className="icon-heading-container">
                              <div className="icon-heading">{TitleMap[TabIndex.APPLICATION]}</div>
                            </div>                            
                            <div className="pointer add-section" onClick={
                              //@ts-ignore 
                              (e) => this.addRule("applicationRules", e)
                              }>
                              <PlusIcon /> &nbsp;Add Rule
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          {applicationRules.length > 0 ?
                            <div>
                              <Table
                                onRowEdit={(evt, type, isEditable, rowIndex, validationErrors) => (
                                  this.updateEditableRows(evt, type, isEditable, rowIndex, validationErrors, "applicationRules"), 
                                  this.handleSubmit(evt)
                                )}
                                aria-label="Edit Application Rules"
                                variant={TableVariant.compact}
                                cells={getAppRuleColumns()}
                                rows={applicationRules}
                                className="acl-rule-table"
                              >
                                <TableHeader />
                                <TableBody />
                              </Table>
                              <br />
                              <span className="error">{error}</span>
                            </div>
                          : 
                            <div className="font-12 d-flex justify-content-center align-items-center mt-5">No Application Rules configured!</div> 
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Col>
                      <Card>
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                          <div className="w-100 d-flex justify-content-between">
                            <div className="icon-heading-container">
                              <div className="icon-heading">Sites</div>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          {sites.length > 0 ?
                            <div>
                              <PatternflyTable
                                columns={this.getColumns}
                                data={sites}
                              />
                              <br />
                              <span className="error">{error}</span>
                            </div>
                          : 
                            <div className="font-12 d-flex justify-content-center align-items-center mt-5">No Application Rules configured!</div> 
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Col>
              </Row>
              </>
          </Page>
        )}
      </>
    );
  }
})
