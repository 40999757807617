import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  PageSection,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  AlertActionCloseButton,
  AlertVariant,
  Card,
  CardBody,
} from '@patternfly/react-core';
import { InfoSection } from '@app/components/InfoSection';
import { useLinkDetails } from '@app/hooks/useLinkDetails';
import Loader from '@app/components/Loader';
import { 
  getAddressTypeName, 
  getLinkStatusName, 
  getCarrierTypeName
} from '@app/utils/linkEnums';

export const LinkDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<AlertVariant>(AlertVariant.info);

  const {
    link,
    loading,
    error,
    refreshLinkDetails,
  } = useLinkDetails({ linkId: id });

  useEffect(() => {
    console.log('Link data:', link);
  }, [link]);

  const showAlert = (message: string, variant: AlertVariant = AlertVariant.info) => {
    setAlertMessage(message);
    setAlertVariant(variant);
    setAlertVisible(true);
  };

  // Format data for display
  const formatDataUsage = (used: number, limit?: number) => {
    if (!used) return 'N/A';
    
    const usedKB = used;
    const usedMB = (usedKB / 1000).toFixed(2);
    const usedGB = (usedKB / 1000 / 1000).toFixed(2);
    
    if (limit) {
      const limitGB = (limit / 1000 / 1000).toFixed(2);
      return `${usedGB} GB of ${limitGB} GB`;
    }
    
    return usedKB > 1000000 ? `${usedGB} GB` : usedKB > 1000 ? `${usedMB} MB` : `${usedKB} KB`;
  };

  // Format date
  const formatDate = (dateString: string) => {
    if (!dateString) return 'N/A';
    
    try {
      const dateParts = dateString.split('T');
      const dateStr = dateParts[0];
      const timeStr = dateParts[1].split('+')[0];
      const timeParts = timeStr.split(':');
      return `${dateStr} ${timeParts[0]}:${timeParts[1]}`;
    } catch (e) {
      return dateString;
    }
  };

  // Prepare link data if link exists
  const getLinkData = () => {
    if (!link) return [];

    // Get carrier type display name
    const carrierType = getCarrierTypeName(link.carrier_type);

    return [
      { label: 'Link State', value: getLinkStatusName(link.status) || link.state || (link.isEnabled ? 'Enabled' : 'Disabled') },
      { label: 'Interface', value: link.interfaceName ? `eth${link.interfaceName}` : 'N/A' },
      { label: 'Address Type', value: getAddressTypeName(link.address_type) || (link.isDHCP ? 'Dynamic' : 'Static') },
      { label: 'Link Type', value: carrierType || link.type || 'WAN' },
      { label: 'Carrier', value: link.carrierName || 'N/A' },
      { label: 'Data Limit Exceeded', value: link.dataLimit > 0 ? (link.dataLimitUsed >= link.dataLimit ? 'Yes' : 'No') : 'N/A' },
      { label: 'Data Limit Reset Date', value: formatDate(link.dataLimitReset) },
      { label: 'Data Usage', value: formatDataUsage(link.dataLimitUsed, link.dataLimit) },
    ];
  };

  return (
    <>
      <PageSection variant="light">
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/sites">Sites</BreadcrumbItem>
          <BreadcrumbItem isActive>Link Details</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1" size="2xl" className="pf-u-mt-md">
          {link?.name || 'Link Details'}
        </Title>
      </PageSection>

      <PageSection>
        {alertVisible && (
          <Alert
            variant={alertVariant}
            title={alertMessage}
            actionClose={<AlertActionCloseButton onClose={() => setAlertVisible(false)} />}
            className="pf-u-mb-md"
          />
        )}

        {loading ? (
          <Loader />
        ) : error ? (
          <Alert variant="danger" title="Error loading link details" className="pf-u-mb-md">
            {error.message || 'An error occurred while loading link details'}
          </Alert>
        ) : link ? (
          <Card>
            <CardBody>
              <InfoSection keyValuePairs={getLinkData()} showHeading={false} />
            </CardBody>
          </Card>
        ) : (
          <Alert variant="warning" title="Link not found" className="pf-u-mb-md">
            The requested link could not be found.
          </Alert>
        )}
      </PageSection>
    </>
  );
};

export default LinkDetailsPage; 
