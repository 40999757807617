import React from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import throughputFromLinks from './SiteHelper';
import { IconHeading } from '@app/components/IconHeading';
import { InfoSection } from '@app/components/InfoSection';
import { Spacer } from '@app/components/Spacer';
import { PatternflyTable } from '@app/components/PatternflyTable';
import {
  protocolOptions,
  directionOptions,
  getAddressTypeName,
  getLinkStatusName,
  getCarrierTypeName,
  shouldShowSatelliteControls,
  AddressType,
  LinkStatus,
  CarrierType,
} from '@app/common/constants';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { sortable, cellWidth } from '@patternfly/react-table';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionToggle,
  FormGroup,
  Spinner,
  Select,
  SelectOption,
  SelectVariant,
  Tabs,
  Tab,
  TabTitleIcon,
  TabTitleText,
  TextInput,
  FormSelect,
  FormSelectOption,
  Checkbox,
  Tooltip,
  Popover,
  Alert,
  AlertActionCloseButton,
  AlertVariant,
  Text,
  TextVariants,
  Flex,
  FlexItem,
  Modal,
  ModalVariant,
  ToggleGroup,
  ToggleGroupItem,
  Grid,
  GridItem,
} from '@patternfly/react-core';
import { sortBy, reverseSortBy } from '@app/lib/functions';
import Site from '@app/api/site/site.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import SiteApi from '@app/api/site-api/site.actions';
import SpeedTest from '@app/api/speed-test/speed-test.actions';
import ControllerApi from '@app/api/controller-api/controller.actions';
import License from '@app/api/license/license.actions';
import SiteIcon from '@app/bgimages/sites-black.svg';
import WanIcon from '@app/bgimages/wans-black.svg';
import ACLProfileIcon from '@app/bgimages/service-catalog.svg';
import { getLbAlgoNameFromId } from './TunnelHelper';
import {
  AutomationIcon,
  BullhornIcon,
  ConnectedIcon,
  EnterpriseIcon,
  NetworkIcon,
  CatalogIcon,
  ClusterIcon,
  MapMarkerIcon,
  ExternalLinkAltIcon,
  ExclamationCircleIcon,
  SyncAltIcon,
  InfrastructureIcon,
  OutlinedClockIcon,
  TopologyIcon,
  DomainIcon,
  ServiceCatalogIcon,
  UserIcon,
  WrenchIcon,
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  PencilAltIcon,
  PficonNetworkRangeIcon,
  CogIcon,
  TimesIcon,
} from '@patternfly/react-icons';
import { PageSection, Title } from '@patternfly/react-core';
import { networkCidrBlock, formatDate } from '@app/lib/functions';
import { isValidIP, isValidNumber } from '@app/lib/validator';
import { SPEED_TEST_POLL_FREQ } from '@app/lib/constants';
import '../../components/InfoSection.css';
import './Site.css';
import { useSiteContext } from '@app/contexts/site.context';
import {
  selectClientCompanyById,
  selectControllerById,
  selectServerById,
  selectSiteById,
  selectWanById,
} from '@app/common/configuration/configuration.selectors';
import { push } from 'connected-react-router';
import { selectPartnerId } from '@app/common/session/session.selectors';
import UserSession from '@app/common/user-session';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import '../../app.css';
import './Site.css';
import {
  MdAccountTree,
  MdAltRoute,
  MdAppBlocking,
  MdForkLeft,
  MdInsertLink,
  MdLanguage,
  MdNewLabel,
  MdOutlineSecurity,
} from 'react-icons/md';
import Page from '@app/components/Page';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import { CardHeader, Col, Row, Card, CardBody } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement } from 'chart.js';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionToggle,
  FormGroup,
  Spinner,
  Select,
  SelectOption,
  SelectVariant,
  Tabs,
  Tab,
  TabTitleIcon,
  TabTitleText,
  TextInput,
  FormSelect,
  FormSelectOption,
  Button,
  Flex,
  FlexItem,
  Divider,
} from '@patternfly/react-core';
import loaderLogo from '@app/assets/images/logo/CloudAccess-Logo-BlkCloud.jpg';
import Loader from '@app/components/Loader';
import {
  getAddressTypeName,
  getLinkStatusName,
  getCarrierTypeName,
  shouldShowSatelliteControls,
  CarrierType,
} from '@app/common/constants';
import { LinkService, LinkCommandType } from '@app/api/link/link.service';
import axios from 'axios';
import MetricChart from '@app/components/MetricChart';
import DashboardApi from '@app/api/dashboard-api/dashboard.actions';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

enum TabIndex {
  // OSPF,
  BGP,
}

export const SiteView: React.FC = () => {
  const dispatch = useDispatch();
  const { id, isLoading, isFailed, isSuccess } = useSiteContext();
  Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement);
  const speedTestRef = React.useRef(null);

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, rebootType: '' });
  const [expandedLinkId, setExpandedLinkId] = React.useState(null);
  const [rebootingLinks, setRebootingLinks] = React.useState<Record<string, boolean>>({});
  const [stowingLinks, setStowingLinks] = React.useState<Record<string, boolean>>({});
  const [confirmLinkAction, setConfirmLinkAction] = React.useState<{
    isOpen: boolean;
    linkId: string;
    linkName: string;
    provider: string;
    action: LinkCommandType | null;
  }>({ isOpen: false, linkId: '', linkName: '', provider: '', action: null });

  const partnerId = useSelector(selectPartnerId);
  const site = useSelector(selectSiteById(id));
  const wan = useSelector(selectWanById(site?.wanId));
  const primaryTunnel = site?.Tunnels.find((tunnel) => tunnel.isPrimary === true) ?? site?.Tunnels?.[0];
  const server = useSelector(selectServerById(primaryTunnel?.Server.id));
  const gateway = useSelector(selectControllerById(primaryTunnel?.Server.gatewayId));

  const siteContact = site?.SiteContacts[0];
  const routedIpState = (site?.SiteRoutes?.length ?? 0) > 0 ? 'Enabled' : 'Disabled';
  const wanState = site?.wanId ? 'Enabled' : 'Disabled';

  const secondaryTunnel = site?.Tunnels.find((tunnel) => tunnel.isPrimary === false);
  const standbyServer = useSelector(selectServerById(secondaryTunnel?.Server.id));
  const standbyGateway = useSelector(selectControllerById(secondaryTunnel?.Server.gatewayId));
  const [aclProfiles, setAclProfiles] = React.useState([]);
  const [activeController, setActiveController] = React.useState(undefined);
  const [standbyController, setStandbyController] = React.useState(undefined);
  const [siteLans, setSiteLans] = React.useState([]);
  const [licenseId, setLicenseId] = React.useState('');
  const [licenseLoading, setLicenseLoading] = React.useState(true);
  const [isSpeedTestModalOpen, setIsSpeedTestModalOpen] = React.useState(false);
  const [isSpeedTestResultsModalOpen, setIsSpeedTestResultsModalOpen] = React.useState(false);
  const [isSpeedTestDropdownOpen, setIsSpeedTestDropdownOpen] = React.useState(false);
  const [isCancelSpeedTestConfirmOpen, setIsCancelSpeedTestConfirmOpen] = React.useState(false);
  const [speedTestInterface, setSpeedTestInterface] = React.useState('Tunnel');
  const [completedSpeedTestInterface, setCompletedSpeedTestInterface] = React.useState('');
  const [speedTests, setSpeedTests] = React.useState([]);
  const [runningTestId, setRunningTestId] = React.useState('');
  const [speedTestIsRunning, setSpeedTestIsRunning] = React.useState(false);
  const [speedTestResultStatus, setSpeedTestResultStatus] = React.useState('');
  const [speedTestInputsDisabled, setSpeedTestInputsDisabled] = React.useState(false);
  const [speedTestResults, setSpeedTestResults] = React.useState({
    download: 0,
    upload: 0,
    jitter: 0,
    loss: 0,
    latency: 0,
  });
  const [samples, setSamples] = React.useState([]);
  const [download, setDownload] = React.useState(0);
  const [upload, setUpload] = React.useState(0);
  const [protocol, setProtocol] = React.useState('TCP');
  const [direction, setDirection] = React.useState('Bidirectional');
  const [duration, setDuration] = React.useState('10');
  const [occurences, setOccurences] = React.useState('1');
  const [cancelButtonText, setCancelButtonText] = React.useState('Cancel');
  // const [period, setPeriod] = React.useState('');
  const [isProtocolDropdownOpen, setIsProtocolDropdownOpen] = React.useState(false);
  const [isDirectionDropdownOpen, setIsDirectionDropdownOpen] = React.useState(false);
  const [advancedExpanded, setAdvancedExpanded] = React.useState('');
  const [routingConfig, setRoutingConfig] = React.useState('');
  const [activeTabIndex, setActiveTabIndex] = React.useState(TabIndex.BGP);
  const [isSpeedTestsLoading, setIsSpeedTestsLoading] = React.useState(true);
  const [siteStatus, setSiteStatus] = React.useState(0);
  const [siteMetrics, setSiteMetrics] = React.useState([]);
  const [siteApiData, setSiteApiData] = React.useState(null);
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;

  const nocLink = process.env.REACT_APP_NOC_LINK
    ? String(process.env.REACT_APP_NOC_LINK).replace('{{site_id}}', id ?? '')
    : '';

  const TitleMap = {
    [TabIndex.BGP]: 'BGP',
  };
  React.useEffect(() => {
    (async function () {
      const licenseList = await License.getAll(partnerId);
      const siteLicenseId = licenseList.filter((site) => site.assigned_to === id);
      const siteRouting = await SiteApi.getRouting(id);
      const sites = await Site.getAll();
      const lans = await Site.getSiteLansBySiteId(id);
      const allSpeedTests = await SpeedTest.getAll(id);
      const theSite = sites.find((site) => site.id === id);
      const tunnels = await Tunnel.getBySiteId(id);
      const activeTunnel = tunnels?.filter((tunnel) => tunnel.isActive === true);
      const standbyTunnel = tunnels?.filter((tunnel) => tunnel.isActive === false);
      let active, standby;

      if (siteRouting.routing_configuration) {
        setRoutingConfig(siteRouting.routing_configuration);
      }

      if (activeTunnel && activeTunnel.length > 0) {
        active = await ControllerApi.get(activeTunnel[0].Server?.gatewayId);
        active.Server = { ...activeTunnel[0].Server };
      }
      if (standbyTunnel.length) {
        standby = await ControllerApi.get(standbyTunnel[0].Server?.gatewayId);
        standby.Server = { ...standbyTunnel[0].Server };
      }

      let acls = await ACLProfile.getAssignedACLs(id);

      if (theSite.wanId) {
        const wanAcls = await ACLProfile.getAssignedACLs(theSite.wanId);
        acls = acls.concat(wanAcls);
      }

      if (siteLicenseId.length) {
        setLicenseId(siteLicenseId[0].id);
      } else {
        setLicenseId('Unlicensed');
      }

      const sortedSpeedTests = reverseSortBy(allSpeedTests.tests, 'test_date');
      setSpeedTests(sortedSpeedTests);
      setActiveController(active);
      setStandbyController(standby);
      setLicenseLoading(false);
      setIsSpeedTestsLoading(false);
      setAclProfiles(acls);
      setSiteLans(lans);

      // Set all links as expanded by default
      if (activeTunnel.length && activeTunnel[0].Links && activeTunnel[0].Links.length > 0) {
        setExpandedLinkId(activeTunnel[0].Links[0].id);
      }
    })();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const siteCurrentStatus = await SiteApi.getSiteStatus(id);

        if (siteCurrentStatus) {
          setSiteStatus(siteCurrentStatus);
        }

        const siteCurrentMetrics = await SiteApi.getSiteMetrics(id);

        const metricsArray = Object.entries(siteCurrentMetrics);

        if (metricsArray) {
          const keysToRemove = ["ip_addr", "link_status", "link_status_changes", "uptime"];
          const filteredData = metricsArray.filter((item) => !keysToRemove.includes(item[0]));

          setSiteMetrics(filteredData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchSiteApiData = async () => {
    try {
      const token = UserSession.getToken();
      if (!token) {
        return;
      }

      const apiUrl = `${process.env.REACT_APP_USER_MGMT_API_BASE_URL}/sites/${id}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setSiteApiData(response.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchSiteApiData();
  }, [id]);

  const getTunnelInfo = React.useCallback(() => {
    return [
      { label: 'Loss Threshold', value: primaryTunnel?.lossTolerance + '%' },
      { label: 'Encryption', value: primaryTunnel?.isClearTextData ? 'Disabled' : 'Enabled' },
      { label: 'MTU Size', value: primaryTunnel?.autoMTU ? 'Auto' : 'Custom ' + primaryTunnel?.mtu },
      { label: 'Reorder Buffer Time', value: primaryTunnel?.reorderBufferTime + ' ms' },
      { label: 'Link Port Range', value: primaryTunnel?.portRangeStart + '-' + primaryTunnel?.portRangeEnd },
      { label: 'Bonding Algorithm', value: getLbAlgoNameFromId(primaryTunnel?.lbAlgo) },
      { label: 'Flowlet', value: primaryTunnel?.flowlet ? 'Enabled' : 'Disabled' },
    ];
  }, [primaryTunnel]);

  const getBusinessContinuityInfo = React.useCallback(() => {
    const linkConfig = site?.Tunnels?.[0]?.Links.find((link) => link.isPassthrough);
    const interfaceName = linkConfig === undefined ? 'N/A' : `eth${linkConfig.interfaceName}`;
    return [{ label: 'Interface Port', value: interfaceName }];
  }, [site]);

  const getLanInfo = (lan) => {
    const subnetAddress = `${networkCidrBlock(`${lan?.lanSegmentIp4}${lan?.lanCidrIp4}`)}${lan?.lanCidrIp4}`;

    return [
      { label: 'LAN Subnet', value: subnetAddress },
      { label: 'LAN Gateway IP', value: lan?.lanSegmentIp4 },
      { label: 'Primary DNS', value: lan?.dnsServer1 },
      { label: 'Secondary DNS', value: lan?.dnsServer2 },
      { label: 'DMZ Forwarding IP', value: lan?.isDMZ ? lan?.ip4DMZ : 'Disabled' },
      //{ label: 'VLAN', value: lan?.vlanId }
    ];
  };

  const getWifiInfo = (lan) => {
    const mode =
      lan?.apMode === 'mode_5'
        ? {
            speed: '5',
            channel: lan?.ap5Channel,
            ssid: lan?.ap5SSID,
            password: lan?.ap5Password,
          }
        : {
            speed: '2.4',
            channel: lan?.apChannel,
            ssid: lan?.apSSID,
            password: lan?.apPassword,
          };

    return [
      { label: mode.speed + ' GHz SSID', value: mode.ssid },
      { label: mode.speed + ' GHz Password', value: mode.password },
      { label: mode.speed + ' GHz Channel', value: mode.channel },
    ];
  };

  const getMiscInfo = (lan) => {
    return [{ label: 'VLAN', value: lan?.vlanId }];
  };

  const getDhcpInfo = (lan) => {
    return [
      { label: 'IP Range Start', value: lan?.dhcpRangeStart },
      { label: 'IP Range End', value: lan?.dhcpRangeEnd },
      { label: 'Default Lease Time (s)', value: lan?.dhcpDefaultLease },
      { label: 'Max Lease Time (s)', value: lan?.dhcpMaxLease },
    ];
  };

  const getRoutedIpInfo = React.useCallback(() => {
    return site?.SiteRoutes.map((route) => {
      return { label: route.subnet, value: route.routerIP };
    });
  }, [site]);

  const getCompanyInfo = React.useCallback(() => {
    return [
      { label: 'Name', value: site?.ClientCompany?.name },
      { label: 'Industry', value: site?.ClientCompany?.industry },
    ];
  }, [site]);

  const getWanInfo = React.useCallback(() => {
    const accessibleIps = site?.ipAccessLevel === 'All' ? 'All' : site?.accessibleIps;
    return [
      { label: 'WAN Name', value: wan?.name, id: site?.Wan?.id },
      { label: 'Accessible IPs', value: wan?.name ? accessibleIps : '' },
    ];
  }, [site, wan]);

  const getQuickLinks = React.useCallback(() => {
    if (site?.ClientCompany?.Quicklinks === undefined) {
      return '';
    }
    return site?.ClientCompany?.Quicklinks.map((quickLink, index) => {
      return (
        <div className="quick-link" key={quickLink.id}>
          Quick Link:{' '}
          <a href={quickLink.link as string} target="_blank">
            {quickLink.link}
          </a>
          <br />
          Description: <strong>{quickLink.description}</strong>
        </div>
      );
    });
  }, [site]);

  const getContactInfo = React.useCallback(() => {
    return [
      { label: 'First Name', value: siteContact?.firstName },
      { label: 'Last Name', value: siteContact?.lastName },
      { label: 'Email', value: siteContact?.email },
      { label: 'Phone', value: siteContact?.phone },
      { label: 'Ext.', value: siteContact?.ext },
      { label: 'Receives Alerts', value: siteContact?.emailAlert ? 'Yes' : 'No' },
    ];
  }, [siteContact]);

  const getLocationInfo = React.useCallback(() => {
    return [
      { label: 'Site Address', value: site?.address1 },
      { label: 'Address line 2', value: site?.address2 },
      { label: 'City', value: site?.city },
      { label: 'Country', value: site?.country },
      { label: 'State/Province', value: site?.state },
      { label: 'Postal Code', value: site?.postalCode },
    ];
  }, [site]);

  const getAclProfileInfo = () => {
    if (aclProfiles?.length > 0) {
      return aclProfiles.map((acl) => {
        let aclProfile = `/aclprofile/${acl.id}`;
        return { label: <Link to={aclProfile}>{acl.name}</Link>, value: acl.description };
      });
    }
    return [];
  };

  const getControllerInfo = () => {
    return [
      { label: 'Tunnel', value: site?.isServerless ? 'Disabled' : 'Enabled' },
      { label: 'Active Controller', value: site?.isServerless ? 'N/A' : activeController?.name },
      {
        label: 'Active Gateway',
        value: site?.isServerless
          ? 'N/A'
          : activeController?.Server.ip4Address
          ? activeController.Server.ip4Address
          : 'N/A',
      },
      { label: 'Standby Controller', value: standbyController ? standbyController.name : 'N/A' },
      {
        label: 'Standby Gateway',
        value: standbyController?.Server.ip4Address ? standbyController.Server.ip4Address : 'N/A',
      },
    ];
  };

  const handleTabClick = (e, index) => {
    setActiveTabIndex(index);
  };

  const editSite = React.useCallback(
    (event) => {
      dispatch(push(`/site/edit/${id}`));
    },
    [dispatch, id]
  );

  const scrollToSpeedTest = () => {
    if (speedTestRef.current) {
      speedTestRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const openSpeedTestModal = async (e, testId) => {
    setIsSpeedTestModalOpen(true);

    if (testId) {
      const speedTest = await SpeedTest.getById(id, testId);
      const params = speedTest.test.params;

      setProtocol(params.protocol.toUpperCase());
      setDirection(capitalizeFirstLetter(params.direction));
      setDuration(params.duration);
      setOccurences(params.recurrence.repeat);
      // setPeriod(params.recurrence.period);
      setSpeedTestInterface(speedTest.test.device.name);
      setSpeedTestInputsDisabled(true);
      setRunningTestId(testId);
      setSpeedTestIsRunning(true);
      pollSpeedTest(testId);
    } else {
      initSpeedTest(true);
    }
  };

  const initSpeedTest = (setDefaults) => {
    setRunningTestId('');
    setSpeedTestInputsDisabled(false);
    setSpeedTestIsRunning(false);

    if (setDefaults) {
      setSpeedTestResultStatus('');
      setSpeedTestResults({ download: 0, upload: 0, jitter: 0, loss: 0, latency: 0 });
      setProtocol('TCP');
      setDirection('Bidirectional');
      setDuration('10');
      setOccurences('1');
      // setPeriod('');
      setSpeedTestInterface('Tunnel');
      setAdvancedExpanded('');
    }
  };

  const handleSpeedTestResultsToggle = () => {
    setIsSpeedTestResultsModalOpen(false);
  };

  const handleCancelSpeedTestConfirmToggle = () => {
    setIsCancelSpeedTestConfirmOpen(false);
  };

  const runSpeedTest = async () => {
    const payload = {
      device_name: speedTestInterface,
      protocol: protocol.toLowerCase(),
      direction: direction.toLowerCase(),
      duration: parseInt(duration),
      recurrence: {
        repeat: parseInt(occurences),
        // period: period
      },
    };

    try {
      const result = await SpeedTest.start(id, payload);
      const testId = result?.data.test_id;

      if (testId) {
        setRunningTestId(testId);
        setSpeedTestIsRunning(true);
        pollSpeedTest(testId);
        setSpeedTestResultStatus('');
        setSpeedTestResults({ download: 0, upload: 0, jitter: 0, loss: 0, latency: 0 });
      } else {
        setSpeedTestResultStatus('StartFailed');
        setSpeedTestIsRunning(false);
      }
    } catch (error) {
      console.log(error);
      setSpeedTestResultStatus('StartFailed');
      setSpeedTestIsRunning(false);
    }
  };

  const pollSpeedTest = async (testId) => {
    let poll = await SpeedTest.getById(id, testId);
    let testStatus = capitalizeFirstLetter(poll.test.status);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    while (testStatus === 'Pending' || testStatus === 'Running') {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // SPEED_TEST_POLL_FREQ
      poll = await SpeedTest.getById(id, testId);
      testStatus = capitalizeFirstLetter(poll.test.status);

      if (poll.test.samples) {
        const peakDownload = getPeak(poll.test.samples, 'rx_rate');
        const peakUpload = getPeak(poll.test.samples, 'tx_rate');
        const peakJitter = getPeak(poll.test.samples, 'jitter');
        const peakLatency = getPeak(poll.test.samples, 'latency');
        const peakLoss = getPeak(poll.test.samples, 'loss');

        setSpeedTestResults({
          download: peakDownload.rx_rate,
          upload: peakUpload.tx_rate,
          latency: peakLatency.latency,
          jitter: peakJitter.jitter,
          loss: peakLoss.loss,
        });
      }
    }

    setSpeedTestResultStatus(testStatus);

    if (testStatus === 'Completed' || testStatus === 'Failed' || testStatus === 'Cancelled') {
      setCancelButtonText('Close');
      setCompletedSpeedTestInterface(speedTestInterface);
      initSpeedTest(false);
    }
  };

  const getPeak = (samples, prop) => {
    const last = samples.length - 1;
    return sortBy(samples, prop)[last];
  };

  const cancelSpeedTest = async () => {
    if (runningTestId !== '') {
      await SpeedTest.cancel(id, runningTestId);
    }

    setProtocol('');
    setDirection('');
    setDuration('10');
    setOccurences('1');
    // setPeriod('');
    setSpeedTestInterface('');
    setIsCancelSpeedTestConfirmOpen(false);
    setIsSpeedTestModalOpen(false);
  };

  const cancelSpeedTestConfirm = async (isOpen) => {
    if (speedTestIsRunning) {
      setIsCancelSpeedTestConfirmOpen(isOpen);
    } else {
      setIsSpeedTestModalOpen(false);
    }
  };

  const onSelectInterfaceToggle = (isOpen) => {
    setIsSpeedTestDropdownOpen(isOpen);
  };

  const onSelectProtocolToggle = (isOpen) => {
    setIsProtocolDropdownOpen(isOpen);
  };

  const onSelectDirectionToggle = (isOpen) => {
    setIsDirectionDropdownOpen(isOpen);
  };

  const onInterfaceSelect = (event, selection, isPlaceholder) => {
    setSpeedTestInterface(selection);
    setIsSpeedTestDropdownOpen(false);
  };

  const onProtocolSelect = (event, selection, isPlaceholder) => {
    setProtocol(selection);
    setIsProtocolDropdownOpen(false);
  };

  const onDirectionSelect = (event, selection, isPlaceholder) => {
    setDirection(selection);
    setIsDirectionDropdownOpen(false);
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };

  const handleOccurencesChange = (value) => {
    setOccurences(value);
  };

  // const handlePeriodChange = (value) => {
  //   setPeriod(value);
  // };

  const getDirectionOptions = () => {
    const dirOptions = directionOptions();
    return dirOptions.map((dir) => {
      return <SelectOption key={dir.value} id={dir.value} value={dir.value} />;
    });
  };

  const getProtocolOptions = () => {
    const protoOptions = protocolOptions();
    return protoOptions.map((proto) => {
      return <SelectOption key={proto.value} id={proto.value} value={proto.value} />;
    });
  };

  const getLinkInterfaceOptions = () => {
    const interfaces = [];
    site.Tunnels[0]?.Links?.map((link) => {
      interfaces.push(<SelectOption key={link.id} id={link.id} value={'eth' + link.interfaceName} />);
    });
    interfaces.push(<SelectOption key="tunnel" id="tunnel" value="Tunnel" />);
    return interfaces;
  };

  const getTestColumns = () => {
    return [
      { title: 'Run Date', transforms: [sortable] },
      { title: 'Download', props: { className: 'text-right' } },
      { title: 'Upload', props: { className: 'text-right' } },
      { title: 'Device', transforms: [sortable] },
      { title: 'Status', transforms: [sortable] },
      //{ title: 'LB Algorithm' },
      { title: 'Direction' },
      { title: 'Details' },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
    ];
  };

  const showResults = async (e, testId) => {
    const results = await SpeedTest.getById(id, testId);
    const params = results.test.params;

    if (results.test.samples) {
      setDownload(getPeak(results.test.samples, 'rx_rate').rx_rate);
      setUpload(getPeak(results.test.samples, 'tx_rate').tx_rate);
    }

    setProtocol(params.protocol.toUpperCase());
    setDirection(capitalizeFirstLetter(params.direction));
    setDuration(params.duration);
    setOccurences(params.recurrence.repeat);
    // setPeriod(params.recurrence.period);
    setSpeedTestInterface(results.test.device.name);
    setSamples(results.test.samples);
    setIsSpeedTestResultsModalOpen(true);
  };

  const getSampleColumns = (isResults) => {
    return [
      { title: isResults ? 'Timestamp' : 'Run Date' },
      { title: 'Latency', props: { className: 'text-right' } },
      { title: 'Jitter', props: { className: 'text-right' } },
      { title: 'Loss', props: { className: 'text-right' } },
      { title: 'Download Rate (Mbps)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Bytes Downloaded (MB)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Upload Rate (Mbps)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'Bytes Uploaded (MB)', transforms: [cellWidth(15)], props: { className: 'text-right' } },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
    ];
  };

  const sampleFromItem = (item, isResults) => {
    return {
      cells: [
        { title: isResults ? Date.parse(item.test_time) : formatDate(item.test_time, false) },
        { title: item.latency },
        { title: item.jitter },
        { title: item.loss },
        { title: getMbps(item.rx_rate) },
        { title: getMB(item.rx_bytes) },
        { title: getMbps(item.tx_rate) },
        { title: getMB(item.tx_bytes) },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
      ],
    };
  };

  const getMbps = (bitsPerSecond) => {
    if (bitsPerSecond === 0) {
      return bitsPerSecond;
    }
    const mbps = bitsPerSecond / 1000000;
    return mbps.toFixed(1);
  };

  const getMB = (bytesPerSecond) => {
    if (bytesPerSecond === 0) {
      return bytesPerSecond;
    }
    const mb = bytesPerSecond / 1000000;
    return mb.toFixed(1);
  };

  const speedTestFromItem = (item) => {
    const device = item.device?.name?.toLowerCase();
    const isTunnelTest = device?.startsWith('ubond') || device === 'tunnel';
    const testStatus = capitalizeFirstLetter(item.status);

    if (!item.samples) {
      return {
        cells: [
          { title: sortableDate(item.test_date) },
          { title: '' },
          { title: '' },
          { title: isTunnelTest ? 'Tunnel' : device },
          { title: testStatus },
          // { title: 'N/A' },
          { title: capitalizeFirstLetter(item.params.direction) },
          {
            title: (
              <div
                key={`results-${item.test_id}`}
                className="pointer link"
                onClick={(e) => showResults(e, item.test_id)}
              >
                Show
              </div>
            ),
          },
          { title: item.test_id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
        ],
      };
    }

    const sample = item.samples[0];
    const last = item.samples.length - 1;
    const peakDownload = sortBy(item.samples, 'rx_rate')[last];
    const peakUpload = sortBy(item.samples, 'tx_rate')[last];

    return {
      cells: [
        { title: sortableDate(item.test_date) },
        { title: getMbps(peakDownload.rx_rate) },
        { title: getMbps(peakUpload.tx_rate) },
        { title: isTunnelTest ? 'Tunnel' : device },
        {
          title:
            testStatus === 'Running' ? (
              <div
                key={`speed-test-${item.test_id}`}
                className="pointer link"
                onClick={(e) => openSpeedTestModal(e, item.test_id)}
              >
                Running
              </div>
            ) : (
              testStatus
            ),
        },
        // { title: isTunnelTest ? sample?.algo : 'N/A' },
        { title: capitalizeFirstLetter(item.params.direction) },
        {
          title: (
            <div key={`results-${item.test_id}`} className="pointer link" onClick={(e) => showResults(e, item.test_id)}>
              Show
            </div>
          ),
        },
        { title: item.test_id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
      ],
    };
  };

  const sortableDate = (date) => {
    if (!date) {
      return '';
    }
    return date.replaceAll('T', ' ').split('.')[0];
  };

  const onAccordionToggle = (id) => {
    if (id === advancedExpanded) {
      setAdvancedExpanded('');
    } else {
      setAdvancedExpanded(id);
    }
  };

  const viewSitePerformance = React.useCallback(() => {
    // Get the NOC_LINK from environment variables
    let nocLinkTemplate = process.env.REACT_APP_NOC_LINK || '';
    
    // Remove any quotation marks that might be in the environment variable
    nocLinkTemplate = nocLinkTemplate.replace(/['"]/g, '');
    
    // Replace the site_id placeholder with the actual site ID
    const capulseUrl = nocLinkTemplate.replace('{{site_id}}', id);
    
    // Use the location.href approach to avoid encoding issues
    const newWindow = window.open('about:blank', '_blank');
    if (newWindow) {
      newWindow.location.href = capulseUrl;
    }
  }, [id]);

  const rebootGateway = React.useCallback(() => {
    dispatch(actionCreators.serverRebootRequest(server?.id));
    setConfirmOpen({ isOpen: false, rebootType: '' });
  }, [dispatch, server]);

  const rebootSite = React.useCallback(() => {
    dispatch(actionCreators.siteRebootRequest(id));
    setConfirmOpen({ isOpen: false, rebootType: '' });
  }, [dispatch, site]);

  const getLans = () => {
    return siteLans.length === 0
      ? []
      : siteLans?.map((lan, index) => {
          const dhcpState = lan?.isDhcpRequired ? 'Enabled' : 'Disabled';
          const wifiState = lan?.isAP ? 'Enabled' : 'Disabled';
          const dmzState = lan?.isDMZ ? 'Enabled' : 'Disabled';

          return (
            <Row className="font-12">
              <Col>
                <Card className="h-100">
                  <CardHeader>
                    <IconHeading icon={<NetworkIcon className="icon-medium" />} heading={lan.interfaceName} />
                  </CardHeader>
                  <CardBody>
                    <InfoSection keyValuePairs={getLanInfo(lan)} showHeading={false} />
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <Card className="h-100">
                  <CardHeader>
                    <IconHeading icon={<ClusterIcon className="icon-medium" />} heading="DHCP" subHeading={dhcpState} />
                  </CardHeader>
                  <CardBody>
                    <InfoSection keyValuePairs={getDhcpInfo(lan)} showHeading={false} />
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <Card className="h-100">
                  <CardHeader>
                    <IconHeading
                      icon={<ConnectedIcon className="icon-medium" />}
                      heading="WiFi Settings"
                      subHeading={wifiState}
                    />
                  </CardHeader>
                  <CardBody>
                    <InfoSection keyValuePairs={getWifiInfo(lan)} showHeading={false} />
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <Card className="h-100">
                  <CardHeader>
                    <IconHeading icon={<CogIcon className="icon-medium" />} heading="Misc" subHeading={dmzState} />
                  </CardHeader>
                  <CardBody>
                    <InfoSection keyValuePairs={getMiscInfo(lan)} showHeading={false} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        });
  };

  const getLinks = React.useCallback(
    (isStandby) => {
      const links = site?.Tunnels?.[0]?.Links.filter((link) => link.isStandby === isStandby);
      const linkClass = isStandby ? 'standby-link' : '';
      let dataResetDate = '';

      return links?.map((link, index) => {
        if (link.dataLimitReset) {
          let dateParts = link.dataLimitReset.split('T');
          let dateStr = dateParts[0];
          let timeStr = dateParts[1].split('+')[0];
          let timeParts = timeStr.split(':');
          timeStr = timeParts[0] + ':' + timeParts[1];
          dataResetDate = dateStr + ' ' + timeStr;
        }

        const dnsAddresses = link.staticDnsIp?.split(',') || [];
        const dataLimitGb = (link.dataLimit / 1000 / 1000).toFixed(2);

        const linkData = [
          { label: 'Link State', value: link.isEnabled ? 'Enabled' : 'Disabled' },
          { label: 'Interface', value: 'eth' + link.interfaceName },
          { label: 'Address Type', value: link.isDHCP ? 'Dynamic' : 'Static' },
          // @ts-ignore
          ...(!link.isDHCP && [{ label: 'Address', value: link.staticIp }]),
          // @ts-ignore
          ...(!link.isDHCP && link?.staticGatewayIp?.length > 0 && [{ label: 'Gateway', value: link.staticGatewayIp }]),
          // @ts-ignore
          ...(!link.isDHCP && isValidIP(dnsAddresses[0]) && [{ label: 'DNS - Primary', value: dnsAddresses[0] }]),
          // @ts-ignore
          ...(!link.isDHCP && isValidIP(dnsAddresses[1]) && [{ label: 'DNS - Secondary', value: dnsAddresses[1] }]),
          { label: 'Link Type', value: link?.type },
          { label: 'Carrier', value: link.carrierName },
          {
            label: 'Data Limit Exceeded',
            value: link.dataLimit > 0 ? (link.dataLimitUsed >= link.dataLimit ? 'Yes' : 'No') : 'N/A',
          },
          { label: 'Data Limit Reset Date', value: dataResetDate },
          {
            label: 'Data Usage',
            value:
              link.dataLimit > 0 ? link.dataLimitUsed + ' KB of ' + dataLimitGb + ' GB' : link.dataLimitUsed + ' KB',
          },
        ];

        const isExpanded = expandedLinkId === link.id;

        // Format date for last updated
        const formatDate = (dateString) => {
          if (!dateString) return new Date().toLocaleString();
          try {
            return new Date(dateString).toLocaleString();
          } catch (e) {
            return dateString;
          }
        };

        // Find the matching link in the API response
        const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
          (apiLink) => apiLink.id === link.id || apiLink.interface_name === link.interfaceName
        );

        // Function to get metadata fields from the link
        const getMetadataFields = (link) => {
          if (!link?.metadata || typeof link.metadata !== 'object') {
            return [];
          }
          
          // Convert the metadata object to an array of key-value pairs for display
          return Object.entries(link.metadata).map(([key, value]) => ({
            label: key,
            value: value
          }));
        };

        // Detailed information for expanded view
        const detailedLinkData = [
          { label: 'Name', value: apiLink?.name || link.name || `eth${link.interfaceName}` || '' },
          { label: 'IP Address', value: apiLink?.ip_address || '' },
          { label: 'MAC Address', value: apiLink?.mac_address || '' },
          {
            label: 'Address Type',
            value: apiLink?.address_type !== undefined ? getAddressTypeName(apiLink.address_type) : '',
          },
          { label: 'Status', value: apiLink?.status !== undefined ? getLinkStatusName(apiLink.status) : '' },
          {
            label: 'Download',
            value: apiLink?.rx_rate !== undefined && apiLink?.rx_rate !== null ? `${apiLink.rx_rate} Mbps` : '',
          },
          {
            label: 'Upload',
            value: apiLink?.tx_rate !== undefined && apiLink?.tx_rate !== null ? `${apiLink.tx_rate} Mbps` : '',
          },
          {
            label: 'Provider',
            value: apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2 
              ? 'Starlink' 
              : apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
                ? 'OneWeb'
                : getCarrierTypeName(Number(apiLink?.provider)),
          },
          // Add all metadata fields dynamically
          ...getMetadataFields(apiLink),
        ];

        // Check if satellite controls should be shown - handle both string values and numeric enum values
        const showSatelliteControls = 
          // Check string values
          apiLink?.provider === 'Starlink' || 
          apiLink?.provider === 'OneWeb' ||
          // Check numeric enum values (CarrierType.Starlink = 2, CarrierType.OneWeb = 3)
          Number(apiLink?.provider) === 2 ||
          Number(apiLink?.provider) === 3;

        return (
          <React.Fragment key={`primary-link-${index}`}>
            <Row className="font-12 mb-3">
              <Col>
                <Card className={`h-100 ${linkClass}`} isCompact>
                  <CardHeader>Configured Throughput</CardHeader>
                  <CardBody>
                    <div className="w-100">
                      <ArrowCircleDownIcon className="icon-small" /> Download
                      <strong className="ms-3">{link.downloadThroughput} Mbps</strong>
                    </div>
                    <div className="w-100">
                      <ArrowCircleUpIcon className="icon-small" /> Upload
                      <strong className="ms-3">{link.uploadThroughput} Mbps</strong>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className={`h-100 ${linkClass}`} isCompact>
                  <CardBody>
                    <InfoSection keyValuePairs={linkData} showHeading={false} />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className={`h-100 ${linkClass}`} isCompact>
                  <CardBody>
                    <InfoSection keyValuePairs={detailedLinkData} showHeading={false} />

                    {showSatelliteControls && (
                      <div className="d-flex justify-content-center w-100 mt-2">
                        <Button
                          className="ethica-button-green mx-2"
                          style={{ width: '200px' }}
                          onClick={() => openLinkActionConfirm(link.id, LinkCommandType.Reboot, link.name)}
                          isLoading={rebootingLinks[link.id] || false}
                          isDisabled={rebootingLinks[link.id] || false}
                        >
                          Reboot
                        </Button>
                        <Button
                          className="ethica-button-green mx-2"
                          style={{ width: '200px' }}
                          onClick={() =>
                            openLinkActionConfirm(
                              link.id,
                              apiLink?.status === 2 ? LinkCommandType.Unstow : LinkCommandType.Stow,
                              link.name
                            )
                          }
                          isLoading={stowingLinks[link.id] || false}
                          isDisabled={stowingLinks[link.id] || false}
                        >
                          {apiLink?.status === 2 ? 'Unstow' : 'Stow'}
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        );
      });
    },
    [site, expandedLinkId, siteApiData]
  );

  // Handle link reboot
  const handleRebootLink = async (linkId: string) => {
    try {
      setRebootingLinks((prev) => ({ ...prev, [linkId]: true }));

      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Call the API service
      const response = await LinkService.sendLinkCommand(
        id!, // site ID from the component with non-null assertion
        linkId,
        LinkCommandType.Reboot
      );

      // Show success message
      alert(`Reboot command sent successfully.`);
    } catch (error: any) {
      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Show user-friendly error message
      if (error.response) {
        if (error.response.status === 401) {
          alert('Authentication error. Please log in again.');
        } else if (error.response.status === 403) {
          alert(`You do not have permission to reboot this ${dishType} dish.`);
        } else if (error.response.status === 400) {
          const errorMsg = error.response.data?.error || 'Invalid request format';
          alert(`Bad request: ${errorMsg}`);
        } else if (error.response.status === 404) {
          alert(`Unable to reboot ${dishType} dish. Please refresh the page and try again.`);
        } else {
          alert(
            `Failed to reboot ${dishType} dish: ${error.response.data?.message || 'Server error'} (Status: ${
              error.response.status
            })`
          );
        }
      } else if (error.message) {
        if (error.message.includes('Network Error')) {
          alert(`Network connection issue. Please check your internet connection and try again.`);
        } else {
          alert(`Failed to reboot ${dishType} dish: ${error.message}`);
        }
      } else {
        alert(`Failed to reboot ${dishType} dish. Please try again.`);
      }
    } finally {
      // Reset loading state after a delay to show feedback to user
      setTimeout(() => {
        setRebootingLinks((prev) => ({ ...prev, [linkId]: false }));
      }, 1000);
    }
  };

  // Handle link stow
  const handleStowLink = async (linkId: string) => {
    try {
      setStowingLinks((prev) => ({ ...prev, [linkId]: true }));

      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Call the API service
      const response = await LinkService.sendLinkCommand(
        id!, // site ID from the component with non-null assertion
        linkId,
        LinkCommandType.Stow
      );

      // Show success message
      alert(`Stow command sent successfully.`);
    } catch (error: any) {
      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Show user-friendly error message
      if (error.response) {
        if (error.response.status === 401) {
          alert('Authentication error. Please log in again.');
        } else if (error.response.status === 403) {
          alert(`You do not have permission to stow this ${dishType} dish.`);
        } else if (error.response.status === 400) {
          const errorMsg = error.response.data?.error || 'Invalid request format';
          alert(`Bad request: ${errorMsg}`);
        } else if (error.response.status === 404) {
          alert(`Unable to stow ${dishType} dish. Please refresh the page and try again.`);
        } else {
          alert(
            `Failed to stow ${dishType} dish: ${error.response.data?.message || 'Server error'} (Status: ${
              error.response.status
            })`
          );
        }
      } else if (error.message) {
        if (error.message.includes('Network Error')) {
          alert(`Network connection issue. Please check your internet connection and try again.`);
        } else {
          alert(`Failed to stow ${dishType} dish: ${error.message}`);
        }
      } else {
        alert(`Failed to stow ${dishType} dish. Please try again.`);
      }
    } finally {
      // Reset loading state after a delay to show feedback to user
      setTimeout(() => {
        setStowingLinks((prev) => ({ ...prev, [linkId]: false }));
        // Refresh the site data to update the button state
        fetchSiteApiData();
      }, 1000);
    }
  };

  // Handle link unstow
  const handleUnstowLink = async (linkId: string) => {
    try {
      setStowingLinks((prev) => ({ ...prev, [linkId]: true }));

      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Call the API service
      const response = await LinkService.sendLinkCommand(
        id!, // site ID from the component with non-null assertion
        linkId,
        LinkCommandType.Unstow
      );

      // Show success message
      alert(`Unstow command sent successfully.`);
    } catch (error: any) {
      // Find the link in the API data to get the provider
      const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
        (link) => link.id === linkId || link.interface_name === linkId
      );

      // Determine the dish type based on provider
      const dishType =
        apiLink?.provider === 'starlink' || apiLink?.provider === 'Starlink' || Number(apiLink?.provider) === 2
          ? 'Starlink' 
          : apiLink?.provider === 'oneweb' || apiLink?.provider === 'OneWeb' || Number(apiLink?.provider) === 3
            ? 'OneWeb' 
            : 'Satellite';

      // Show user-friendly error message
      if (error.response) {
        if (error.response.status === 401) {
          alert('Authentication error. Please log in again.');
        } else if (error.response.status === 403) {
          alert(`You do not have permission to unstow this ${dishType} dish.`);
        } else if (error.response.status === 400) {
          const errorMsg = error.response.data?.error || 'Invalid request format';
          alert(`Bad request: ${errorMsg}`);
        } else if (error.response.status === 404) {
          alert(`Unable to unstow ${dishType} dish. Please refresh the page and try again.`);
        } else {
          alert(
            `Failed to unstow ${dishType} dish: ${error.response.data?.message || 'Server error'} (Status: ${
              error.response.status
            })`
          );
        }
      } else if (error.message) {
        if (error.message.includes('Network Error')) {
          alert(`Network connection issue. Please check your internet connection and try again.`);
        } else {
          alert(`Failed to unstow ${dishType} dish: ${error.message}`);
        }
      } else {
        alert(`Failed to unstow ${dishType} dish. Please try again.`);
      }
    } finally {
      // Reset loading state after a delay to show feedback to user
      setTimeout(() => {
        setStowingLinks((prev) => ({ ...prev, [linkId]: false }));
        // Refresh the site data to update the button state
        fetchSiteApiData();
      }, 1000);
    }
  };

  // Open confirmation dialog
  const openLinkActionConfirm = (linkId: string, action: LinkCommandType, linkName: string) => {
    // Find the link in the API data to get the provider
    const apiLink = siteApiData?.tunnels?.[0]?.links?.find(
      (link) => link.id === linkId || link.interface_name === linkId
    );

    // Convert provider to a user-friendly name
    let providerName = '';
    if (apiLink?.provider === 'Starlink' || apiLink?.provider === 'starlink' || Number(apiLink?.provider) === 2) {
      providerName = 'Starlink';
    } else if (apiLink?.provider === 'OneWeb' || apiLink?.provider === 'oneweb' || Number(apiLink?.provider) === 3) {
      providerName = 'OneWeb';
    } else {
      // Try to convert numeric value if it exists
      if (apiLink?.provider !== undefined) {
        providerName = getCarrierTypeName(Number(apiLink.provider));
      } else {
        providerName = 'Satellite';
      }
    }

    setConfirmLinkAction({
      isOpen: true,
      linkId,
      linkName,
      provider: providerName,
      action,
    });
  };

  // Close confirmation dialog
  const closeLinkActionConfirm = () => {
    setConfirmLinkAction({
      isOpen: false,
      linkId: '',
      linkName: '',
      provider: '',
      action: null,
    });
  };

  // Handle confirmation
  const handleLinkActionConfirm = () => {
    const { linkId, action } = confirmLinkAction;

    if (linkId && action) {
      if (action === LinkCommandType.Reboot) {
        handleRebootLink(linkId);
      } else if (action === LinkCommandType.Stow) {
        handleStowLink(linkId);
      } else if (action === LinkCommandType.Unstow) {
        handleUnstowLink(linkId);
      }
    }

    closeLinkActionConfirm();
  };

  const getStatus = (statusId) => {
    const statuses = [
      { status: 'Down', color: 'ethica-bg-black' },
      { status: 'Warning', color: 'ethica-bg-magenta' },
      { status: 'Good', color: 'ethica-bg-green' },
    ];
    return statuses[statusId];
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isFailed) {
    return <ErrorView title="Failed to load Site" message="🤖 Failed to load Site" />;
  }

  if (isSuccess && !site) {
    return <NotFound />;
  }

  const aclProfileInfo = getAclProfileInfo();
  const controllerInfo = getControllerInfo();
  const linkInterfaces = getLinkInterfaceOptions();
  const protocols = getProtocolOptions();
  const directions = getDirectionOptions();

  const durationIsValid = isValidNumber(duration);
  const durationErrorState = durationIsValid
    ? { validated: 'default', errorText: '' }
    : {
        validated: 'error',
        errorText: 'Must be a positive number',
      };
  const occurencesIsValid = isValidNumber(occurences);
  const occurencesErrorState = occurencesIsValid
    ? { validated: 'default', errorText: '' }
    : {
        validated: 'error',
        errorText: 'Must be a positive number',
      };
  // const periodErrorState = period !== '' ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Must be a valid CRON string' };
  const interfaceErrorState =
    speedTestInterface !== ''
      ? {
          validated: 'default',
          errorText: '',
        }
      : { validated: 'error', errorText: '' };
  const protocolErrorState =
    protocol !== ''
      ? { validated: 'default', errorText: '' }
      : {
          validated: 'error',
          errorText: '',
        };
  const directionErrorState =
    direction !== ''
      ? { validated: 'default', errorText: '' }
      : {
          validated: 'error',
          errorText: '',
        };

  const speedTestParamsInvalid =
    durationErrorState.validated === 'error' ||
    occurencesErrorState.validated === 'error' ||
    // periodErrorState.validated === 'error' ||
    interfaceErrorState.validated === 'error' ||
    protocolErrorState.validated === 'error' ||
    directionErrorState.validated === 'error';

  // const ospfConfig = [];
  const bgpConfig = [];
  // const ospf = routingConfig?.ospf_configuration;
  //@ts-ignore
  const bgp = routingConfig?.bgp_configuration;

  // if (ospf) {
  //   for (let i=0; i<ospf.length; i++) {
  //     ospfConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{ospf[i]}<br /></div>);
  //   }
  // }
  if (bgp) {
    for (let i = 0; i < bgp.length; i++) {
      bgpConfig.push(
        <div style={{ whiteSpace: 'pre' }} key={i}>
          {bgp[i]}
          <br />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Page
          tag="div"
          className={`cr-page px-3 pt-2 sites-list ${theme}`}
          title=""
          breadcrumbs={[
            { name: <a href="/sites">Sites</a>, active: false },
            { name: site?.address1, active: true },
          ]}
        >
          <>
            <Row>
              <Col lg={5} md={5} sm={12} xs={12}>
                <div className="p-3 h-100 sites-card-wrapper d-flex position-relative">
                  <div
                    className={`position-absolute top-0 end-0 ${getStatus(siteStatus).color} p-2 text-white fw-bold`}
                    style={{ borderBottomLeftRadius: '5px', borderTopRightRadius: '5px' }}
                  >
                    {getStatus(siteStatus).status}
                  </div>
                  <Title headingLevel="h1" size="lg" style={{ paddingRight: '70px' }}>
                    {site?.address1}
                  </Title>
                  <Row className="w-100">
                    <Col lg={9} md={9} sm={12} xs={12}>
                      <div className="w-100 mt-2 mb-2">
                        <strong>Site ID: </strong>&nbsp;{id}
                        <br />
                        <strong>Client Company: </strong>&nbsp;{site?.ClientCompany?.name}
                        <br />
                        <strong>Client Company ID: </strong>&nbsp;{site?.ClientCompany?.id}
                        <br />
                        <strong>License ID: </strong>&nbsp;{licenseLoading ? <Spinner size="sm" /> : licenseId}
                      </div>
                      <Card className="mt-3">
                        <CardHeader>
                          <IconHeading icon={<NetworkIcon className="icon-medium" />} heading="Private WAN" />
                        </CardHeader>
                        <CardBody>
                          <InfoSection keyValuePairs={getWanInfo()} showHeading={false} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <div className="d-flex flex-column">
                        <Button 
                          className="text-white w-100 mb-2 ethica-button-green" 
                          onClick={editSite} 
                          style={{ 
                            textAlign: 'center', 
                            padding: '10px',
                            fontSize: '16px'
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          className="text-white w-100 mb-2 ethica-button-green"
                          onClick={(e) => setConfirmOpen({ rebootType: 'Site', isOpen: true })}
                          style={{ 
                            textAlign: 'center', 
                            padding: '10px',
                            fontSize: '16px'
                          }}
                        >
                          Reboot Site
                        </Button>
                        {(site?.isServerless || wan) && (
                          <Button
                            className="text-white w-100 mb-2 ethica-button-green"
                            onClick={(e) => setConfirmOpen({ rebootType: 'Tunnel Gateway', isOpen: true })}
                            style={{ 
                              textAlign: 'center', 
                              padding: '10px',
                              fontSize: '16px'
                            }}
                          >
                            Reboot Gateway
                          </Button>
                        )}
                        <Button 
                          className="text-white w-100 mb-2 ethica-button-green" 
                          onClick={viewSitePerformance} 
                          style={{ 
                            textAlign: 'center', 
                            padding: '10px',
                            fontSize: '15px'
                          }}
                        >
                          Performance
                        </Button>
                        <Button 
                          className="text-white w-100 mb-2 ethica-button-green" 
                          onClick={scrollToSpeedTest} 
                          style={{ 
                            textAlign: 'center', 
                            padding: '10px',
                            fontSize: '16px'
                          }}
                        >
                          Speed Test
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={7} md={7} sm={12} xs={12}>
                <Row className="w-100 font-12 h-100">
                  <Col className="mb-0">
                    <div className="controllers-card-wrapper d-flex position-relative h-100">
                      <Card className="mb-3">
                        <CardHeader>
                          <IconHeading icon={<EnterpriseIcon className="icon-medium" />} heading="Company Profile" />
                        </CardHeader>
                        <CardBody>
                          <InfoSection keyValuePairs={getCompanyInfo()} showHeading={false} />
                        </CardBody>
                      </Card>

                      <Card>
                        <CardHeader>
                          <IconHeading icon={<CatalogIcon className="icon-medium" />} heading="Company Notes" />
                        </CardHeader>
                        <CardBody>
                          {getQuickLinks()}
                          {site?.ClientCompany?.notes && site?.ClientCompany.notes.length > 0 ? (
                            <div className="textarea-input">
                              <strong>Notes:</strong> <br />
                              {site?.ClientCompany.notes}
                            </div>
                          ) : (
                            ''
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </Col>

                  <Col className="mb-0">
                    <div className="controllers-card-wrapper d-flex position-relative h-100">
                      <Card>
                        <CardHeader>
                          <IconHeading icon={<MapMarkerIcon className="icon-medium" />} heading="Site Location" />
                        </CardHeader>
                        <CardBody>
                          <InfoSection keyValuePairs={getLocationInfo()} showHeading={false} />
                        </CardBody>
                      </Card>
                    </div>
                  </Col>

                  <Col className="mb-0">
                    <div className="controllers-card-wrapper d-flex position-relative h-100">
                      <Card>
                        <CardHeader>
                          <IconHeading icon={<UserIcon className="icon-medium" />} heading="Contact Information" />
                        </CardHeader>
                        <CardBody>
                          <InfoSection keyValuePairs={getContactInfo()} showHeading={false} />
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="controllers-card-wrapper d-flex position-relative">
              <Row className="w-100">
                <Col lg={12} md={12} sm={12} xs={12}>
                  {siteMetrics.map(([metricName, metricData]) => (
                    <>
                      {Array.isArray(metricData) ? (
                        <MetricChart metricData={metricData} metricName={metricName} />
                      ) : (
                        Object.entries(metricData).map(([subMetricName, subMetricData]) => (
                          <div key={subMetricName}>
                            <MetricChart metricData={subMetricData} metricName={subMetricName} />
                          </div>
                        ))
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<MdAccountTree className="icon-medium" />} heading="LAN Interfaces" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      {isLoading ? (
                        <Spinner size="lg" />
                      ) : (
                        <React.Fragment>
                          {(getLans()?.length ?? 0) > 0 ? getLans() : <div>There are no LANs configured</div>}
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between font-12">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<MdAltRoute className="icon-medium" />} heading="Routing" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <Card isCompact className={`mb-3 w-100 h-100`}>
                            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                              <div className="w-100 d-flex justify-content-between">
                                <div className="w-100">
                                  <IconHeading
                                    icon={<MdAppBlocking className="icon-medium" />}
                                    heading="Routed IP Blocks"
                                    subHeading={routedIpState}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              {isLoading ? (
                                <Spinner size="lg" />
                              ) : (
                                <>
                                  <Card>
                                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                                      <div className="w-100 d-flex justify-content-between">
                                        <IconHeading
                                          icon={<MdNewLabel className="icon-medium" />}
                                          heading="Assign Routed IP Addresses"
                                        />
                                      </div>
                                    </CardHeader>
                                    <CardBody>
                                      {(getRoutedIpInfo()?.length ?? 0) > 0 ? (
                                        <div>
                                          <Spacer />
                                          <div className="right">
                                            <strong>Router IP</strong>
                                          </div>
                                          <div>
                                            <strong>Public Subnet</strong>
                                          </div>
                                          <Spacer />
                                          <InfoSection keyValuePairs={getRoutedIpInfo()} showHeading={false} />
                                        </div>
                                      ) : (
                                        <div>No routes defined</div>
                                      )}
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card isCompact className="h-100">
                            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                              <div className="w-100 d-flex justify-content-between">
                                <div className="w-100">
                                  <IconHeading
                                    icon={<TopologyIcon className="icon-medium" />}
                                    heading="Advanced"
                                    subHeading={
                                      //@ts-ignore
                                      !routingConfig.bgp_configuration ? 'disabled' : ''
                                    }
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <Card>
                                <CardHeader>
                                  <div className="w-100 d-flex justify-content-between">
                                    <IconHeading
                                      icon={<PficonNetworkRangeIcon className="icon-medium" />}
                                      heading="BGP"
                                    />
                                  </div>
                                </CardHeader>
                                <CardBody>{bgpConfig}</CardBody>
                              </Card>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between font-12">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<MdForkLeft className="icon-medium" />} heading="Tunnel Settings" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <Card isCompact className="tunnel-settings-row">
                            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                              <div className="w-100 d-flex justify-content-between">
                                <IconHeading
                                  icon={<InfrastructureIcon className="icon-medium" />}
                                  heading="Controllers"
                                />
                              </div>
                            </CardHeader>
                            <CardBody>
                              {isLoading ? (
                                <Spinner size="lg" />
                              ) : (
                                <div>
                                  <InfoSection keyValuePairs={controllerInfo} showHeading={false} />
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card isCompact className="tunnel-settings-row">
                            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                              <div className="w-100 d-flex justify-content-between">
                                <IconHeading icon={<DomainIcon className="icon-medium" />} heading="Tunnel Settings" />
                              </div>
                            </CardHeader>
                            <CardBody>
                              {isLoading ? (
                                <Spinner size="lg" />
                              ) : (
                                <div>
                                  <InfoSection keyValuePairs={getTunnelInfo()} showHeading={false} />
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card isCompact className="tunnel-settings-row">
                            <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                              <div className="w-100 d-flex justify-content-between">
                                <IconHeading
                                  icon={<WrenchIcon className="icon-medium" />}
                                  heading="Expected Throughput"
                                />
                              </div>
                            </CardHeader>
                            <CardBody>
                              {isLoading ? (
                                <Spinner size="lg" />
                              ) : (
                                <div>
                                  {(getLinks(false)?.length ?? 0) > 0 || (getLinks(true)?.length ?? 0) > 0 ? (
                                    throughputFromLinks(site?.Tunnels[0].Links)
                                  ) : (
                                    <div>There are no active links configured!</div>
                                  )}
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between font-12">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<MdOutlineSecurity className="icon-medium" />} heading="Security Settings" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Card>
                        <CardHeader>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="w-100">
                              <IconHeading
                                icon={<ServiceCatalogIcon className="icon-medium" />}
                                heading="ACL Profiles"
                                subHeading={aclProfileInfo?.length > 0 ? 'Enabled' : 'Disabled'}
                              />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          {aclProfileInfo?.length > 0 ? (
                            <div>
                              <InfoSection keyValuePairs={aclProfileInfo} showHeading={false} />
                            </div>
                          ) : (
                            <div>No ACL Profiles assigned!</div>
                          )}
                        </CardBody>
                      </Card>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between font-12">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<MdInsertLink className="icon-medium" />} heading="Internet Interfaces" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      {(getLinks(false)?.length ?? 0) > 0 ? (
                        getLinks(false)
                      ) : (
                        <div>
                          There are no Primary links configured
                          <br />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between font-12">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between">
                        <IconHeading icon={<AutomationIcon className="icon-medium" />} heading="Business Continuity" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Card>
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                          <div className="icon-heading-container">
                            <div className="icon-heading">A last-resort pass-through interface if tunnel fails</div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <InfoSection keyValuePairs={getBusinessContinuityInfo()} showHeading={false} />
                        </CardBody>
                      </Card>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between">
                  <Card isCompact className={`mb-3 w-100`}>
                    <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                      <div className="w-100 d-flex justify-content-between" ref={speedTestRef}>
                        <IconHeading icon={<OutlinedClockIcon className="icon-medium" />} heading="Speed Tests" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <Card className="h-100">
                            <CardHeader className="font-12">
                              Start a speed test with the default settings, or expand below for advanced options
                            </CardHeader>
                            <CardBody>
                              <Accordion asDefinitionList>
                                <AccordionItem>
                                  <AccordionToggle
                                    onClick={() => {
                                      onAccordionToggle('advanced-options');
                                    }}
                                    isExpanded={advancedExpanded === 'advanced-options'}
                                    id="advanced-options"
                                  >
                                    Advanced options
                                  </AccordionToggle>
                                  <AccordionContent
                                    id="advanced-expand"
                                    isHidden={advancedExpanded !== 'advanced-options'}
                                  >
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <FormGroup
                                              label="Number of Runs"
                                              isRequired
                                              helperTextInvalid=" Required Field"
                                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                                              //@ts-ignore
                                              validated={occurencesErrorState.validated}
                                              className="form-item me-3"
                                            >
                                              <TextInput
                                                type="text"
                                                id="occurences"
                                                name="occurences"
                                                //@ts-ignore
                                                validated={occurencesErrorState.validated}
                                                value={occurences}
                                                onChange={handleOccurencesChange}
                                                className="form-control"
                                              />
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup
                                              label="Duration(sec)"
                                              isRequired
                                              helperTextInvalid=" Required Field"
                                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                                              //@ts-ignore
                                              validated={durationErrorState.validated}
                                              className="form-item me-3"
                                            >
                                              <TextInput
                                                type="text"
                                                id="duration"
                                                name="duration"
                                                //@ts-ignore
                                                validated={durationErrorState.validated}
                                                value={duration}
                                                onChange={handleDurationChange}
                                                className="form-control"
                                              />
                                            </FormGroup>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <FormGroup
                                              label="Interface"
                                              isRequired
                                              helperTextInvalid=""
                                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                                              //@ts-ignore
                                              validated={interfaceErrorState.validated}
                                            >
                                              <Select
                                                className="speed-test-select"
                                                maxHeight={300}
                                                variant={SelectVariant.single}
                                                //@ts-ignore
                                                validated={interfaceErrorState.validated}
                                                onToggle={onSelectInterfaceToggle}
                                                onSelect={onInterfaceSelect}
                                                placeholderText="Select ..."
                                                isOpen={isSpeedTestDropdownOpen}
                                                isDisabled={speedTestInputsDisabled}
                                                selections={speedTestInterface}
                                              >
                                                {linkInterfaces}
                                              </Select>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup
                                              label="Protocol"
                                              isRequired
                                              helperTextInvalid=""
                                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                                              //@ts-ignore
                                              validated={protocolErrorState.validated}
                                            >
                                              <Select
                                                className="speed-test-select"
                                                maxHeight={300}
                                                variant={SelectVariant.single}
                                                //@ts-ignore
                                                validated={protocolErrorState.validated}
                                                onToggle={onSelectProtocolToggle}
                                                onSelect={onProtocolSelect}
                                                placeholderText="Select ..."
                                                isOpen={isProtocolDropdownOpen}
                                                selections={protocol}
                                              >
                                                {protocols}
                                              </Select>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup
                                              label="Direction"
                                              isRequired
                                              helperTextInvalid=""
                                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                                              //@ts-ignore
                                              validated={directionErrorState.validated}
                                            >
                                              <Select
                                                className="speed-test-select"
                                                maxHeight={300}
                                                variant={SelectVariant.single}
                                                //@ts-ignore
                                                validated={directionErrorState.validated}
                                                onToggle={onSelectDirectionToggle}
                                                onSelect={onDirectionSelect}
                                                placeholderText="Select ..."
                                                isOpen={isDirectionDropdownOpen}
                                                selections={direction}
                                              >
                                                {directions}
                                              </Select>
                                            </FormGroup>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <br />
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>

                              {speedTestIsRunning ? (
                                <div>
                                  <br />
                                  <Spinner size="md" /> &nbsp;Speed Test is running. Please wait ...
                                  <br />
                                </div>
                              ) : (
                                <></>
                              )}

                              {speedTestResultStatus === 'Completed' ? (
                                <div>
                                  <br />
                                  Speed Test on <strong>{completedSpeedTestInterface}</strong> completed successfully!
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                <></>
                              )}

                              {speedTestResultStatus === 'Cancelled' ? (
                                <div>
                                  <br />
                                  Speed Test cancelled successfully
                                </div>
                              ) : (
                                <></>
                              )}

                              {speedTestResultStatus === 'Failed' ? (
                                <div>
                                  <br />
                                  Speed Test failed to complete
                                </div>
                              ) : (
                                <></>
                              )}

                              {speedTestResultStatus === 'StartFailed' ? (
                                <div>
                                  <br />
                                  The Speed Test failed to start due to an error, or there may already be one scheduled
                                  or running. Please try again later
                                </div>
                              ) : (
                                <></>
                              )}

                              {speedTestResults?.download > 0 || speedTestResults?.upload > 0 ? (
                                <div>
                                  <br />
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div>
                                            <strong>Download</strong>
                                            <br />
                                            <span className="peak">{getMbps(speedTestResults.download)} Mbps</span>
                                          </div>
                                        </td>
                                        <td width="50"></td>
                                        <td>
                                          <div>
                                            <strong>Upload</strong>
                                            <br />
                                            <span className="peak">{getMbps(speedTestResults.upload)} Mbps</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <Spacer />
                                  <hr className="hr-results" />
                                  <Spacer />

                                  <table>
                                    <tbody>
                                      <tr>
                                        <td width="175">
                                          <strong>Latency:</strong> {speedTestResults.latency} ms
                                        </td>
                                        <td width="175">
                                          <strong>Jitter:</strong> {speedTestResults.jitter} ms
                                        </td>
                                        <td width="175">
                                          <strong>Loss:</strong> {speedTestResults.loss}%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <></>
                              )}

                              <Button
                                variant="primary"
                                className="me-2 ethica-button-green"
                                isDisabled={speedTestParamsInvalid || speedTestIsRunning}
                                onClick={runSpeedTest}
                              >
                                Start
                              </Button>
                              <Button variant="link" className="ethica-button-black" onClick={cancelSpeedTestConfirm}>
                                {cancelButtonText}
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col>
                          <Card className="h-100">
                            <CardBody>
                              {isSpeedTestsLoading ? (
                                <Spinner size="md" />
                              ) : speedTests.length === 0 ? (
                                <div>No Speed Tests found!</div>
                              ) : (
                                <div>
                                  <PatternflyTable
                                    columns={getTestColumns}
                                    perPage={6}
                                    data={speedTests.map(speedTestFromItem)}
                                    page={1}
                                  />
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Modal
              title="Confirm Reboot"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={confirmOpen.isOpen}
              onClose={() => setConfirmOpen({ isOpen: false, rebootType: '' })}
              actions={[
                <Button
                  key="confirm"
                  variant="primary"
                  onClick={confirmOpen.rebootType === 'Site' ? rebootSite : rebootGateway}
                >
                  Yes
                </Button>,
                <Button key="cancel" variant="link" onClick={() => setConfirmOpen({ isOpen: false, rebootType: '' })}>
                  Cancel
                </Button>,
              ]}
            >
              Are you sure you want to reboot this {confirmOpen.rebootType}?
            </Modal>

            <Modal
              title="Run Speed Test"
              titleIconVariant={OutlinedClockIcon}
              variant={ModalVariant.medium}
              isOpen={isSpeedTestModalOpen}
              //@ts-ignore
              onClose={cancelSpeedTestConfirm}
              actions={[
                <Button
                  key="confirm"
                  variant="primary"
                  isDisabled={speedTestParamsInvalid || speedTestIsRunning}
                  onClick={runSpeedTest}
                >
                  Start
                </Button>,
                <Button key="cancel" variant="link" onClick={cancelSpeedTestConfirm}>
                  {cancelButtonText}
                </Button>,
              ]}
            >
              <p>Start a speed test with the default settings, or expand below for advanced options</p>
              <br />

              <Accordion asDefinitionList>
                <AccordionItem>
                  <AccordionToggle
                    onClick={() => {
                      onAccordionToggle('advanced-options');
                    }}
                    isExpanded={advancedExpanded === 'advanced-options'}
                    id="advanced-options"
                  >
                    Advanced options
                  </AccordionToggle>
                  <AccordionContent id="advanced-expand" isHidden={advancedExpanded !== 'advanced-options'}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <FormGroup
                              label="Number of Runs"
                              isRequired
                              helperTextInvalid=" Required Field"
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              //@ts-ignore
                              validated={occurencesErrorState.validated}
                              className="form-item"
                            >
                              <TextInput
                                type="text"
                                id="occurences"
                                name="occurences"
                                //@ts-ignore
                                validated={occurencesErrorState.validated}
                                value={occurences}
                                onChange={handleOccurencesChange}
                                className="form-control"
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup
                              label="Duration(sec)"
                              isRequired
                              helperTextInvalid=" Required Field"
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              //@ts-ignore
                              validated={durationErrorState.validated}
                              className="form-item"
                            >
                              <TextInput
                                type="text"
                                id="duration"
                                name="duration"
                                //@ts-ignore
                                validated={durationErrorState.validated}
                                value={duration}
                                onChange={handleDurationChange}
                                className="form-control"
                              />
                            </FormGroup>
                          </td>
                          <td>
                            {/* <FormGroup
                                  label="Period(CRON)" isRequired
                                  helperTextInvalid=" Required Field"
                                  helperTextInvalidIcon={<ExclamationCircleIcon />}
                                  // validated={periodErrorState.validated}
                                >
                                  <TextInput
                                    type="text" id="period" name="period"
                                    // validated={periodErrorState.validated}
                                    placeholder="* * * * *"
                                    value={period}
                                    onChange={handlePeriodChange}
                                  />
                                </FormGroup> */}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup
                              label="Interface"
                              isRequired
                              helperTextInvalid=""
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              //@ts-ignore
                              validated={interfaceErrorState.validated}
                            >
                              <Select
                                className="speed-test-select"
                                maxHeight={300}
                                variant={SelectVariant.single}
                                //@ts-ignore
                                validated={interfaceErrorState.validated}
                                onToggle={onSelectInterfaceToggle}
                                onSelect={onInterfaceSelect}
                                placeholderText="Select ..."
                                isOpen={isSpeedTestDropdownOpen}
                                isDisabled={speedTestInputsDisabled}
                                selections={speedTestInterface}
                              >
                                {linkInterfaces}
                              </Select>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup
                              label="Protocol"
                              isRequired
                              helperTextInvalid=""
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              //@ts-ignore
                              validated={protocolErrorState.validated}
                            >
                              <Select
                                className="speed-test-select"
                                maxHeight={300}
                                variant={SelectVariant.single}
                                //@ts-ignore
                                validated={protocolErrorState.validated}
                                onToggle={onSelectProtocolToggle}
                                onSelect={onProtocolSelect}
                                placeholderText="Select ..."
                                isOpen={isProtocolDropdownOpen}
                                selections={protocol}
                              >
                                {protocols}
                              </Select>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup
                              label="Direction"
                              isRequired
                              helperTextInvalid=""
                              helperTextInvalidIcon={<ExclamationCircleIcon />}
                              //@ts-ignore
                              validated={directionErrorState.validated}
                            >
                              <Select
                                className="speed-test-select"
                                maxHeight={300}
                                variant={SelectVariant.single}
                                //@ts-ignore
                                validated={directionErrorState.validated}
                                onToggle={onSelectDirectionToggle}
                                onSelect={onDirectionSelect}
                                placeholderText="Select ..."
                                isOpen={isDirectionDropdownOpen}
                                selections={direction}
                              >
                                {directions}
                              </Select>
                            </FormGroup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>

              {speedTestIsRunning ? (
                <div>
                  <br />
                  <Spinner size="md" /> &nbsp;Speed Test is running. Please wait ...
                  <br />
                </div>
              ) : (
                <></>
              )}

              {speedTestResultStatus === 'Completed' ? (
                <div>
                  <br />
                  Speed Test on <strong>{completedSpeedTestInterface}</strong> completed successfully!
                  <br />
                  <br />
                </div>
              ) : (
                <></>
              )}

              {speedTestResultStatus === 'Cancelled' ? (
                <div>
                  <br />
                  Speed Test cancelled successfully
                </div>
              ) : (
                <></>
              )}

              {speedTestResultStatus === 'Failed' ? (
                <div>
                  <br />
                  Speed Test failed to complete
                </div>
              ) : (
                <></>
              )}

              {speedTestResultStatus === 'StartFailed' ? (
                <div>
                  <br />
                  The Speed Test failed to start due to an error, or there may already be one scheduled or running.
                  Please try again later
                </div>
              ) : (
                <></>
              )}

              {speedTestResults?.download > 0 || speedTestResults?.upload > 0 ? (
                <div>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <strong>Download</strong>
                            <br />
                            <span className="peak">{getMbps(speedTestResults.download)} Mbps</span>
                          </div>
                        </td>
                        <td width="50"></td>
                        <td>
                          <div>
                            <strong>Upload</strong>
                            <br />
                            <span className="peak">{getMbps(speedTestResults.upload)} Mbps</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <Spacer />
                  <hr className="hr-results" />
                  <Spacer />

                  <table>
                    <tbody>
                      <tr>
                        <td width="175">
                          <strong>Latency:</strong> {speedTestResults.latency} ms
                        </td>
                        <td width="175">
                          <strong>Jitter:</strong> {speedTestResults.jitter} ms
                        </td>
                        <td width="175">
                          <strong>Loss:</strong> {speedTestResults.loss}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
            </Modal>

            <Modal
              title="Speed Test Results"
              titleIconVariant={OutlinedClockIcon}
              variant={ModalVariant.large}
              isOpen={isSpeedTestResultsModalOpen}
              onClose={handleSpeedTestResultsToggle}
              actions={[
                <Button
                  key="cancel"
                  className="ethica-button-black"
                  variant="link"
                  onClick={handleSpeedTestResultsToggle}
                >
                  Close
                </Button>,
              ]}
            >
              <table>
                <tbody>
                  <tr>
                    <td width="150">
                      <strong>Peak Download:</strong>
                    </td>
                    <td width="110">{getMbps(download)} Mbps</td>
                    <td width="90">
                      <strong>Device:</strong>
                    </td>
                    <td width="100">{speedTestInterface}</td>
                    <td width="100">
                      <strong>Duration:</strong>
                    </td>
                    <td width="80">{duration}</td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Peak Upload:</strong>
                    </td>
                    <td width="110">{getMbps(upload)} Mbps</td>
                    <td width="90">
                      <strong>Protocol:</strong>
                    </td>
                    <td width="100">{protocol}</td>
                    <td width="90">
                      <strong>Direction:</strong>
                    </td>
                    <td width="145">{direction}</td>
                  </tr>
                  <tr>
                    <td width="145">
                      <strong>Number of Runs:</strong>
                    </td>
                    <td width="80">{occurences}</td>
                  </tr>
                </tbody>
              </table>
              <br />

              {samples?.length ? (
                <div>
                  <strong>Samples:</strong>

                  <PatternflyTable
                    columns={() => getSampleColumns(true)}
                    data={samples.map((sample) => sampleFromItem(sample, true))}
                    page={1}
                  />
                </div>
              ) : (
                <div>No samples to display!</div>
              )}
            </Modal>

            <Modal
              title="Confirm Action"
              titleIconVariant={BullhornIcon}
              variant={ModalVariant.small}
              isOpen={isCancelSpeedTestConfirmOpen}
              onClose={handleCancelSpeedTestConfirmToggle}
              actions={[
                <Button key="confirm" variant="primary" onClick={cancelSpeedTest}>
                  Yes
                </Button>,
                <Button key="cancel" variant="link" onClick={handleCancelSpeedTestConfirmToggle}>
                  Cancel
                </Button>,
              ]}
            >
              <p>Are you sure you want to cancel this speed test?</p>
            </Modal>
          </>
        </Page>
      )}

      {/* Link Action Confirmation Modal */}
      <Modal
        variant={ModalVariant.small}
        title={
          confirmLinkAction.action === LinkCommandType.Reboot
            ? 'Confirm Reboot'
            : confirmLinkAction.action === LinkCommandType.Stow
            ? 'Confirm Stow'
            : 'Confirm Unstow'
        }
        isOpen={confirmLinkAction.isOpen}
        onClose={closeLinkActionConfirm}
        className="modal-fade-in"
        actions={[
          <Button key="confirm" variant="primary" onClick={handleLinkActionConfirm} className="ethica-button-green">
            Confirm
          </Button>,
          <Button key="cancel" variant="primary" onClick={closeLinkActionConfirm} className="ethica-button-cancel">
            Cancel
          </Button>,
        ]}
      >
        Are you sure you want to{' '}
        {confirmLinkAction.action === LinkCommandType.Reboot
          ? 'reboot'
          : confirmLinkAction.action === LinkCommandType.Stow
          ? 'stow'
          : 'unstow'}{' '}
        the {confirmLinkAction.provider} dish connected to {confirmLinkAction.linkName || 'this link'}? This action
        cannot be undone.
      </Modal>
    </React.Fragment>
  );
};

export default SiteView;
