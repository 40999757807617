import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../components/InfoSection.css';
import './Course.css';
import { selectCourseId, selectCourseLessons } from '@app/common/session/session.selectors';
import { Col, Row } from 'reactstrap';
import Page from '@app/components/Page';
import { Link } from 'react-router-dom';

export const CourseView: React.FC = () => {
  const dispatch = useDispatch();

  // const courseId = useSelector(selectCourseId);
  const courseId = 1;
  // const course = useSelector(selectCourseId(courseId));
  const course = [];
  // const lessons = useSelector(selectCourseLessons(courseId));
  const lessons = [{
    "id": 101,
    "title": "Getting Started with Programming",
    "image": "https://portal.ethica.partners/wp-content/uploads/2023/05/image-48-1024x239.png",
    "content": "Introduction to programming concepts and setting up the environment.",
    },
    {
    "id": 102,
    "title": "Variables and Data Types",
    "image": "https://portal.ethica.partners/wp-content/uploads/2023/05/image-47-1024x428.png",
    "content": "Introduction to programming concepts and setting up the environment.",
    }
  ];

  return (
    <React.Fragment>
      <Page
        tag="div"
        title=""
        className={`cr-page px-3 pt-2 courses-view`}
        breadcrumbs={[{ name: ` Courses `, active: true }, { name: ` CourseName `, active: true }]}
      >
        <div className={`disable-wrapper active`}></div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
            <div className="dashboard-card-wrapper user-courses">
              <h2>
                <strong>User Lessons</strong>
              </h2>
              <Row>
                {lessons.map(({ id, title, image, content }, index) => (
                  <Col key={index} lg={4} md={4} sm={6} xs={12} className="mb-3">
                    <div className="course-item p-3">
                      <div className="course-image mb-3">
                        <img src={image} alt="" />
                      </div>
                      <div className="course-name mb-3">
                        <strong>
                          {title}
                        </strong>
                      </div>
                      <div className="course-description mb-3">{content}</div>
                      <div className="ld_course_grid_button mb-3">
                        <Link to={`/courses/1/lessons/${id}`} className="btn w-100 ethica-button-green">
                          <strong>Start Lesson</strong>
                        </Link>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};