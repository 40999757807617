import world50m from '@app/assets/geo-data/world-50m.json';
import React, { Component } from 'react';
import { MdCircle, MdRouter, MdLocationOn } from 'react-icons/md';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';

// Цвета статусов для тултипа
const statusColors: Record<number, string> = {
  0: "#85ba3b", // Зеленый
  1: "#e93a54", // Красный
  2: "#000"     // Черный
};

type DeviceType = 'controller' | 'site';

interface MapItem {
  id: string;
  status: number;
  type: DeviceType;
  location: {
    city: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
  link?: string;
}

interface BubbleMapProps {
  controllers: MapItem[];
  sites: MapItem[];
}

interface BubbleMapState {
  tooltip: {
    items: MapItem[];
    position: { x: number; y: number };
  } | null;
  isHoveringTooltip: boolean;
}

class MapWithBubblesDashboard extends Component<BubbleMapProps, BubbleMapState> {
  private mapContainerRef = React.createRef<HTMLDivElement>();

  state: BubbleMapState = {
    tooltip: null,
    isHoveringTooltip: false,
  };

  private groupDevicesByCoordinates(): MapItem[][] {
    const { controllers, sites } = this.props;
    const allDevices = [...controllers, ...sites];
    const groups = new Map<string, MapItem[]>();

    allDevices.forEach(item => {
      const key = `${item.location.coordinates.latitude},${item.location.coordinates.longitude}`;
      const group = groups.get(key) || [];
      groups.set(key, [...group, item]);
    });

    return Array.from(groups.values());
  }

  handleMarkerMouseEnter = (event: React.MouseEvent, items: MapItem[]): void => {
    const container = this.mapContainerRef.current?.getBoundingClientRect();
    if (!container) return;

    this.setState({
      tooltip: {
        items,
        position: {
          x: event.clientX - container.left + 15,
          y: event.clientY - container.top + 15,
        },
      },
    });
  };

  handleMarkerMouseLeave = (): void => {
    if (!this.state.isHoveringTooltip) {
      this.setState({ tooltip: null });
    }
  };

  renderTooltip() {
    const { tooltip } = this.state;
    if (!tooltip) return null;

    return (
      <div
        onMouseEnter={() => this.setState({ isHoveringTooltip: true })}
        onMouseLeave={() => this.setState({ isHoveringTooltip: false, tooltip: null })}
        style={{
          position: 'absolute',
          left: `${tooltip.position.x - 15}px`, // Уменьшаем отступ
          top: `${tooltip.position.y}px`, // Уменьшаем отступ
          backgroundColor: 'white',
          padding: '12px',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
          zIndex: 100,
          maxWidth: '400px',
          maxHeight: '500px',
          overflowY: 'auto',
          transform: 'translateY(-50%)',
          filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
        }}
      >
        <h4 style={{ margin: '0 0 8px 0', fontSize: '16px' }}>{tooltip.items[0].location.city}</h4>

        {tooltip.items.some((i) => i.type === 'controller') && (
          <div style={{ marginBottom: '12px' }}>
            <h5 style={{ margin: '0 0 6px 0', color: '#555', display: 'flex', alignItems: 'center' }}>
              <MdRouter style={{ marginRight: '8px' }} />
              Controllers
            </h5>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {tooltip.items
                .filter((item) => item.type === 'controller')
                .map((item) => (
                  <li
                    key={`ctrl-${item.id}`}
                    style={{
                      padding: '6px 0',
                      borderBottom: '1px solid #eee',
                      cursor: item.link ? 'pointer' : 'default',
                    }}
                    onClick={() => item.link && window.open(item.link, '_blank')}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdCircle color={statusColors[item.status]} style={{ marginRight: '8px' }} />
                      <a href={`/controller/${item.id}`}>
                        <span style={{ color: '#000' }}>{item.id}</span>
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}

        {tooltip.items.some((i) => i.type === 'site') && (
          <div>
            <h5 style={{ margin: '0 0 6px 0', color: '#555', display: 'flex', alignItems: 'center' }}>
              <MdLocationOn style={{ marginRight: '8px' }} />
              Sites
            </h5>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {tooltip.items
                .filter((item) => item.type === 'site')
                .map((item) => (
                  <li
                    key={`site-${item.id}`}
                    style={{
                      padding: '6px 0',
                      borderBottom: '1px solid #eee',
                      cursor: item.link ? 'pointer' : 'default',
                    }}
                    onClick={() => item.link && window.open(item.link, '_blank')}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdCircle color={statusColors[item.status]} style={{ marginRight: '8px' }} />
                      <a href={`/site/${item.id}`}>
                        <span style={{ color: '#000' }}>{item.id}</span>
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  render() {
    const deviceGroups = this.groupDevicesByCoordinates();

    return (
      <div ref={this.mapContainerRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{ scale: 150, center: [0, 30] }}
          width={980}
          height={551}
          style={{ width: '100%', height: 'auto', backgroundColor: '#808080' }}
        >
          <ZoomableGroup>
            <Geographies geography={world50m}>
              {({ geographies }) =>
                geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: '#000',
                        stroke: '#000',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      hover: { fill: '#000' },
                      pressed: { fill: '#000' },
                    }}
                  />
                ))
              }
            </Geographies>

            {deviceGroups.map((group, i) => (
              <Marker
                key={`marker-${i}`}
                coordinates={[
                  group[0].location.coordinates.longitude,
                  group[0].location.coordinates.latitude,
                ]}
                onMouseEnter={(e) => this.handleMarkerMouseEnter(e, group)}
                onMouseLeave={this.handleMarkerMouseLeave}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={Math.min(4 + group.length * 0.5, 8)}
                  fill="#85ba3b"
                  stroke="#fff"
                  strokeWidth="0.5"
                />
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>

        {this.renderTooltip()}
      </div>
    );
  }
}

export default MapWithBubblesDashboard;
