import gql from "graphql-tag";

export const GET_SITE_TUNNELS = gql`
  query AllTunnels {
    Tunnel(order_by: { isPrimary: desc }) {
      id
      isPrimary
      cidr
      downloadCap
      hasTrafficManagement
      interfaceName
      isCapEnabled
      isClearTextData
      isQOSEnabled
      isActive
      lossPenalty
      lossTolerance
      mtu
      lbAlgo
      flowlet
      shouldReorderBuffer
      reorderBufferTime
      reorderBufferSize
      reorderExpireTime
      reorderLink
      status
      timeout
      tunnelAddressPairSeed
      tunnelPassword
      tunBufferSize
      tuntap
      uploadCap
      serverId
      siteId
      autoMTU
      portRangeStart
      portRangeEnd
    }
  }
`;

export const GET_SITE_TUNNELS_BY_SITEID = gql`
  query SiteTunnels($siteId: uuid) {
    Tunnel(where: { siteId: { _eq: $siteId } }) {
      id
      isPrimary
      cidr
      downloadCap
      hasTrafficManagement
      interfaceName
      isCapEnabled
      isClearTextData
      isQOSEnabled
      isActive
      lossPenalty
      lossTolerance
      mtu
      lbAlgo
      flowlet
      shouldReorderBuffer
      reorderBufferTime
      reorderBufferSize
      reorderExpireTime
      reorderLink
      status
      timeout
      tunnelAddressPairSeed
      tunnelPassword
      tunBufferSize
      tuntap
      uploadCap
      serverId
      siteId
      autoMTU
      portRangeStart
      portRangeEnd
      Server {
        id
        gatewayId
        ip4Address
        ip4Cidr
        ip4Router
      }
      Links {
        id
        type
        carrierName
        isDHCP
        cpeBindIp
        dataLimit
        dataLimitReset
        dataLimitUsed
        downloadThroughput
        downloadThroughputCap
        interfaceName
        isCapped
        isCapEnabled
        isEnabled
        isEncrypted
        isMetered
        isPassthrough
        isStandby
        name
        notes
        serverBindIp
        serverPort
        state
        uploadThroughput
        uploadThroughputCap
        modemAPN
        isPPPoE
        isWWAN
        pppoeUsername
        pppoePassword
        staticIp
        staticGatewayIp
        staticDnsIp
        updated_at
        created_at
        mac_address
        ip_address
        address_type
        status
        provider
        rx_rate
        tx_rate
      }
    }
  }
`;

export const CREATE_TUNNEL = gql`
  mutation CreateTunnel($input: [Tunnel_insert_input!]!) {
    insert_Tunnel(objects: $input) {
      returning {
        id
      }
    }
  }
`;

export const GET_TUNNEL = gql`
  query GetTunnel($id: uuid) {
    Tunnel(where: { id: { _eq: $id } }) {
      id
      serverId
      portRangeStart
      portRangeEnd
      Links {
        id
        type
        carrierName
        isDHCP
        cpeBindIp
        dataLimit
        dataLimitReset
        dataLimitUsed
        downloadThroughput
        downloadThroughputCap
        interfaceName
        isCapped
        isCapEnabled
        isEnabled
        isEncrypted
        isMetered
        isPassthrough
        isStandby
        name
        notes
        serverBindIp
        serverPort
        state
        uploadThroughput
        uploadThroughputCap
        modemAPN
        isPPPoE
        isWWAN
        pppoeUsername
        pppoePassword
        staticIp
        staticGatewayIp
        staticDnsIp
        mac_address
        ip_address
        address_type
        status
        provider
        rx_rate
        tx_rate
      }
    }
  }
`;

export const UPDATE_TUNNEL = gql`
  mutation UpdateTunnel($id: uuid, $changes: Tunnel_set_input) {
    update_Tunnel(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_TUNNEL = gql`
  mutation DeleteTunnel($id: uuid) {
    delete_Tunnel(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const MAX_SERVER_LINK_PORT = gql`
  query MaxLinkPorts($serverId: uuid!) {
    Link(
      where: { Tunnel: { Server: { id: { _eq: $serverId } } } }
      order_by: { serverPort: desc }
      limit: 1
    ) {
      serverPort
      Tunnel {
        id
        Site {
          id
        }
      }
    }
  }
`;

export const LAST_PARTNER_TUNNEL = gql`
  query LastPartnerTunnel($partnerId: uuid) {
    Tunnel(
      where: {
        Site: { ClientCompany: { Partner: { id: { _eq: $partnerId } } } }
      }
      order_by: { tunnelAddressPairSeed: desc }
      limit: 1
    ) {
      tunnelAddressPairSeed
    }
  }
`;