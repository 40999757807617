import * as React from 'react';
import { Helmet } from 'react-helmet';
import UserSession from '@app/common/user-session';
import UserMgmtApi from '@app/api/user-api/user.actions';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import { Saver } from '@app/components/Saver';
import { PasswordPolicy } from '@app/components/PasswordPolicy';
import { textInputRequiredOnly, textInputValidate, validatePassword } from '@app/lib/validator';
import { FormGroup, PageSection, Title, TextInput, Flex, FlexItem } from '@patternfly/react-core';
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { Form, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

interface ResetPasswordState {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  message: string;
  messageType: string;
  formSubmitted: boolean;
  isLoading: boolean;
}
class ResetPassword extends React.Component<any, ResetPasswordState> {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      message: "",
      messageType: "",
      formSubmitted: false,
      isLoading: false
    };
  }

  handleChange = (key: keyof ResetPasswordState, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
      message: ""
    }));
  };

  handleSubmit = async () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const passwordValidator = validatePassword(newPassword, confirmPassword);

    if (passwordValidator.isValid === true) {
      this.setState(() => ({ 
        isLoading: true
      }));
      await this.verifyCurrentPassword(oldPassword, newPassword);
    }
    else {
      this.setState(() => ({ 
        message: passwordValidator.error,
        isLoading: false
      }));
    }
  };

  verifyCurrentPassword = async (oldPassword, newPassword) => {
    const userName = UserSession.getParam('userName');
    const poolId = process.env.REACT_APP_USER_POOL_ID;
    const clientId = process.env.REACT_APP_POOL_WEB_CLI_ID;
    const pool = new CognitoUserPool({ UserPoolId: poolId, ClientId: clientId });
    const authDetails = new AuthenticationDetails({ Username: userName, Password: oldPassword });
    const cognitoUser = new CognitoUser({ Username: userName, Pool: pool });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async (result) => {
        await this.resetPassword(oldPassword, newPassword); 
      },
      onFailure: async (error) => {
        this.setState(() => ({ 
          message: "Current password is invalid. Please try again",
          oldPassword: "",
          isLoading: false
        }));
      }
    });
  }

  resetPassword = async (oldPassword, newPassword) => {
    const userId = UserSession.getParam("userId");
    const accessToken = UserSession.getParam("accessToken");
    const payload = {
      old_password: oldPassword,
      new_password: newPassword,
      access_token: accessToken,
      partner_id: UserSession.getPartnerId(),
      user_id: userId
    }

    try {
      let result = await UserMgmtApi.resetPassword(userId, payload);

      if (result?.status === 202) {
        this.setState(() => ({
          message: "Password was updated successfully!",
          formSubmitted: true,
          isLoading: false,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }));
      }
      else {
        this.setState(() => ({
          message: "There was an error updating your password",
          formSubmitted: true,
          isLoading: false,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }));
      }
    }
    catch (error) {
      console.log(error);
      this.setState(() => ({
        message: "There was an error updating your password",
        isLoading: false
      }));
    }
  }

  render() {
    const { oldPassword, newPassword, confirmPassword, formSubmitted, isLoading } = this.state;
    const passwordErrorState = newPassword ? textInputRequiredOnly(newPassword) : { validated: 'default', errorText: "" };
    const confirmPasswordErrorState = confirmPassword ? textInputValidate(confirmPassword, true, newPassword === confirmPassword, "Passwords must match") : { validated: 'default', errorText: "" };
    const formValid = oldPassword && newPassword && confirmPassword && (newPassword === confirmPassword);

    return (
      <Col>
        <Card className="h-100">
          <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
            <div className="w-100 d-flex justify-content-between">
              <div className="icon-heading-container">
                <div className="icon-heading">
                  Reset Password                  
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Form isHorizontal>
                  <FormGroup label="Current Password" isRequired fieldId="oldPassword" className="form-item"
                    helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={oldPassword ? textInputRequiredOnly(oldPassword) : 'default'}>
                    <TextInput className="text-input form-control"
                      isRequired type="password" id="oldPassword" name="oldPassword" validated={oldPassword ? textInputRequiredOnly(oldPassword) : 'default'}
                      value={oldPassword} onChange={(e) => this.handleChange("oldPassword", e)}
                      autoComplete='new-password'
                    />
                  </FormGroup>
                  <FormGroup label="New Password" isRequired fieldId="newPassword" className="form-item"
                    helperTextInvalidIcon={<ExclamationCircleIcon />}>
                    <TextInput className="text-input form-control"
                      isRequired type="password" id="newPassword" name="newPassword" 
                      value={newPassword} onChange={(e) => this.handleChange("newPassword", e)}
                      autoComplete='new-password'
                    />
                  </FormGroup>
                  <FormGroup label="Confirm Password" isRequired fieldId="confirmPassword" className="form-item"
                    helperTextInvalid={confirmPasswordErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />}>
                    <TextInput className="text-input form-control"
                      isRequired type="password" id="confirmPassword" name="confirmPassword"
                      value={confirmPassword} onChange={(e) => this.handleChange("confirmPassword", e)}
                      autoComplete='new-password'
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col>
                <PasswordPolicy />
              </Col>
            </Row>
            <Row>
              <Col>
                <Saver 
                  submitButtonText="Update Password" 
                  submitButtonisDisabled={!formValid || formSubmitted}
                  submit={this.handleSubmit} 
                  showCancel={false}
                  isLoading={isLoading}
                  confirmationMessage="Are you sure you want to update your password?"
                  message={this.state.message}
                  messageType={this.state.messageType}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default ResetPassword;