import { MAX_SERVER_LINK_PORT, LAST_PARTNER_TUNNEL, GET_SITE_TUNNELS, GET_SITE_TUNNELS_BY_SITEID } from './gql.queries';
import UserSession from '@app/common/user-session';
import { apolloClient } from '../graphql/client';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { data: { Tunnel } } = await apolloClient.query({ 
        query: GET_SITE_TUNNELS
      });
      return Tunnel;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getBySiteId(siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { data: { Tunnel } } = await apolloClient.query({
        query: GET_SITE_TUNNELS_BY_SITEID,
        variables: { siteId },
      });
      return Tunnel;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async nextPort(serverId, startPort) {
    let port = startPort - 1;

    const { data } = await apolloClient.query({
      query: MAX_SERVER_LINK_PORT,
      variables: { serverId },
    });

    try {
      port = data.Link[0].serverPort;
    } 
    catch {
      // TODO: Fix this so that there is no swallowed exception, and handle accordingly
    }
    
    return port + 1;
  },

  async getTunnelAddressPairSeed() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const partnerId = UserSession.getPartnerId();
      let tunnelAddressPairSeed = -1;
  
      const { data } = await apolloClient.query({
        query: LAST_PARTNER_TUNNEL,
        variables: { partnerId },
      });
  
      if (Array.isArray(data.Tunnel) && data.Tunnel.length > 0) {
        tunnelAddressPairSeed = data.Tunnel[0].tunnelAddressPairSeed;
        if (tunnelAddressPairSeed === null || tunnelAddressPairSeed === undefined) {
          tunnelAddressPairSeed = -1;
        }
      }
  
      return tunnelAddressPairSeed + 1;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  }
}