import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { DownloadImage } from '@app/components/DownloadImage';
import { sortable, cellWidth } from '@patternfly/react-table';
import { PageSection, Title, Modal, ModalVariant } from '@patternfly/react-core';
import { BullhornIcon } from '@patternfly/react-icons';
import { useControllerContext } from '@app/contexts/controller.context';
import { useDispatch, useSelector } from 'react-redux';
import { controllerSelectors } from '@app/common/configuration/configuration.selectors';
import { push } from 'connected-react-router';
import { useSearch } from '@app/lib/use-search';
import { Gateway } from '@app/types';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { MdAddCircle, MdArrowDownward, MdArrowUpward, MdCancel, MdCircle, MdError, MdExpandCircleDown, MdInfo, MdInfoOutline, MdOutlineErrorOutline, MdOutlineExpandCircleDown, MdUnfoldMore } from 'react-icons/md';
import { Button, Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Tooltip } from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  Column,
  Updater,
} from '@tanstack/react-table';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import MapWithBubbles from '@app/components/MapWithBubbles';
import Loader from '@app/components/Loader';
import Swal from 'sweetalert2';
import Controller from '@app/api/controller/controller.actions';
import ProductApi from '@app/api/product-api/product.actions';
import { reverseSortBy } from '@app/lib/functions';

type ColumnFiltersState = ColumnFilter[];
interface ColumnFilter {
  id: string;
  value: unknown;
}

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ControllerList: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isFailed } = useControllerContext();
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement);
  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, actionId: '' });
  const [expandedRows, setExpandedRows] = React.useState({});
  const [allControllers, setAllControllers] = React.useState([]);

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const statuses = [
    "#85ba3b",
    "#e93a54",
    "#000"
  ];

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * statuses.length);
    return statuses[randomNumber];
  }

  const enhanceDataWithDemo = (data) => {
    return data.map(item => ({
      ...item,
      status: generateRandomNumber(),
      gateway: item.gateway || [
        <>192.168.1.1</>,
        <>192.168.1.2</>,
        <>192.168.1.3</>,
      ],
    }));
  };

  React.useEffect(() => {
    (async function() {
      try {
        const controllers = await Controller.getAll();
        setAllControllers(controllers);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const fetchedControllers = React.useMemo(
    () => allControllers.filter((x) => x.name?.toLowerCase() !== 'serverless'),
    [allControllers]
  );

  const controllers = useSearch(fetchedControllers);

  //const controllersData = React.useMemo(() => enhanceDataWithDemo(controllers), [controllers]);
  const controllersData = controllers;

  console.log('controllersData',controllersData);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const columns = React.useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'region',
      header: 'Location',
    },
    {
      header: 'Image',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <DownloadImage imageType="controller" imageId={row.original.id} />
        </>
      ),
    },
    {
      header: 'Actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <Link to={`${basePath}/controller/edit/${row.original.id}`}>
            <Button className="ethica-button-green" size="sm me-2">Edit</Button>
          </Link>
          <Button className="ethica-button-black" onClick={() => removeControllerAlert(row.original.id)} size="sm me-2">Delete</Button>
        </>
      ),
    },
  ], []);

  // Настройки для таблицы
  const table = useReactTable({
    data: controllersData,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  const removeControllerAlert = (controllerId) => {
    Swal.fire({
      title: 'Are you sure you want to delete this controller?',
      icon: 'warning',
      text: controllerId,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Success!'
        );
        deleteController(controllerId);
      }
    });
  };

  const deleteController = React.useCallback((controllerId) => {
    dispatch(actionCreators.controllerDeleteRequest(controllerId));
  }, [dispatch]);

  // Функция для генерации временных меток
  const generateLabels = () => {
    const labels = [];
    const now = moment();

    for (let i = -4; i <= 4; i++) {
      labels.push(now.clone().add(i, 'hours').format('HH:mm'));
    }

    return labels;
  };

  // Генерация цвета для каждой линии
  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.5)`; // Полупрозрачный цвет для линий
  };

  const generateRandomChange = (min, max) =>  {
    return Math.random() * (max - min) + min;
  }

  // Генерация данных для каждого контроллера
  const generateDatasets = (dataCollection, labels) => {
    return dataCollection.map((item, index) => ({
      label: item.name,
      data: labels.map(() => index + generateRandomChange(1,5)), // Случайные данные
      borderColor: generateRandomColor(), // Уникальный цвет для каждой линии
      fill: false, // Отключаем заливку под линией
      tension: 0.4,
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
    }));
  };

  // Генерация данных для каждого контроллера
  const generateDatasetsBandwidt = (dataCollection, labels) => {
    return dataCollection.map((item, index) => ({
      label: item.name,
      data: labels.map(() => generateRandomChange(0.5,3.5)), // Случайные данные
      borderColor: generateRandomColor(), // Уникальный цвет для каждой линии
      fill: false, // Отключаем заливку под линией
      tension: 0.4,
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
    }));
  };

  const displayedData = table.getRowModel().rows.map(row => row.original);

  const labels = generateLabels();

  const chartData = {
    labels, // Метки времени
    datasets: generateDatasets(displayedData, labels), // Наборы данных
  };

  const bandwidthData = {
    labels, // Метки времени
    datasets: generateDatasetsBandwidt(displayedData, labels), // Наборы данных
  };

  const chartOptions = (theme) => ({
    scales: {
      x: {
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
      y: {
        min: 0,
        max: 20,
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
          },
          color: theme === 'dark' ? '#fff' : '#000',
        },
      },
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        ctx.save();
        ctx.fillStyle = theme === 'dark' ? '#000' : '#fff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    },
  });

  const bandwidthOptions = (theme) => ({
    scales: {
      x: {
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
      y: {
        min: 0,
        max: 5,
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
          callback: function (value) {
            return value + 'Mb/s';
          },
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
          },
          color: theme === 'dark' ? '#fff' : '#000',
        },
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.save();
          ctx.fillStyle = theme === 'dark' ? '#000' : '#fff';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    },
  });
  //end new

  if (isFailed) {
    return <ErrorView title="Failed to load Controller" message="🤖 Failed to load Controller" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Controllers</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Page
          tag="div"
          className={`cr-page px-3 pt-2 controllers-list ${theme}`}
          title=""
          breadcrumbs={[{ name: 'Controllers', active: true }]}
        >

          {controllersData.length === 0 ? (
            <div>No Controllers found!</div>
          ) : (
            <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="controllers-card-wrapper">
                  <Card className="p-3">
                    <table className="controllers-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div className={`d-flex  align-items-center ${header.column.getCanFilter() ? 'justify-content-start' : 'justify-content-center'}`}>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? 'select-none'
                                          : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}

                                      {header.column.getCanSort() && (
                                        {
                                          asc: <MdArrowUpward className="ms-2" />,
                                          desc: <MdArrowDownward className="ms-2" />,
                                        }[header.column.getIsSorted() as string] ?? (
                                          <MdUnfoldMore className="ms-2 opacity-50" />
                                        )
                                      )}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                      <div>
                                        <Filter column={header.column} />
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map(row => (
                          <React.Fragment key={row.id}>
                              <tr>
                              {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={cell.column.getCanFilter() ? '' : 'text-center'}>
                                  {cell.column.columnDef.header === 'Name' && row.original.Servers.length > 0 ? (
                                    <>
                                      <button onClick={() => toggleRow(row.id)} className="table-arrow">
                                        {expandedRows[row.id] ? '▼' : '▶'}
                                      </button>
                                      <strong><a href={`${basePath}/controller/${row.original.id}`} className="controller-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                    </>
                                  ) : (
                                    flexRender(cell.column.columnDef.cell, cell.getContext())
                                  )}
                                </td>
                              ))}
                            </tr>

                            {expandedRows[row.id] && (
                              <>
                                {row.original.Servers && row.original.Servers.map(({id, ip4Address}, index) => (
                                  <tr key={index} className="expanded-row">
                                    <td style={{paddingLeft: '34px'}}>
                                      {`Gateway ${id}`}</td>
                                    <td className="text-center">{ip4Address}</td>
                                    <td className="text-center">
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>

                    {/* Пагинация */}
                    <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                      <div className="me-2">
                        <span className="flex items-center gap-1">
                          Go to page:
                          <input
                            type="number"
                            min="1"
                            max={table.getPageCount()}
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                              const page = e.target.value ? Number(e.target.value) - 1 : 0
                              table.setPageIndex(page)
                            }}
                            className="border p-1 rounded w-16"
                          />
                        </span>
                      </div>
                      <div>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<<'}
                          </button>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<'}
                          </button>
                          <span className="flex items-center me-1">
                            <strong>
                              {table.getState().pagination.pageIndex + 1} of{' '}
                              {table.getPageCount()}
                            </strong>
                          </span>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>'}
                          </button>
                          <button
                            className="border rounded p-1 me-2"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>>'}
                          </button>
                        </div>
                        <div>
                          <select
                            className="form-select"
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                              table.setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Link to={`${basePath}/controller/new`}>
                        <Button className="ethica-button-green" size="sm"><MdAddCircle className="me-1 ms-auto"/>New Controller</Button>
                      </Link>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="controllers-card-wrapper controller-performance justify-content-between">
                  <h2><strong>Controllers Performance</strong></h2>
                  <div className="d-flex flex-nowrap justify-content-between w-100">
                    <Col lg={6} md={6} sm={12} xs={12} className="me-1">
                        <Card>
                          <CardHeader>
                            CPU
                          </CardHeader>
                          <CardBody className="p-2">
                          <Line data={chartData} options={chartOptions(theme)}
                            style={{
                              backgroundColor: theme === 'dark' ? '#333030' : '#fff',
                            }}/>
                          </CardBody>
                        </Card>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Card>
                        <CardHeader>
                          Bandwidth
                        </CardHeader>
                        <CardBody className="p-2">
                          <Line data={bandwidthData} options={bandwidthOptions(theme)}/>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="controllers-card-wrapper controller-map">
                  <h2><strong>Controllers Map</strong></h2>
                  <Card inverse style={{background: '#808080'}}>
                    <MapWithBubbles controllers={controllers || []}/>
                  </Card>
                </div>
              </Col>
            </Row>
            </>
          )}

          {/* <Modal
            title="Confirm Action"
            titleIconVariant={BullhornIcon}
            variant={ModalVariant.small}
            isOpen={confirmOpen.isOpen}
            onClose={() => setConfirmOpen({ isOpen: false, actionId: '' })}
            actions={[
              <Button key="confirm" variant="primary" onClick={deleteRow}>
                Yes
              </Button>,
              <Button key="cancel" variant="link" onClick={() => setConfirmOpen({ isOpen: false, actionId: '' })}>
                Cancel
              </Button>,
            ]}
          >
            <p>Are you sure you want to delete this controller?</p>
            <p>
              <b>{confirmOpen.actionId}</b>
            </p>
          </Modal> */}
        </Page>
      )}
    </React.Fragment>
  );
};

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

interface CustomColumnMeta {
  filterVariant?: 'range' | 'select' | 'text';
}

function Filter({ column }: { column: Column<any, unknown> & { columnDef: { meta?: CustomColumnMeta } } }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
