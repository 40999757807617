import * as React from 'react';
import { AppDispatch } from '@app/store';
import UserApi from '@app/api/user-api/user.actions';
import { textInputRequiredOnly, textInputValidate, isValidPhone } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { PageSection, Title, TextInput, Form, FormGroup } from '@patternfly/react-core';
import Page from '@app/components/Page';
import '../admin/Admin.css';
import { connect } from 'react-redux';
import { State } from '@app/store/root-reducer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Loader from '@app/components/Loader';
import { Helmet } from 'react-helmet/es/Helmet';
import ResetPassword from '@app/pages/auth/ResetPassword';
import ApiKeys from '@app/pages/admin/ApiKeys';

interface ProfileState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  isLoading: boolean;
  messageType?: string;
  responseMessage?: string;
}

class Profile extends React.Component<AppDispatch & { partnerId: string; userId: string }, ProfileState> {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      isLoading: false,
    };
  }

  componentDidMount = () => {
    try {
      this.loadProfile();
    } catch (error) {
      this.setState(() => ({
        message: 'There was an error getting the user profile',
      }));
    }
  };

  loadProfile = async () => {
    const { userId } = this.props;
    const user = await UserApi.get(userId);

    this.setState(() => ({
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      email: user?.email || '',
      phone: user?.phone || '',
    }));
  };

  handleChange = <K extends keyof ProfileState>(name: K, value: ProfileState[K]) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleSubmit = async (event) => {
    try {
      const { userId } = this.props;
      const { firstName, lastName, phone } = this.state;
      const payload = {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
      };

      const result = await UserApi.update(userId, payload);

      if (result?.data?.request_id) {
        this.setState(() => ({
          message: 'User was updated successfully!',
        }));
      } else {
        this.setState(() => ({
          message: 'There was an error updating the user',
        }));
      }
    } catch (error) {
      this.setState(() => ({
        message: 'There was an error updating the user',
      }));
    }
  };

  render() {
    const { partnerId } = this.props;
    const { firstName, lastName, email, phone, isLoading, responseMessage } = this.state;
    const phoneIsValid = isValidPhone(phone);
    const phoneErrorState = textInputValidate(phone, true, phoneIsValid, 'Invalid phone number');
    const formValid = firstName && lastName && phone && phoneIsValid;
    const { theme } = this.context;

    return (
      <React.Fragment>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <Page
            tag="div"
            className={`cr-page px-3 pt-2 acl-form position-relative ${theme}`}
            title=""
            breadcrumbs={[{ name: 'Admin', active: true }]}
          >
            <Row>
              <Col>
                <Card className="h-100">
                  <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                    <div className="w-100 d-flex justify-content-between">
                      <div className="icon-heading-container">
                        <div className="icon-heading">Profile</div>
                      </div>
                      <div className="pointer add-section">
                        <strong>Partner ID:</strong>&nbsp; {partnerId}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <FormGroup
                      label="First Name"
                      isRequired
                      fieldId="firstName"
                      className="form-item"
                      helperTextInvalid=" Required field"
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={textInputRequiredOnly(firstName)}
                    >
                      <TextInput
                        isRequired
                        type="text"
                        id="firstName"
                        name="firstName"
                        validated={textInputRequiredOnly(firstName)}
                        value={firstName}
                        onChange={(e) => this.handleChange('firstName', e)}
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Last Name"
                      isRequired
                      fieldId="lastName"
                      className="form-item"
                      helperTextInvalid=" Required field"
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                      validated={textInputRequiredOnly(lastName)}
                    >
                      <TextInput
                        isRequired
                        type="text"
                        id="lastName"
                        name="lastName"
                        validated={textInputRequiredOnly(lastName)}
                        value={lastName}
                        onChange={(e) => this.handleChange('lastName', e)}
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup label="Email" fieldId="email" className="form-item">
                      <TextInput
                        isDisabled
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Phone Number"
                      isRequired
                      fieldId="phone"
                      className="form-item"
                      helperTextInvalid={phoneErrorState.errorText}
                      helperTextInvalidIcon={<ExclamationCircleIcon />}
                    >
                      <TextInput
                        isRequired
                        type="tel"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => this.handleChange('phone', e)}
                        className="form-control"
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <ResetPassword />
            </Row>
            <Row className="w-100">
              <ApiKeys />
            </Row>
          </Page>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state: State) => ({
  partnerId: state.common.auth.session?.partnerId,
  userId: state.common.auth.session?.userId,
}))(Profile);
