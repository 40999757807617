import React, { createContext, useContext, useReducer, useEffect } from 'react';
import cartAPI from '@app/api/cart-api/cart.actions';

const CartContext = createContext<any | null>(null);

export const useCart = () => useContext(CartContext);

const initialState = { items: [] };

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CART':
      return action.payload || { items: [] };
    case 'ADD_ITEM':
      return { ...state, items: [...state.items, action.payload] };
    case 'REMOVE_ITEM':
      return { ...state, items: state.items.filter(item => item.id !== action.payload) };
    case 'REMOVE_CART':
      return { items: [] };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const data = await cartAPI.getAll();
        dispatch({ type: 'SET_CART', payload: data || { items: [] } });
      } catch (error) {
        console.error('Error fetching cart:', error);
      }
    };
    fetchCart();
  }, []);

  const addToCart = async product => {
    console.log('addToCart', cart);
    if (cart?.id) {
      addItemToUserCart(product);
    } else {
      addItemToCart(product);
    }
  };

  const addItemToCart = async product => {
    try {
      const newItem = await cartAPI.addToCartAPI(product);
      dispatch({ type: 'ADD_ITEM', payload: newItem });
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const addItemToUserCart = async product => {
    try {
      const newItem = await cartAPI.addToUserCartAPI(cart.id, product);
      dispatch({ type: 'ADD_ITEM', payload: newItem });
    } catch (error) {
      console.error('Error adding item to user cart:', error);
    }
  };

  const removeItemFromCart = async (cartItemId) => {
    try {
      await cartAPI.removeItemFromCartAPI(cart.id, cartItemId);
      dispatch({ type: 'REMOVE_ITEM', payload: cartItemId });
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const removeCart = async cartId => {
    try {
      await cartAPI.removeCartAPI(cartId);
      dispatch({ type: 'REMOVE_CART', payload: null });
    } catch (error) {
      console.error('Error removing all items from cart:', error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeItemFromCart,
        removeCart,
        addItemToUserCart,
        addItemToCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
