import React from 'react';

const DocumentationSidebar = ({ onSelect, docs }) => {
  const renderTree = (node, path = '') => {
    return Object.keys(node).map((key) => {
      const newPath = path ? `${path}/${key}` : key;

      if (typeof node[key] === 'string') {
        return (
          <li key={newPath} style={{ marginLeft: '15px' }}>
            <button 
                style={{
                background: 'none',
                border: 'none',
                color: '#85ba3b',
                cursor: 'pointer',
                textAlign: 'left',
                padding: '0.5rem 0',
                width: '100%',
                }} 
                onClick={() => onSelect(node[key])}
            >
                {key.replace('.md', '')}
            </button>
          </li>
        );
      } else if (Object.keys(node[key]).length > 0) {
        return (
          <li key={newPath} style={{ marginLeft: '0', marginBottom: '5px' }}>
            <h3 className="fs-6">{key}</h3>
            <ul style={{ listStyle: 'none', padding: 0, marginLeft: '15px' }}>{renderTree(node[key], newPath)}</ul>
          </li>
        );
      } else {
        return null;
      }
    }).filter(Boolean);
  };

  return <ul style={{ listStyle: 'none', padding: 0 }}>{renderTree(docs)}</ul>;
};

export default DocumentationSidebar;
