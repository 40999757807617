import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@patternfly/react-core';
import { DownloadImage } from '@app/components/DownloadImage';
import { sortable, cellWidth } from '@patternfly/react-table';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { PageSection, Title, ModalVariant, Modal } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import { useSiteContext } from '../../contexts/site.context';
import { controllerSelectors, serverSelectors, siteSelectors } from '@app/common/configuration/configuration.selectors';
import { useSearch } from '@app/lib/use-search';
import { push } from 'connected-react-router';
import { ErrorView } from '../error/error';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  Updater,
  Column,
} from '@tanstack/react-table';
import { MdAddCircle, MdArrowDownward, MdArrowUpward, MdCircle, MdUnfoldMore } from 'react-icons/md';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import MapWithBubbles from '@app/components/MapWithBubblesSites';
import Loader from '@app/components/Loader';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

type ColumnFiltersState = ColumnFilter[];
interface ColumnFilter {
  id: string;
  value: unknown;
}

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const SiteList: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isFailed } = useSiteContext();
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;

  const [confirmOpen, setConfirmOpen] = React.useState({ isOpen: false, actionId: '' });
  const [page, setPage] = React.useState(1);

  const sites = useSearch(useSelector(siteSelectors.selectAll));
  const controllerById = useSelector(controllerSelectors.selectEntities);
  const serverById = useSelector(serverSelectors.selectEntities);

  //console.log('Sites',sites);

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  const statuses = [
    "#85ba3b",
    "#e93a54",
    "#000"
  ];

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * statuses.length);
    return statuses[randomNumber];
  }

  const enhanceDataWithDemo = (data) => {
    return data.map(item => ({
      ...item,
      status: generateRandomNumber(),
    }));
  };

  const sitessData = React.useMemo(() => enhanceDataWithDemo(sites), [sites]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const getController = React.useCallback((row) => {
    const gatewayId = row.original.Tunnels?.[0]?.Server?.gatewayId;
    console.log("gatewayId:", gatewayId); // Отладка
    console.log("controllerById:", controllerById); // Отладка

    if (!gatewayId) {
      return "N/A"; // Если gatewayId отсутствует
    }

    const controller = controllerById[gatewayId];
    console.log("Controller:", controller); // Отладка

    return controller && controller.name ? controller.name : "N/A";
  }, [controllerById]);

  const columns = React.useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'city',
      header: 'City',
    },
    {
      accessorKey: 'ClientCompany',
      header: 'Company',
      cell: ({ row }) => (
        <>
          {row.original.ClientCompany && row.original.ClientCompany.name && (
            <strong>{row.original.ClientCompany.name}</strong>
          )}
        </>
      ),
    },
    {
      accessorKey: 'controller',
      header: 'Controller',
      cell: ({ row }) => (
        <div>
          {row.original.field2}
          <div>{getController(row)}</div>
        </div>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableColumnFilter: false,
      cell: ({ row }) => {
        return <MdCircle color={row.original.status} />;
      },
    },
    {
      accessorKey: 'Wan',
      header: 'WAN',
      enableColumnFilter: true,
      enableSorting: true,
      cell: ({ row }) => (
        <>
          {row.original.Wan && row.original.Wan.id && row.original.Wan.name && (
            <Link to={`${basePath}/site/edit/${row.original.Wan.id}`} className="wan-name">
              <strong>{row.original.Wan.name}</strong>
            </Link>
          )}
        </>
      ),
    },
    {
      accessorKey: 'id',
      header: 'Site ID',
    },
    {
      header: 'Image',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <DownloadImage imageType="site" imageId={row.original.id} />
        </>
      ),
    },
    {
      header: 'Actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <Link to={`${basePath}/site/edit/${row.original.id}`}>
            <Button className="ethica-button-green" size="sm me-2">Edit</Button>
          </Link>
          <Button className="ethica-button-black" onClick={() => removeSiteAlert(row.original.id)} size="sm me-2">Delete</Button>
        </>
      ),
    },
  ], [getController]);

  const table = useReactTable({
    data: sitessData,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  const removeSiteAlert = (siteId) => {
    Swal.fire({
      title: 'Are you sure you want to delete this site?',
      icon: 'warning',
      text: siteId,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Success!'
        );
        deleteSiteAction(siteId);
      }
    });
  };

  const getColumns = () => {
    return [
      { title: 'Address', transforms: [sortable] },
      { title: 'City' },
      { title: 'Company' },
      { title: 'Controller', transforms: [sortable] },
      { title: 'WAN', transforms: [sortable] },
      { title: 'Site ID' },
      { title: 'Image' },
      { title: '' },
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  const editSite = React.useCallback((_, __, data) => {
    const siteId = data.cells[7].id;
    dispatch(push(`${basePath}/site/edit/${siteId}`));
  }, [dispatch]);

  const deleteSite = React.useCallback((_, __, data) => {
    const siteId = data.cells[7].id;
    setConfirmOpen({ isOpen: true, actionId: siteId });
  }, [confirmOpen]);

  const deleteSiteAction = React.useCallback((siteId) => {
    dispatch(actionCreators.siteDeleteRequest(siteId));
  }, [dispatch]);

  const deleteRow = React.useCallback(() => {
    dispatch(actionCreators.siteDeleteRequest(confirmOpen.actionId));
    setConfirmOpen({ isOpen: false, actionId: '' });
  }, [dispatch, confirmOpen]);

  const handleConfirmToggle = React.useCallback(() => {
    setConfirmOpen({ isOpen: false, actionId: '' });
  }, [setConfirmOpen]);

  const siteFromItem = React.useCallback((item) => {
    const siteServerId = item.Tunnels?.[0]?.serverId ?? "N/A";
    const siteServer = siteServerId !== "N/A" ? serverById[siteServerId] : "N/A";
    const controller = controllerById[(siteServer as any)?.gatewayId] || { name: "N/A "};
    return {
      cells: [
        { title: <Link to={{ pathname: `${basePath}/site/` + item.id }}>{item.address1}</Link>},
        { title: item.city },
        { title: item.ClientCompany.name },
        { title: controller.name },
        { title: item.Wan ? <Link to={{ pathname: `${basePath}/wan/` + item.Wan.id }}>{item.Wan.name}</Link> : ""},
        { title: item.id.replaceAll('-', '') },
        { title: <DownloadImage imageType="site" imageId={item.id} />},
        { id: item.id }
      ]
    }
  }, [sites, serverById, controllerById]);

  if (isFailed) {
    return <ErrorView title="Error" message="Failed to load Sites" />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Sites</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Page
          tag="div"
          className={`cr-page px-3 pt-2 sites-list ${theme}`}
          title=""
          breadcrumbs={[{ name: 'Sites', active: true }]}
        >
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="sites-card-wrapper">
                  <Card className="p-3">
                    <table className="sites-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div className={`d-flex  align-items-center ${header.column.getCanFilter() ? 'justify-content-start' : 'justify-content-center'}`}>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? 'select-none sites-header'
                                          : 'sites-header',
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {header.column.getCanSort() && (
                                        {
                                          asc: <MdArrowUpward className="ms-2" />,
                                          desc: <MdArrowDownward className="ms-2" />,
                                        }[header.column.getIsSorted() as string] ?? (
                                          <MdUnfoldMore className="ms-2 opacity-50" />
                                        )
                                      )}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                      <div className="d-flex justify-content-end">
                                        <Filter column={header.column} />
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map(row => (
                          <React.Fragment key={row.id}>
                              <tr>
                              {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={`${cell.column.getCanFilter() ? '' : 'text-center'} ${cell.column.columnDef.header === 'Actions' ? 'd-flex' : ''}`}>
                                  {cell.column.columnDef.header === 'Name' ? (
                                    <>
                                      <strong><a href={`${basePath}/site/${row.original.id}`} className="site-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                    </>
                                  ) : (
                                    flexRender(cell.column.columnDef.cell, cell.getContext())
                                  )}
                                </td>
                              ))}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>

                    {/* Пагинация */}
                    <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                      <div className="me-2">
                        <span className="flex items-center gap-1">
                          Go to page:
                          <input
                            type="number"
                            min="1"
                            max={table.getPageCount()}
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                              const page = e.target.value ? Number(e.target.value) - 1 : 0
                              table.setPageIndex(page)
                            }}
                            className="border p-1 rounded w-16"
                          />
                        </span>
                      </div>
                      <div>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<<'}
                          </button>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<'}
                          </button>
                          <span className="flex items-center me-1">
                            <strong>
                              {table.getState().pagination.pageIndex + 1} of{' '}
                              {table.getPageCount()}
                            </strong>
                          </span>
                          <button
                            className="border rounded p-1 me-1"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>'}
                          </button>
                          <button
                            className="border rounded p-1 me-2"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>>'}
                          </button>
                        </div>
                        <div>
                          <select
                            className="form-select"
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                              table.setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Link to={`${basePath}/site/new`}>
                        <Button className="ethica-button-green" size="sm"><MdAddCircle className="me-1 ms-auto"/>New Site</Button>
                      </Link>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="sites-card-wrapper site-map">
                  <h2><strong>Sites Map</strong></h2>
                  <Card inverse style={{background: '#808080'}}>
                    <MapWithBubbles sites={sites || []}/>
                  </Card>
                </div>
              </Col>
            </Row>

        <Modal
          title="Confirm Action"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={confirmOpen.isOpen}
          onClose={handleConfirmToggle}
          actions={[
            <Button key="confirm" variant="primary" onClick={deleteRow}>Yes</Button>,
            <Button key="cancel" variant="link" onClick={handleConfirmToggle}>Cancel</Button>
          ]}
        >
          <React.Fragment>
            <p>Are you sure you want to delete this site?</p>
            <b>{confirmOpen.actionId}</b>
          </React.Fragment>
        </Modal>
      </Page>
      )}
    </React.Fragment>
  )
}

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

interface CustomColumnMeta {
  filterVariant?: 'range' | 'select' | 'text';
}

function Filter({ column }: { column: Column<any, unknown> & { columnDef: { meta?: CustomColumnMeta } } }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
