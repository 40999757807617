import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async start(siteId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/speed-tests/${siteId}`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async getAll(siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/speed-tests/${siteId}`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getById(siteId, testId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/speed-tests/${siteId}/${testId}`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async cancel(siteId, testId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.delete(`/speed-tests/${siteId}/${testId}`, {});
      return result;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  }
}
