import * as React from 'react';
import Fuse from 'fuse.js';
import { SearchContext } from '../contexts/search.context';

const fuseOptions = {
  includeScore: false,
  keys: [
    'id',
    'name',
    'description',
    'address1',
    'address2',
    'city',
    'country',
    "clientCompanyId",
    'ClientCompany.name',
    'activeGatewayId',
    'activeServerId',
    'region',
    'controller.name'
  ],
};

export const useSearch = <T>(list: T[]) => {
  const fuse = new Fuse(list, fuseOptions);
  const { searchText, setSearchText } = React.useContext(SearchContext);

  React.useEffect(() => {
    return () => {
      setSearchText('');
    }
  }, []);

  return React.useMemo(
    () => ((searchText?.length ?? 0) > 0 ? fuse.search(searchText).map(({ item }) => item) : list),
    [list, searchText]
  );
};
