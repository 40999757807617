import gql from 'graphql-tag';

export const GET_LINK_DETAILS = gql`
  query GetLinkDetails($id: uuid) {
    Link(where: { id: { _eq: $id } }) {
      id
      name
      type
      carrierName
      isDHCP
      cpeBindIp
      dataLimit
      dataLimitReset
      dataLimitUsed
      downloadThroughput
      downloadThroughputCap
      interfaceName
      isCapped
      isCapEnabled
      isEnabled
      isEncrypted
      isMetered
      isPassthrough
      isStandby
      notes
      serverBindIp
      serverPort
      state
      uploadThroughput
      uploadThroughputCap
      modemAPN
      isPPPoE
      pppoeUsername
      pppoePassword
      staticIp
      staticGatewayIp
      staticDnsIp
      updated_at
      created_at
      mac_address
      ip_address
      address_type
      status
      provider
      rx_rate
      tx_rate
    }
  }
`;

export const REBOOT_LINK = gql`
  mutation RebootLink($id: uuid!) {
    rebootLink(id: $id) {
      success
      message
    }
  }
`;

export const STOW_LINK = gql`
  mutation StowLink($id: uuid!) {
    stowLink(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_LINK_NOTES = gql`
  mutation UpdateLinkNotes($id: uuid!, $notes: String!) {
    update_Link_by_pk(pk_columns: { id: $id }, _set: { notes: $notes }) {
      id
      notes
    }
  }
`; 
