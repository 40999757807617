import _map from 'lodash-es/map';
import UserSession from '@app/common/user-session';

import { apolloClient } from '../graphql/client';
import { GQLHelpers } from '../hasura/ts';
import { Server, Server_Set_Input } from '@app/types';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

// SERVER = GATEWAY

export default {
  async getAll(partnerId: string = UserSession.getPartnerId()) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { objects: Servers } = await GQLHelpers.Fragments.Server.queryObjects({
        apolloClient,
        options: { variables: { where: { Gateway: { partnerId: { _eq: partnerId } } } } },
      });
      return Servers;
    } 
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async delete(id) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const { server } = await GQLHelpers.Fragments.Server.queryById({ apolloClient, serverId: id });

      const { objects: Wans } = await GQLHelpers.Fragments.Wan.queryObjects({
        apolloClient,
        options: {
          variables: {
            where: { _or: [{ activeServerId: { _eq: id } }, { standbyServerId: { _eq: id } }] },
          },
        },
      });
  
      const hasTunnels = server?.Gateway.Servers.some((server) => server.id === id && server.Tunnels?.length > 0);
      const hasWans = Wans?.length > 0;
  
      if (!hasTunnels && !hasWans) {
        // Delete Server: Order - PublicIp, Tunnel Links, Tunnel
  
        await GQLHelpers.Models.PublicIp.removeObjects({
          apolloClient,
          options: { variables: { where: { serverId: { _eq: id } } } },
        });
  
        await GQLHelpers.Models.Link.removeObjects({
          apolloClient,
          options: { variables: { where: { Tunnel: { serverId: { _eq: id } } } } },
        });
  
        await GQLHelpers.Models.Tunnel.removeObjects({
          apolloClient,
          options: { variables: { where: { serverId: { _eq: id } } } },
        });
  
        await GQLHelpers.Models.Server.removeById({ apolloClient, serverId: id });
      } else {
        throw new Error('SERVER_ASSIGNED');
      }
    }
    else {
      cognitoUser.signOut();
    }
  },

  async reboot(serverIds: string[]) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const now = new Date().toISOString();
      const changes: Server_Set_Input = { rebootRequest: now };
  
      await GQLHelpers.Fragments.Server.updateObjects({
        apolloClient,
        options: { variables: { set: changes, where: { id: { _in: serverIds } } } },
      });
      return null;
    } else {
      cognitoUser.signOut();
      return null;
    }
  },
};
