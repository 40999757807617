import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import CourseApi from '@app/api/course-api/course.actions';
import { Spacer } from '@app/components/Spacer';
import UserSession from '@app/common/user-session';
import { getNamesFromFullname } from './CourseHelper';
import { getParamFromQueryString, formatDate, reverseSortBy } from '@app/lib/functions';
import { Flex, FlexItem, Spinner, TreeView, ToggleGroup, ToggleGroupItem, PageSection, Title, Modal, ModalVariant } from '@patternfly/react-core';
import { BullhornIcon, PencilAltIcon, ShareSquareIcon } from '@patternfly/react-icons';
import './Course.css';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Card, CardBody, Col, Row, Button, CardHeader } from 'reactstrap';
import CoursesList from '@app/components/CoursesList';
import {
  MdClose,
  MdAddCircle,
  MdOutlinePeople
} from 'react-icons/md';
import { CourseForm } from './CourseForm';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const CourseList: React.FunctionComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [courses, setCourses] = React.useState([]);
  const [activeItems, setActiveItems] = React.useState({});
  const [activeCourse, setActiveCourse] = React.useState({});
  const [enteringCourse, setEnteringCourse] = React.useState(false);
  const [courseForm, setCourseForm] = React.useState(false);
  const courseName = UserSession.getParam('courseName');
  const id = getParamFromQueryString('id');
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [activeCourseId, setActiveCourseId] = React.useState(null);

  React.useEffect(() => {
    (async function() {
      try {
        // const courses = await CourseApi.getAll(true);
        const courses = [{
          "id": 1,
          "name": "CAPulse Access for End Users",
          "image": "https://portal.ethica.partners/wp-content/uploads/2021/11/image-23-300x147.png",
          "description": "This course aims to introduce Service Providers to CAPulse, the new CloudAccess Metrics and Monitoring solution",
          "lessons": [
            {
              "lesson_id": 101,
              "title": "Getting Started with Programming",
              "content": "Introduction to programming concepts and setting up the environment."
            },
            {
              "lesson_id": 102,
              "title": "Variables and Data Types",
              "content": "Understanding variables, data types, and basic operations."
            }
          ]
        },
          {
            "id": 2,
            "name": "Commercializing CloudAccess – A Guide",
            "image": "https://portal.ethica.partners/wp-content/uploads/2021/08/Commercialization-training-course-image-300x157.png",
            "description": "This course will explain to service providers how best to productize CloudAccess. We will go over the costing and provide a few considerations for you to consider when pricing the service.",
            "lessons": [
              {
                "lesson_id": 104,
                "title": "Getting Started with Programming",
                "content": "Introduction to programming concepts and setting up the environment."
              },
              {
                "lesson_id": 105,
                "title": "Variables and Data Types",
                "content": "Understanding variables, data types, and basic operations."
              },
              {
                "lesson_id": 106,
                "title": "Control Structures",
                "content": "Learning about if statements, loops, and control flow."
              }
            ]
          },
          {
            "id": 3,
            "name": "CAPulse",
            "image": "https://portal.ethica.partners/wp-content/uploads/2021/11/image-23-300x147.png",
            "description": "This course aims to introduce Service Providers to CAPulse, the new CloudAccess Metrics and Monitoring solution",
            "lessons": [
              {
                "lesson_id": 104,
                "title": "Getting Started with Programming",
                "content": "Introduction to programming concepts and setting up the environment."
              },
              {
                "lesson_id": 105,
                "title": "Variables and Data Types",
                "content": "Understanding variables, data types, and basic operations."
              },
              {
                "lesson_id": 106,
                "title": "Control Structures",
                "content": "Learning about if statements, loops, and control flow."
              },
              {
                "lesson_id": 107,
                "title": "Control Structures",
                "content": "Learning about if statements, loops, and control flow."
              }
            ]
          }];
        const courseId = 1; //UserSession.getCourseId();
        const sortedCourses = reverseSortBy(courses, 'updated');
        const coursePromises = [];

        if (sortedCourses) {
          //@ts-ignore
          coursePromises = sortedCourses.map(item => {
            return courseFromItem(item);
          });
        }

        const courseRows = await Promise.all(coursePromises);

        setCourses(courseRows);
        setLoading(false);

        // If no course id is passed, just expand the first course
        if (!id && courseRows?.length) {
          courseRows[0].defaultExpanded = true;
          setActiveCourse(courseRows[0]);
          setActiveItems([courseRows[0]]);
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const enterCourseSpace = async (e) => {
    e.preventDefault();
    const flattened = flattenCourses(courses);
    const currentCourseId = UserSession.getCourseId();
    //@ts-ignore
    const courseChain = getCourseAuthChain(flattened, activeCourse.id, currentCourseId, activeCourse);

    setEnteringCourse(true);

    // If the active selected course is nested, add it to the end of the course chain
    //@ts-ignore
    if (currentCourseId !== activeCourse.parent_id) {
      //@ts-ignore
      courseChain.push({ id: activeCourse.id, parent_id: activeCourse.parent_id, name: activeCourse.name });
    }

    for (let i = 0; i < courseChain.length; i++) {
      const course = courseChain[i];
      //@ts-ignore
      const assumedRole = await CourseApi.getAssumedRole(course.id);
      //@ts-ignore
      const preferences = await CourseApi.getPreferences(course.id);

      UserSession.setParam('token', assumedRole.assumed_token);
      UserSession.setParam('courseId', course.id);
      UserSession.setParam('courseName', course.name);

      // Override course preferences
      UserSession.setParam('logoFileUrl', preferences?.logo_filename_url || false);
      UserSession.setParam('faviconFileUrl', preferences?.favicon_filename_url || false);
      UserSession.setParam('pageTitle', preferences?.page_title || false);
      UserSession.setParam('headerColor', preferences?.header_color || false);
      UserSession.setParam('logoFilename', preferences?.logo_filename || false);
      UserSession.setParam('faviconFilename', preferences?.favicon_filename || false);
    }

    // When exiting the course space from components/layout/sidebar.component.tsx, this will
    // get set to false and app will be reloaded, and original session attributes from redux 
    // store will get loaded
    UserSession.setParam('isImpersonating', true);

    window.location.href = '/dashboard';
  }

  const getCourseAuthChain = (flattened, courseId, currentCourseId, activeCourse) => {
    const course = flattened.find(p => p.id === courseId);
    let courseChain = [];

    if (currentCourseId === course.parent_id) {
      courseChain = courseChain.concat({ id: courseId, parent_id: course.parent_id, name: course.name });
    }
    else {
      //@ts-ignore
      courseChain = courseChain.concat(getCourseAuthChain(flattened, course.parent_id, currentCourseId));
    }

    return courseChain;
  }

  const flattenCourses = (courses) => {
    var result = [];

    console.log(courses);

    courses.forEach(function (course) {
      result.push(course);

      if (Array.isArray(course.lessons)) {
        console.log(course.lessons);
        result = result.concat(flattenCourses(course.lessons));
      }
    });

    return result;
  }

  const courseFromItem = async (item) => {
    const subCourseCount = item.courses ? item.courses.length : 0;
    const names = getNamesFromFullname(item.billing_contact);
    const course = {
      name: item.name,
      image: item.image,
      description: item.description,
      id: item.id,
      created: item.created_at,
      updated: item.updated_at,
    }

    // If id is passed via query string, make that the active node in the TreeView
    if (id === item.id) {
      //@ts-ignore
      course.defaultExpanded = true;
      setActiveItems([course]);
      setActiveCourse(course);
    }

    // Only show the badge containing the sub course count if > 0
    if (subCourseCount > 0) {
      const badgeText = subCourseCount === 1 ? `${subCourseCount} course` : `${subCourseCount} courses`;
      //@ts-ignore
      course.hasBadge = true;
      //@ts-ignore
      course.customBadgeContent = badgeText;
      item.courses = item.courses.map(item => {
        return courseFromItem(item);
      });
      //@ts-ignore
      course.lessons = await Promise.all(item.courses);
    }
    else {
      //@ts-ignore
      course.hasBadge = false;
    }

    return course;
  }

  const findById = (id, coursesList) => {
    for (const course of coursesList) {
        if (course.id === id) {
            return course;
        }
        if (course.lessons && course.lessons.length > 0) {
            const foundInChildren = findById(id, course.lessons);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
    }
    return null;
  };
  
  const selectedCourse = activeCourseId ? findById(activeCourseId, courses) : null;

  return (
    <React.Fragment>
      <Page
          tag="div"
          title=""
          className={`cr-page px-3 pt-2 courses-list ${theme}`}
          breadcrumbs={[{ name: 'Courses', active: true }]}
        >
        <div className={`disable-wrapper ${courseForm ? 'active' : ''}`}></div>
        <Row>
          <Col md={activeCourseId ? "9" : "12"} sm="12" xs="12" className="courses-col">
            <Card>
              <CardBody>
                <CoursesList
                  headers={[
                    'ID',
                    'name',
                    'image',
                    'Description',
                    'Lessons',
                  ]}
                  coursesData={courses}
                  activeCourseId={activeCourseId}
                  setActiveCourseId={setActiveCourseId}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
}
