import * as React from 'react';
import { Flex, FlexItem, Title, Radio } from '@patternfly/react-core';
import { Button, CardHeader, Col, Row, Card, CardBody } from 'reactstrap';
import './ProductCard.css';

const ProductCard = ({ product, submit, intervals, currency, handleIntervalChange }) => {
  const submitButtonText = "Purchase Now!";
  let yearDisabled, monthDisabled = false;

  if (product.prices.length === 1) {
    const firstPrice = product.prices[0];

    if (firstPrice.recurring?.interval === 'year') {
      yearDisabled = false;
      monthDisabled = true;
    } 
    else {
      yearDisabled = true;
      monthDisabled = false;
    }
  }

  const interval = intervals[product.id];
  const price = product.prices.find(p => p.recurring?.interval === interval.split('-')[0] && p.currency === currency);
  
  if (!price) {
    return (
      <Card isCompact>
        <CardBody className="product-card">
          <Title headingLevel="h1" size="xl">{product.name}</Title>
          <p>There was an error loading the pricing information</p>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card isCompact className="h-100">
      <CardHeader>
        {product.name}
      </CardHeader>
      <CardBody className="product-card">        
        <p>{product.description}</p><br />
        <p><b>${price.unit_amount} {price.currency.toUpperCase()} / {price.recurring?.interval}</b></p>
        <br />

        <table>
          <tbody>
            <tr>
              <td>
                <Radio
                  className="form-input"
                  isChecked={interval.startsWith('month')}
                  name={'month-' + price.id}
                  id={'month-' + price.id}
                  isDisabled={monthDisabled}
                  onChange={(e) => handleIntervalChange('month-' + product.id, e)}
                  label="Monthly"
                  value={'month-' + product.id}
                />
              </td>
              <td>
                <Radio
                  className="form-input"
                  isChecked={interval.startsWith('year')}
                  name={'year-' + price.id}
                  id={'year-' + price.id}
                  isDisabled={yearDisabled}
                  onChange={(e) => handleIntervalChange('year-' + product.id, e)}
                  label="Yearly"
                  value={'year-' + product.id}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <Button id="submit-btn" variant="primary" className="button-confirm" onClick={submit}>
          {submitButtonText} 
        </Button>
      </CardBody>
    </Card>
  );
}

export default ProductCard;