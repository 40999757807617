import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectLogoFileUrl } from '@app/common/session/session.selectors';
import logoBlack from '@app/bgimages/ethica-logo-white.svg';
import logoWhite from '@app/bgimages/ethica-wht-colourbg.svg';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import { State } from '@app/store/root-reducer';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const Logo: React.FC = () => {
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const preferences = useSelector((state: State) => state.common.auth.partnerPreferences);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(push('/dashboard'));
  const defaultLogo = theme === 'dark' ? logoBlack : logoWhite;
  const logoUrl = preferences && preferences?.logoFileUrl.length > 0 ? preferences.logoFileUrl.length : defaultLogo;

  return React.useMemo(
    () => <img src={logoUrl} width="150px" height="auto" onClick={handleClick} alt="Orchestrator Logo" />,
    [logoUrl]
  );
};
