import { createSelector } from 'reselect';
import { State } from '../../store/root-reducer';
import UserSession from '@app/common/user-session';
import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';

const imageRepo = process.env.REACT_APP_IMAGE_REPOSITORY_BASE_URL;
const inPartnerSpace = UserSession.getParam('isImpersonating');

export const selectNetworkStatus = (state: State) => state.common.session?.networkStatus;
export const selectPartnerId = (state: State) => inPartnerSpace ? UserSession.getPartnerId() : state.common.auth.session?.partnerId;
export const selectCourseId = (state: State) => inPartnerSpace ? UserSession.getCourseId() : state.common.auth.session?.courseId;
export const selectCourseLessons = (state: State) => inPartnerSpace ? UserSession.getCourseId() : state.common.auth.session?.courseId;
export const selectToken = (state: State) => state.common.auth.session?.token;
export const selectLogoFilename = (state: State) => inPartnerSpace ? UserSession.getParam('logoFilename') : state.common.auth.partnerPreferences?.logoFilename;
export const selectFaviconFilename = (state: State) => inPartnerSpace ? UserSession.getParam('faviconFilename') : state.common.auth.partnerPreferences?.faviconFilename;
export const selectFaviconFileUrl = (state: State) => inPartnerSpace ? UserSession.getParam('faviconFileUrl') : state.common.auth.partnerPreferences?.faviconFilename ?? '';
export const selectLogoFileUrl = (state: State) => inPartnerSpace ? UserSession.getParam('logoFileUrl') : state.common.auth.partnerPreferences?.logoFilename ?? EthicaLogo;
export const selectPageTitle = (state: State) => inPartnerSpace ? UserSession.getParam('pageTitle') : state.common.auth.partnerPreferences?.pageTitle || "Orchestrator";
export const selectHeaderColor = (state: State) => inPartnerSpace ? UserSession.getParam('headerColor') : state.common.auth.partnerPreferences?.headerColor;
export const selectPartnerPreferences = (state: State) => state.common.auth.partnerPreferences;
export const selectNotifications = (state: State) => state.common.session.notifications;