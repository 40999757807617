import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import DocumentationSidebar from '@app/components/documentation/docSidebar';
import Page from '@app/components/Page';
import { Col, Row } from 'reactstrap';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import getDocsStructure from '@app/utils/getDocsStructure';

//@ts-ignore
const context = require.context('@app/pages/documentation/docs', true, /\.md$/);

const DocumentationView = () => {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [content, setContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [docsStructure, setDocsStructure] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { theme } = React.useContext(ThemeContext);

  const searchRef = useRef(null);

  const extractFileName = (filePath) => {
    return filePath.replace(/^.*\/\d*\s*(.*?)\.md$/, '$1');
  }

  useEffect(() => {
    setDocsStructure(getDocsStructure());
  }, []);

  const handleSelect = (docPath) => {
    try {
      const mdContent = context(`./${docPath}`);
      setContent(mdContent.default || mdContent);
    } catch (error) {
      console.error('Error loading document:', error);
    }
    setSelectedDoc(docPath);
    setShowDropdown(false);
    setSearchQuery('');
  };

  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }
  
    const lowerCaseQuery = searchQuery.toLowerCase();
    const results = new Map();
  
    context.keys().forEach((filePath) => {
      const fileName = filePath.replace('./', '');
      const mdContent = context(filePath).default || context(filePath);
      let foundTitle = extractFileName(fileName);
  
      const lines = mdContent.split('\n');
      for (const line of lines) {
        if (line.startsWith('#')) {
          foundTitle = line.replace(/^#+\s*/, '');
        }
        
        if (line.toLowerCase().includes(lowerCaseQuery)) {
          results.set(fileName, { path: fileName, title: foundTitle });
          break;
        }
      }
    });
  
    const finalResults = Array.from(results.values());
  
    setSearchResults(finalResults);
    setShowDropdown(finalResults.length > 0);
  }, [searchQuery]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Page
      tag="div"
      className={`cr-page cart-page px-3 pt-2`}
      title=""
      breadcrumbs={[{ name: 'Documentation', active: true }]}
    >
      <Row>
        <Col>
          <div className="cart-card-wrapper mt-3">
            <Row className="w-100">
              <Col lg={3} md={3} sm={12} xs={12}>
                <div ref={searchRef} style={{ position: 'relative' }}>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control mb-2"
                    onFocus={() => setShowDropdown(searchResults.length > 0)}
                  />

                  {showDropdown && (
                    <ul
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                        listStyle: 'none',
                        padding: 0,
                        margin: 0,
                        zIndex: 1000,
                      }}
                    >
                      {searchResults.map((result, index) => (
                        <li
                          key={index}
                          style={{
                            padding: '8px',
                            cursor: 'pointer',
                            borderBottom: '1px solid #eee',
                            color: '#333',
                          }}
                          onClick={() => handleSelect(result.path)}
                        >
                          {result.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <DocumentationSidebar onSelect={handleSelect} docs={docsStructure} />
              </Col>
              <Col lg={9} md={9} sm={12} xs={12}>
                <main style={{ flex: 1, padding: '1rem', overflowY: 'auto' }}>
                  {selectedDoc ? (
                    <ReactMarkdown>{content}</ReactMarkdown>
                  ) : (
                    <p>Select a document to view</p>
                  )}
                </main>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Page>
  );
};

export default DocumentationView;
