// Enum definitions for Link properties

// Address Type Enum
export enum AddressType {
  None = 0,
  IPv4 = 1,
  IPv6 = 2,
  Loopback = 3
}

// Status Enum
export enum LinkStatus {
  Down = 0,
  Up = 1,
  Idle = 2
}

// Carrier Type Enum
export enum CarrierType {
  Unknown = 0,
  WAN = 1,
  Starlink = 2,
  OneWeb = 3
}

// Utility functions to convert enum values to display names

/**
 * Converts address_type numeric value to display name
 * @param value The numeric value of the address type
 * @returns The display name of the address type
 */
export const getAddressTypeName = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return 'None';
  
  switch (value) {
    case AddressType.None:
      return 'None';
    case AddressType.IPv4:
      return 'IPv4';
    case AddressType.IPv6:
      return 'IPv6';
    case AddressType.Loopback:
      return 'Loopback';
    default:
      return 'Unknown';
  }
};

/**
 * Converts status numeric value to display name
 * @param value The numeric value of the status
 * @returns The display name of the status
 */
export const getLinkStatusName = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return 'Down';
  
  switch (value) {
    case LinkStatus.Down:
      return 'Down';
    case LinkStatus.Up:
      return 'Up';
    case LinkStatus.Idle:
      return 'Idle';
    default:
      return 'Unknown';
  }
};

/**
 * Converts carrier_type numeric value to display name
 * @param value The numeric value of the carrier type
 * @returns The display name of the carrier type
 */
export const getCarrierTypeName = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return 'Unknown';
  
  switch (value) {
    case CarrierType.Unknown:
      return 'Unknown';
    case CarrierType.WAN:
      return 'WAN';
    case CarrierType.Starlink:
      return 'Starlink';
    case CarrierType.OneWeb:
      return 'OneWeb';
    default:
      return 'Unknown';
  }
};

/**
 * Determines if a link should show satellite controls (Reboot/Stow)
 * @param value The numeric value of the carrier type
 * @returns Boolean indicating if satellite controls should be shown
 */
export const shouldShowSatelliteControls = (value: number | null | undefined): boolean => {
  return value === CarrierType.Starlink || value === CarrierType.OneWeb;
}; 