import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import UserSession from '@app/common/user-session';
import License from '@app/api/license/license.actions';
import { DEFAULT_UUID } from '@app/lib/constants';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import { truncateString } from '@app/utils/utils';
import { sortBy, formatDate } from '@app/lib/functions';
import { PageSection, Title, Modal, ModalVariant } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { Column, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Updater, useReactTable } from '@tanstack/react-table';
import { MdAddCircle, MdArrowDownward, MdArrowUpward, MdCircle, MdOutlineCheck, MdOutlineClear, MdUnfoldMore } from 'react-icons/md';
import { LicenseUpdate } from './LicenseUpdate';
import Loader from '@app/components/Loader';

type ColumnFiltersState = ColumnFilter[];
interface ColumnFilter {
  id: string;
  value: unknown;
}

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const LicenseList: React.FunctionComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [licenses, setLicenses] = React.useState([]);
  const [licensesData, setLicensesData] = React.useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [deleteError, setDeleteError] = React.useState("");
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [expandedRows, setExpandedRows] = React.useState({});
  const [licenceForm, setLicenceForm] = React.useState(false);
  const [activeLicenceId, setActiveLicenceId] = React.useState(null);

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const statuses = [
    "#85ba3b",
    "#e93a54",
    "#000"
  ];

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * statuses.length);
    return statuses[randomNumber];
  }

  React.useEffect(() => {
    (async function() {
      try {
        const partnerId = UserSession.getPartnerId();
        const productList = await License.getProducts(partnerId);
        const licenseList = await License.getAll(partnerId);
        let licenseRows = [];
        let licenseDataRows = [];

        if (licenseList) {
          licenseRows = licenseList.map(item => {
            return licenseFromItem(licenseList, item, productList);
          });
          licenseDataRows = licenseList.map(item => {
            return licenseDataFromItem(licenseList, item, productList);
          });
        }

        setLicenses(licenseRows);
        setLicensesData(licenseDataRows);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const licenseDataFromItem = (licenses, item, products) => {
    const assignedTo = item.assigned_to !== DEFAULT_UUID ? <Link to={{ pathname: `${basePath}/site/` + item.assigned_to }}>{item.assigned_to}</Link> : '';
    const product = products.find(product => item.type === product.id);

    return {
        id: item.id,
        type: product.name,
        created: formatDate(item.created, true),
        activated: formatDate(item.activated, true),
        assignedTo: assignedTo,
        expires: formatDate(item.expires, true),
        product: product
    }
  }

  const columns = React.useMemo(() => [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'type',
      header: 'Type',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'created',
      header: 'Created',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'activated',
      header: 'Activated',
      enableColumnFilter: false,
      cell: ({ row }) => (
        <>
          {row.original.product.active ? (
            <MdOutlineCheck color="#85ba3b" />
          ) : (
            <MdOutlineClear color="#e93a54" />
          )}
        </>
      ),
    },
    {
      accessorKey: 'assignedTo',
      header: 'AssignedTo',
    },
    {
      accessorKey: 'expires',
      header: 'Expires',
      enableColumnFilter: false,
    },
    {
      header: 'Actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <Button className="ethica-button-green" size="sm me-2" onClick={() => { setActiveLicenceId(row.original.id); setLicenceForm(true); }}>Edit</Button>
        </>
      ),
    },
  ], []);

  const table = useReactTable({
    data: licensesData,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  const getColumns = () => {
    return [
      { title: 'ID' },
      { title: 'Type', transforms: [sortable] },
      { title: 'Created', transforms: [sortable] },
      { title: 'Activated' },
      { title: 'Assigned To', transforms: [sortable] },
      { title: 'Expires', transforms: [sortable, cellWidth(10)] },
    ];
  }

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  const handleErrorToggle = () => {
    setIsErrorOpen(!isErrorOpen);
  };

  const licenseFromItem = (licenses, item, products) => {
    const assignedTo = item.assigned_to !== DEFAULT_UUID ? <Link to={{ pathname: `${basePath}/site/` + item.assigned_to }}>{item.assigned_to}</Link> : '';
    const product = products.find(product => item.type === product.id);

    return {
      cells: [
        { title: <Link to={{ pathname: `${basePath}/license/` + item.id }}>{item.id}</Link> },
        { title: product.name },
        { title: formatDate(item.created, true) },
        { title: formatDate(item.activated, true) },
        { title: assignedTo },
        { title: formatDate(item.expires, true) }
      ]
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Licenses</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Page
            tag="div"
            className={`cr-page px-3 pt-2 licences-list h-100 ${theme}`}
            title=""
            breadcrumbs={[{ name: 'Licenses', active: true }]}
          >
          <div className={`disable-wrapper ${licenceForm ? 'active' : ''}`}></div>
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="licences-card-wrapper">
                  <Card>
                    <CardBody>
                      {licenses.length === 0 ?
                        <div>No Licenses found!</div>
                      :
                        <>
                          <table className="licences-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                              {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan}>
                                      {header.isPlaceholder ? null : (
                                        <div className={`d-flex align-items-center ${header.column.getCanFilter() ? 'justify-content-start' : 'justify-content-center'}`}>
                                          <div
                                            {...{
                                              className: header.column.getCanSort()
                                                ? 'select-none'
                                                : '',
                                              onClick: header.column.getToggleSortingHandler(),
                                            }}
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}

                                            {header.column.getCanSort() && (
                                              {
                                                asc: <MdArrowUpward className="ms-2" />,
                                                desc: <MdArrowDownward className="ms-2" />,
                                              }[header.column.getIsSorted() as string] ?? (
                                                <MdUnfoldMore className="ms-2 opacity-50" />
                                              )
                                            )}
                                          </div>
                                          {header.column.getCanFilter() ? (
                                            <div>
                                              <Filter column={header.column} />
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {table.getRowModel().rows.map(row => (
                                <React.Fragment key={row.id}>
                                    <tr>
                                    {row.getVisibleCells().map(cell => (
                                      <td key={cell.id} className={cell.column.getCanFilter() ? '' : 'text-center'}>
                                        {cell.column.columnDef.header === 'ID' ? (
                                          <>
                                            <button onClick={() => toggleRow(row.id)} className="table-arrow">
                                              {expandedRows[row.id] ? '▼' : '▶'}
                                            </button>
                                            <strong><a href={`/licence/${row.original.id}`} className="licence-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                          </>
                                        ) : (
                                          <span className="no-arrow d-flex flex-wrap-nowrap w-100 justify-content-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                                        )}
                                      </td>
                                    ))}
                                  </tr>

                                  {expandedRows[row.id] && (
                                    <>
                                      <tr>
                                        <td className="fw-bold">Name:</td>
                                        <td>{row.original.product.name} License</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">License ID:</td>
                                        <td>{row.original.id}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold">License Description:</td>
                                        <td>{row.original.product.description}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>

                          {/* Пагинация */}
                          <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                            <div className="me-2">
                              <span className="flex items-center gap-1">
                                Go to page:
                                <input
                                  type="number"
                                  min="1"
                                  max={table.getPageCount()}
                                  defaultValue={table.getState().pagination.pageIndex + 1}
                                  onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                  }}
                                  className="border p-1 rounded w-16"
                                />
                              </span>
                            </div>
                            <div>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.setPageIndex(0)}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  {'<<'}
                                </button>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.previousPage()}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  {'<'}
                                </button>
                                <span className="flex items-center me-1">
                                  <strong>
                                    {table.getState().pagination.pageIndex + 1} of{' '}
                                    {table.getPageCount()}
                                  </strong>
                                </span>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.nextPage()}
                                  disabled={!table.getCanNextPage()}
                                >
                                  {'>'}
                                </button>
                                <button
                                  className="border rounded p-1 me-2"
                                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                  disabled={!table.getCanNextPage()}
                                >
                                  {'>>'}
                                </button>
                              </div>
                              <div>
                                <select
                                  className="form-select"
                                  value={table.getState().pagination.pageSize}
                                  onChange={e => {
                                    table.setPageSize(Number(e.target.value))
                                  }}
                                >
                                  {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                      Show {pageSize}
                                    </option>
                                  ))}
                                </select>
                              </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <Link to={`${basePath}/license/new`}>
                              <Button className="ethica-button-green" size="sm"><MdAddCircle className="me-1 ms-auto"/>New Licence</Button>
                            </Link>
                          </div>
                        </>
                      }
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            {licenceForm && (
              <div className={`popup-edit ${licenceForm ? 'active' : ''}`}>
                <LicenseUpdate setLicenceForm={setLicenceForm} activeLicenceId={activeLicenceId}/>
              </div>
            )}
          </>

        <Modal
          title="Error"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isErrorOpen}
          onClose={handleErrorToggle}
          actions={[
            <Button key="cancel" variant="primary" onClick={handleErrorToggle}>OK</Button>
          ]}
        >
          {deleteError}
        </Modal>
      </Page>
      )}
    </React.Fragment>
  );
}

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();
  //@ts-ignore
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
