import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
    async getAll() {
        const state = store.getState();
        const expiration = state.common.auth.session?.expiration ?? 0;

        if (tokenIsValid(expiration)) {
            const path = '/support';
            const result = await ApiGateway.get(path);
            return result.data.cases;
        }
        else {
            cognitoUser.signOut();
        }
    },

    async addCase(data) {
        const state = store.getState();
        const expiration = state.common.auth.session?.expiration ?? 0;

        if (tokenIsValid(expiration)) {
            const result = await ApiGateway.post(`/support`, data);
            return result.data;
        } else {
            cognitoUser.signOut();
        }
    },
}
