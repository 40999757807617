import { DEFAULT_UUID } from '@app/lib/constants';
import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll(partnerId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${partnerId}/licenses`);
      return result.data.licenses;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getUnassigned(partnerId, siteId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const licenses = await this.getAll(partnerId);
      let unassigned = licenses.filter(lic => lic.assigned_to === DEFAULT_UUID || lic.assigned_to === siteId);
      return unassigned;
    }
    else {
      cognitoUser.signOut();
    }
  },

  // async getUnassigned(partnerId, siteId) {
  //   const licenses = await this.getAll(partnerId);
  //   let unassigned = licenses.filter(lic => lic.assigned_to === DEFAULT_UUID);

  //   if (siteId) {
  //     unassigned = licenses.filter(lic => lic.assigned_to === DEFAULT_UUID || lic.assigned_to === siteId);
  //   }

  //   return unassigned;
  // },

  async get(partnerId, licenseId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${partnerId}/licenses/${licenseId}`);
      return result.data.license;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async purchase(partnerId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/partners/${partnerId}/licenses`, payload);
      return result.data.license;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getProducts(partnerId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${partnerId}/products`);
      return result.data.products;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
