import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const path = '/carts';
      const result = await ApiGateway.get(path);
      return result.data.cart;
    }
    else {
      if (cognitoUser && typeof cognitoUser.signOut === 'function') {
        cognitoUser.signOut();
      } else {
        console.warn('Session expired: Auth provider not available for logout');
        return { items: [] };
      }
    }
  },  

  async addToCartAPI(product) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;
  
    if (tokenIsValid(expiration)) {
      try {
        const payload = {
          items: [
            {
              options: product.selectedOptions || [],
              product_id: product.id,
            },
          ],
        };
  
        const result = await ApiGateway.post(`/carts`, payload);
        return result.data;
      } catch (error) {
        console.error("Error adding to cart:", error);
        throw error;
      }
    } else {
      if (cognitoUser && typeof cognitoUser.signOut === 'function') {
        cognitoUser.signOut();
      } else {
        console.warn('Session expired: Auth provider not available for logout');
        return { items: [] };
      }
    }
  },
  
  async addToUserCartAPI(cartId, product) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;
  
    if (tokenIsValid(expiration)) {
      try {
        const payload = {
          items: [
            {
              options: product.selectedOptions || [],
              product_id: product.id,
            },
          ],
        };
  
        const result = await ApiGateway.post(`/carts/${cartId}/items`, payload);
        return result.data;
      } catch (error) {
        console.error("Error adding to cart:", error);
        throw error;
      }
    } else {
      if (cognitoUser && typeof cognitoUser.signOut === 'function') {
        cognitoUser.signOut();
      } else {
        console.warn('Session expired: Auth provider not available for logout');
        return { items: [] };
      }
    }
  },

  async removeCartAPI(cartId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;
  
    if (tokenIsValid(expiration)) {
      try {  
        const result = await ApiGateway.delete(`/carts/${cartId}`, {});
        return result.data;
      } catch (error) {
        console.error("Error removing cart:", error);
        throw error;
      }
    } else {
      if (cognitoUser && typeof cognitoUser.signOut === 'function') {
        cognitoUser.signOut();
      } else {
        console.warn('Session expired: Auth provider not available for logout');
        return { items: [] };
      }
    }
  },

  async removeItemFromCartAPI(cartId, cartItemId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;
  
    if (tokenIsValid(expiration)) {
      try {  
        const result = await ApiGateway.delete(`/carts/${cartId}/items/${cartItemId}`, {});
        return result.data;
      } catch (error) {
        console.error("Error removing item from cart:", error);
        throw error;
      }
    } else {
      if (cognitoUser && typeof cognitoUser.signOut === 'function') {
        cognitoUser.signOut();
      } else {
        console.warn('Session expired: Auth provider not available for logout');
        return { items: [] };
      }
    }
  },
  
}
