import * as React from 'react';
import { IconHeading } from '@app/components/IconHeading';
import { Spinner } from '@patternfly/react-core';
import WanIcon from '@app/bgimages/wans-black.svg';
import { Flex, FlexItem, Select, SelectVariant } from '@patternfly/react-core';
import { Card, CardBody, FormGroup, CardHeader, Input } from 'reactstrap';
import { MdWidthWide } from 'react-icons/md';

interface WanSettingsProps {
  dataLoading: boolean;
  handleEnabled: any;
  isWanOpen: boolean;
  onSelect: any;
  onToggle: any;
  wanCardClass: string;
  wanEnabled: boolean;
  wanName: any;
  wanOptions: any;
}

export const WanSettings: React.FC<WanSettingsProps> = ({
  dataLoading,
  handleEnabled,
  isWanOpen,
  onSelect,
  onToggle,
  wanCardClass,
  wanEnabled,
  wanName,
  wanOptions,
}) => {
  return (
    <Card isCompact className={`mb-3 ${wanCardClass}`}>
      <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
        <div className="w-100">
          <IconHeading
            icon={<MdWidthWide className="icon-medium"/>}
            heading="Private WAN"
            showEnable={true}
            handleEnable={handleEnabled}
            enabled={wanEnabled} />
        </div>
      </CardHeader>
      <CardBody>
        {dataLoading ? (
          <Spinner size="lg" />
        ) : (
          <>
            <FormGroup label="WAN Name" isRequired={wanEnabled} fieldId="wanName" className="form-item">
              <Input
                type="select"
                value={wanName || ''}
                onChange={(e) => onSelect(e, e.target.value)}
                onClick={(e) => onToggle(e, 'isWanOpen')}
                open={isWanOpen}
                disabled={!wanEnabled}
              >
                {wanOptions &&
                  <>
                    <option key="default" value="">Select...</option>
                    {wanOptions.map((wan, index) => (
                        <option key={wan.key || index} value={wan.props.value}>
                            {wan.props.value}
                        </option>
                    ))}
                  </>
                }
              </Input>
            </FormGroup>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default WanSettings;
