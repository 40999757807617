import * as React from 'react';
import { AppDispatch } from '@app/store';
import UserMgmtApi from '@app/api/user-api/user.actions';
import { textInputValidate, isValidEmail } from '@app/lib/validator';
import { formatDate } from '@app/lib/functions';
import { capitalizeFirstLetter } from '@app/utils/utils';
import { Saver } from '@app/components/Saver';
import { BullhornIcon, ExclamationCircleIcon } from '@patternfly/react-icons';
import { PageSection, Title, TextInput, FormGroup, Select, SelectOption, SelectVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import './Admin.css';
import { State } from '@app/store/root-reducer';
import { connect } from 'react-redux';
import { Card, CardBody, Button, CardHeader, Col } from 'reactstrap';
import { from } from '@apollo/client';
import { useState, useCallback, useEffect } from 'react';
import { Column, ColumnFiltersState, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Updater, useReactTable } from '@tanstack/react-table';
import { MdArrowDownward, MdArrowUpward, MdCircle } from 'react-icons/md';
import { useDispatch } from 'react-redux';

interface UsersProps {
  partnerId: string;
  partnerName: string;
}

const Users = ({ partnerId, partnerName }: UsersProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [updateRoles, setUpdateRoles] = useState([]);
  const [role, setRole] = useState('Viewer');
  const [updatedRole, setUpdatedRole] = useState('');
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [actionId, setActionId] = useState('');
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = useState(false);
  const [isUpdateRoleOpen, setIsUpdateRoleOpen] = useState(false);
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = useCallback(async () => {
    try {
      const userList = await UserMgmtApi.getAll();
      const rolesList = getRoleOptions('invite');
      const updateRolesList = getRoleOptions('update');

      setRoles(rolesList);
      setUpdateRoles(updateRolesList);
      setUsers(userList);
    } catch (error) {
      setMessage('There was an error getting the users');
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const columns = React.useMemo(() => [
    {
      accessorKey: 'first_name',
      header: 'Name',
      cell: ({ row }) => (
        <>
          {row.original.first_name + ' ' + row.original.last_name}
        </>
      ),
    },
    {
      accessorKey: 'account_status',
      header: 'Status',
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      accessorKey: 'role',
      header: 'Role',
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      accessorKey: 'last_login',
      header: 'Last login',
      enableColumnFilter: false,
      enableSorting: true,
      cell: ({ row }) => (
        <>
          {row.original.status?.toLowerCase() === 'pending' ? (
            'None'
          ) : (
            row.original.last_login
          )}
        </>
      ),
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      header: 'Actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <Button className="ethica-button-green" size="sm me-2">Update Role</Button>
          <Button className="ethica-button-green" size="sm">Change Status</Button>
        </>
      ),
    },
  ], []);

  const table = useReactTable({
    data: users,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  const userFromItem = (item) => {
    const status = item.account_status;
    return {
      cells: [
        { title: item.first_name + ' ' + item.last_name },
        { title: status },
        { title: capitalizeFirstLetter(item.role) },
        { title: status?.toLowerCase() === 'pending' ? 'None' : formatDate(item.last_login, true) },
        { title: item.phone },
        { title: item.email },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' } }
      ],
    };
  };

  const getColumns = () => [
    { title: 'Name', transforms: [sortable] },
    { title: 'Status' },
    { title: 'Role' },
    { title: 'Last Login' },
    { title: 'Phone' },
    { title: 'Email', transforms: [sortable] },
    { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' } },
    { title: 'Actions', transforms: [cellWidth(10)] },
  ];

  const onRoleToggle = (isOpen) => setIsRoleOpen(isOpen);

  const onRoleSelect = (event, selection, isPlaceholder) => {
    if (!isPlaceholder) {
      setRole(selection);
      setIsRoleOpen(false);
    }
  };

  const onUpdateRoleToggle = (isOpen) => setIsUpdateRoleOpen(isOpen);

  const onUpdateRoleSelect = (event, selection, isPlaceholder) => {
    if (!isPlaceholder) {
      setUpdatedRole(capitalizeFirstLetter(selection));
      setIsUpdateRoleOpen(false);
    }
  };

  const showUpdateRoleModal = (event, rowId, rowData) => {
    const userId = rowData['id']['title'];
    const userRole = rowData['role']['title'];
    const userEmail = rowData['email']['title'];

    setIsUpdateRoleModalOpen(true);
    setUpdatedRole(capitalizeFirstLetter(userRole));
    setUserEmail(userEmail);
    setActionId(userId);
  };

  const showUpdateStatusModal = (event, rowId, rowData) => {
    const userId = rowData['id']['title'];
    const userStatus = rowData['status']['title'];
    const userEmail = rowData['email']['title'];

    setIsUpdateStatusModalOpen(true);
    setUserEmail(userEmail);
    setUpdatedStatus(userStatus);
    setActionId(userId);
  };

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    return columns.findIndex(col => col.title === columnName);
  };

  const updateRole = async () => {
    const payload = { change: { role: updatedRole.toLowerCase() } };

    try {
      await UserMgmtApi.update(actionId, payload);
      const idIndex = getColumnIndex('ID');
      const roleIndex = getColumnIndex('Role');
      const updatedUsers = users.map(user => {
        if (user.cells[idIndex].title === actionId) {
          user.cells[roleIndex] = { title: capitalizeFirstLetter(updatedRole) };
          return user;
        }
        return user;
      });

      setIsUpdateRoleModalOpen(false);
      setUsers(updatedUsers);
    } catch (error) {
      setMessage('There was an error updating the user role');
    }
  };

  const updateStatus = async () => {
    try {
      await UserMgmtApi.updateAccountStatus(actionId);
      const idIndex = getColumnIndex('ID');
      const statusIndex = getColumnIndex('Status');
      const action = getStatusAction();
      const updatedUsers = users.map(user => {
        if (user.cells[idIndex].title === actionId) {
          user.cells[statusIndex] = { title: action === 'Enable' ? 'Active' : 'Disabled' };
          return user;
        }
        return user;
      });

      setIsUpdateStatusModalOpen(false);
      setUsers(updatedUsers);
    } catch (error) {
      setIsUpdateStatusModalOpen(false);
      setMessage('There was an error updating the user account status');
    }
  };

  const handleInviteUser = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: role,
    };

    try {
      const result = await UserMgmtApi.inviteUser(payload);

      if (result?.data?.request_id) {
        const user = result.data.user;
        const fullName = user.first_name + ' ' + user.last_name;

        setMessage(fullName + ' was invited successfully!');
        setFirstName('');
        setLastName('');
        setEmail('');
        setRole('Viewer');
        setUsers([
          ...users,
          userFromItem({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            account_status: user.account_status,
            role: user.role,
            last_login: 'N/A',
            phone: user.phone,
            email: user.email,
          })
        ]);
        setIsLoading(false);
      } else {
        setMessage(result.data.error);
        setIsLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("An unknown error occurred");
      }
      setIsLoading(false);
    }
  };

  const getRoleOptions = (uniqueVal) => {
    const allRoles = ['Editor', 'Manager', 'Viewer'];
    return allRoles.map((name, index) => (
      <SelectOption key={`role-${uniqueVal}-${index}`} value={name} />
    ));
  };

  const getStatusAction = () => {
    return updatedStatus === 'Active' ? 'Disable' : 'Enable';
  };

  const emailIsValid = email ? isValidEmail(email) : false;
  const emailErrorState = email ? textInputValidate(email, true, emailIsValid, 'Invalid email format') : { validated: 'default', errorText: '' };
  const statusAction = getStatusAction();
  const formValid = emailIsValid;

  return (
    <>
      {/* Invite User Form */}
      <Col lg={2} md={2} sm={12} xs={12}>
        <Card className="h-100">
          <CardHeader>
            <div className="w-100 d-flex justify-content-between">
              <div className="icon-heading-container">
                <div className="icon-heading">
                  Invite User
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleInviteUser}>
              <FormGroup label="First Name" fieldId="first-name" isRequired className="form-item">
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  type="text"
                  id="first-name"
                  isRequired
                  isDisabled={isLoading}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup label="Last Name" fieldId="last-name" isRequired className="form-item">
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  type="text"
                  id="last-name"
                  isRequired
                  isDisabled={isLoading}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup label="Email" fieldId="email" isRequired className="form-item">
                <TextInput
                  value={email}
                  onChange={setEmail}
                  type="email"
                  id="email"
                  isRequired
                  isDisabled={isLoading}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup label="Role" fieldId="role" isRequired className="form-item">
                <Select
                  toggleId="role"
                  onToggle={onRoleToggle}
                  isOpen={isRoleOpen}
                  onSelect={onRoleSelect}
                  selections={role}
                  isDisabled={isLoading}
                  variant={SelectVariant.single}
                  aria-label="Select Role"
                  className="form-control"
                >
                  {roles}
                </Select>
              </FormGroup>
              <Button
                isDisabled={!formValid || isLoading}
                variant="primary"
                type="submit"
                className="mt-2 m-auto ethica-button-green"
              >
                Invite
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>

      {/* Users Table */}
      <Col lg={10} md={10} sm={12} xs={12}>
        <Card className="h-100">
          <CardBody className="font-12 profile-users">
            <table className="sites-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div className={`d-flex  align-items-center ${header.column.getCanFilter() ? 'justify-content-start' : 'justify-content-center'}`}>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'select-none sites-header'
                                  : 'sites-header',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <MdArrowUpward />,
                                desc: <MdArrowDownward />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div className="d-flex justify-content-end">
                                <Filter column={header.column} />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <React.Fragment key={row.id}>
                      <tr>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className={`${cell.column.getCanFilter() ? '' : 'text-center'} ${cell.column.columnDef.header === 'Actions' ? 'd-flex' : ''}`}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            {/* Пагинация */}
            <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
              <div className="me-2">
                <span className="flex items-center gap-1">
                  Go to page:
                  <input
                    type="number"
                    min="1"
                    max={table.getPageCount()}
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      table.setPageIndex(page)
                    }}
                    className="border p-1 rounded w-16"
                  />
                </span>
              </div>
              <div>
                  <button
                    className="border rounded p-1 me-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className="border rounded p-1 me-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <span className="flex items-center me-1">
                    <strong>
                      {table.getState().pagination.pageIndex + 1} of{' '}
                      {table.getPageCount()}
                    </strong>
                  </span>
                  <button
                    className="border rounded p-1 me-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className="border rounded p-1 me-2"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>
                <div>
                  <select
                    className="form-select"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* Update Role Modal */}
      <Modal
        variant={ModalVariant.small}
        title="Update User Role"
        isOpen={isUpdateRoleModalOpen}
        onClose={() => setIsUpdateRoleModalOpen(false)}
        actions={[
          <Button key="cancel" variant="link" onClick={() => setIsUpdateRoleModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            variant="primary"
            onClick={updateRole}
            isDisabled={isLoading}
          >
            Save
          </Button>
        ]}
      >
        <FormGroup label="Role" fieldId="role" isRequired>
          <Select
            toggleId="role"
            onToggle={onUpdateRoleToggle}
            isOpen={isUpdateRoleOpen}
            onSelect={onUpdateRoleSelect}
            selections={updatedRole}
            isDisabled={isLoading}
            variant={SelectVariant.single}
            aria-label="Select Role"
          >
            {updateRoles}
          </Select>
        </FormGroup>
      </Modal>

      {/* Update Status Modal */}
      <Modal
        variant={ModalVariant.small}
        title={`Are you sure you want to ${statusAction} the user?`}
        isOpen={isUpdateStatusModalOpen}
        onClose={() => setIsUpdateStatusModalOpen(false)}
        actions={[
          <Button key="cancel" variant="link" onClick={() => setIsUpdateStatusModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            variant="primary"
            onClick={updateStatus}
            isDisabled={isLoading}
          >
            Save
          </Button>
        ]}
      >
        <div>{`User Email: ${userEmail}`}</div>
      </Modal>
    </>
  );
};

export default Users;

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const filterVariant = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
