import * as React from 'react';
import { Field } from 'formik';
import { FileUpload as PFFileUpload, FileUploadProps as PFFileUploadProps, FormGroup } from '@patternfly/react-core';
import { Dispatch } from 'redux';
import { actionCreators } from '@app/common/session/session.reducer';

type FileUploadProps = PFFileUploadProps & {
  acceptFileTypes: string;
  maxFileSize: number;
  helperTextInvalid: string;
  file: string;
  filename: string;
  dispatch: Dispatch<any>;
};

export const FileUpload: React.FC<FileUploadProps> = (props) => {
  const [preview, setPreview] = React.useState<string>('');
  const handleFileChange = React.useCallback(
    (value, _, event) => {
      if (!value) {
        setPreview('');
        return;
      }

      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();

        reader.onload = (event) => {
          setPreview(event.target?.result as string);
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    },
    [setPreview]
  );
  const handleFileRejected = React.useCallback(() => {
    setPreview('');
    props.dispatch(actionCreators.notificationEnqueue({
      message: 'File Rejected',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
      },
    }))
  }, [setPreview]);

  return (
    <Field name={props.name}>
      {(fieldProps) => {
        return (
          <FormGroup
            fieldId={props.id}
            helperTextInvalid={props.helperTextInvalid}
            className="form-item"
          >
            <PFFileUpload
              id={`${props.id}-upload`}
              filename={fieldProps.form.values[props.filename]}
              className="file-input form-control"
              onChange={(value, filename, event) => {
                fieldProps.form.setFieldValue(props.file, value);
                fieldProps.form.setFieldValue(props.filename, filename);
                handleFileChange(value, filename, event);
              }}
              dropzoneProps={{
                accept: props.acceptFileTypes,
                maxSize: props.maxFileSize,
                onDropRejected: handleFileRejected,
              }}
            />
            {preview !== '' && <img id={`${props.id}-preview`} src={preview} width="100px" height="42px" />}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export default FileUpload;
