import * as React from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { DashboardView } from '@app/pages/dashboard/DashboardView';
import { AdminView } from '@app/pages/admin/AdminView';
import { CompanyForm } from '@app/pages/client-company/CompanyForm';
import { ControllerList } from '@app/pages/controller/controller.list';
import { ControllerView } from '@app/pages/controller/controller.view';
import { ControllerForm } from '@app/pages/controller/ControllerForm';
import { WanList } from '@app/pages/wan/wan.list';
import { WanView } from '@app/pages/wan/wan.view';
import { WanForm } from '@app/pages/wan/WanForm';
import { SiteList } from '@app/pages/site/site.list';
import { SiteView } from '@app/pages/site/site.view';
import { SiteForm } from '@app/pages/site/SiteForm';
import { SitePerformance } from '@app/pages/site/SitePerformance';
import { LinkDetailsPage } from '@app/pages/link/link-details.page';
import { ACLProfileList } from '@app/pages/aclprofile/ACLProfileList';
import { ACLProfileForm } from '@app/pages/aclprofile/ACLProfileForm';
import { ACLProfileView } from '@app/pages/aclprofile/ACLProfileView';
import { LicenseList } from '@app/pages/license/LicenseList';
import { LicenseUpdate } from '@app/pages/license/LicenseUpdate';
import { LicensePurchase } from '@app/pages/license/LicensePurchase';
import { LicenseView } from '@app/pages/license/LicenseView';
import { PartnerList } from '@app/pages/partner/PartnerList';
import { PartnerForm } from '@app/pages/partner/PartnerForm';
import { CourseList } from '@app/pages/course/CourseList';
import { CourseView } from '@app/pages/course/CourseView';
import { ProductList } from '@app/pages/product/ProductList';
import { ProductView } from '@app/pages/product/ProductView';
import { LessonView } from '@app/pages/lesson/LessonView';
import { NotFound } from '@app/pages/not-found/not-found';
import { LastLocationProvider } from '@app/providers/LastLocationProvider';
import { sideBarLinkStyle } from '@app/common/sidebar-styles';
import CartPage from './pages/cart/CartPage';
import DocumentationView from './pages/documentation/DocumentationView';
import SupportView from './pages/support/SupportView';
import Profile from './pages/profile/profile.view';

export interface IAppRoute {
  label?: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact?: boolean;
  path: string;
  title: string;
  isAsync?: boolean;
  routes?: undefined;
  hasChild?: IAppRoute[];
  sidebar?: boolean;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;
export type AppPartnerItems = IAppRoute | IAppRouteGroup;

const basePath = process.env.REACT_APP_BASE_PATH || '';

const routes: AppRouteConfig[] = [
  {
    component: DashboardView,
    exact: true,
    label: 'Dashboard',
    path: `${basePath}/dashboard`,
    title: 'Dashboard',
    sidebar: true
  },
  {
    component: PartnerList,
    exact: true,
    label: 'Partners',
    path: `${basePath}/partners`,
    title: 'Partners',
    sidebar: true
  },
  {
    component: ControllerList,
    exact: true,
    label: 'Controllers',
    path: `${basePath}/controllers`,
    title: 'Controllers',
    sidebar: true
  },
  {
    component: ControllerForm,
    exact: true,
    path: `${basePath}/controller/new`,
    title: 'New Controller',
    sidebar: false
  },
  {
    component: ControllerForm,
    exact: true,
    path: `${basePath}/controller/edit/:id`,
    title: 'Edit Controller',
    sidebar: false
  },
  {
    component: ControllerView,
    exact: true,
    path: `${basePath}/controller/:id`,
    title: 'View Controller',
    sidebar: false
  },
  {
    component: SiteList,
    exact: true,
    label: 'Sites',
    path: `${basePath}/sites`,
    title: 'Sites',
    sidebar: true
  },
  {
    component: SiteForm,
    exact: true,
    path: `${basePath}/site/new`,
    title: 'New Site',
    sidebar: false
  },
  {
    component: SiteView,
    exact: true,
    path: `${basePath}/site/:id`,
    title: 'View Site',
    sidebar: false
  },
  {
    component: SiteForm,
    exact: true,
    path: `${basePath}/site/edit/:id`,
    title: 'Edit Site',
    sidebar: false
  },
  {
    component: SitePerformance,
    exact: true,
    path: `${basePath}/site/performance/:id`,
    title: 'Site Performance',
    sidebar: false
  },
  {
    component: LinkDetailsPage,
    exact: true,
    path: `${basePath}/link/:id`,
    title: 'Link Details',
    sidebar: false
  },
  {
    component: WanList,
    exact: true,
    label: 'WANs',
    path: `${basePath}/wans`,
    title: 'WANs',
    sidebar: true
  },
  {
    component: WanForm,
    exact: true,
    path: `${basePath}/wan/new`,
    title: 'New WAN',
    sidebar: false
  },
  {
    component: WanForm,
    exact: true,
    path: `${basePath}/wan/edit/:id`,
    title: 'Edit WAN',
    sidebar: false
  },
  {
    component: WanView,
    exact: true,
    path: `${basePath}/wan/:id`,
    title: 'View WAN',
    sidebar: false
  },
  {
    component: ACLProfileList,
    exact: true,
    label: 'ACL Profiles',
    path: `${basePath}/aclprofiles`,
    title: 'ACL Profiles',
    sidebar: true
  },
  {
    component: ACLProfileForm,
    exact: true,
    path: `${basePath}/aclprofile/new`,
    title: 'New ACL Profile',
    sidebar: false
  },
  {
    component: ACLProfileForm,
    exact: true,
    path: `${basePath}/aclprofile/edit/:id`,
    title: 'Edit ACL Profile',
    sidebar: false
  },
  {
    component: ACLProfileView,
    exact: true,
    path: `${basePath}/aclprofile/:id`,
    title: 'View ACL Profile',
    sidebar: false
  },
  {
    component: LicenseList,
    exact: true,
    label: 'Licenses',
    path: `${basePath}/licenses`,
    title: 'Licenses',
    sidebar: true
  },
  {
    component: LicensePurchase,
    exact: true,
    path: `${basePath}/license/new`,
    title: 'Purchase License',
    sidebar: false
  },
  {
    component: LicenseUpdate,
    exact: true,
    path: `${basePath}/license/edit/:id`,
    title: 'Edit License',
    sidebar: false
  },
  {
    component: LicenseView,
    exact: true,
    path: `${basePath}/license/:id`,
    title: 'View License',
    sidebar: false
  },
  {
    component: AdminView,
    exact: true,
    label: 'Admin',
    path: `${basePath}/admin`,
    title: 'Admin',
    sidebar: true
  },
  {
    component: CourseList,
    exact: true,
    label: 'Training',
    path: '/courses',
    title: 'Training',
    sidebar: true
  },
  {
    component: CourseView,
    exact: true,
    path: `/courses/:id`,
    title: 'View Course',
    sidebar: false
  },
  {
    component: LessonView,
    exact: true,
    path: `/courses/:id/lessons/:id`,
    title: 'View Lesson',
    sidebar: false
  },
  {
    component: ProductList,
    exact: true,
    label: 'Store',
    path: '/products',
    title: 'Store',
    sidebar: true
  },
  {
    component: ProductView,
    exact: true,
    path: `/products/:id`,
    title: 'View Product',
    sidebar: false
  },
  {
    component: DocumentationView,
    exact: true,
    label: 'Documentation',
    path: '/docs/intro',
    title: 'Documentation',
    sidebar: true
  },
  {
    component: CartPage,
    exact: true,
    label: 'Cart',
    path: '/cart',
    title: 'Cart',
    sidebar: false
  },
  {
    component: SupportView,
    exact: true,
    label: 'Support',
    path: '/support',
    title: 'Support',
    sidebar: true
  },
  {
    component: Profile,
    exact: true,
    label: 'Profile',
    path: '/profile',
    title: 'Profile',
    sidebar: false
  },
];

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  return <Route component={NotFound} />;
};

const flattenedRoutes: IAppRoute[] = routes.reduce<IAppRoute[]>(
  (flattened, route) => [
    ...flattened,
    ...(Array.isArray(route.routes) ? (route.routes as IAppRoute[]) : [route as IAppRoute]),
  ],
  []
);


const AppRoutes = (): React.ReactElement => (
    <LastLocationProvider>
      <Switch>
        {flattenedRoutes.map(({ path, exact, component, title, isAsync }, idx) => (
          <RouteWithTitleUpdates
            path={path}
            exact={exact}
            component={component}
            key={idx}
            title={title}
          />
        ))}
        <PageNotFound title="404 Page Not Found" />
      </Switch>
    </LastLocationProvider>
);

export { AppRoutes, routes };
