import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_LINK_DETAILS, REBOOT_LINK, STOW_LINK, UPDATE_LINK_NOTES } from '@app/api/link/gql.queries';

interface UseLinkDetailsProps {
  linkId: string;
}

export const useLinkDetails = ({ linkId }: UseLinkDetailsProps) => {
  const [link, setLink] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  // GraphQL queries and mutations
  const [fetchLinkDetails, { loading: queryLoading, error: queryError, data }] = useLazyQuery(GET_LINK_DETAILS, {
    fetchPolicy: 'network-only',
  });

  const [rebootLink, { loading: rebootLoading }] = useMutation(REBOOT_LINK);
  const [stowLink, { loading: stowLoading }] = useMutation(STOW_LINK);
  const [updateNotes, { loading: updateNotesLoading }] = useMutation(UPDATE_LINK_NOTES);

  // Fetch link details
  useEffect(() => {
    if (linkId) {
      fetchLinkDetails({ variables: { id: linkId } });
    }
  }, [linkId, fetchLinkDetails]);

  // Update state when data changes
  useEffect(() => {
    if (data && data.Link && data.Link.length > 0) {
      console.log('Link data from GraphQL:', data.Link[0]);
      setLink(data.Link[0]);
      setLoading(false);
      setError(null);
    } else if (data && (!data.Link || data.Link.length === 0)) {
      console.log('No link data found in GraphQL response');
      setLink(null);
      setLoading(false);
      setError(new Error('Link not found'));
    }
  }, [data]);

  // Update error state
  useEffect(() => {
    if (queryError) {
      setError(queryError);
      setLoading(false);
    }
  }, [queryError]);

  // Handle reboot
  const handleReboot = async () => {
    try {
      const result = await rebootLink({ variables: { id: linkId } });
      return result.data?.rebootLink;
    } catch (err: any) {
      setError(err);
      return { success: false, message: err.message || 'An error occurred during reboot' };
    }
  };

  // Handle stow
  const handleStow = async () => {
    try {
      const result = await stowLink({ variables: { id: linkId } });
      return result.data?.stowLink;
    } catch (err: any) {
      setError(err);
      return { success: false, message: err.message || 'An error occurred during stow' };
    }
  };

  // Handle notes update
  const handleUpdateNotes = async (notes: string) => {
    try {
      const result = await updateNotes({ variables: { id: linkId, notes } });
      if (result.data?.update_Link_by_pk) {
        setLink({ ...link, notes: result.data.update_Link_by_pk.notes });
      }
      return result.data?.update_Link_by_pk;
    } catch (err: any) {
      setError(err);
      return null;
    }
  };

  // Refresh link details
  const refreshLinkDetails = () => {
    setLoading(true);
    fetchLinkDetails({ variables: { id: linkId } });
  };

  return {
    link,
    loading: loading || queryLoading,
    error,
    rebootLoading,
    stowLoading,
    updateNotesLoading,
    handleReboot,
    handleStow,
    handleUpdateNotes,
    refreshLinkDetails,
  };
};

export default useLinkDetails; 
