import React, { useContext, useState } from 'react';
import { routes } from '@app/routes';
import { NavLink, useHistory } from 'react-router-dom';
import UserSession from '@app/common/user-session';
import AdminIcon from '@app/bgimages/admin.svg';
import DashboardIcon from '@app/bgimages/dashboard.svg';
import HelpIcon from '@app/bgimages/help.svg';
import SupportIcon from '@app/bgimages/support.svg';
import LicenseIcon from '@app/bgimages/license-key.svg';
import ControllerIcon from '@app/bgimages/controllers.svg';
import SiteIcon from '@app/bgimages/sites.svg';
import WanIcon from '@app/bgimages/wans.svg';
import ACLProfileIcon from '@app/bgimages/service-catalog.svg';
import PartnerIcon from '@app/bgimages/users.svg';
import ExitPartner from '@app/bgimages/exit-partner.svg';
import SourceLink from '@app/components/SourceLink';

import sidebarBgImage from '@app/assets/images/sidebar/sidebar-4.jpg';
import { renderToStaticMarkup } from 'react-dom/server';
import ThemeContext from '@app/providers/contexts/ThemeContext';

import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdAdd,
  MdEdit,
  MdAddBox,
  MdOutlinePeople,
  MdControlCamera,
  MdViewInAr,
  MdWebStories,
  MdAppRegistration,
  MdAdminPanelSettings,
  MdModelTraining,
  MdBrandingWatermark,
  MdDocumentScanner,
  MdContactSupport,
  MdExitToApp,
} from 'react-icons/md';

import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import SidebarContext from '@app/providers/contexts/SidebarContext';
import { Logo } from './logo.component';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const helpLink = process.env.REACT_APP_HELP_LINK;

interface AppRouteConfig {
  path: string;
  sidebar?: boolean;
  title: string;
  hasChild?: boolean;
  exact?: boolean;
}

interface IAppRouteGroup {
  routes: AppRouteConfig[];
}

const isAppRouteConfig = (route: any): route is AppRouteConfig => {
  return 'path' in route && 'title' in route;
};

const bem = bn.create('sidebar');

export const Sidebar: React.FC = () => {
  const [state, setState] = useState({
    isOpenPartners: false,
    isOpenControllers: false,
    isOpenSites: false,
    isOpenWANs: false,
    isOpenACLProfiles: false,
    isOpenLicenses: false,
  });

  //@ts-ignore
  const { sidebarStatus } = useContext(SidebarContext);
  const isImpersonating = UserSession.getParam('isImpersonating');

  const navIcons = {
    dashboard: <MdDashboard size="24" className="cr-sidebar__nav-item-icon" />,
    partners: <MdOutlinePeople size="24" className="cr-sidebar__nav-item-icon" />,
    licenses: <MdAppRegistration size="24" className="cr-sidebar__nav-item-icon" />,
    admin: <MdAdminPanelSettings size="24" className="cr-sidebar__nav-item-icon" />,
    help: <MdOutlinePeople size="24" className="cr-sidebar__nav-item-icon" />,
    support: <MdContactSupport size="24" className="cr-sidebar__nav-item-icon" />,
    controllers: <MdControlCamera size="24" className="cr-sidebar__nav-item-icon" />,
    sites: <MdWeb size="24" className="cr-sidebar__nav-item-icon" />,
    wans: <MdViewInAr size="24" className="cr-sidebar__nav-item-icon" />,
    ['acl profiles']: <MdWebStories size="24" className="cr-sidebar__nav-item-icon" />,
    exitPartner: <MdOutlinePeople size="24" className="cr-sidebar__nav-item-icon" />,
    training: <MdModelTraining size="24" className="cr-sidebar__nav-item-icon" />,
    store: <MdBrandingWatermark size="24" className="cr-sidebar__nav-item-icon" />,
    documentation: <MdDocumentScanner size="24" className="cr-sidebar__nav-item-icon" />,
  };

  const handleClick = (name: string) => () => {
    setState((prevState) => ({
      ...prevState,
      [`isOpen${name}`]: !prevState[`isOpen${name}`],
    }));
  };

  const { theme } = useContext(ThemeContext);

  const exitPartner = () => {
    UserSession.setParam('isImpersonating', false);
    window.location.href = '/partners';
  };

  return (
    <aside
      id="aside_sidebar"
      data-testid="aside_sidebar"
      className={bem.b('') + ' ' + bem.m(sidebarStatus) + ' ' + theme}
      data-image={sidebarBgImage}
    >
      {/* <div className={bem.e('background')} style={sidebarBackground} /> */}
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className={`navbar-brand ${sidebarStatus} d-flex m-auto`}>
            <Logo />
          </SourceLink>
        </Navbar>

        <Nav vertical className="flex-column">
          {routes.map((route, index) =>
            isAppRouteConfig(route) && route.sidebar ? (
              <NavItem key={route.path} className={bem.e('nav-item')} onClick={handleClick(route.title)}>
                <BSNavLink
                  id={`navItem-${route.title.replace(/\s/g, '').toLowerCase()}-${index}`}
                  tag={NavLink}
                  to={route.path}
                  exact={route.exact}
                  className={`text-uppercase ${route.hasChild ? 'cr-sidebar__nav-item-collapse' : ''}`}
                >
                  <div className="d-flex">
                    {navIcons[route.title.toLowerCase()]}
                    <span>{route.title}</span>
                  </div>
                  {route.hasChild && (
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        transform: state[route.title] ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  )}
                </BSNavLink>
              </NavItem>
            ) : null
          )}
          {isImpersonating ? (
            <NavItem key="exit" id="exit">
              <BSNavLink
                id={`navItem-exit-1`}
                tag={NavLink}
                to="#"
                exact={false}
                className={`text-uppercase`}
              >
                <div className="d-flex" onClick={() => exitPartner()}>
                  {navIcons['exitPartner']}
                  <span>Exit Partner</span>
                </div>
              </BSNavLink>
            </NavItem>
          ) : (
            <></>
          )}
        </Nav>
      </div>
    </aside>
  );
};
