import { defaultCacheIdFromObject, generateOptimisticResponseForMutation, generateUpdateFunctionForMutation, convertToGraph, ObjectWithId, FieldMap, getLogLevel, ensureTypenameOnFragment, ensureTypenameOnFragments, stripInsertInputClientFields } from 'graphql-codegen-hasura-core'
import { ApolloClient, ApolloCache, ApolloQueryResult, DataProxy, FetchResult, MutationOptions, Observable, ObservableQuery, QueryOptions, Reference, SubscriptionOptions } from '@apollo/client'
import { CarrierFragment } from '../../api/hasura';
import { QueryCarrierByIdQuery } from '../../api/hasura';
import { QueryCarrierByIdDocument } from '../../api/hasura';
import { QueryCarrierByIdQueryVariables } from '../../api/hasura';
import { QueryCarrierObjectsQuery } from '../../api/hasura';
import { QueryCarrierObjectsDocument } from '../../api/hasura';
import { QueryCarrierObjectsQueryVariables } from '../../api/hasura';
import { Carrier_Insert_Input } from '../../api/hasura';
import { Carrier_On_Conflict } from '../../api/hasura';
import { InsertCarrierMutation } from '../../api/hasura';
import { InsertCarrierWithOnConflictMutation } from '../../api/hasura';
import { InsertCarrierMutationVariables } from '../../api/hasura';
import { InsertCarrierWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertCarrierDocument } from '../../api/hasura';
import { InsertCarrierWithOnConflictDocument } from '../../api/hasura';
import { Carrier_Set_Input } from '../../api/hasura';
import { UpdateCarrierByIdMutation } from '../../api/hasura';
import { UpdateCarrierByIdMutationVariables } from '../../api/hasura';
import { UpdateCarrierByIdDocument } from '../../api/hasura';
import { UpdateCarrierMutation } from '../../api/hasura';
import { UpdateCarrierMutationVariables } from '../../api/hasura';
import { UpdateCarrierDocument } from '../../api/hasura';
import { RemoveCarrierModelMutation } from '../../api/hasura';
import { RemoveCarrierModelMutationVariables } from '../../api/hasura';
import { RemoveCarrierModelDocument } from '../../api/hasura';
import { RemoveCarrierModelByIdMutation } from '../../api/hasura';
import { RemoveCarrierModelByIdMutationVariables } from '../../api/hasura';
import { RemoveCarrierModelByIdDocument } from '../../api/hasura';
import { ClientCompanyFragment } from '../../api/hasura';
import { QueryClientCompanyByIdQuery } from '../../api/hasura';
import { QueryClientCompanyByIdDocument } from '../../api/hasura';
import { QueryClientCompanyByIdQueryVariables } from '../../api/hasura';
import { QueryClientCompanyObjectsQuery } from '../../api/hasura';
import { QueryClientCompanyObjectsDocument } from '../../api/hasura';
import { QueryClientCompanyObjectsQueryVariables } from '../../api/hasura';
import { ClientCompany_Insert_Input } from '../../api/hasura';
import { ClientCompany_On_Conflict } from '../../api/hasura';
import { InsertClientCompanyMutation } from '../../api/hasura';
import { InsertClientCompanyWithOnConflictMutation } from '../../api/hasura';
import { InsertClientCompanyMutationVariables } from '../../api/hasura';
import { InsertClientCompanyWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertClientCompanyDocument } from '../../api/hasura';
import { InsertClientCompanyWithOnConflictDocument } from '../../api/hasura';
import { ClientCompany_Set_Input } from '../../api/hasura';
import { UpdateClientCompanyByIdMutation } from '../../api/hasura';
import { UpdateClientCompanyByIdMutationVariables } from '../../api/hasura';
import { UpdateClientCompanyByIdDocument } from '../../api/hasura';
import { UpdateClientCompanyMutation } from '../../api/hasura';
import { UpdateClientCompanyMutationVariables } from '../../api/hasura';
import { UpdateClientCompanyDocument } from '../../api/hasura';
import { RemoveClientCompanyModelMutation } from '../../api/hasura';
import { RemoveClientCompanyModelMutationVariables } from '../../api/hasura';
import { RemoveClientCompanyModelDocument } from '../../api/hasura';
import { RemoveClientCompanyModelByIdMutation } from '../../api/hasura';
import { RemoveClientCompanyModelByIdMutationVariables } from '../../api/hasura';
import { RemoveClientCompanyModelByIdDocument } from '../../api/hasura';
import { GatewayFragment } from '../../api/hasura';
import { QueryGatewayByIdQuery } from '../../api/hasura';
import { QueryGatewayByIdDocument } from '../../api/hasura';
import { QueryGatewayByIdQueryVariables } from '../../api/hasura';
import { QueryGatewayObjectsQuery } from '../../api/hasura';
import { QueryGatewayObjectsDocument } from '../../api/hasura';
import { QueryGatewayObjectsQueryVariables } from '../../api/hasura';
import { Gateway_Insert_Input } from '../../api/hasura';
import { Gateway_On_Conflict } from '../../api/hasura';
import { InsertGatewayMutation } from '../../api/hasura';
import { InsertGatewayWithOnConflictMutation } from '../../api/hasura';
import { InsertGatewayMutationVariables } from '../../api/hasura';
import { InsertGatewayWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertGatewayDocument } from '../../api/hasura';
import { InsertGatewayWithOnConflictDocument } from '../../api/hasura';
import { Gateway_Set_Input } from '../../api/hasura';
import { UpdateGatewayByIdMutation } from '../../api/hasura';
import { UpdateGatewayByIdMutationVariables } from '../../api/hasura';
import { UpdateGatewayByIdDocument } from '../../api/hasura';
import { UpdateGatewayMutation } from '../../api/hasura';
import { UpdateGatewayMutationVariables } from '../../api/hasura';
import { UpdateGatewayDocument } from '../../api/hasura';
import { RemoveGatewayModelMutation } from '../../api/hasura';
import { RemoveGatewayModelMutationVariables } from '../../api/hasura';
import { RemoveGatewayModelDocument } from '../../api/hasura';
import { RemoveGatewayModelByIdMutation } from '../../api/hasura';
import { RemoveGatewayModelByIdMutationVariables } from '../../api/hasura';
import { RemoveGatewayModelByIdDocument } from '../../api/hasura';
import { PartnerFragment } from '../../api/hasura';
import { QueryPartnerByIdQuery } from '../../api/hasura';
import { QueryPartnerByIdDocument } from '../../api/hasura';
import { QueryPartnerByIdQueryVariables } from '../../api/hasura';
import { QueryPartnerObjectsQuery } from '../../api/hasura';
import { QueryPartnerObjectsDocument } from '../../api/hasura';
import { QueryPartnerObjectsQueryVariables } from '../../api/hasura';
import { Partner_Insert_Input } from '../../api/hasura';
import { Partner_On_Conflict } from '../../api/hasura';
import { InsertPartnerMutation } from '../../api/hasura';
import { InsertPartnerWithOnConflictMutation } from '../../api/hasura';
import { InsertPartnerMutationVariables } from '../../api/hasura';
import { InsertPartnerWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertPartnerDocument } from '../../api/hasura';
import { InsertPartnerWithOnConflictDocument } from '../../api/hasura';
import { Partner_Set_Input } from '../../api/hasura';
import { UpdatePartnerByIdMutation } from '../../api/hasura';
import { UpdatePartnerByIdMutationVariables } from '../../api/hasura';
import { UpdatePartnerByIdDocument } from '../../api/hasura';
import { UpdatePartnerMutation } from '../../api/hasura';
import { UpdatePartnerMutationVariables } from '../../api/hasura';
import { UpdatePartnerDocument } from '../../api/hasura';
import { RemovePartnerModelMutation } from '../../api/hasura';
import { RemovePartnerModelMutationVariables } from '../../api/hasura';
import { RemovePartnerModelDocument } from '../../api/hasura';
import { RemovePartnerModelByIdMutation } from '../../api/hasura';
import { RemovePartnerModelByIdMutationVariables } from '../../api/hasura';
import { RemovePartnerModelByIdDocument } from '../../api/hasura';
import { QuicklinkFragment } from '../../api/hasura';
import { QueryQuicklinkByIdQuery } from '../../api/hasura';
import { QueryQuicklinkByIdDocument } from '../../api/hasura';
import { QueryQuicklinkByIdQueryVariables } from '../../api/hasura';
import { QueryQuicklinkObjectsQuery } from '../../api/hasura';
import { QueryQuicklinkObjectsDocument } from '../../api/hasura';
import { QueryQuicklinkObjectsQueryVariables } from '../../api/hasura';
import { Quicklink_Insert_Input } from '../../api/hasura';
import { Quicklink_On_Conflict } from '../../api/hasura';
import { InsertQuicklinkMutation } from '../../api/hasura';
import { InsertQuicklinkWithOnConflictMutation } from '../../api/hasura';
import { InsertQuicklinkMutationVariables } from '../../api/hasura';
import { InsertQuicklinkWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertQuicklinkDocument } from '../../api/hasura';
import { InsertQuicklinkWithOnConflictDocument } from '../../api/hasura';
import { Quicklink_Set_Input } from '../../api/hasura';
import { UpdateQuicklinkByIdMutation } from '../../api/hasura';
import { UpdateQuicklinkByIdMutationVariables } from '../../api/hasura';
import { UpdateQuicklinkByIdDocument } from '../../api/hasura';
import { UpdateQuicklinkMutation } from '../../api/hasura';
import { UpdateQuicklinkMutationVariables } from '../../api/hasura';
import { UpdateQuicklinkDocument } from '../../api/hasura';
import { RemoveQuicklinkModelMutation } from '../../api/hasura';
import { RemoveQuicklinkModelMutationVariables } from '../../api/hasura';
import { RemoveQuicklinkModelDocument } from '../../api/hasura';
import { RemoveQuicklinkModelByIdMutation } from '../../api/hasura';
import { RemoveQuicklinkModelByIdMutationVariables } from '../../api/hasura';
import { RemoveQuicklinkModelByIdDocument } from '../../api/hasura';
import { FirmwareFragment } from '../../api/hasura';
import { QueryFirmwareByIdQuery } from '../../api/hasura';
import { QueryFirmwareByIdDocument } from '../../api/hasura';
import { QueryFirmwareByIdQueryVariables } from '../../api/hasura';
import { QueryFirmwareObjectsQuery } from '../../api/hasura';
import { QueryFirmwareObjectsDocument } from '../../api/hasura';
import { QueryFirmwareObjectsQueryVariables } from '../../api/hasura';
import { Firmware_Insert_Input } from '../../api/hasura';
import { Firmware_On_Conflict } from '../../api/hasura';
import { InsertFirmwareMutation } from '../../api/hasura';
import { InsertFirmwareWithOnConflictMutation } from '../../api/hasura';
import { InsertFirmwareMutationVariables } from '../../api/hasura';
import { InsertFirmwareWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertFirmwareDocument } from '../../api/hasura';
import { InsertFirmwareWithOnConflictDocument } from '../../api/hasura';
import { Firmware_Set_Input } from '../../api/hasura';
import { UpdateFirmwareByIdMutation } from '../../api/hasura';
import { UpdateFirmwareByIdMutationVariables } from '../../api/hasura';
import { UpdateFirmwareByIdDocument } from '../../api/hasura';
import { UpdateFirmwareMutation } from '../../api/hasura';
import { UpdateFirmwareMutationVariables } from '../../api/hasura';
import { UpdateFirmwareDocument } from '../../api/hasura';
import { RemoveFirmwareModelMutation } from '../../api/hasura';
import { RemoveFirmwareModelMutationVariables } from '../../api/hasura';
import { RemoveFirmwareModelDocument } from '../../api/hasura';
import { RemoveFirmwareModelByIdMutation } from '../../api/hasura';
import { RemoveFirmwareModelByIdMutationVariables } from '../../api/hasura';
import { RemoveFirmwareModelByIdDocument } from '../../api/hasura';
import { GatewayBridgeFragment } from '../../api/hasura';
import { QueryGatewayBridgeByIdQuery } from '../../api/hasura';
import { QueryGatewayBridgeByIdDocument } from '../../api/hasura';
import { QueryGatewayBridgeByIdQueryVariables } from '../../api/hasura';
import { QueryGatewayBridgeObjectsQuery } from '../../api/hasura';
import { QueryGatewayBridgeObjectsDocument } from '../../api/hasura';
import { QueryGatewayBridgeObjectsQueryVariables } from '../../api/hasura';
import { GatewayBridge_Insert_Input } from '../../api/hasura';
import { GatewayBridge_On_Conflict } from '../../api/hasura';
import { InsertGatewayBridgeMutation } from '../../api/hasura';
import { InsertGatewayBridgeWithOnConflictMutation } from '../../api/hasura';
import { InsertGatewayBridgeMutationVariables } from '../../api/hasura';
import { InsertGatewayBridgeWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertGatewayBridgeDocument } from '../../api/hasura';
import { InsertGatewayBridgeWithOnConflictDocument } from '../../api/hasura';
import { GatewayBridge_Set_Input } from '../../api/hasura';
import { UpdateGatewayBridgeByIdMutation } from '../../api/hasura';
import { UpdateGatewayBridgeByIdMutationVariables } from '../../api/hasura';
import { UpdateGatewayBridgeByIdDocument } from '../../api/hasura';
import { UpdateGatewayBridgeMutation } from '../../api/hasura';
import { UpdateGatewayBridgeMutationVariables } from '../../api/hasura';
import { UpdateGatewayBridgeDocument } from '../../api/hasura';
import { RemoveGatewayBridgeModelMutation } from '../../api/hasura';
import { RemoveGatewayBridgeModelMutationVariables } from '../../api/hasura';
import { RemoveGatewayBridgeModelDocument } from '../../api/hasura';
import { RemoveGatewayBridgeModelByIdMutation } from '../../api/hasura';
import { RemoveGatewayBridgeModelByIdMutationVariables } from '../../api/hasura';
import { RemoveGatewayBridgeModelByIdDocument } from '../../api/hasura';
import { GatewaySubnetFragment } from '../../api/hasura';
import { QueryGatewaySubnetByIdQuery } from '../../api/hasura';
import { QueryGatewaySubnetByIdDocument } from '../../api/hasura';
import { QueryGatewaySubnetByIdQueryVariables } from '../../api/hasura';
import { QueryGatewaySubnetObjectsQuery } from '../../api/hasura';
import { QueryGatewaySubnetObjectsDocument } from '../../api/hasura';
import { QueryGatewaySubnetObjectsQueryVariables } from '../../api/hasura';
import { GatewaySubnet_Insert_Input } from '../../api/hasura';
import { GatewaySubnet_On_Conflict } from '../../api/hasura';
import { InsertGatewaySubnetMutation } from '../../api/hasura';
import { InsertGatewaySubnetWithOnConflictMutation } from '../../api/hasura';
import { InsertGatewaySubnetMutationVariables } from '../../api/hasura';
import { InsertGatewaySubnetWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertGatewaySubnetDocument } from '../../api/hasura';
import { InsertGatewaySubnetWithOnConflictDocument } from '../../api/hasura';
import { GatewaySubnet_Set_Input } from '../../api/hasura';
import { UpdateGatewaySubnetByIdMutation } from '../../api/hasura';
import { UpdateGatewaySubnetByIdMutationVariables } from '../../api/hasura';
import { UpdateGatewaySubnetByIdDocument } from '../../api/hasura';
import { UpdateGatewaySubnetMutation } from '../../api/hasura';
import { UpdateGatewaySubnetMutationVariables } from '../../api/hasura';
import { UpdateGatewaySubnetDocument } from '../../api/hasura';
import { RemoveGatewaySubnetModelMutation } from '../../api/hasura';
import { RemoveGatewaySubnetModelMutationVariables } from '../../api/hasura';
import { RemoveGatewaySubnetModelDocument } from '../../api/hasura';
import { RemoveGatewaySubnetModelByIdMutation } from '../../api/hasura';
import { RemoveGatewaySubnetModelByIdMutationVariables } from '../../api/hasura';
import { RemoveGatewaySubnetModelByIdDocument } from '../../api/hasura';
import { LanNodeFragment } from '../../api/hasura';
import { QueryLanNodeByIdQuery } from '../../api/hasura';
import { QueryLanNodeByIdDocument } from '../../api/hasura';
import { QueryLanNodeByIdQueryVariables } from '../../api/hasura';
import { QueryLanNodeObjectsQuery } from '../../api/hasura';
import { QueryLanNodeObjectsDocument } from '../../api/hasura';
import { QueryLanNodeObjectsQueryVariables } from '../../api/hasura';
import { LanNode_Insert_Input } from '../../api/hasura';
import { LanNode_On_Conflict } from '../../api/hasura';
import { InsertLanNodeMutation } from '../../api/hasura';
import { InsertLanNodeWithOnConflictMutation } from '../../api/hasura';
import { InsertLanNodeMutationVariables } from '../../api/hasura';
import { InsertLanNodeWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertLanNodeDocument } from '../../api/hasura';
import { InsertLanNodeWithOnConflictDocument } from '../../api/hasura';
import { LanNode_Set_Input } from '../../api/hasura';
import { UpdateLanNodeByIdMutation } from '../../api/hasura';
import { UpdateLanNodeByIdMutationVariables } from '../../api/hasura';
import { UpdateLanNodeByIdDocument } from '../../api/hasura';
import { UpdateLanNodeMutation } from '../../api/hasura';
import { UpdateLanNodeMutationVariables } from '../../api/hasura';
import { UpdateLanNodeDocument } from '../../api/hasura';
import { RemoveLanNodeModelMutation } from '../../api/hasura';
import { RemoveLanNodeModelMutationVariables } from '../../api/hasura';
import { RemoveLanNodeModelDocument } from '../../api/hasura';
import { RemoveLanNodeModelByIdMutation } from '../../api/hasura';
import { RemoveLanNodeModelByIdMutationVariables } from '../../api/hasura';
import { RemoveLanNodeModelByIdDocument } from '../../api/hasura';
import { LinkFragment } from '../../api/hasura';
import { QueryLinkByIdQuery } from '../../api/hasura';
import { QueryLinkByIdDocument } from '../../api/hasura';
import { QueryLinkByIdQueryVariables } from '../../api/hasura';
import { QueryLinkObjectsQuery } from '../../api/hasura';
import { QueryLinkObjectsDocument } from '../../api/hasura';
import { QueryLinkObjectsQueryVariables } from '../../api/hasura';
import { Link_Insert_Input } from '../../api/hasura';
import { Link_On_Conflict } from '../../api/hasura';
import { InsertLinkMutation } from '../../api/hasura';
import { InsertLinkWithOnConflictMutation } from '../../api/hasura';
import { InsertLinkMutationVariables } from '../../api/hasura';
import { InsertLinkWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertLinkDocument } from '../../api/hasura';
import { InsertLinkWithOnConflictDocument } from '../../api/hasura';
import { Link_Set_Input } from '../../api/hasura';
import { UpdateLinkByIdMutation } from '../../api/hasura';
import { UpdateLinkByIdMutationVariables } from '../../api/hasura';
import { UpdateLinkByIdDocument } from '../../api/hasura';
import { UpdateLinkMutation } from '../../api/hasura';
import { UpdateLinkMutationVariables } from '../../api/hasura';
import { UpdateLinkDocument } from '../../api/hasura';
import { RemoveLinkModelMutation } from '../../api/hasura';
import { RemoveLinkModelMutationVariables } from '../../api/hasura';
import { RemoveLinkModelDocument } from '../../api/hasura';
import { RemoveLinkModelByIdMutation } from '../../api/hasura';
import { RemoveLinkModelByIdMutationVariables } from '../../api/hasura';
import { RemoveLinkModelByIdDocument } from '../../api/hasura';
import { LinkTypeFragment } from '../../api/hasura';
import { QueryLinkTypeByIdQuery } from '../../api/hasura';
import { QueryLinkTypeByIdDocument } from '../../api/hasura';
import { QueryLinkTypeByIdQueryVariables } from '../../api/hasura';
import { QueryLinkTypeObjectsQuery } from '../../api/hasura';
import { QueryLinkTypeObjectsDocument } from '../../api/hasura';
import { QueryLinkTypeObjectsQueryVariables } from '../../api/hasura';
import { LinkType_Insert_Input } from '../../api/hasura';
import { LinkType_On_Conflict } from '../../api/hasura';
import { InsertLinkTypeMutation } from '../../api/hasura';
import { InsertLinkTypeWithOnConflictMutation } from '../../api/hasura';
import { InsertLinkTypeMutationVariables } from '../../api/hasura';
import { InsertLinkTypeWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertLinkTypeDocument } from '../../api/hasura';
import { InsertLinkTypeWithOnConflictDocument } from '../../api/hasura';
import { LinkType_Set_Input } from '../../api/hasura';
import { UpdateLinkTypeByIdMutation } from '../../api/hasura';
import { UpdateLinkTypeByIdMutationVariables } from '../../api/hasura';
import { UpdateLinkTypeByIdDocument } from '../../api/hasura';
import { UpdateLinkTypeMutation } from '../../api/hasura';
import { UpdateLinkTypeMutationVariables } from '../../api/hasura';
import { UpdateLinkTypeDocument } from '../../api/hasura';
import { RemoveLinkTypeModelMutation } from '../../api/hasura';
import { RemoveLinkTypeModelMutationVariables } from '../../api/hasura';
import { RemoveLinkTypeModelDocument } from '../../api/hasura';
import { RemoveLinkTypeModelByIdMutation } from '../../api/hasura';
import { RemoveLinkTypeModelByIdMutationVariables } from '../../api/hasura';
import { RemoveLinkTypeModelByIdDocument } from '../../api/hasura';
import { MeshWanFragment } from '../../api/hasura';
import { QueryMeshWanByIdQuery } from '../../api/hasura';
import { QueryMeshWanByIdDocument } from '../../api/hasura';
import { QueryMeshWanByIdQueryVariables } from '../../api/hasura';
import { QueryMeshWanObjectsQuery } from '../../api/hasura';
import { QueryMeshWanObjectsDocument } from '../../api/hasura';
import { QueryMeshWanObjectsQueryVariables } from '../../api/hasura';
import { MeshWan_Insert_Input } from '../../api/hasura';
import { MeshWan_On_Conflict } from '../../api/hasura';
import { InsertMeshWanMutation } from '../../api/hasura';
import { InsertMeshWanWithOnConflictMutation } from '../../api/hasura';
import { InsertMeshWanMutationVariables } from '../../api/hasura';
import { InsertMeshWanWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertMeshWanDocument } from '../../api/hasura';
import { InsertMeshWanWithOnConflictDocument } from '../../api/hasura';
import { MeshWan_Set_Input } from '../../api/hasura';
import { UpdateMeshWanByIdMutation } from '../../api/hasura';
import { UpdateMeshWanByIdMutationVariables } from '../../api/hasura';
import { UpdateMeshWanByIdDocument } from '../../api/hasura';
import { UpdateMeshWanMutation } from '../../api/hasura';
import { UpdateMeshWanMutationVariables } from '../../api/hasura';
import { UpdateMeshWanDocument } from '../../api/hasura';
import { RemoveMeshWanModelMutation } from '../../api/hasura';
import { RemoveMeshWanModelMutationVariables } from '../../api/hasura';
import { RemoveMeshWanModelDocument } from '../../api/hasura';
import { RemoveMeshWanModelByIdMutation } from '../../api/hasura';
import { RemoveMeshWanModelByIdMutationVariables } from '../../api/hasura';
import { RemoveMeshWanModelByIdDocument } from '../../api/hasura';
import { MonitorFragment } from '../../api/hasura';
import { QueryMonitorByIdQuery } from '../../api/hasura';
import { QueryMonitorByIdDocument } from '../../api/hasura';
import { QueryMonitorByIdQueryVariables } from '../../api/hasura';
import { QueryMonitorObjectsQuery } from '../../api/hasura';
import { QueryMonitorObjectsDocument } from '../../api/hasura';
import { QueryMonitorObjectsQueryVariables } from '../../api/hasura';
import { Monitor_Insert_Input } from '../../api/hasura';
import { Monitor_On_Conflict } from '../../api/hasura';
import { InsertMonitorMutation } from '../../api/hasura';
import { InsertMonitorWithOnConflictMutation } from '../../api/hasura';
import { InsertMonitorMutationVariables } from '../../api/hasura';
import { InsertMonitorWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertMonitorDocument } from '../../api/hasura';
import { InsertMonitorWithOnConflictDocument } from '../../api/hasura';
import { Monitor_Set_Input } from '../../api/hasura';
import { UpdateMonitorByIdMutation } from '../../api/hasura';
import { UpdateMonitorByIdMutationVariables } from '../../api/hasura';
import { UpdateMonitorByIdDocument } from '../../api/hasura';
import { UpdateMonitorMutation } from '../../api/hasura';
import { UpdateMonitorMutationVariables } from '../../api/hasura';
import { UpdateMonitorDocument } from '../../api/hasura';
import { RemoveMonitorModelMutation } from '../../api/hasura';
import { RemoveMonitorModelMutationVariables } from '../../api/hasura';
import { RemoveMonitorModelDocument } from '../../api/hasura';
import { RemoveMonitorModelByIdMutation } from '../../api/hasura';
import { RemoveMonitorModelByIdMutationVariables } from '../../api/hasura';
import { RemoveMonitorModelByIdDocument } from '../../api/hasura';
import { ProfileFragment } from '../../api/hasura';
import { QueryProfileByIdQuery } from '../../api/hasura';
import { QueryProfileByIdDocument } from '../../api/hasura';
import { QueryProfileByIdQueryVariables } from '../../api/hasura';
import { QueryProfileObjectsQuery } from '../../api/hasura';
import { QueryProfileObjectsDocument } from '../../api/hasura';
import { QueryProfileObjectsQueryVariables } from '../../api/hasura';
import { Profile_Insert_Input } from '../../api/hasura';
import { Profile_On_Conflict } from '../../api/hasura';
import { InsertProfileMutation } from '../../api/hasura';
import { InsertProfileWithOnConflictMutation } from '../../api/hasura';
import { InsertProfileMutationVariables } from '../../api/hasura';
import { InsertProfileWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertProfileDocument } from '../../api/hasura';
import { InsertProfileWithOnConflictDocument } from '../../api/hasura';
import { Profile_Set_Input } from '../../api/hasura';
import { UpdateProfileByIdMutation } from '../../api/hasura';
import { UpdateProfileByIdMutationVariables } from '../../api/hasura';
import { UpdateProfileByIdDocument } from '../../api/hasura';
import { UpdateProfileMutation } from '../../api/hasura';
import { UpdateProfileMutationVariables } from '../../api/hasura';
import { UpdateProfileDocument } from '../../api/hasura';
import { RemoveProfileModelMutation } from '../../api/hasura';
import { RemoveProfileModelMutationVariables } from '../../api/hasura';
import { RemoveProfileModelDocument } from '../../api/hasura';
import { RemoveProfileModelByIdMutation } from '../../api/hasura';
import { RemoveProfileModelByIdMutationVariables } from '../../api/hasura';
import { RemoveProfileModelByIdDocument } from '../../api/hasura';
import { PublicIpFragment } from '../../api/hasura';
import { QueryPublicIpByIdQuery } from '../../api/hasura';
import { QueryPublicIpByIdDocument } from '../../api/hasura';
import { QueryPublicIpByIdQueryVariables } from '../../api/hasura';
import { QueryPublicIpObjectsQuery } from '../../api/hasura';
import { QueryPublicIpObjectsDocument } from '../../api/hasura';
import { QueryPublicIpObjectsQueryVariables } from '../../api/hasura';
import { PublicIp_Insert_Input } from '../../api/hasura';
import { PublicIp_On_Conflict } from '../../api/hasura';
import { InsertPublicIpMutation } from '../../api/hasura';
import { InsertPublicIpWithOnConflictMutation } from '../../api/hasura';
import { InsertPublicIpMutationVariables } from '../../api/hasura';
import { InsertPublicIpWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertPublicIpDocument } from '../../api/hasura';
import { InsertPublicIpWithOnConflictDocument } from '../../api/hasura';
import { PublicIp_Set_Input } from '../../api/hasura';
import { UpdatePublicIpByIdMutation } from '../../api/hasura';
import { UpdatePublicIpByIdMutationVariables } from '../../api/hasura';
import { UpdatePublicIpByIdDocument } from '../../api/hasura';
import { UpdatePublicIpMutation } from '../../api/hasura';
import { UpdatePublicIpMutationVariables } from '../../api/hasura';
import { UpdatePublicIpDocument } from '../../api/hasura';
import { RemovePublicIpModelMutation } from '../../api/hasura';
import { RemovePublicIpModelMutationVariables } from '../../api/hasura';
import { RemovePublicIpModelDocument } from '../../api/hasura';
import { RemovePublicIpModelByIdMutation } from '../../api/hasura';
import { RemovePublicIpModelByIdMutationVariables } from '../../api/hasura';
import { RemovePublicIpModelByIdDocument } from '../../api/hasura';
import { ServerFragment } from '../../api/hasura';
import { QueryServerByIdQuery } from '../../api/hasura';
import { QueryServerByIdDocument } from '../../api/hasura';
import { QueryServerByIdQueryVariables } from '../../api/hasura';
import { QueryServerObjectsQuery } from '../../api/hasura';
import { QueryServerObjectsDocument } from '../../api/hasura';
import { QueryServerObjectsQueryVariables } from '../../api/hasura';
import { Server_Insert_Input } from '../../api/hasura';
import { Server_On_Conflict } from '../../api/hasura';
import { InsertServerMutation } from '../../api/hasura';
import { InsertServerWithOnConflictMutation } from '../../api/hasura';
import { InsertServerMutationVariables } from '../../api/hasura';
import { InsertServerWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertServerDocument } from '../../api/hasura';
import { InsertServerWithOnConflictDocument } from '../../api/hasura';
import { Server_Set_Input } from '../../api/hasura';
import { UpdateServerByIdMutation } from '../../api/hasura';
import { UpdateServerByIdMutationVariables } from '../../api/hasura';
import { UpdateServerByIdDocument } from '../../api/hasura';
import { UpdateServerMutation } from '../../api/hasura';
import { UpdateServerMutationVariables } from '../../api/hasura';
import { UpdateServerDocument } from '../../api/hasura';
import { RemoveServerModelMutation } from '../../api/hasura';
import { RemoveServerModelMutationVariables } from '../../api/hasura';
import { RemoveServerModelDocument } from '../../api/hasura';
import { RemoveServerModelByIdMutation } from '../../api/hasura';
import { RemoveServerModelByIdMutationVariables } from '../../api/hasura';
import { RemoveServerModelByIdDocument } from '../../api/hasura';
import { ServerBridgeFragment } from '../../api/hasura';
import { QueryServerBridgeObjectsQuery } from '../../api/hasura';
import { QueryServerBridgeObjectsDocument } from '../../api/hasura';
import { QueryServerBridgeObjectsQueryVariables } from '../../api/hasura';
// import { RemoveServerBridgeModelDocument } from '../../api/hasura';
//import { RemoveServerBridgeModelByIdMutation } from '../../api/hasura';
//import { RemoveServerBridgeModelByIdMutationVariables } from '../../api/hasura';
//import { RemoveServerBridgeModelByIdDocument } from '../../api/hasura';
import { SiteFragment } from '../../api/hasura';
import { QuerySiteByIdQuery } from '../../api/hasura';
import { QuerySiteByIdDocument } from '../../api/hasura';
import { QuerySiteByIdQueryVariables } from '../../api/hasura';
import { QuerySiteObjectsQuery } from '../../api/hasura';
import { QuerySiteObjectsDocument } from '../../api/hasura';
import { QuerySiteObjectsQueryVariables } from '../../api/hasura';
import { Site_Insert_Input } from '../../api/hasura';
import { Site_On_Conflict } from '../../api/hasura';
import { InsertSiteMutation } from '../../api/hasura';
import { InsertSiteWithOnConflictMutation } from '../../api/hasura';
import { InsertSiteMutationVariables } from '../../api/hasura';
import { InsertSiteWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertSiteDocument } from '../../api/hasura';
import { InsertSiteWithOnConflictDocument } from '../../api/hasura';
import { Site_Set_Input } from '../../api/hasura';
import { UpdateSiteByIdMutation } from '../../api/hasura';
import { UpdateSiteByIdMutationVariables } from '../../api/hasura';
import { UpdateSiteByIdDocument } from '../../api/hasura';
import { UpdateSiteMutation } from '../../api/hasura';
import { UpdateSiteMutationVariables } from '../../api/hasura';
import { UpdateSiteDocument } from '../../api/hasura';
import { RemoveSiteModelMutation } from '../../api/hasura';
import { RemoveSiteModelMutationVariables } from '../../api/hasura';
import { RemoveSiteModelDocument } from '../../api/hasura';
import { RemoveSiteModelByIdMutation } from '../../api/hasura';
import { RemoveSiteModelByIdMutationVariables } from '../../api/hasura';
import { RemoveSiteModelByIdDocument } from '../../api/hasura';
import { SiteContactFragment } from '../../api/hasura';
import { QuerySiteContactByIdQuery } from '../../api/hasura';
import { QuerySiteContactByIdDocument } from '../../api/hasura';
import { QuerySiteContactByIdQueryVariables } from '../../api/hasura';
import { QuerySiteContactObjectsQuery } from '../../api/hasura';
import { QuerySiteContactObjectsDocument } from '../../api/hasura';
import { QuerySiteContactObjectsQueryVariables } from '../../api/hasura';
import { SiteContact_Insert_Input } from '../../api/hasura';
import { SiteContact_On_Conflict } from '../../api/hasura';
import { InsertSiteContactMutation } from '../../api/hasura';
import { InsertSiteContactWithOnConflictMutation } from '../../api/hasura';
import { InsertSiteContactMutationVariables } from '../../api/hasura';
import { InsertSiteContactWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertSiteContactDocument } from '../../api/hasura';
import { InsertSiteContactWithOnConflictDocument } from '../../api/hasura';
import { SiteContact_Set_Input } from '../../api/hasura';
import { UpdateSiteContactByIdMutation } from '../../api/hasura';
import { UpdateSiteContactByIdMutationVariables } from '../../api/hasura';
import { UpdateSiteContactByIdDocument } from '../../api/hasura';
import { UpdateSiteContactMutation } from '../../api/hasura';
import { UpdateSiteContactMutationVariables } from '../../api/hasura';
import { UpdateSiteContactDocument } from '../../api/hasura';
import { RemoveSiteContactModelMutation } from '../../api/hasura';
import { RemoveSiteContactModelMutationVariables } from '../../api/hasura';
import { RemoveSiteContactModelDocument } from '../../api/hasura';
import { RemoveSiteContactModelByIdMutation } from '../../api/hasura';
import { RemoveSiteContactModelByIdMutationVariables } from '../../api/hasura';
import { RemoveSiteContactModelByIdDocument } from '../../api/hasura';
import { SiteLanFragment } from '../../api/hasura';
import { QuerySiteLanByIdQuery } from '../../api/hasura';
import { QuerySiteLanByIdDocument } from '../../api/hasura';
import { QuerySiteLanByIdQueryVariables } from '../../api/hasura';
import { QuerySiteLanObjectsQuery } from '../../api/hasura';
import { QuerySiteLanObjectsDocument } from '../../api/hasura';
import { QuerySiteLanObjectsQueryVariables } from '../../api/hasura';
import { SiteLan_Insert_Input } from '../../api/hasura';
import { SiteLan_On_Conflict } from '../../api/hasura';
import { InsertSiteLanMutation } from '../../api/hasura';
import { InsertSiteLanWithOnConflictMutation } from '../../api/hasura';
import { InsertSiteLanMutationVariables } from '../../api/hasura';
import { InsertSiteLanWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertSiteLanDocument } from '../../api/hasura';
import { InsertSiteLanWithOnConflictDocument } from '../../api/hasura';
import { SiteLan_Set_Input } from '../../api/hasura';
import { UpdateSiteLanByIdMutation } from '../../api/hasura';
import { UpdateSiteLanByIdMutationVariables } from '../../api/hasura';
import { UpdateSiteLanByIdDocument } from '../../api/hasura';
import { UpdateSiteLanMutation } from '../../api/hasura';
import { UpdateSiteLanMutationVariables } from '../../api/hasura';
import { UpdateSiteLanDocument } from '../../api/hasura';
import { RemoveSiteLanModelMutation } from '../../api/hasura';
import { RemoveSiteLanModelMutationVariables } from '../../api/hasura';
import { RemoveSiteLanModelDocument } from '../../api/hasura';
import { RemoveSiteLanModelByIdMutation } from '../../api/hasura';
import { RemoveSiteLanModelByIdMutationVariables } from '../../api/hasura';
import { RemoveSiteLanModelByIdDocument } from '../../api/hasura';
import { SiteRouteFragment } from '../../api/hasura';
import { QuerySiteRouteByIdQuery } from '../../api/hasura';
import { QuerySiteRouteByIdDocument } from '../../api/hasura';
import { QuerySiteRouteByIdQueryVariables } from '../../api/hasura';
import { QuerySiteRouteObjectsQuery } from '../../api/hasura';
import { QuerySiteRouteObjectsDocument } from '../../api/hasura';
import { QuerySiteRouteObjectsQueryVariables } from '../../api/hasura';
import { SiteRoute_Insert_Input } from '../../api/hasura';
import { SiteRoute_On_Conflict } from '../../api/hasura';
import { InsertSiteRouteMutation } from '../../api/hasura';
import { InsertSiteRouteWithOnConflictMutation } from '../../api/hasura';
import { InsertSiteRouteMutationVariables } from '../../api/hasura';
import { InsertSiteRouteWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertSiteRouteDocument } from '../../api/hasura';
import { InsertSiteRouteWithOnConflictDocument } from '../../api/hasura';
import { SiteRoute_Set_Input } from '../../api/hasura';
import { UpdateSiteRouteByIdMutation } from '../../api/hasura';
import { UpdateSiteRouteByIdMutationVariables } from '../../api/hasura';
import { UpdateSiteRouteByIdDocument } from '../../api/hasura';
import { UpdateSiteRouteMutation } from '../../api/hasura';
import { UpdateSiteRouteMutationVariables } from '../../api/hasura';
import { UpdateSiteRouteDocument } from '../../api/hasura';
import { RemoveSiteRouteModelMutation } from '../../api/hasura';
import { RemoveSiteRouteModelMutationVariables } from '../../api/hasura';
import { RemoveSiteRouteModelDocument } from '../../api/hasura';
import { RemoveSiteRouteModelByIdMutation } from '../../api/hasura';
import { RemoveSiteRouteModelByIdMutationVariables } from '../../api/hasura';
import { RemoveSiteRouteModelByIdDocument } from '../../api/hasura';
import { TunnelFragment } from '../../api/hasura';
import { QueryTunnelByIdQuery } from '../../api/hasura';
import { QueryTunnelByIdDocument } from '../../api/hasura';
import { QueryTunnelByIdQueryVariables } from '../../api/hasura';
import { QueryTunnelObjectsQuery } from '../../api/hasura';
import { QueryTunnelObjectsDocument } from '../../api/hasura';
import { QueryTunnelObjectsQueryVariables } from '../../api/hasura';
import { Tunnel_Insert_Input } from '../../api/hasura';
import { Tunnel_On_Conflict } from '../../api/hasura';
import { InsertTunnelMutation } from '../../api/hasura';
import { InsertTunnelWithOnConflictMutation } from '../../api/hasura';
import { InsertTunnelMutationVariables } from '../../api/hasura';
import { InsertTunnelWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertTunnelDocument } from '../../api/hasura';
import { InsertTunnelWithOnConflictDocument } from '../../api/hasura';
import { Tunnel_Set_Input } from '../../api/hasura';
import { UpdateTunnelByIdMutation } from '../../api/hasura';
import { UpdateTunnelByIdMutationVariables } from '../../api/hasura';
import { UpdateTunnelByIdDocument } from '../../api/hasura';
import { UpdateTunnelMutation } from '../../api/hasura';
import { UpdateTunnelMutationVariables } from '../../api/hasura';
import { UpdateTunnelDocument } from '../../api/hasura';
import { RemoveTunnelModelMutation } from '../../api/hasura';
import { RemoveTunnelModelMutationVariables } from '../../api/hasura';
import { RemoveTunnelModelDocument } from '../../api/hasura';
import { RemoveTunnelModelByIdMutation } from '../../api/hasura';
import { RemoveTunnelModelByIdMutationVariables } from '../../api/hasura';
import { RemoveTunnelModelByIdDocument } from '../../api/hasura';
import { TunnelProfileFragment } from '../../api/hasura';
import { QueryTunnelProfileByIdQuery } from '../../api/hasura';
import { QueryTunnelProfileByIdDocument } from '../../api/hasura';
import { QueryTunnelProfileByIdQueryVariables } from '../../api/hasura';
import { QueryTunnelProfileObjectsQuery } from '../../api/hasura';
import { QueryTunnelProfileObjectsDocument } from '../../api/hasura';
import { QueryTunnelProfileObjectsQueryVariables } from '../../api/hasura';
import { TunnelProfile_Insert_Input } from '../../api/hasura';
import { TunnelProfile_On_Conflict } from '../../api/hasura';
import { InsertTunnelProfileMutation } from '../../api/hasura';
import { InsertTunnelProfileWithOnConflictMutation } from '../../api/hasura';
import { InsertTunnelProfileMutationVariables } from '../../api/hasura';
import { InsertTunnelProfileWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertTunnelProfileDocument } from '../../api/hasura';
import { InsertTunnelProfileWithOnConflictDocument } from '../../api/hasura';
import { TunnelProfile_Set_Input } from '../../api/hasura';
import { UpdateTunnelProfileByIdMutation } from '../../api/hasura';
import { UpdateTunnelProfileByIdMutationVariables } from '../../api/hasura';
import { UpdateTunnelProfileByIdDocument } from '../../api/hasura';
import { UpdateTunnelProfileMutation } from '../../api/hasura';
import { UpdateTunnelProfileMutationVariables } from '../../api/hasura';
import { UpdateTunnelProfileDocument } from '../../api/hasura';
import { RemoveTunnelProfileModelMutation } from '../../api/hasura';
import { RemoveTunnelProfileModelMutationVariables } from '../../api/hasura';
import { RemoveTunnelProfileModelDocument } from '../../api/hasura';
import { RemoveTunnelProfileModelByIdMutation } from '../../api/hasura';
import { RemoveTunnelProfileModelByIdMutationVariables } from '../../api/hasura';
import { RemoveTunnelProfileModelByIdDocument } from '../../api/hasura';
import { UserFragment } from '../../api/hasura';
import { QueryUserByIdQuery } from '../../api/hasura';
import { QueryUserByIdDocument } from '../../api/hasura';
import { QueryUserByIdQueryVariables } from '../../api/hasura';
import { QueryUserObjectsQuery } from '../../api/hasura';
import { QueryUserObjectsDocument } from '../../api/hasura';
import { QueryUserObjectsQueryVariables } from '../../api/hasura';
import { User_Insert_Input } from '../../api/hasura';
import { User_On_Conflict } from '../../api/hasura';
import { InsertUserMutation } from '../../api/hasura';
import { InsertUserWithOnConflictMutation } from '../../api/hasura';
import { InsertUserMutationVariables } from '../../api/hasura';
import { InsertUserWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertUserDocument } from '../../api/hasura';
import { InsertUserWithOnConflictDocument } from '../../api/hasura';
import { User_Set_Input } from '../../api/hasura';
import { UpdateUserByIdMutation } from '../../api/hasura';
import { UpdateUserByIdMutationVariables } from '../../api/hasura';
import { UpdateUserByIdDocument } from '../../api/hasura';
import { UpdateUserMutation } from '../../api/hasura';
import { UpdateUserMutationVariables } from '../../api/hasura';
import { UpdateUserDocument } from '../../api/hasura';
import { RemoveUserModelMutation } from '../../api/hasura';
import { RemoveUserModelMutationVariables } from '../../api/hasura';
import { RemoveUserModelDocument } from '../../api/hasura';
import { RemoveUserModelByIdMutation } from '../../api/hasura';
import { RemoveUserModelByIdMutationVariables } from '../../api/hasura';
import { RemoveUserModelByIdDocument } from '../../api/hasura';
import { UserHistoryFragment } from '../../api/hasura';
import { QueryUserHistoryByIdQuery } from '../../api/hasura';
import { QueryUserHistoryByIdDocument } from '../../api/hasura';
import { QueryUserHistoryByIdQueryVariables } from '../../api/hasura';
import { QueryUserHistoryObjectsQuery } from '../../api/hasura';
import { QueryUserHistoryObjectsDocument } from '../../api/hasura';
import { QueryUserHistoryObjectsQueryVariables } from '../../api/hasura';
import { UserHistory_Insert_Input } from '../../api/hasura';
import { UserHistory_On_Conflict } from '../../api/hasura';
import { InsertUserHistoryMutation } from '../../api/hasura';
import { InsertUserHistoryWithOnConflictMutation } from '../../api/hasura';
import { InsertUserHistoryMutationVariables } from '../../api/hasura';
import { InsertUserHistoryWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertUserHistoryDocument } from '../../api/hasura';
import { InsertUserHistoryWithOnConflictDocument } from '../../api/hasura';
import { UserHistory_Set_Input } from '../../api/hasura';
import { UpdateUserHistoryByIdMutation } from '../../api/hasura';
import { UpdateUserHistoryByIdMutationVariables } from '../../api/hasura';
import { UpdateUserHistoryByIdDocument } from '../../api/hasura';
import { UpdateUserHistoryMutation } from '../../api/hasura';
import { UpdateUserHistoryMutationVariables } from '../../api/hasura';
import { UpdateUserHistoryDocument } from '../../api/hasura';
import { RemoveUserHistoryModelMutation } from '../../api/hasura';
import { RemoveUserHistoryModelMutationVariables } from '../../api/hasura';
import { RemoveUserHistoryModelDocument } from '../../api/hasura';
import { RemoveUserHistoryModelByIdMutation } from '../../api/hasura';
import { RemoveUserHistoryModelByIdMutationVariables } from '../../api/hasura';
import { RemoveUserHistoryModelByIdDocument } from '../../api/hasura';
import { UserRoleFragment } from '../../api/hasura';
import { QueryUserRoleByIdQuery } from '../../api/hasura';
import { QueryUserRoleByIdDocument } from '../../api/hasura';
import { QueryUserRoleByIdQueryVariables } from '../../api/hasura';
import { QueryUserRoleObjectsQuery } from '../../api/hasura';
import { QueryUserRoleObjectsDocument } from '../../api/hasura';
import { QueryUserRoleObjectsQueryVariables } from '../../api/hasura';
import { UserRole_Insert_Input } from '../../api/hasura';
import { UserRole_On_Conflict } from '../../api/hasura';
import { InsertUserRoleMutation } from '../../api/hasura';
import { InsertUserRoleWithOnConflictMutation } from '../../api/hasura';
import { InsertUserRoleMutationVariables } from '../../api/hasura';
import { InsertUserRoleWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertUserRoleDocument } from '../../api/hasura';
import { InsertUserRoleWithOnConflictDocument } from '../../api/hasura';
import { UserRole_Set_Input } from '../../api/hasura';
import { UpdateUserRoleByIdMutation } from '../../api/hasura';
import { UpdateUserRoleByIdMutationVariables } from '../../api/hasura';
import { UpdateUserRoleByIdDocument } from '../../api/hasura';
import { UpdateUserRoleMutation } from '../../api/hasura';
import { UpdateUserRoleMutationVariables } from '../../api/hasura';
import { UpdateUserRoleDocument } from '../../api/hasura';
import { RemoveUserRoleModelMutation } from '../../api/hasura';
import { RemoveUserRoleModelMutationVariables } from '../../api/hasura';
import { RemoveUserRoleModelDocument } from '../../api/hasura';
import { RemoveUserRoleModelByIdMutation } from '../../api/hasura';
import { RemoveUserRoleModelByIdMutationVariables } from '../../api/hasura';
import { RemoveUserRoleModelByIdDocument } from '../../api/hasura';
import { WanFragment } from '../../api/hasura';
import { QueryWanByIdQuery } from '../../api/hasura';
import { QueryWanByIdDocument } from '../../api/hasura';
import { QueryWanByIdQueryVariables } from '../../api/hasura';
import { QueryWanObjectsQuery } from '../../api/hasura';
import { QueryWanObjectsDocument } from '../../api/hasura';
import { QueryWanObjectsQueryVariables } from '../../api/hasura';
import { Wan_Insert_Input } from '../../api/hasura';
import { Wan_On_Conflict } from '../../api/hasura';
import { InsertWanMutation } from '../../api/hasura';
import { InsertWanWithOnConflictMutation } from '../../api/hasura';
import { InsertWanMutationVariables } from '../../api/hasura';
import { InsertWanWithOnConflictMutationVariables } from '../../api/hasura';
import { InsertWanDocument } from '../../api/hasura';
import { InsertWanWithOnConflictDocument } from '../../api/hasura';
import { Wan_Set_Input } from '../../api/hasura';
import { UpdateWanByIdMutation } from '../../api/hasura';
import { UpdateWanByIdMutationVariables } from '../../api/hasura';
import { UpdateWanByIdDocument } from '../../api/hasura';
import { UpdateWanMutation } from '../../api/hasura';
import { UpdateWanMutationVariables } from '../../api/hasura';
import { UpdateWanDocument } from '../../api/hasura';
import { RemoveWanModelMutation } from '../../api/hasura';
import { RemoveWanModelMutationVariables } from '../../api/hasura';
import { RemoveWanModelDocument } from '../../api/hasura';
import { RemoveWanModelByIdMutation } from '../../api/hasura';
import { RemoveWanModelByIdMutationVariables } from '../../api/hasura';
import { RemoveWanModelByIdDocument } from '../../api/hasura';

    // GLOBAL TYPES
    //------------------------------------------------
    export type RemoveEntitiesQueryHelperResultEx = { affected_rows:number };

    //
    // GLOBAL VALUES
    const logLevel = getLogLevel();

  

    // Carrier HELPERS
    //------------------------------------------------

    export type CarrierByIdHelperResultEx = { carrier:CarrierFragment | null | undefined };
    export type CarrierObjectsHelperResultEx = { objects:CarrierFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryCarrierByIdApolloQueryResult = ApolloQueryResult<QueryCarrierByIdQuery>;
      export type QueryCarrierByIdApolloQueryHelperResultEx = QueryCarrierByIdApolloQueryResult & CarrierByIdHelperResultEx;

      async function queryCarrierById({ apolloClient, carrierId, options }: { apolloClient: ApolloClient<object>, carrierId: string, options?: Omit<QueryOptions<QueryCarrierByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryCarrierByIdApolloQueryHelperResultEx> {
        const query: QueryCarrierByIdApolloQueryResult = await apolloClient.query<QueryCarrierByIdQuery>({ query: QueryCarrierByIdDocument, variables: { carrierId }, ...options });
        
        return { ...query, carrier: query?.data?.Carrier_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryCarrierByIdApolloObservableQuery = ObservableQuery<QueryCarrierByIdQuery>;
      async function watchQueryCarrierById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryCarrierByIdQueryVariables>, 'query'> }) : Promise<WatchQueryCarrierByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryCarrierByIdQuery>({ query: QueryCarrierByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryCarrierObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryCarrierObjectsQuery>;
      export type QueryCarrierObjectsObjectsApolloQueryResultEx = QueryCarrierObjectsObjectsApolloQueryResult & CarrierObjectsHelperResultEx;

      async function queryCarrierObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryCarrierObjectsQueryVariables>, 'query'> }): Promise<QueryCarrierObjectsObjectsApolloQueryResultEx> {
        const query: QueryCarrierObjectsObjectsApolloQueryResult = await apolloClient.query<QueryCarrierObjectsQuery>({ query: QueryCarrierObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Carrier || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryCarrierObjectsApolloObservableQuery = ObservableQuery<QueryCarrierObjectsQuery>;
      async function watchQueryCarrierObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryCarrierObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryCarrierObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryCarrierObjectsQuery>({ query: QueryCarrierObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertCarrierFetchResult = FetchResult<InsertCarrierMutation, Record<string, any>, Record<string, any>>;
    export type InsertCarrierFetchHelperResultEx = InsertCarrierFetchResult & CarrierByIdHelperResultEx;

    async function insertCarrier({ apolloClient, carrier, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, carrier: Carrier_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertCarrierMutation, InsertCarrierMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertCarrierFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: carrier });
      const mutationOptions:MutationOptions<InsertCarrierMutation, InsertCarrierMutationVariables> = { mutation: InsertCarrierDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'carrier'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCarrierMutation>({ operationType: 'insert', entityName:'Carrier', objects:[objectForInsert as Carrier_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertCarrier - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertCarrierFetchResult = await apolloClient.mutate<InsertCarrierMutation, InsertCarrierMutationVariables>(mutationOptions);
        
      return { ...mutation, carrier: mutation?.data?.insert_Carrier?.returning && mutation.data.insert_Carrier.returning[0] };
    }

    async function insertCarrierWithOnConflict({ apolloClient, carrier, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, carrier: Carrier_Insert_Input, onConflict: Carrier_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertCarrierWithOnConflictMutation, InsertCarrierWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertCarrierFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: carrier });
      const mutationOptions:MutationOptions<InsertCarrierWithOnConflictMutation, InsertCarrierWithOnConflictMutationVariables> = { mutation: InsertCarrierWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'carrier'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCarrierWithOnConflictMutation>({ operationType: 'insert', entityName:'Carrier', objects:[objectForInsert as Carrier_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertCarrierWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertCarrierFetchResult = await apolloClient.mutate<InsertCarrierWithOnConflictMutation, InsertCarrierWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, carrier: mutation?.data?.insert_Carrier?.returning && mutation.data.insert_Carrier.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertCarrierObjectsFetchResult = FetchResult<InsertCarrierMutation, Record<string, any>, Record<string, any>>;
    export type InsertCarrierObjectsHelperResultEx = InsertCarrierObjectsFetchResult & CarrierObjectsHelperResultEx;

    async function insertCarrierObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Carrier_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertCarrierMutation, InsertCarrierMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertCarrierObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertCarrierMutation, InsertCarrierMutationVariables> = { mutation: InsertCarrierDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCarrierMutation>({ operationType: 'insert', entityName:'Carrier', objects:objectsForInsert as (Carrier_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertCarrier - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertCarrierObjectsFetchResult = await apolloClient.mutate<InsertCarrierMutation, InsertCarrierMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Carrier?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateCarrierByIdQueryResult = FetchResult<UpdateCarrierByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateCarrierByIdHelperResultEx = UpdateCarrierByIdQueryResult & CarrierByIdHelperResultEx;

    async function updateCarrierById({ apolloClient, carrierId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, carrierId: string, set: Carrier_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateCarrierByIdMutation, UpdateCarrierByIdMutationVariables>, 'mutation'> }): Promise<UpdateCarrierByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateCarrierByIdMutation, UpdateCarrierByIdMutationVariables> = { mutation: UpdateCarrierByIdDocument, variables: { id:carrierId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCarrierByIdMutation>({ operationType: 'update', entityName:'Carrier', objects:[{ id:carrierId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateCarrierById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateCarrierByIdQueryResult = await apolloClient.mutate<UpdateCarrierByIdMutation, UpdateCarrierByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, carrier: mutation?.data?.update_Carrier?.returning && mutation.data.update_Carrier.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateCarrierObjectsFetchResult = FetchResult<UpdateCarrierMutation, Record<string, any>, Record<string, any>>;
    export type UpdateCarrierObjectsHelperResultEx = UpdateCarrierObjectsFetchResult & CarrierObjectsHelperResultEx;

    async function updateCarrierObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateCarrierMutation, UpdateCarrierMutationVariables>, 'mutation'> }): Promise<UpdateCarrierObjectsHelperResultEx> {  
      const mutation:UpdateCarrierObjectsFetchResult = await apolloClient.mutate<UpdateCarrierMutation, UpdateCarrierMutationVariables>({ mutation: UpdateCarrierDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Carrier?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveCarrierModelByIdQueryResult = FetchResult<RemoveCarrierModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveCarrierModelByIdQueryHelperResultEx = RemoveCarrierModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeCarrierModelById({ apolloClient, carrierId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, carrierId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveCarrierModelByIdMutation, RemoveCarrierModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveCarrierModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveCarrierModelByIdMutation, RemoveCarrierModelByIdMutationVariables> = { mutation: RemoveCarrierModelByIdDocument, variables: { id:carrierId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCarrierModelByIdMutation>({ operationType: 'delete', entityName:'carrier', objects:[{ id:carrierId }] }); 
        if(logLevel >= 2) console.log(' --> removeCarrierModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveCarrierModelByIdMutation>({ operationType: 'delete', entityName:'carrier', entityId:carrierId }); }
      
      const mutation:RemoveCarrierModelByIdQueryResult = await apolloClient.mutate<RemoveCarrierModelByIdMutation, RemoveCarrierModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Carrier?.affected_rows || 0 };
    }
  

    type RemoveCarrierModelObjectsQueryResult = FetchResult<RemoveCarrierModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveCarrierModelObjectsQueryHelperResultEx = RemoveCarrierModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeCarrierModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveCarrierModelMutation, RemoveCarrierModelMutationVariables>, 'mutation'> }): Promise<RemoveCarrierModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveCarrierModelObjectsQueryResult = await apolloClient.mutate<RemoveCarrierModelMutation, RemoveCarrierModelMutationVariables>({ mutation: RemoveCarrierModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Carrier?.affected_rows || 0 };
      }
  

    // Carrier Fragment Helper Object
    //------------------------------------------------

    export const CarrierFragmentGQLHelper = {
      queryById: queryCarrierById,
      queryObjects: queryCarrierObjects,
      watchQueryById: watchQueryCarrierById,
      watchQueryObjects: watchQueryCarrierObjects,
      insert: insertCarrier,
      insertWithOnConflict: insertCarrierWithOnConflict,
      insertObjects: insertCarrierObjects,
      updateById: updateCarrierById,
      updateObjects: updateCarrierObjects
    }
  

    // Carrier Entity Helper Object
    //------------------------------------------------

    export const CarrierModelGQLHelper = {
      removeById: removeCarrierModelById,
      removeObjects: removeCarrierModelObjects
    }
  

    // ClientCompany HELPERS
    //------------------------------------------------

    export type ClientCompanyByIdHelperResultEx = { clientCompany:ClientCompanyFragment | null | undefined };
    export type ClientCompanyObjectsHelperResultEx = { objects:ClientCompanyFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryClientCompanyByIdApolloQueryResult = ApolloQueryResult<QueryClientCompanyByIdQuery>;
      export type QueryClientCompanyByIdApolloQueryHelperResultEx = QueryClientCompanyByIdApolloQueryResult & ClientCompanyByIdHelperResultEx;

      async function queryClientCompanyById({ apolloClient, clientCompanyId, options }: { apolloClient: ApolloClient<object>, clientCompanyId: string, options?: Omit<QueryOptions<QueryClientCompanyByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryClientCompanyByIdApolloQueryHelperResultEx> {
        const query: QueryClientCompanyByIdApolloQueryResult = await apolloClient.query<QueryClientCompanyByIdQuery>({ query: QueryClientCompanyByIdDocument, variables: { clientCompanyId }, ...options });
        
        return { ...query, clientCompany: query?.data?.ClientCompany_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryClientCompanyByIdApolloObservableQuery = ObservableQuery<QueryClientCompanyByIdQuery>;
      async function watchQueryClientCompanyById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryClientCompanyByIdQueryVariables>, 'query'> }) : Promise<WatchQueryClientCompanyByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryClientCompanyByIdQuery>({ query: QueryClientCompanyByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryClientCompanyObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientCompanyObjectsQuery>;
      export type QueryClientCompanyObjectsObjectsApolloQueryResultEx = QueryClientCompanyObjectsObjectsApolloQueryResult & ClientCompanyObjectsHelperResultEx;

      async function queryClientCompanyObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryClientCompanyObjectsQueryVariables>, 'query'> }): Promise<QueryClientCompanyObjectsObjectsApolloQueryResultEx> {
        const query: QueryClientCompanyObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientCompanyObjectsQuery>({ query: QueryClientCompanyObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.ClientCompany || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryClientCompanyObjectsApolloObservableQuery = ObservableQuery<QueryClientCompanyObjectsQuery>;
      async function watchQueryClientCompanyObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryClientCompanyObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryClientCompanyObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryClientCompanyObjectsQuery>({ query: QueryClientCompanyObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertClientCompanyFetchResult = FetchResult<InsertClientCompanyMutation, Record<string, any>, Record<string, any>>;
    export type InsertClientCompanyFetchHelperResultEx = InsertClientCompanyFetchResult & ClientCompanyByIdHelperResultEx;

    async function insertClientCompany({ apolloClient, clientCompany, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, clientCompany: ClientCompany_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertClientCompanyMutation, InsertClientCompanyMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertClientCompanyFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: clientCompany });
      const mutationOptions:MutationOptions<InsertClientCompanyMutation, InsertClientCompanyMutationVariables> = { mutation: InsertClientCompanyDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientCompany'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientCompanyMutation>({ operationType: 'insert', entityName:'ClientCompany', objects:[objectForInsert as ClientCompany_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertClientCompany - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertClientCompanyFetchResult = await apolloClient.mutate<InsertClientCompanyMutation, InsertClientCompanyMutationVariables>(mutationOptions);
        
      return { ...mutation, clientCompany: mutation?.data?.insert_ClientCompany?.returning && mutation.data.insert_ClientCompany.returning[0] };
    }

    async function insertClientCompanyWithOnConflict({ apolloClient, clientCompany, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, clientCompany: ClientCompany_Insert_Input, onConflict: ClientCompany_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertClientCompanyWithOnConflictMutation, InsertClientCompanyWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertClientCompanyFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: clientCompany });
      const mutationOptions:MutationOptions<InsertClientCompanyWithOnConflictMutation, InsertClientCompanyWithOnConflictMutationVariables> = { mutation: InsertClientCompanyWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientCompany'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientCompanyWithOnConflictMutation>({ operationType: 'insert', entityName:'ClientCompany', objects:[objectForInsert as ClientCompany_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertClientCompanyWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertClientCompanyFetchResult = await apolloClient.mutate<InsertClientCompanyWithOnConflictMutation, InsertClientCompanyWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, clientCompany: mutation?.data?.insert_ClientCompany?.returning && mutation.data.insert_ClientCompany.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertClientCompanyObjectsFetchResult = FetchResult<InsertClientCompanyMutation, Record<string, any>, Record<string, any>>;
    export type InsertClientCompanyObjectsHelperResultEx = InsertClientCompanyObjectsFetchResult & ClientCompanyObjectsHelperResultEx;

    async function insertClientCompanyObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: ClientCompany_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertClientCompanyMutation, InsertClientCompanyMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertClientCompanyObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertClientCompanyMutation, InsertClientCompanyMutationVariables> = { mutation: InsertClientCompanyDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientCompanyMutation>({ operationType: 'insert', entityName:'ClientCompany', objects:objectsForInsert as (ClientCompany_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertClientCompany - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertClientCompanyObjectsFetchResult = await apolloClient.mutate<InsertClientCompanyMutation, InsertClientCompanyMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_ClientCompany?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateClientCompanyByIdQueryResult = FetchResult<UpdateClientCompanyByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateClientCompanyByIdHelperResultEx = UpdateClientCompanyByIdQueryResult & ClientCompanyByIdHelperResultEx;

    async function updateClientCompanyById({ apolloClient, clientCompanyId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, clientCompanyId: string, set: ClientCompany_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateClientCompanyByIdMutation, UpdateClientCompanyByIdMutationVariables>, 'mutation'> }): Promise<UpdateClientCompanyByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateClientCompanyByIdMutation, UpdateClientCompanyByIdMutationVariables> = { mutation: UpdateClientCompanyByIdDocument, variables: { id:clientCompanyId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientCompanyByIdMutation>({ operationType: 'update', entityName:'ClientCompany', objects:[{ id:clientCompanyId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateClientCompanyById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateClientCompanyByIdQueryResult = await apolloClient.mutate<UpdateClientCompanyByIdMutation, UpdateClientCompanyByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, clientCompany: mutation?.data?.update_ClientCompany?.returning && mutation.data.update_ClientCompany.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateClientCompanyObjectsFetchResult = FetchResult<UpdateClientCompanyMutation, Record<string, any>, Record<string, any>>;
    export type UpdateClientCompanyObjectsHelperResultEx = UpdateClientCompanyObjectsFetchResult & ClientCompanyObjectsHelperResultEx;

    async function updateClientCompanyObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateClientCompanyMutation, UpdateClientCompanyMutationVariables>, 'mutation'> }): Promise<UpdateClientCompanyObjectsHelperResultEx> {  
      const mutation:UpdateClientCompanyObjectsFetchResult = await apolloClient.mutate<UpdateClientCompanyMutation, UpdateClientCompanyMutationVariables>({ mutation: UpdateClientCompanyDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_ClientCompany?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveClientCompanyModelByIdQueryResult = FetchResult<RemoveClientCompanyModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveClientCompanyModelByIdQueryHelperResultEx = RemoveClientCompanyModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeClientCompanyModelById({ apolloClient, clientCompanyId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, clientCompanyId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveClientCompanyModelByIdMutation, RemoveClientCompanyModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveClientCompanyModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveClientCompanyModelByIdMutation, RemoveClientCompanyModelByIdMutationVariables> = { mutation: RemoveClientCompanyModelByIdDocument, variables: { id:clientCompanyId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientCompanyModelByIdMutation>({ operationType: 'delete', entityName:'clientCompany', objects:[{ id:clientCompanyId }] }); 
        if(logLevel >= 2) console.log(' --> removeClientCompanyModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientCompanyModelByIdMutation>({ operationType: 'delete', entityName:'clientCompany', entityId:clientCompanyId }); }
      
      const mutation:RemoveClientCompanyModelByIdQueryResult = await apolloClient.mutate<RemoveClientCompanyModelByIdMutation, RemoveClientCompanyModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_ClientCompany?.affected_rows || 0 };
    }
  

    type RemoveClientCompanyModelObjectsQueryResult = FetchResult<RemoveClientCompanyModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveClientCompanyModelObjectsQueryHelperResultEx = RemoveClientCompanyModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeClientCompanyModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveClientCompanyModelMutation, RemoveClientCompanyModelMutationVariables>, 'mutation'> }): Promise<RemoveClientCompanyModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveClientCompanyModelObjectsQueryResult = await apolloClient.mutate<RemoveClientCompanyModelMutation, RemoveClientCompanyModelMutationVariables>({ mutation: RemoveClientCompanyModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_ClientCompany?.affected_rows || 0 };
      }
  

    // ClientCompany Fragment Helper Object
    //------------------------------------------------

    export const ClientCompanyFragmentGQLHelper = {
      queryById: queryClientCompanyById,
      queryObjects: queryClientCompanyObjects,
      watchQueryById: watchQueryClientCompanyById,
      watchQueryObjects: watchQueryClientCompanyObjects,
      insert: insertClientCompany,
      insertWithOnConflict: insertClientCompanyWithOnConflict,
      insertObjects: insertClientCompanyObjects,
      updateById: updateClientCompanyById,
      updateObjects: updateClientCompanyObjects
    }
  

    // ClientCompany Entity Helper Object
    //------------------------------------------------

    export const ClientCompanyModelGQLHelper = {
      removeById: removeClientCompanyModelById,
      removeObjects: removeClientCompanyModelObjects
    }
  

    // Gateway HELPERS
    //------------------------------------------------

    export type GatewayByIdHelperResultEx = { gateway:GatewayFragment | null | undefined };
    export type GatewayObjectsHelperResultEx = { objects:GatewayFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryGatewayByIdApolloQueryResult = ApolloQueryResult<QueryGatewayByIdQuery>;
      export type QueryGatewayByIdApolloQueryHelperResultEx = QueryGatewayByIdApolloQueryResult & GatewayByIdHelperResultEx;

      async function queryGatewayById({ apolloClient, gatewayId, options }: { apolloClient: ApolloClient<object>, gatewayId: string, options?: Omit<QueryOptions<QueryGatewayByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryGatewayByIdApolloQueryHelperResultEx> {
        const query: QueryGatewayByIdApolloQueryResult = await apolloClient.query<QueryGatewayByIdQuery>({ query: QueryGatewayByIdDocument, variables: { gatewayId }, ...options });
        
        return { ...query, gateway: query?.data?.Gateway_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryGatewayByIdApolloObservableQuery = ObservableQuery<QueryGatewayByIdQuery>;
      async function watchQueryGatewayById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayByIdQueryVariables>, 'query'> }) : Promise<WatchQueryGatewayByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewayByIdQuery>({ query: QueryGatewayByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryGatewayObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryGatewayObjectsQuery>;
      export type QueryGatewayObjectsObjectsApolloQueryResultEx = QueryGatewayObjectsObjectsApolloQueryResult & GatewayObjectsHelperResultEx;

      async function queryGatewayObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayObjectsQueryVariables>, 'query'> }): Promise<QueryGatewayObjectsObjectsApolloQueryResultEx> {
        const query: QueryGatewayObjectsObjectsApolloQueryResult = await apolloClient.query<QueryGatewayObjectsQuery>({ query: QueryGatewayObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Gateway || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryGatewayObjectsApolloObservableQuery = ObservableQuery<QueryGatewayObjectsQuery>;
      async function watchQueryGatewayObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryGatewayObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewayObjectsQuery>({ query: QueryGatewayObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertGatewayFetchResult = FetchResult<InsertGatewayMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewayFetchHelperResultEx = InsertGatewayFetchResult & GatewayByIdHelperResultEx;

    async function insertGateway({ apolloClient, gateway, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gateway: Gateway_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayMutation, InsertGatewayMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gateway });
      const mutationOptions:MutationOptions<InsertGatewayMutation, InsertGatewayMutationVariables> = { mutation: InsertGatewayDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gateway'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayMutation>({ operationType: 'insert', entityName:'Gateway', objects:[objectForInsert as Gateway_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGateway - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewayFetchResult = await apolloClient.mutate<InsertGatewayMutation, InsertGatewayMutationVariables>(mutationOptions);
        
      return { ...mutation, gateway: mutation?.data?.insert_Gateway?.returning && mutation.data.insert_Gateway.returning[0] };
    }

    async function insertGatewayWithOnConflict({ apolloClient, gateway, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gateway: Gateway_Insert_Input, onConflict: Gateway_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayWithOnConflictMutation, InsertGatewayWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gateway });
      const mutationOptions:MutationOptions<InsertGatewayWithOnConflictMutation, InsertGatewayWithOnConflictMutationVariables> = { mutation: InsertGatewayWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gateway'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayWithOnConflictMutation>({ operationType: 'insert', entityName:'Gateway', objects:[objectForInsert as Gateway_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewayWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewayFetchResult = await apolloClient.mutate<InsertGatewayWithOnConflictMutation, InsertGatewayWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, gateway: mutation?.data?.insert_Gateway?.returning && mutation.data.insert_Gateway.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertGatewayObjectsFetchResult = FetchResult<InsertGatewayMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewayObjectsHelperResultEx = InsertGatewayObjectsFetchResult & GatewayObjectsHelperResultEx;

    async function insertGatewayObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Gateway_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayMutation, InsertGatewayMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertGatewayMutation, InsertGatewayMutationVariables> = { mutation: InsertGatewayDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayMutation>({ operationType: 'insert', entityName:'Gateway', objects:objectsForInsert as (Gateway_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGateway - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertGatewayObjectsFetchResult = await apolloClient.mutate<InsertGatewayMutation, InsertGatewayMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Gateway?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateGatewayByIdQueryResult = FetchResult<UpdateGatewayByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewayByIdHelperResultEx = UpdateGatewayByIdQueryResult & GatewayByIdHelperResultEx;

    async function updateGatewayById({ apolloClient, gatewayId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, gatewayId: string, set: Gateway_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateGatewayByIdMutation, UpdateGatewayByIdMutationVariables>, 'mutation'> }): Promise<UpdateGatewayByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateGatewayByIdMutation, UpdateGatewayByIdMutationVariables> = { mutation: UpdateGatewayByIdDocument, variables: { id:gatewayId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateGatewayByIdMutation>({ operationType: 'update', entityName:'Gateway', objects:[{ id:gatewayId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateGatewayById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateGatewayByIdQueryResult = await apolloClient.mutate<UpdateGatewayByIdMutation, UpdateGatewayByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, gateway: mutation?.data?.update_Gateway?.returning && mutation.data.update_Gateway.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateGatewayObjectsFetchResult = FetchResult<UpdateGatewayMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewayObjectsHelperResultEx = UpdateGatewayObjectsFetchResult & GatewayObjectsHelperResultEx;

    async function updateGatewayObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateGatewayMutation, UpdateGatewayMutationVariables>, 'mutation'> }): Promise<UpdateGatewayObjectsHelperResultEx> {  
      const mutation:UpdateGatewayObjectsFetchResult = await apolloClient.mutate<UpdateGatewayMutation, UpdateGatewayMutationVariables>({ mutation: UpdateGatewayDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Gateway?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveGatewayModelByIdQueryResult = FetchResult<RemoveGatewayModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewayModelByIdQueryHelperResultEx = RemoveGatewayModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeGatewayModelById({ apolloClient, gatewayId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, gatewayId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveGatewayModelByIdMutation, RemoveGatewayModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveGatewayModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveGatewayModelByIdMutation, RemoveGatewayModelByIdMutationVariables> = { mutation: RemoveGatewayModelByIdDocument, variables: { id:gatewayId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveGatewayModelByIdMutation>({ operationType: 'delete', entityName:'gateway', objects:[{ id:gatewayId }] }); 
        if(logLevel >= 2) console.log(' --> removeGatewayModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveGatewayModelByIdMutation>({ operationType: 'delete', entityName:'gateway', entityId:gatewayId }); }
      
      const mutation:RemoveGatewayModelByIdQueryResult = await apolloClient.mutate<RemoveGatewayModelByIdMutation, RemoveGatewayModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Gateway?.affected_rows || 0 };
    }
  

    type RemoveGatewayModelObjectsQueryResult = FetchResult<RemoveGatewayModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewayModelObjectsQueryHelperResultEx = RemoveGatewayModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeGatewayModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveGatewayModelMutation, RemoveGatewayModelMutationVariables>, 'mutation'> }): Promise<RemoveGatewayModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveGatewayModelObjectsQueryResult = await apolloClient.mutate<RemoveGatewayModelMutation, RemoveGatewayModelMutationVariables>({ mutation: RemoveGatewayModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Gateway?.affected_rows || 0 };
      }
  

    // Gateway Fragment Helper Object
    //------------------------------------------------

    export const GatewayFragmentGQLHelper = {
      queryById: queryGatewayById,
      queryObjects: queryGatewayObjects,
      watchQueryById: watchQueryGatewayById,
      watchQueryObjects: watchQueryGatewayObjects,
      insert: insertGateway,
      insertWithOnConflict: insertGatewayWithOnConflict,
      insertObjects: insertGatewayObjects,
      updateById: updateGatewayById,
      updateObjects: updateGatewayObjects
    }
  

    // Gateway Entity Helper Object
    //------------------------------------------------

    export const GatewayModelGQLHelper = {
      removeById: removeGatewayModelById,
      removeObjects: removeGatewayModelObjects
    }
  

    // Partner HELPERS
    //------------------------------------------------

    export type PartnerByIdHelperResultEx = { partner:PartnerFragment | null | undefined };
    export type PartnerObjectsHelperResultEx = { objects:PartnerFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryPartnerByIdApolloQueryResult = ApolloQueryResult<QueryPartnerByIdQuery>;
      export type QueryPartnerByIdApolloQueryHelperResultEx = QueryPartnerByIdApolloQueryResult & PartnerByIdHelperResultEx;

      async function queryPartnerById({ apolloClient, partnerId, options }: { apolloClient: ApolloClient<object>, partnerId: string, options?: Omit<QueryOptions<QueryPartnerByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryPartnerByIdApolloQueryHelperResultEx> {
        const query: QueryPartnerByIdApolloQueryResult = await apolloClient.query<QueryPartnerByIdQuery>({ query: QueryPartnerByIdDocument, variables: { partnerId }, ...options });
        
        return { ...query, partner: query?.data?.Partner_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryPartnerByIdApolloObservableQuery = ObservableQuery<QueryPartnerByIdQuery>;
      async function watchQueryPartnerById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPartnerByIdQueryVariables>, 'query'> }) : Promise<WatchQueryPartnerByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryPartnerByIdQuery>({ query: QueryPartnerByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryPartnerObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryPartnerObjectsQuery>;
      export type QueryPartnerObjectsObjectsApolloQueryResultEx = QueryPartnerObjectsObjectsApolloQueryResult & PartnerObjectsHelperResultEx;

      async function queryPartnerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPartnerObjectsQueryVariables>, 'query'> }): Promise<QueryPartnerObjectsObjectsApolloQueryResultEx> {
        const query: QueryPartnerObjectsObjectsApolloQueryResult = await apolloClient.query<QueryPartnerObjectsQuery>({ query: QueryPartnerObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Partner || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryPartnerObjectsApolloObservableQuery = ObservableQuery<QueryPartnerObjectsQuery>;
      async function watchQueryPartnerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPartnerObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryPartnerObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryPartnerObjectsQuery>({ query: QueryPartnerObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertPartnerFetchResult = FetchResult<InsertPartnerMutation, Record<string, any>, Record<string, any>>;
    export type InsertPartnerFetchHelperResultEx = InsertPartnerFetchResult & PartnerByIdHelperResultEx;

    async function insertPartner({ apolloClient, partner, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, partner: Partner_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPartnerFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: partner });
      const mutationOptions:MutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables> = { mutation: InsertPartnerDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'partner'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPartnerMutation>({ operationType: 'insert', entityName:'Partner', objects:[objectForInsert as Partner_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPartner - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertPartnerFetchResult = await apolloClient.mutate<InsertPartnerMutation, InsertPartnerMutationVariables>(mutationOptions);
        
      return { ...mutation, partner: mutation?.data?.insert_Partner?.returning && mutation.data.insert_Partner.returning[0] };
    }

    async function insertPartnerWithOnConflict({ apolloClient, partner, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, partner: Partner_Insert_Input, onConflict: Partner_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPartnerWithOnConflictMutation, InsertPartnerWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPartnerFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: partner });
      const mutationOptions:MutationOptions<InsertPartnerWithOnConflictMutation, InsertPartnerWithOnConflictMutationVariables> = { mutation: InsertPartnerWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'partner'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPartnerWithOnConflictMutation>({ operationType: 'insert', entityName:'Partner', objects:[objectForInsert as Partner_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPartnerWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertPartnerFetchResult = await apolloClient.mutate<InsertPartnerWithOnConflictMutation, InsertPartnerWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, partner: mutation?.data?.insert_Partner?.returning && mutation.data.insert_Partner.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertPartnerObjectsFetchResult = FetchResult<InsertPartnerMutation, Record<string, any>, Record<string, any>>;
    export type InsertPartnerObjectsHelperResultEx = InsertPartnerObjectsFetchResult & PartnerObjectsHelperResultEx;

    async function insertPartnerObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Partner_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPartnerObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables> = { mutation: InsertPartnerDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPartnerMutation>({ operationType: 'insert', entityName:'Partner', objects:objectsForInsert as (Partner_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPartner - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertPartnerObjectsFetchResult = await apolloClient.mutate<InsertPartnerMutation, InsertPartnerMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Partner?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdatePartnerByIdQueryResult = FetchResult<UpdatePartnerByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdatePartnerByIdHelperResultEx = UpdatePartnerByIdQueryResult & PartnerByIdHelperResultEx;

    async function updatePartnerById({ apolloClient, partnerId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, partnerId: string, set: Partner_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdatePartnerByIdMutation, UpdatePartnerByIdMutationVariables>, 'mutation'> }): Promise<UpdatePartnerByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdatePartnerByIdMutation, UpdatePartnerByIdMutationVariables> = { mutation: UpdatePartnerByIdDocument, variables: { id:partnerId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdatePartnerByIdMutation>({ operationType: 'update', entityName:'Partner', objects:[{ id:partnerId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updatePartnerById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdatePartnerByIdQueryResult = await apolloClient.mutate<UpdatePartnerByIdMutation, UpdatePartnerByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, partner: mutation?.data?.update_Partner?.returning && mutation.data.update_Partner.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdatePartnerObjectsFetchResult = FetchResult<UpdatePartnerMutation, Record<string, any>, Record<string, any>>;
    export type UpdatePartnerObjectsHelperResultEx = UpdatePartnerObjectsFetchResult & PartnerObjectsHelperResultEx;

    async function updatePartnerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>, 'mutation'> }): Promise<UpdatePartnerObjectsHelperResultEx> {  
      const mutation:UpdatePartnerObjectsFetchResult = await apolloClient.mutate<UpdatePartnerMutation, UpdatePartnerMutationVariables>({ mutation: UpdatePartnerDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Partner?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemovePartnerModelByIdQueryResult = FetchResult<RemovePartnerModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemovePartnerModelByIdQueryHelperResultEx = RemovePartnerModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removePartnerModelById({ apolloClient, partnerId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, partnerId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemovePartnerModelByIdMutation, RemovePartnerModelByIdMutationVariables>, 'mutation'> }): Promise<RemovePartnerModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemovePartnerModelByIdMutation, RemovePartnerModelByIdMutationVariables> = { mutation: RemovePartnerModelByIdDocument, variables: { id:partnerId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemovePartnerModelByIdMutation>({ operationType: 'delete', entityName:'partner', objects:[{ id:partnerId }] }); 
        if(logLevel >= 2) console.log(' --> removePartnerModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemovePartnerModelByIdMutation>({ operationType: 'delete', entityName:'partner', entityId:partnerId }); }
      
      const mutation:RemovePartnerModelByIdQueryResult = await apolloClient.mutate<RemovePartnerModelByIdMutation, RemovePartnerModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Partner?.affected_rows || 0 };
    }
  

    type RemovePartnerModelObjectsQueryResult = FetchResult<RemovePartnerModelMutation, Record<string, any>, Record<string, any>>;
    export type RemovePartnerModelObjectsQueryHelperResultEx = RemovePartnerModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removePartnerModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemovePartnerModelMutation, RemovePartnerModelMutationVariables>, 'mutation'> }): Promise<RemovePartnerModelObjectsQueryHelperResultEx> {  
        const mutation:RemovePartnerModelObjectsQueryResult = await apolloClient.mutate<RemovePartnerModelMutation, RemovePartnerModelMutationVariables>({ mutation: RemovePartnerModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Partner?.affected_rows || 0 };
      }
  

    // Partner Fragment Helper Object
    //------------------------------------------------

    export const PartnerFragmentGQLHelper = {
      queryById: queryPartnerById,
      queryObjects: queryPartnerObjects,
      watchQueryById: watchQueryPartnerById,
      watchQueryObjects: watchQueryPartnerObjects,
      insert: insertPartner,
      insertWithOnConflict: insertPartnerWithOnConflict,
      insertObjects: insertPartnerObjects,
      updateById: updatePartnerById,
      updateObjects: updatePartnerObjects
    }
  

    // Partner Entity Helper Object
    //------------------------------------------------

    export const PartnerModelGQLHelper = {
      removeById: removePartnerModelById,
      removeObjects: removePartnerModelObjects
    }
  

    // Quicklink HELPERS
    //------------------------------------------------

    export type QuicklinkByIdHelperResultEx = { quicklink:QuicklinkFragment | null | undefined };
    export type QuicklinkObjectsHelperResultEx = { objects:QuicklinkFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryQuicklinkByIdApolloQueryResult = ApolloQueryResult<QueryQuicklinkByIdQuery>;
      export type QueryQuicklinkByIdApolloQueryHelperResultEx = QueryQuicklinkByIdApolloQueryResult & QuicklinkByIdHelperResultEx;

      async function queryQuicklinkById({ apolloClient, quicklinkId, options }: { apolloClient: ApolloClient<object>, quicklinkId: string, options?: Omit<QueryOptions<QueryQuicklinkByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryQuicklinkByIdApolloQueryHelperResultEx> {
        const query: QueryQuicklinkByIdApolloQueryResult = await apolloClient.query<QueryQuicklinkByIdQuery>({ query: QueryQuicklinkByIdDocument, variables: { quicklinkId }, ...options });
        
        return { ...query, quicklink: query?.data?.Quicklink_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryQuicklinkByIdApolloObservableQuery = ObservableQuery<QueryQuicklinkByIdQuery>;
      async function watchQueryQuicklinkById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryQuicklinkByIdQueryVariables>, 'query'> }) : Promise<WatchQueryQuicklinkByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryQuicklinkByIdQuery>({ query: QueryQuicklinkByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryQuicklinkObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryQuicklinkObjectsQuery>;
      export type QueryQuicklinkObjectsObjectsApolloQueryResultEx = QueryQuicklinkObjectsObjectsApolloQueryResult & QuicklinkObjectsHelperResultEx;

      async function queryQuicklinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryQuicklinkObjectsQueryVariables>, 'query'> }): Promise<QueryQuicklinkObjectsObjectsApolloQueryResultEx> {
        const query: QueryQuicklinkObjectsObjectsApolloQueryResult = await apolloClient.query<QueryQuicklinkObjectsQuery>({ query: QueryQuicklinkObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Quicklink || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryQuicklinkObjectsApolloObservableQuery = ObservableQuery<QueryQuicklinkObjectsQuery>;
      async function watchQueryQuicklinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryQuicklinkObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryQuicklinkObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryQuicklinkObjectsQuery>({ query: QueryQuicklinkObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertQuicklinkFetchResult = FetchResult<InsertQuicklinkMutation, Record<string, any>, Record<string, any>>;
    export type InsertQuicklinkFetchHelperResultEx = InsertQuicklinkFetchResult & QuicklinkByIdHelperResultEx;

    async function insertQuicklink({ apolloClient, quicklink, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, quicklink: Quicklink_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertQuicklinkMutation, InsertQuicklinkMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertQuicklinkFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: quicklink });
      const mutationOptions:MutationOptions<InsertQuicklinkMutation, InsertQuicklinkMutationVariables> = { mutation: InsertQuicklinkDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'quicklink'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQuicklinkMutation>({ operationType: 'insert', entityName:'Quicklink', objects:[objectForInsert as Quicklink_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertQuicklink - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertQuicklinkFetchResult = await apolloClient.mutate<InsertQuicklinkMutation, InsertQuicklinkMutationVariables>(mutationOptions);
        
      return { ...mutation, quicklink: mutation?.data?.insert_Quicklink?.returning && mutation.data.insert_Quicklink.returning[0] };
    }

    async function insertQuicklinkWithOnConflict({ apolloClient, quicklink, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, quicklink: Quicklink_Insert_Input, onConflict: Quicklink_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertQuicklinkWithOnConflictMutation, InsertQuicklinkWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertQuicklinkFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: quicklink });
      const mutationOptions:MutationOptions<InsertQuicklinkWithOnConflictMutation, InsertQuicklinkWithOnConflictMutationVariables> = { mutation: InsertQuicklinkWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'quicklink'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQuicklinkWithOnConflictMutation>({ operationType: 'insert', entityName:'Quicklink', objects:[objectForInsert as Quicklink_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertQuicklinkWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertQuicklinkFetchResult = await apolloClient.mutate<InsertQuicklinkWithOnConflictMutation, InsertQuicklinkWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, quicklink: mutation?.data?.insert_Quicklink?.returning && mutation.data.insert_Quicklink.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertQuicklinkObjectsFetchResult = FetchResult<InsertQuicklinkMutation, Record<string, any>, Record<string, any>>;
    export type InsertQuicklinkObjectsHelperResultEx = InsertQuicklinkObjectsFetchResult & QuicklinkObjectsHelperResultEx;

    async function insertQuicklinkObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Quicklink_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertQuicklinkMutation, InsertQuicklinkMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertQuicklinkObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertQuicklinkMutation, InsertQuicklinkMutationVariables> = { mutation: InsertQuicklinkDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQuicklinkMutation>({ operationType: 'insert', entityName:'Quicklink', objects:objectsForInsert as (Quicklink_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertQuicklink - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertQuicklinkObjectsFetchResult = await apolloClient.mutate<InsertQuicklinkMutation, InsertQuicklinkMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Quicklink?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateQuicklinkByIdQueryResult = FetchResult<UpdateQuicklinkByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateQuicklinkByIdHelperResultEx = UpdateQuicklinkByIdQueryResult & QuicklinkByIdHelperResultEx;

    async function updateQuicklinkById({ apolloClient, quicklinkId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, quicklinkId: string, set: Quicklink_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateQuicklinkByIdMutation, UpdateQuicklinkByIdMutationVariables>, 'mutation'> }): Promise<UpdateQuicklinkByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateQuicklinkByIdMutation, UpdateQuicklinkByIdMutationVariables> = { mutation: UpdateQuicklinkByIdDocument, variables: { id:quicklinkId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQuicklinkByIdMutation>({ operationType: 'update', entityName:'Quicklink', objects:[{ id:quicklinkId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateQuicklinkById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateQuicklinkByIdQueryResult = await apolloClient.mutate<UpdateQuicklinkByIdMutation, UpdateQuicklinkByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, quicklink: mutation?.data?.update_Quicklink?.returning && mutation.data.update_Quicklink.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateQuicklinkObjectsFetchResult = FetchResult<UpdateQuicklinkMutation, Record<string, any>, Record<string, any>>;
    export type UpdateQuicklinkObjectsHelperResultEx = UpdateQuicklinkObjectsFetchResult & QuicklinkObjectsHelperResultEx;

    async function updateQuicklinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateQuicklinkMutation, UpdateQuicklinkMutationVariables>, 'mutation'> }): Promise<UpdateQuicklinkObjectsHelperResultEx> {  
      const mutation:UpdateQuicklinkObjectsFetchResult = await apolloClient.mutate<UpdateQuicklinkMutation, UpdateQuicklinkMutationVariables>({ mutation: UpdateQuicklinkDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Quicklink?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveQuicklinkModelByIdQueryResult = FetchResult<RemoveQuicklinkModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveQuicklinkModelByIdQueryHelperResultEx = RemoveQuicklinkModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeQuicklinkModelById({ apolloClient, quicklinkId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, quicklinkId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveQuicklinkModelByIdMutation, RemoveQuicklinkModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveQuicklinkModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveQuicklinkModelByIdMutation, RemoveQuicklinkModelByIdMutationVariables> = { mutation: RemoveQuicklinkModelByIdDocument, variables: { id:quicklinkId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQuicklinkModelByIdMutation>({ operationType: 'delete', entityName:'quicklink', objects:[{ id:quicklinkId }] }); 
        if(logLevel >= 2) console.log(' --> removeQuicklinkModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveQuicklinkModelByIdMutation>({ operationType: 'delete', entityName:'quicklink', entityId:quicklinkId }); }
      
      const mutation:RemoveQuicklinkModelByIdQueryResult = await apolloClient.mutate<RemoveQuicklinkModelByIdMutation, RemoveQuicklinkModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Quicklink?.affected_rows || 0 };
    }
  

    type RemoveQuicklinkModelObjectsQueryResult = FetchResult<RemoveQuicklinkModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveQuicklinkModelObjectsQueryHelperResultEx = RemoveQuicklinkModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeQuicklinkModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveQuicklinkModelMutation, RemoveQuicklinkModelMutationVariables>, 'mutation'> }): Promise<RemoveQuicklinkModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveQuicklinkModelObjectsQueryResult = await apolloClient.mutate<RemoveQuicklinkModelMutation, RemoveQuicklinkModelMutationVariables>({ mutation: RemoveQuicklinkModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Quicklink?.affected_rows || 0 };
      }
  

    // Quicklink Fragment Helper Object
    //------------------------------------------------

    export const QuicklinkFragmentGQLHelper = {
      queryById: queryQuicklinkById,
      queryObjects: queryQuicklinkObjects,
      watchQueryById: watchQueryQuicklinkById,
      watchQueryObjects: watchQueryQuicklinkObjects,
      insert: insertQuicklink,
      insertWithOnConflict: insertQuicklinkWithOnConflict,
      insertObjects: insertQuicklinkObjects,
      updateById: updateQuicklinkById,
      updateObjects: updateQuicklinkObjects
    }
  

    // Quicklink Entity Helper Object
    //------------------------------------------------

    export const QuicklinkModelGQLHelper = {
      removeById: removeQuicklinkModelById,
      removeObjects: removeQuicklinkModelObjects
    }
  

    // Firmware HELPERS
    //------------------------------------------------

    export type FirmwareByIdHelperResultEx = { firmware:FirmwareFragment | null | undefined };
    export type FirmwareObjectsHelperResultEx = { objects:FirmwareFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryFirmwareByIdApolloQueryResult = ApolloQueryResult<QueryFirmwareByIdQuery>;
      export type QueryFirmwareByIdApolloQueryHelperResultEx = QueryFirmwareByIdApolloQueryResult & FirmwareByIdHelperResultEx;

      async function queryFirmwareById({ apolloClient, firmwareId, options }: { apolloClient: ApolloClient<object>, firmwareId: string, options?: Omit<QueryOptions<QueryFirmwareByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryFirmwareByIdApolloQueryHelperResultEx> {
        const query: QueryFirmwareByIdApolloQueryResult = await apolloClient.query<QueryFirmwareByIdQuery>({ query: QueryFirmwareByIdDocument, variables: { firmwareId }, ...options });
        
        return { ...query, firmware: query?.data?.Firmware_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryFirmwareByIdApolloObservableQuery = ObservableQuery<QueryFirmwareByIdQuery>;
      async function watchQueryFirmwareById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryFirmwareByIdQueryVariables>, 'query'> }) : Promise<WatchQueryFirmwareByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryFirmwareByIdQuery>({ query: QueryFirmwareByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryFirmwareObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryFirmwareObjectsQuery>;
      export type QueryFirmwareObjectsObjectsApolloQueryResultEx = QueryFirmwareObjectsObjectsApolloQueryResult & FirmwareObjectsHelperResultEx;

      async function queryFirmwareObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryFirmwareObjectsQueryVariables>, 'query'> }): Promise<QueryFirmwareObjectsObjectsApolloQueryResultEx> {
        const query: QueryFirmwareObjectsObjectsApolloQueryResult = await apolloClient.query<QueryFirmwareObjectsQuery>({ query: QueryFirmwareObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Firmware || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryFirmwareObjectsApolloObservableQuery = ObservableQuery<QueryFirmwareObjectsQuery>;
      async function watchQueryFirmwareObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryFirmwareObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryFirmwareObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryFirmwareObjectsQuery>({ query: QueryFirmwareObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertFirmwareFetchResult = FetchResult<InsertFirmwareMutation, Record<string, any>, Record<string, any>>;
    export type InsertFirmwareFetchHelperResultEx = InsertFirmwareFetchResult & FirmwareByIdHelperResultEx;

    async function insertFirmware({ apolloClient, firmware, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, firmware: Firmware_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertFirmwareMutation, InsertFirmwareMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertFirmwareFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: firmware });
      const mutationOptions:MutationOptions<InsertFirmwareMutation, InsertFirmwareMutationVariables> = { mutation: InsertFirmwareDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'firmware'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertFirmwareMutation>({ operationType: 'insert', entityName:'Firmware', objects:[objectForInsert as Firmware_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertFirmware - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertFirmwareFetchResult = await apolloClient.mutate<InsertFirmwareMutation, InsertFirmwareMutationVariables>(mutationOptions);
        
      return { ...mutation, firmware: mutation?.data?.insert_Firmware?.returning && mutation.data.insert_Firmware.returning[0] };
    }

    async function insertFirmwareWithOnConflict({ apolloClient, firmware, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, firmware: Firmware_Insert_Input, onConflict: Firmware_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertFirmwareWithOnConflictMutation, InsertFirmwareWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertFirmwareFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: firmware });
      const mutationOptions:MutationOptions<InsertFirmwareWithOnConflictMutation, InsertFirmwareWithOnConflictMutationVariables> = { mutation: InsertFirmwareWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'firmware'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertFirmwareWithOnConflictMutation>({ operationType: 'insert', entityName:'Firmware', objects:[objectForInsert as Firmware_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertFirmwareWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertFirmwareFetchResult = await apolloClient.mutate<InsertFirmwareWithOnConflictMutation, InsertFirmwareWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, firmware: mutation?.data?.insert_Firmware?.returning && mutation.data.insert_Firmware.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertFirmwareObjectsFetchResult = FetchResult<InsertFirmwareMutation, Record<string, any>, Record<string, any>>;
    export type InsertFirmwareObjectsHelperResultEx = InsertFirmwareObjectsFetchResult & FirmwareObjectsHelperResultEx;

    async function insertFirmwareObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Firmware_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertFirmwareMutation, InsertFirmwareMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertFirmwareObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertFirmwareMutation, InsertFirmwareMutationVariables> = { mutation: InsertFirmwareDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertFirmwareMutation>({ operationType: 'insert', entityName:'Firmware', objects:objectsForInsert as (Firmware_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertFirmware - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertFirmwareObjectsFetchResult = await apolloClient.mutate<InsertFirmwareMutation, InsertFirmwareMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Firmware?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateFirmwareByIdQueryResult = FetchResult<UpdateFirmwareByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateFirmwareByIdHelperResultEx = UpdateFirmwareByIdQueryResult & FirmwareByIdHelperResultEx;

    async function updateFirmwareById({ apolloClient, firmwareId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, firmwareId: string, set: Firmware_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateFirmwareByIdMutation, UpdateFirmwareByIdMutationVariables>, 'mutation'> }): Promise<UpdateFirmwareByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateFirmwareByIdMutation, UpdateFirmwareByIdMutationVariables> = { mutation: UpdateFirmwareByIdDocument, variables: { id:firmwareId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateFirmwareByIdMutation>({ operationType: 'update', entityName:'Firmware', objects:[{ id:firmwareId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateFirmwareById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateFirmwareByIdQueryResult = await apolloClient.mutate<UpdateFirmwareByIdMutation, UpdateFirmwareByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, firmware: mutation?.data?.update_Firmware?.returning && mutation.data.update_Firmware.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateFirmwareObjectsFetchResult = FetchResult<UpdateFirmwareMutation, Record<string, any>, Record<string, any>>;
    export type UpdateFirmwareObjectsHelperResultEx = UpdateFirmwareObjectsFetchResult & FirmwareObjectsHelperResultEx;

    async function updateFirmwareObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateFirmwareMutation, UpdateFirmwareMutationVariables>, 'mutation'> }): Promise<UpdateFirmwareObjectsHelperResultEx> {  
      const mutation:UpdateFirmwareObjectsFetchResult = await apolloClient.mutate<UpdateFirmwareMutation, UpdateFirmwareMutationVariables>({ mutation: UpdateFirmwareDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Firmware?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveFirmwareModelByIdQueryResult = FetchResult<RemoveFirmwareModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveFirmwareModelByIdQueryHelperResultEx = RemoveFirmwareModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeFirmwareModelById({ apolloClient, firmwareId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, firmwareId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveFirmwareModelByIdMutation, RemoveFirmwareModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveFirmwareModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveFirmwareModelByIdMutation, RemoveFirmwareModelByIdMutationVariables> = { mutation: RemoveFirmwareModelByIdDocument, variables: { id:firmwareId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveFirmwareModelByIdMutation>({ operationType: 'delete', entityName:'firmware', objects:[{ id:firmwareId }] }); 
        if(logLevel >= 2) console.log(' --> removeFirmwareModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveFirmwareModelByIdMutation>({ operationType: 'delete', entityName:'firmware', entityId:firmwareId }); }
      
      const mutation:RemoveFirmwareModelByIdQueryResult = await apolloClient.mutate<RemoveFirmwareModelByIdMutation, RemoveFirmwareModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Firmware?.affected_rows || 0 };
    }
  

    type RemoveFirmwareModelObjectsQueryResult = FetchResult<RemoveFirmwareModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveFirmwareModelObjectsQueryHelperResultEx = RemoveFirmwareModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeFirmwareModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveFirmwareModelMutation, RemoveFirmwareModelMutationVariables>, 'mutation'> }): Promise<RemoveFirmwareModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveFirmwareModelObjectsQueryResult = await apolloClient.mutate<RemoveFirmwareModelMutation, RemoveFirmwareModelMutationVariables>({ mutation: RemoveFirmwareModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Firmware?.affected_rows || 0 };
      }
  

    // Firmware Fragment Helper Object
    //------------------------------------------------

    export const FirmwareFragmentGQLHelper = {
      queryById: queryFirmwareById,
      queryObjects: queryFirmwareObjects,
      watchQueryById: watchQueryFirmwareById,
      watchQueryObjects: watchQueryFirmwareObjects,
      insert: insertFirmware,
      insertWithOnConflict: insertFirmwareWithOnConflict,
      insertObjects: insertFirmwareObjects,
      updateById: updateFirmwareById,
      updateObjects: updateFirmwareObjects
    }
  

    // Firmware Entity Helper Object
    //------------------------------------------------

    export const FirmwareModelGQLHelper = {
      removeById: removeFirmwareModelById,
      removeObjects: removeFirmwareModelObjects
    }
  

    // GatewayBridge HELPERS
    //------------------------------------------------

    export type GatewayBridgeByIdHelperResultEx = { gatewayBridge:GatewayBridgeFragment | null | undefined };
    export type GatewayBridgeObjectsHelperResultEx = { objects:GatewayBridgeFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryGatewayBridgeByIdApolloQueryResult = ApolloQueryResult<QueryGatewayBridgeByIdQuery>;
      export type QueryGatewayBridgeByIdApolloQueryHelperResultEx = QueryGatewayBridgeByIdApolloQueryResult & GatewayBridgeByIdHelperResultEx;

      async function queryGatewayBridgeById({ apolloClient, gatewayBridgeId, options }: { apolloClient: ApolloClient<object>, gatewayBridgeId: string, options?: Omit<QueryOptions<QueryGatewayBridgeByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryGatewayBridgeByIdApolloQueryHelperResultEx> {
        const query: QueryGatewayBridgeByIdApolloQueryResult = await apolloClient.query<QueryGatewayBridgeByIdQuery>({ query: QueryGatewayBridgeByIdDocument, variables: { gatewayBridgeId }, ...options });
        
        return { ...query, gatewayBridge: query?.data?.GatewayBridge_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryGatewayBridgeByIdApolloObservableQuery = ObservableQuery<QueryGatewayBridgeByIdQuery>;
      async function watchQueryGatewayBridgeById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayBridgeByIdQueryVariables>, 'query'> }) : Promise<WatchQueryGatewayBridgeByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewayBridgeByIdQuery>({ query: QueryGatewayBridgeByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryGatewayBridgeObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryGatewayBridgeObjectsQuery>;
      export type QueryGatewayBridgeObjectsObjectsApolloQueryResultEx = QueryGatewayBridgeObjectsObjectsApolloQueryResult & GatewayBridgeObjectsHelperResultEx;

      async function queryGatewayBridgeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayBridgeObjectsQueryVariables>, 'query'> }): Promise<QueryGatewayBridgeObjectsObjectsApolloQueryResultEx> {
        const query: QueryGatewayBridgeObjectsObjectsApolloQueryResult = await apolloClient.query<QueryGatewayBridgeObjectsQuery>({ query: QueryGatewayBridgeObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.GatewayBridge || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryGatewayBridgeObjectsApolloObservableQuery = ObservableQuery<QueryGatewayBridgeObjectsQuery>;
      async function watchQueryGatewayBridgeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewayBridgeObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryGatewayBridgeObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewayBridgeObjectsQuery>({ query: QueryGatewayBridgeObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertGatewayBridgeFetchResult = FetchResult<InsertGatewayBridgeMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewayBridgeFetchHelperResultEx = InsertGatewayBridgeFetchResult & GatewayBridgeByIdHelperResultEx;

    async function insertGatewayBridge({ apolloClient, gatewayBridge, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gatewayBridge: GatewayBridge_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayBridgeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gatewayBridge });
      const mutationOptions:MutationOptions<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables> = { mutation: InsertGatewayBridgeDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gatewayBridge'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayBridgeMutation>({ operationType: 'insert', entityName:'GatewayBridge', objects:[objectForInsert as GatewayBridge_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewayBridge - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewayBridgeFetchResult = await apolloClient.mutate<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewayBridge: mutation?.data?.insert_GatewayBridge?.returning && mutation.data.insert_GatewayBridge.returning[0] };
    }

    async function insertGatewayBridgeWithOnConflict({ apolloClient, gatewayBridge, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gatewayBridge: GatewayBridge_Insert_Input, onConflict: GatewayBridge_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayBridgeWithOnConflictMutation, InsertGatewayBridgeWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayBridgeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gatewayBridge });
      const mutationOptions:MutationOptions<InsertGatewayBridgeWithOnConflictMutation, InsertGatewayBridgeWithOnConflictMutationVariables> = { mutation: InsertGatewayBridgeWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gatewayBridge'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayBridgeWithOnConflictMutation>({ operationType: 'insert', entityName:'GatewayBridge', objects:[objectForInsert as GatewayBridge_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewayBridgeWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewayBridgeFetchResult = await apolloClient.mutate<InsertGatewayBridgeWithOnConflictMutation, InsertGatewayBridgeWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewayBridge: mutation?.data?.insert_GatewayBridge?.returning && mutation.data.insert_GatewayBridge.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertGatewayBridgeObjectsFetchResult = FetchResult<InsertGatewayBridgeMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewayBridgeObjectsHelperResultEx = InsertGatewayBridgeObjectsFetchResult & GatewayBridgeObjectsHelperResultEx;

    async function insertGatewayBridgeObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: GatewayBridge_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewayBridgeObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables> = { mutation: InsertGatewayBridgeDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewayBridgeMutation>({ operationType: 'insert', entityName:'GatewayBridge', objects:objectsForInsert as (GatewayBridge_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewayBridge - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertGatewayBridgeObjectsFetchResult = await apolloClient.mutate<InsertGatewayBridgeMutation, InsertGatewayBridgeMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_GatewayBridge?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateGatewayBridgeByIdQueryResult = FetchResult<UpdateGatewayBridgeByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewayBridgeByIdHelperResultEx = UpdateGatewayBridgeByIdQueryResult & GatewayBridgeByIdHelperResultEx;

    async function updateGatewayBridgeById({ apolloClient, gatewayBridgeId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, gatewayBridgeId: string, set: GatewayBridge_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateGatewayBridgeByIdMutation, UpdateGatewayBridgeByIdMutationVariables>, 'mutation'> }): Promise<UpdateGatewayBridgeByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateGatewayBridgeByIdMutation, UpdateGatewayBridgeByIdMutationVariables> = { mutation: UpdateGatewayBridgeByIdDocument, variables: { id:gatewayBridgeId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateGatewayBridgeByIdMutation>({ operationType: 'update', entityName:'GatewayBridge', objects:[{ id:gatewayBridgeId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateGatewayBridgeById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateGatewayBridgeByIdQueryResult = await apolloClient.mutate<UpdateGatewayBridgeByIdMutation, UpdateGatewayBridgeByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewayBridge: mutation?.data?.update_GatewayBridge?.returning && mutation.data.update_GatewayBridge.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateGatewayBridgeObjectsFetchResult = FetchResult<UpdateGatewayBridgeMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewayBridgeObjectsHelperResultEx = UpdateGatewayBridgeObjectsFetchResult & GatewayBridgeObjectsHelperResultEx;

    async function updateGatewayBridgeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateGatewayBridgeMutation, UpdateGatewayBridgeMutationVariables>, 'mutation'> }): Promise<UpdateGatewayBridgeObjectsHelperResultEx> {  
      const mutation:UpdateGatewayBridgeObjectsFetchResult = await apolloClient.mutate<UpdateGatewayBridgeMutation, UpdateGatewayBridgeMutationVariables>({ mutation: UpdateGatewayBridgeDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_GatewayBridge?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveGatewayBridgeModelByIdQueryResult = FetchResult<RemoveGatewayBridgeModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewayBridgeModelByIdQueryHelperResultEx = RemoveGatewayBridgeModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeGatewayBridgeModelById({ apolloClient, gatewayBridgeId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, gatewayBridgeId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveGatewayBridgeModelByIdMutation, RemoveGatewayBridgeModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveGatewayBridgeModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveGatewayBridgeModelByIdMutation, RemoveGatewayBridgeModelByIdMutationVariables> = { mutation: RemoveGatewayBridgeModelByIdDocument, variables: { id:gatewayBridgeId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveGatewayBridgeModelByIdMutation>({ operationType: 'delete', entityName:'gatewayBridge', objects:[{ id:gatewayBridgeId }] }); 
        if(logLevel >= 2) console.log(' --> removeGatewayBridgeModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveGatewayBridgeModelByIdMutation>({ operationType: 'delete', entityName:'gatewayBridge', entityId:gatewayBridgeId }); }
      
      const mutation:RemoveGatewayBridgeModelByIdQueryResult = await apolloClient.mutate<RemoveGatewayBridgeModelByIdMutation, RemoveGatewayBridgeModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_GatewayBridge?.affected_rows || 0 };
    }
  

    type RemoveGatewayBridgeModelObjectsQueryResult = FetchResult<RemoveGatewayBridgeModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewayBridgeModelObjectsQueryHelperResultEx = RemoveGatewayBridgeModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeGatewayBridgeModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveGatewayBridgeModelMutation, RemoveGatewayBridgeModelMutationVariables>, 'mutation'> }): Promise<RemoveGatewayBridgeModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveGatewayBridgeModelObjectsQueryResult = await apolloClient.mutate<RemoveGatewayBridgeModelMutation, RemoveGatewayBridgeModelMutationVariables>({ mutation: RemoveGatewayBridgeModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_GatewayBridge?.affected_rows || 0 };
      }
  

    // GatewayBridge Fragment Helper Object
    //------------------------------------------------

    export const GatewayBridgeFragmentGQLHelper = {
      queryById: queryGatewayBridgeById,
      queryObjects: queryGatewayBridgeObjects,
      watchQueryById: watchQueryGatewayBridgeById,
      watchQueryObjects: watchQueryGatewayBridgeObjects,
      insert: insertGatewayBridge,
      insertWithOnConflict: insertGatewayBridgeWithOnConflict,
      insertObjects: insertGatewayBridgeObjects,
      updateById: updateGatewayBridgeById,
      updateObjects: updateGatewayBridgeObjects
    }
  

    // GatewayBridge Entity Helper Object
    //------------------------------------------------

    export const GatewayBridgeModelGQLHelper = {
      removeById: removeGatewayBridgeModelById,
      removeObjects: removeGatewayBridgeModelObjects
    }
  

    // GatewaySubnet HELPERS
    //------------------------------------------------

    export type GatewaySubnetByIdHelperResultEx = { gatewaySubnet:GatewaySubnetFragment | null | undefined };
    export type GatewaySubnetObjectsHelperResultEx = { objects:GatewaySubnetFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryGatewaySubnetByIdApolloQueryResult = ApolloQueryResult<QueryGatewaySubnetByIdQuery>;
      export type QueryGatewaySubnetByIdApolloQueryHelperResultEx = QueryGatewaySubnetByIdApolloQueryResult & GatewaySubnetByIdHelperResultEx;

      async function queryGatewaySubnetById({ apolloClient, gatewaySubnetId, options }: { apolloClient: ApolloClient<object>, gatewaySubnetId: string, options?: Omit<QueryOptions<QueryGatewaySubnetByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryGatewaySubnetByIdApolloQueryHelperResultEx> {
        const query: QueryGatewaySubnetByIdApolloQueryResult = await apolloClient.query<QueryGatewaySubnetByIdQuery>({ query: QueryGatewaySubnetByIdDocument, variables: { gatewaySubnetId }, ...options });
        
        return { ...query, gatewaySubnet: query?.data?.GatewaySubnet_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryGatewaySubnetByIdApolloObservableQuery = ObservableQuery<QueryGatewaySubnetByIdQuery>;
      async function watchQueryGatewaySubnetById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewaySubnetByIdQueryVariables>, 'query'> }) : Promise<WatchQueryGatewaySubnetByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewaySubnetByIdQuery>({ query: QueryGatewaySubnetByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryGatewaySubnetObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryGatewaySubnetObjectsQuery>;
      export type QueryGatewaySubnetObjectsObjectsApolloQueryResultEx = QueryGatewaySubnetObjectsObjectsApolloQueryResult & GatewaySubnetObjectsHelperResultEx;

      async function queryGatewaySubnetObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewaySubnetObjectsQueryVariables>, 'query'> }): Promise<QueryGatewaySubnetObjectsObjectsApolloQueryResultEx> {
        const query: QueryGatewaySubnetObjectsObjectsApolloQueryResult = await apolloClient.query<QueryGatewaySubnetObjectsQuery>({ query: QueryGatewaySubnetObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.GatewaySubnet || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryGatewaySubnetObjectsApolloObservableQuery = ObservableQuery<QueryGatewaySubnetObjectsQuery>;
      async function watchQueryGatewaySubnetObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryGatewaySubnetObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryGatewaySubnetObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryGatewaySubnetObjectsQuery>({ query: QueryGatewaySubnetObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertGatewaySubnetFetchResult = FetchResult<InsertGatewaySubnetMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewaySubnetFetchHelperResultEx = InsertGatewaySubnetFetchResult & GatewaySubnetByIdHelperResultEx;

    async function insertGatewaySubnet({ apolloClient, gatewaySubnet, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gatewaySubnet: GatewaySubnet_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewaySubnetFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gatewaySubnet });
      const mutationOptions:MutationOptions<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables> = { mutation: InsertGatewaySubnetDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gatewaySubnet'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewaySubnetMutation>({ operationType: 'insert', entityName:'GatewaySubnet', objects:[objectForInsert as GatewaySubnet_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewaySubnet - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewaySubnetFetchResult = await apolloClient.mutate<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewaySubnet: mutation?.data?.insert_GatewaySubnet?.returning && mutation.data.insert_GatewaySubnet.returning[0] };
    }

    async function insertGatewaySubnetWithOnConflict({ apolloClient, gatewaySubnet, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, gatewaySubnet: GatewaySubnet_Insert_Input, onConflict: GatewaySubnet_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewaySubnetWithOnConflictMutation, InsertGatewaySubnetWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewaySubnetFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: gatewaySubnet });
      const mutationOptions:MutationOptions<InsertGatewaySubnetWithOnConflictMutation, InsertGatewaySubnetWithOnConflictMutationVariables> = { mutation: InsertGatewaySubnetWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'gatewaySubnet'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewaySubnetWithOnConflictMutation>({ operationType: 'insert', entityName:'GatewaySubnet', objects:[objectForInsert as GatewaySubnet_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewaySubnetWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertGatewaySubnetFetchResult = await apolloClient.mutate<InsertGatewaySubnetWithOnConflictMutation, InsertGatewaySubnetWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewaySubnet: mutation?.data?.insert_GatewaySubnet?.returning && mutation.data.insert_GatewaySubnet.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertGatewaySubnetObjectsFetchResult = FetchResult<InsertGatewaySubnetMutation, Record<string, any>, Record<string, any>>;
    export type InsertGatewaySubnetObjectsHelperResultEx = InsertGatewaySubnetObjectsFetchResult & GatewaySubnetObjectsHelperResultEx;

    async function insertGatewaySubnetObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: GatewaySubnet_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertGatewaySubnetObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables> = { mutation: InsertGatewaySubnetDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGatewaySubnetMutation>({ operationType: 'insert', entityName:'GatewaySubnet', objects:objectsForInsert as (GatewaySubnet_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertGatewaySubnet - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertGatewaySubnetObjectsFetchResult = await apolloClient.mutate<InsertGatewaySubnetMutation, InsertGatewaySubnetMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_GatewaySubnet?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateGatewaySubnetByIdQueryResult = FetchResult<UpdateGatewaySubnetByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewaySubnetByIdHelperResultEx = UpdateGatewaySubnetByIdQueryResult & GatewaySubnetByIdHelperResultEx;

    async function updateGatewaySubnetById({ apolloClient, gatewaySubnetId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, gatewaySubnetId: string, set: GatewaySubnet_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateGatewaySubnetByIdMutation, UpdateGatewaySubnetByIdMutationVariables>, 'mutation'> }): Promise<UpdateGatewaySubnetByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateGatewaySubnetByIdMutation, UpdateGatewaySubnetByIdMutationVariables> = { mutation: UpdateGatewaySubnetByIdDocument, variables: { id:gatewaySubnetId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateGatewaySubnetByIdMutation>({ operationType: 'update', entityName:'GatewaySubnet', objects:[{ id:gatewaySubnetId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateGatewaySubnetById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateGatewaySubnetByIdQueryResult = await apolloClient.mutate<UpdateGatewaySubnetByIdMutation, UpdateGatewaySubnetByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, gatewaySubnet: mutation?.data?.update_GatewaySubnet?.returning && mutation.data.update_GatewaySubnet.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateGatewaySubnetObjectsFetchResult = FetchResult<UpdateGatewaySubnetMutation, Record<string, any>, Record<string, any>>;
    export type UpdateGatewaySubnetObjectsHelperResultEx = UpdateGatewaySubnetObjectsFetchResult & GatewaySubnetObjectsHelperResultEx;

    async function updateGatewaySubnetObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateGatewaySubnetMutation, UpdateGatewaySubnetMutationVariables>, 'mutation'> }): Promise<UpdateGatewaySubnetObjectsHelperResultEx> {  
      const mutation:UpdateGatewaySubnetObjectsFetchResult = await apolloClient.mutate<UpdateGatewaySubnetMutation, UpdateGatewaySubnetMutationVariables>({ mutation: UpdateGatewaySubnetDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_GatewaySubnet?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveGatewaySubnetModelByIdQueryResult = FetchResult<RemoveGatewaySubnetModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewaySubnetModelByIdQueryHelperResultEx = RemoveGatewaySubnetModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeGatewaySubnetModelById({ apolloClient, gatewaySubnetId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, gatewaySubnetId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveGatewaySubnetModelByIdMutation, RemoveGatewaySubnetModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveGatewaySubnetModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveGatewaySubnetModelByIdMutation, RemoveGatewaySubnetModelByIdMutationVariables> = { mutation: RemoveGatewaySubnetModelByIdDocument, variables: { id:gatewaySubnetId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveGatewaySubnetModelByIdMutation>({ operationType: 'delete', entityName:'gatewaySubnet', objects:[{ id:gatewaySubnetId }] }); 
        if(logLevel >= 2) console.log(' --> removeGatewaySubnetModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveGatewaySubnetModelByIdMutation>({ operationType: 'delete', entityName:'gatewaySubnet', entityId:gatewaySubnetId }); }
      
      const mutation:RemoveGatewaySubnetModelByIdQueryResult = await apolloClient.mutate<RemoveGatewaySubnetModelByIdMutation, RemoveGatewaySubnetModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_GatewaySubnet?.affected_rows || 0 };
    }
  

    type RemoveGatewaySubnetModelObjectsQueryResult = FetchResult<RemoveGatewaySubnetModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveGatewaySubnetModelObjectsQueryHelperResultEx = RemoveGatewaySubnetModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeGatewaySubnetModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveGatewaySubnetModelMutation, RemoveGatewaySubnetModelMutationVariables>, 'mutation'> }): Promise<RemoveGatewaySubnetModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveGatewaySubnetModelObjectsQueryResult = await apolloClient.mutate<RemoveGatewaySubnetModelMutation, RemoveGatewaySubnetModelMutationVariables>({ mutation: RemoveGatewaySubnetModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_GatewaySubnet?.affected_rows || 0 };
      }
  

    // GatewaySubnet Fragment Helper Object
    //------------------------------------------------

    export const GatewaySubnetFragmentGQLHelper = {
      queryById: queryGatewaySubnetById,
      queryObjects: queryGatewaySubnetObjects,
      watchQueryById: watchQueryGatewaySubnetById,
      watchQueryObjects: watchQueryGatewaySubnetObjects,
      insert: insertGatewaySubnet,
      insertWithOnConflict: insertGatewaySubnetWithOnConflict,
      insertObjects: insertGatewaySubnetObjects,
      updateById: updateGatewaySubnetById,
      updateObjects: updateGatewaySubnetObjects
    }
  

    // GatewaySubnet Entity Helper Object
    //------------------------------------------------

    export const GatewaySubnetModelGQLHelper = {
      removeById: removeGatewaySubnetModelById,
      removeObjects: removeGatewaySubnetModelObjects
    }
  

    // LanNode HELPERS
    //------------------------------------------------

    export type LanNodeByIdHelperResultEx = { lanNode:LanNodeFragment | null | undefined };
    export type LanNodeObjectsHelperResultEx = { objects:LanNodeFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryLanNodeByIdApolloQueryResult = ApolloQueryResult<QueryLanNodeByIdQuery>;
      export type QueryLanNodeByIdApolloQueryHelperResultEx = QueryLanNodeByIdApolloQueryResult & LanNodeByIdHelperResultEx;

      async function queryLanNodeById({ apolloClient, lanNodeId, options }: { apolloClient: ApolloClient<object>, lanNodeId: string, options?: Omit<QueryOptions<QueryLanNodeByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryLanNodeByIdApolloQueryHelperResultEx> {
        const query: QueryLanNodeByIdApolloQueryResult = await apolloClient.query<QueryLanNodeByIdQuery>({ query: QueryLanNodeByIdDocument, variables: { lanNodeId }, ...options });
        
        return { ...query, lanNode: query?.data?.LanNode_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryLanNodeByIdApolloObservableQuery = ObservableQuery<QueryLanNodeByIdQuery>;
      async function watchQueryLanNodeById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLanNodeByIdQueryVariables>, 'query'> }) : Promise<WatchQueryLanNodeByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLanNodeByIdQuery>({ query: QueryLanNodeByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryLanNodeObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryLanNodeObjectsQuery>;
      export type QueryLanNodeObjectsObjectsApolloQueryResultEx = QueryLanNodeObjectsObjectsApolloQueryResult & LanNodeObjectsHelperResultEx;

      async function queryLanNodeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLanNodeObjectsQueryVariables>, 'query'> }): Promise<QueryLanNodeObjectsObjectsApolloQueryResultEx> {
        const query: QueryLanNodeObjectsObjectsApolloQueryResult = await apolloClient.query<QueryLanNodeObjectsQuery>({ query: QueryLanNodeObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.LanNode || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryLanNodeObjectsApolloObservableQuery = ObservableQuery<QueryLanNodeObjectsQuery>;
      async function watchQueryLanNodeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLanNodeObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryLanNodeObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLanNodeObjectsQuery>({ query: QueryLanNodeObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertLanNodeFetchResult = FetchResult<InsertLanNodeMutation, Record<string, any>, Record<string, any>>;
    export type InsertLanNodeFetchHelperResultEx = InsertLanNodeFetchResult & LanNodeByIdHelperResultEx;

    async function insertLanNode({ apolloClient, lanNode, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, lanNode: LanNode_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLanNodeMutation, InsertLanNodeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLanNodeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: lanNode });
      const mutationOptions:MutationOptions<InsertLanNodeMutation, InsertLanNodeMutationVariables> = { mutation: InsertLanNodeDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'lanNode'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLanNodeMutation>({ operationType: 'insert', entityName:'LanNode', objects:[objectForInsert as LanNode_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLanNode - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLanNodeFetchResult = await apolloClient.mutate<InsertLanNodeMutation, InsertLanNodeMutationVariables>(mutationOptions);
        
      return { ...mutation, lanNode: mutation?.data?.insert_LanNode?.returning && mutation.data.insert_LanNode.returning[0] };
    }

    async function insertLanNodeWithOnConflict({ apolloClient, lanNode, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, lanNode: LanNode_Insert_Input, onConflict: LanNode_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLanNodeWithOnConflictMutation, InsertLanNodeWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLanNodeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: lanNode });
      const mutationOptions:MutationOptions<InsertLanNodeWithOnConflictMutation, InsertLanNodeWithOnConflictMutationVariables> = { mutation: InsertLanNodeWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'lanNode'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLanNodeWithOnConflictMutation>({ operationType: 'insert', entityName:'LanNode', objects:[objectForInsert as LanNode_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLanNodeWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLanNodeFetchResult = await apolloClient.mutate<InsertLanNodeWithOnConflictMutation, InsertLanNodeWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, lanNode: mutation?.data?.insert_LanNode?.returning && mutation.data.insert_LanNode.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertLanNodeObjectsFetchResult = FetchResult<InsertLanNodeMutation, Record<string, any>, Record<string, any>>;
    export type InsertLanNodeObjectsHelperResultEx = InsertLanNodeObjectsFetchResult & LanNodeObjectsHelperResultEx;

    async function insertLanNodeObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: LanNode_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLanNodeMutation, InsertLanNodeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLanNodeObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertLanNodeMutation, InsertLanNodeMutationVariables> = { mutation: InsertLanNodeDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLanNodeMutation>({ operationType: 'insert', entityName:'LanNode', objects:objectsForInsert as (LanNode_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLanNode - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertLanNodeObjectsFetchResult = await apolloClient.mutate<InsertLanNodeMutation, InsertLanNodeMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_LanNode?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateLanNodeByIdQueryResult = FetchResult<UpdateLanNodeByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLanNodeByIdHelperResultEx = UpdateLanNodeByIdQueryResult & LanNodeByIdHelperResultEx;

    async function updateLanNodeById({ apolloClient, lanNodeId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, lanNodeId: string, set: LanNode_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateLanNodeByIdMutation, UpdateLanNodeByIdMutationVariables>, 'mutation'> }): Promise<UpdateLanNodeByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateLanNodeByIdMutation, UpdateLanNodeByIdMutationVariables> = { mutation: UpdateLanNodeByIdDocument, variables: { id:lanNodeId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateLanNodeByIdMutation>({ operationType: 'update', entityName:'LanNode', objects:[{ id:lanNodeId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateLanNodeById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateLanNodeByIdQueryResult = await apolloClient.mutate<UpdateLanNodeByIdMutation, UpdateLanNodeByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, lanNode: mutation?.data?.update_LanNode?.returning && mutation.data.update_LanNode.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateLanNodeObjectsFetchResult = FetchResult<UpdateLanNodeMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLanNodeObjectsHelperResultEx = UpdateLanNodeObjectsFetchResult & LanNodeObjectsHelperResultEx;

    async function updateLanNodeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateLanNodeMutation, UpdateLanNodeMutationVariables>, 'mutation'> }): Promise<UpdateLanNodeObjectsHelperResultEx> {  
      const mutation:UpdateLanNodeObjectsFetchResult = await apolloClient.mutate<UpdateLanNodeMutation, UpdateLanNodeMutationVariables>({ mutation: UpdateLanNodeDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_LanNode?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveLanNodeModelByIdQueryResult = FetchResult<RemoveLanNodeModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLanNodeModelByIdQueryHelperResultEx = RemoveLanNodeModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeLanNodeModelById({ apolloClient, lanNodeId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, lanNodeId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveLanNodeModelByIdMutation, RemoveLanNodeModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveLanNodeModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveLanNodeModelByIdMutation, RemoveLanNodeModelByIdMutationVariables> = { mutation: RemoveLanNodeModelByIdDocument, variables: { id:lanNodeId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveLanNodeModelByIdMutation>({ operationType: 'delete', entityName:'lanNode', objects:[{ id:lanNodeId }] }); 
        if(logLevel >= 2) console.log(' --> removeLanNodeModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveLanNodeModelByIdMutation>({ operationType: 'delete', entityName:'lanNode', entityId:lanNodeId }); }
      
      const mutation:RemoveLanNodeModelByIdQueryResult = await apolloClient.mutate<RemoveLanNodeModelByIdMutation, RemoveLanNodeModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_LanNode?.affected_rows || 0 };
    }
  

    type RemoveLanNodeModelObjectsQueryResult = FetchResult<RemoveLanNodeModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLanNodeModelObjectsQueryHelperResultEx = RemoveLanNodeModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeLanNodeModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveLanNodeModelMutation, RemoveLanNodeModelMutationVariables>, 'mutation'> }): Promise<RemoveLanNodeModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveLanNodeModelObjectsQueryResult = await apolloClient.mutate<RemoveLanNodeModelMutation, RemoveLanNodeModelMutationVariables>({ mutation: RemoveLanNodeModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_LanNode?.affected_rows || 0 };
      }
  

    // LanNode Fragment Helper Object
    //------------------------------------------------

    export const LanNodeFragmentGQLHelper = {
      queryById: queryLanNodeById,
      queryObjects: queryLanNodeObjects,
      watchQueryById: watchQueryLanNodeById,
      watchQueryObjects: watchQueryLanNodeObjects,
      insert: insertLanNode,
      insertWithOnConflict: insertLanNodeWithOnConflict,
      insertObjects: insertLanNodeObjects,
      updateById: updateLanNodeById,
      updateObjects: updateLanNodeObjects
    }
  

    // LanNode Entity Helper Object
    //------------------------------------------------

    export const LanNodeModelGQLHelper = {
      removeById: removeLanNodeModelById,
      removeObjects: removeLanNodeModelObjects
    }
  

    // Link HELPERS
    //------------------------------------------------

    export type LinkByIdHelperResultEx = { link:LinkFragment | null | undefined };
    export type LinkObjectsHelperResultEx = { objects:LinkFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryLinkByIdApolloQueryResult = ApolloQueryResult<QueryLinkByIdQuery>;
      export type QueryLinkByIdApolloQueryHelperResultEx = QueryLinkByIdApolloQueryResult & LinkByIdHelperResultEx;

      async function queryLinkById({ apolloClient, linkId, options }: { apolloClient: ApolloClient<object>, linkId: string, options?: Omit<QueryOptions<QueryLinkByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryLinkByIdApolloQueryHelperResultEx> {
        const query: QueryLinkByIdApolloQueryResult = await apolloClient.query<QueryLinkByIdQuery>({ query: QueryLinkByIdDocument, variables: { linkId }, ...options });
        
        return { ...query, link: query?.data?.Link_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryLinkByIdApolloObservableQuery = ObservableQuery<QueryLinkByIdQuery>;
      async function watchQueryLinkById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkByIdQueryVariables>, 'query'> }) : Promise<WatchQueryLinkByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLinkByIdQuery>({ query: QueryLinkByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryLinkObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryLinkObjectsQuery>;
      export type QueryLinkObjectsObjectsApolloQueryResultEx = QueryLinkObjectsObjectsApolloQueryResult & LinkObjectsHelperResultEx;

      async function queryLinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkObjectsQueryVariables>, 'query'> }): Promise<QueryLinkObjectsObjectsApolloQueryResultEx> {
        const query: QueryLinkObjectsObjectsApolloQueryResult = await apolloClient.query<QueryLinkObjectsQuery>({ query: QueryLinkObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Link || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryLinkObjectsApolloObservableQuery = ObservableQuery<QueryLinkObjectsQuery>;
      async function watchQueryLinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryLinkObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLinkObjectsQuery>({ query: QueryLinkObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertLinkFetchResult = FetchResult<InsertLinkMutation, Record<string, any>, Record<string, any>>;
    export type InsertLinkFetchHelperResultEx = InsertLinkFetchResult & LinkByIdHelperResultEx;

    async function insertLink({ apolloClient, link, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, link: Link_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkMutation, InsertLinkMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: link });
      const mutationOptions:MutationOptions<InsertLinkMutation, InsertLinkMutationVariables> = { mutation: InsertLinkDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'link'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkMutation>({ operationType: 'insert', entityName:'Link', objects:[objectForInsert as Link_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLink - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLinkFetchResult = await apolloClient.mutate<InsertLinkMutation, InsertLinkMutationVariables>(mutationOptions);
        
      return { ...mutation, link: mutation?.data?.insert_Link?.returning && mutation.data.insert_Link.returning[0] };
    }

    async function insertLinkWithOnConflict({ apolloClient, link, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, link: Link_Insert_Input, onConflict: Link_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkWithOnConflictMutation, InsertLinkWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: link });
      const mutationOptions:MutationOptions<InsertLinkWithOnConflictMutation, InsertLinkWithOnConflictMutationVariables> = { mutation: InsertLinkWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'link'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkWithOnConflictMutation>({ operationType: 'insert', entityName:'Link', objects:[objectForInsert as Link_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLinkWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLinkFetchResult = await apolloClient.mutate<InsertLinkWithOnConflictMutation, InsertLinkWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, link: mutation?.data?.insert_Link?.returning && mutation.data.insert_Link.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertLinkObjectsFetchResult = FetchResult<InsertLinkMutation, Record<string, any>, Record<string, any>>;
    export type InsertLinkObjectsHelperResultEx = InsertLinkObjectsFetchResult & LinkObjectsHelperResultEx;

    async function insertLinkObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Link_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkMutation, InsertLinkMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertLinkMutation, InsertLinkMutationVariables> = { mutation: InsertLinkDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkMutation>({ operationType: 'insert', entityName:'Link', objects:objectsForInsert as (Link_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLink - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertLinkObjectsFetchResult = await apolloClient.mutate<InsertLinkMutation, InsertLinkMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Link?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateLinkByIdQueryResult = FetchResult<UpdateLinkByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLinkByIdHelperResultEx = UpdateLinkByIdQueryResult & LinkByIdHelperResultEx;

    async function updateLinkById({ apolloClient, linkId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, linkId: string, set: Link_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateLinkByIdMutation, UpdateLinkByIdMutationVariables>, 'mutation'> }): Promise<UpdateLinkByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateLinkByIdMutation, UpdateLinkByIdMutationVariables> = { mutation: UpdateLinkByIdDocument, variables: { id:linkId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateLinkByIdMutation>({ operationType: 'update', entityName:'Link', objects:[{ id:linkId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateLinkById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateLinkByIdQueryResult = await apolloClient.mutate<UpdateLinkByIdMutation, UpdateLinkByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, link: mutation?.data?.update_Link?.returning && mutation.data.update_Link.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateLinkObjectsFetchResult = FetchResult<UpdateLinkMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLinkObjectsHelperResultEx = UpdateLinkObjectsFetchResult & LinkObjectsHelperResultEx;

    async function updateLinkObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateLinkMutation, UpdateLinkMutationVariables>, 'mutation'> }): Promise<UpdateLinkObjectsHelperResultEx> {  
      const mutation:UpdateLinkObjectsFetchResult = await apolloClient.mutate<UpdateLinkMutation, UpdateLinkMutationVariables>({ mutation: UpdateLinkDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Link?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveLinkModelByIdQueryResult = FetchResult<RemoveLinkModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLinkModelByIdQueryHelperResultEx = RemoveLinkModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeLinkModelById({ apolloClient, linkId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, linkId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveLinkModelByIdMutation, RemoveLinkModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveLinkModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveLinkModelByIdMutation, RemoveLinkModelByIdMutationVariables> = { mutation: RemoveLinkModelByIdDocument, variables: { id:linkId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveLinkModelByIdMutation>({ operationType: 'delete', entityName:'link', objects:[{ id:linkId }] }); 
        if(logLevel >= 2) console.log(' --> removeLinkModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveLinkModelByIdMutation>({ operationType: 'delete', entityName:'link', entityId:linkId }); }
      
      const mutation:RemoveLinkModelByIdQueryResult = await apolloClient.mutate<RemoveLinkModelByIdMutation, RemoveLinkModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Link?.affected_rows || 0 };
    }
  

    type RemoveLinkModelObjectsQueryResult = FetchResult<RemoveLinkModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLinkModelObjectsQueryHelperResultEx = RemoveLinkModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeLinkModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveLinkModelMutation, RemoveLinkModelMutationVariables>, 'mutation'> }): Promise<RemoveLinkModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveLinkModelObjectsQueryResult = await apolloClient.mutate<RemoveLinkModelMutation, RemoveLinkModelMutationVariables>({ mutation: RemoveLinkModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Link?.affected_rows || 0 };
      }
  

    // Link Fragment Helper Object
    //------------------------------------------------

    export const LinkFragmentGQLHelper = {
      queryById: queryLinkById,
      queryObjects: queryLinkObjects,
      watchQueryById: watchQueryLinkById,
      watchQueryObjects: watchQueryLinkObjects,
      insert: insertLink,
      insertWithOnConflict: insertLinkWithOnConflict,
      insertObjects: insertLinkObjects,
      updateById: updateLinkById,
      updateObjects: updateLinkObjects
    }
  

    // Link Entity Helper Object
    //------------------------------------------------

    export const LinkModelGQLHelper = {
      removeById: removeLinkModelById,
      removeObjects: removeLinkModelObjects
    }
  

    // LinkType HELPERS
    //------------------------------------------------

    export type LinkTypeByIdHelperResultEx = { linkType:LinkTypeFragment | null | undefined };
    export type LinkTypeObjectsHelperResultEx = { objects:LinkTypeFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryLinkTypeByIdApolloQueryResult = ApolloQueryResult<QueryLinkTypeByIdQuery>;
      export type QueryLinkTypeByIdApolloQueryHelperResultEx = QueryLinkTypeByIdApolloQueryResult & LinkTypeByIdHelperResultEx;

      async function queryLinkTypeById({ apolloClient, linkTypeId, options }: { apolloClient: ApolloClient<object>, linkTypeId: string, options?: Omit<QueryOptions<QueryLinkTypeByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryLinkTypeByIdApolloQueryHelperResultEx> {
        const query: QueryLinkTypeByIdApolloQueryResult = await apolloClient.query<QueryLinkTypeByIdQuery>({ query: QueryLinkTypeByIdDocument, variables: { linkTypeId }, ...options });
        
        return { ...query, linkType: query?.data?.LinkType_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryLinkTypeByIdApolloObservableQuery = ObservableQuery<QueryLinkTypeByIdQuery>;
      async function watchQueryLinkTypeById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkTypeByIdQueryVariables>, 'query'> }) : Promise<WatchQueryLinkTypeByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLinkTypeByIdQuery>({ query: QueryLinkTypeByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryLinkTypeObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryLinkTypeObjectsQuery>;
      export type QueryLinkTypeObjectsObjectsApolloQueryResultEx = QueryLinkTypeObjectsObjectsApolloQueryResult & LinkTypeObjectsHelperResultEx;

      async function queryLinkTypeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkTypeObjectsQueryVariables>, 'query'> }): Promise<QueryLinkTypeObjectsObjectsApolloQueryResultEx> {
        const query: QueryLinkTypeObjectsObjectsApolloQueryResult = await apolloClient.query<QueryLinkTypeObjectsQuery>({ query: QueryLinkTypeObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.LinkType || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryLinkTypeObjectsApolloObservableQuery = ObservableQuery<QueryLinkTypeObjectsQuery>;
      async function watchQueryLinkTypeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryLinkTypeObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryLinkTypeObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryLinkTypeObjectsQuery>({ query: QueryLinkTypeObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertLinkTypeFetchResult = FetchResult<InsertLinkTypeMutation, Record<string, any>, Record<string, any>>;
    export type InsertLinkTypeFetchHelperResultEx = InsertLinkTypeFetchResult & LinkTypeByIdHelperResultEx;

    async function insertLinkType({ apolloClient, linkType, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, linkType: LinkType_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkTypeMutation, InsertLinkTypeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkTypeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: linkType });
      const mutationOptions:MutationOptions<InsertLinkTypeMutation, InsertLinkTypeMutationVariables> = { mutation: InsertLinkTypeDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'linkType'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkTypeMutation>({ operationType: 'insert', entityName:'LinkType', objects:[objectForInsert as LinkType_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLinkType - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLinkTypeFetchResult = await apolloClient.mutate<InsertLinkTypeMutation, InsertLinkTypeMutationVariables>(mutationOptions);
        
      return { ...mutation, linkType: mutation?.data?.insert_LinkType?.returning && mutation.data.insert_LinkType.returning[0] };
    }

    async function insertLinkTypeWithOnConflict({ apolloClient, linkType, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, linkType: LinkType_Insert_Input, onConflict: LinkType_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkTypeWithOnConflictMutation, InsertLinkTypeWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkTypeFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: linkType });
      const mutationOptions:MutationOptions<InsertLinkTypeWithOnConflictMutation, InsertLinkTypeWithOnConflictMutationVariables> = { mutation: InsertLinkTypeWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'linkType'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkTypeWithOnConflictMutation>({ operationType: 'insert', entityName:'LinkType', objects:[objectForInsert as LinkType_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLinkTypeWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertLinkTypeFetchResult = await apolloClient.mutate<InsertLinkTypeWithOnConflictMutation, InsertLinkTypeWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, linkType: mutation?.data?.insert_LinkType?.returning && mutation.data.insert_LinkType.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertLinkTypeObjectsFetchResult = FetchResult<InsertLinkTypeMutation, Record<string, any>, Record<string, any>>;
    export type InsertLinkTypeObjectsHelperResultEx = InsertLinkTypeObjectsFetchResult & LinkTypeObjectsHelperResultEx;

    async function insertLinkTypeObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: LinkType_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertLinkTypeMutation, InsertLinkTypeMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertLinkTypeObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertLinkTypeMutation, InsertLinkTypeMutationVariables> = { mutation: InsertLinkTypeDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLinkTypeMutation>({ operationType: 'insert', entityName:'LinkType', objects:objectsForInsert as (LinkType_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertLinkType - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertLinkTypeObjectsFetchResult = await apolloClient.mutate<InsertLinkTypeMutation, InsertLinkTypeMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_LinkType?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateLinkTypeByIdQueryResult = FetchResult<UpdateLinkTypeByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLinkTypeByIdHelperResultEx = UpdateLinkTypeByIdQueryResult & LinkTypeByIdHelperResultEx;

    async function updateLinkTypeById({ apolloClient, linkTypeId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, linkTypeId: string, set: LinkType_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateLinkTypeByIdMutation, UpdateLinkTypeByIdMutationVariables>, 'mutation'> }): Promise<UpdateLinkTypeByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateLinkTypeByIdMutation, UpdateLinkTypeByIdMutationVariables> = { mutation: UpdateLinkTypeByIdDocument, variables: { id:linkTypeId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateLinkTypeByIdMutation>({ operationType: 'update', entityName:'LinkType', objects:[{ id:linkTypeId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateLinkTypeById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateLinkTypeByIdQueryResult = await apolloClient.mutate<UpdateLinkTypeByIdMutation, UpdateLinkTypeByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, linkType: mutation?.data?.update_LinkType?.returning && mutation.data.update_LinkType.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateLinkTypeObjectsFetchResult = FetchResult<UpdateLinkTypeMutation, Record<string, any>, Record<string, any>>;
    export type UpdateLinkTypeObjectsHelperResultEx = UpdateLinkTypeObjectsFetchResult & LinkTypeObjectsHelperResultEx;

    async function updateLinkTypeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateLinkTypeMutation, UpdateLinkTypeMutationVariables>, 'mutation'> }): Promise<UpdateLinkTypeObjectsHelperResultEx> {  
      const mutation:UpdateLinkTypeObjectsFetchResult = await apolloClient.mutate<UpdateLinkTypeMutation, UpdateLinkTypeMutationVariables>({ mutation: UpdateLinkTypeDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_LinkType?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveLinkTypeModelByIdQueryResult = FetchResult<RemoveLinkTypeModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLinkTypeModelByIdQueryHelperResultEx = RemoveLinkTypeModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeLinkTypeModelById({ apolloClient, linkTypeId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, linkTypeId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveLinkTypeModelByIdMutation, RemoveLinkTypeModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveLinkTypeModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveLinkTypeModelByIdMutation, RemoveLinkTypeModelByIdMutationVariables> = { mutation: RemoveLinkTypeModelByIdDocument, variables: { id:linkTypeId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveLinkTypeModelByIdMutation>({ operationType: 'delete', entityName:'linkType', objects:[{ id:linkTypeId }] }); 
        if(logLevel >= 2) console.log(' --> removeLinkTypeModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveLinkTypeModelByIdMutation>({ operationType: 'delete', entityName:'linkType', entityId:linkTypeId }); }
      
      const mutation:RemoveLinkTypeModelByIdQueryResult = await apolloClient.mutate<RemoveLinkTypeModelByIdMutation, RemoveLinkTypeModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_LinkType?.affected_rows || 0 };
    }
  

    type RemoveLinkTypeModelObjectsQueryResult = FetchResult<RemoveLinkTypeModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveLinkTypeModelObjectsQueryHelperResultEx = RemoveLinkTypeModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeLinkTypeModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveLinkTypeModelMutation, RemoveLinkTypeModelMutationVariables>, 'mutation'> }): Promise<RemoveLinkTypeModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveLinkTypeModelObjectsQueryResult = await apolloClient.mutate<RemoveLinkTypeModelMutation, RemoveLinkTypeModelMutationVariables>({ mutation: RemoveLinkTypeModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_LinkType?.affected_rows || 0 };
      }
  

    // LinkType Fragment Helper Object
    //------------------------------------------------

    export const LinkTypeFragmentGQLHelper = {
      queryById: queryLinkTypeById,
      queryObjects: queryLinkTypeObjects,
      watchQueryById: watchQueryLinkTypeById,
      watchQueryObjects: watchQueryLinkTypeObjects,
      insert: insertLinkType,
      insertWithOnConflict: insertLinkTypeWithOnConflict,
      insertObjects: insertLinkTypeObjects,
      updateById: updateLinkTypeById,
      updateObjects: updateLinkTypeObjects
    }
  

    // LinkType Entity Helper Object
    //------------------------------------------------

    export const LinkTypeModelGQLHelper = {
      removeById: removeLinkTypeModelById,
      removeObjects: removeLinkTypeModelObjects
    }
  

    // MeshWan HELPERS
    //------------------------------------------------

    export type MeshWanByIdHelperResultEx = { meshWan:MeshWanFragment | null | undefined };
    export type MeshWanObjectsHelperResultEx = { objects:MeshWanFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryMeshWanByIdApolloQueryResult = ApolloQueryResult<QueryMeshWanByIdQuery>;
      export type QueryMeshWanByIdApolloQueryHelperResultEx = QueryMeshWanByIdApolloQueryResult & MeshWanByIdHelperResultEx;

      async function queryMeshWanById({ apolloClient, meshWanId, options }: { apolloClient: ApolloClient<object>, meshWanId: string, options?: Omit<QueryOptions<QueryMeshWanByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryMeshWanByIdApolloQueryHelperResultEx> {
        const query: QueryMeshWanByIdApolloQueryResult = await apolloClient.query<QueryMeshWanByIdQuery>({ query: QueryMeshWanByIdDocument, variables: { meshWanId }, ...options });
        
        return { ...query, meshWan: query?.data?.MeshWan_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryMeshWanByIdApolloObservableQuery = ObservableQuery<QueryMeshWanByIdQuery>;
      async function watchQueryMeshWanById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMeshWanByIdQueryVariables>, 'query'> }) : Promise<WatchQueryMeshWanByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryMeshWanByIdQuery>({ query: QueryMeshWanByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryMeshWanObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryMeshWanObjectsQuery>;
      export type QueryMeshWanObjectsObjectsApolloQueryResultEx = QueryMeshWanObjectsObjectsApolloQueryResult & MeshWanObjectsHelperResultEx;

      async function queryMeshWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMeshWanObjectsQueryVariables>, 'query'> }): Promise<QueryMeshWanObjectsObjectsApolloQueryResultEx> {
        const query: QueryMeshWanObjectsObjectsApolloQueryResult = await apolloClient.query<QueryMeshWanObjectsQuery>({ query: QueryMeshWanObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.MeshWan || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryMeshWanObjectsApolloObservableQuery = ObservableQuery<QueryMeshWanObjectsQuery>;
      async function watchQueryMeshWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMeshWanObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryMeshWanObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryMeshWanObjectsQuery>({ query: QueryMeshWanObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertMeshWanFetchResult = FetchResult<InsertMeshWanMutation, Record<string, any>, Record<string, any>>;
    export type InsertMeshWanFetchHelperResultEx = InsertMeshWanFetchResult & MeshWanByIdHelperResultEx;

    async function insertMeshWan({ apolloClient, meshWan, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, meshWan: MeshWan_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMeshWanMutation, InsertMeshWanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMeshWanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: meshWan });
      const mutationOptions:MutationOptions<InsertMeshWanMutation, InsertMeshWanMutationVariables> = { mutation: InsertMeshWanDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'meshWan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMeshWanMutation>({ operationType: 'insert', entityName:'MeshWan', objects:[objectForInsert as MeshWan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMeshWan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertMeshWanFetchResult = await apolloClient.mutate<InsertMeshWanMutation, InsertMeshWanMutationVariables>(mutationOptions);
        
      return { ...mutation, meshWan: mutation?.data?.insert_MeshWan?.returning && mutation.data.insert_MeshWan.returning[0] };
    }

    async function insertMeshWanWithOnConflict({ apolloClient, meshWan, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, meshWan: MeshWan_Insert_Input, onConflict: MeshWan_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMeshWanWithOnConflictMutation, InsertMeshWanWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMeshWanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: meshWan });
      const mutationOptions:MutationOptions<InsertMeshWanWithOnConflictMutation, InsertMeshWanWithOnConflictMutationVariables> = { mutation: InsertMeshWanWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'meshWan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMeshWanWithOnConflictMutation>({ operationType: 'insert', entityName:'MeshWan', objects:[objectForInsert as MeshWan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMeshWanWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertMeshWanFetchResult = await apolloClient.mutate<InsertMeshWanWithOnConflictMutation, InsertMeshWanWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, meshWan: mutation?.data?.insert_MeshWan?.returning && mutation.data.insert_MeshWan.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertMeshWanObjectsFetchResult = FetchResult<InsertMeshWanMutation, Record<string, any>, Record<string, any>>;
    export type InsertMeshWanObjectsHelperResultEx = InsertMeshWanObjectsFetchResult & MeshWanObjectsHelperResultEx;

    async function insertMeshWanObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: MeshWan_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMeshWanMutation, InsertMeshWanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMeshWanObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertMeshWanMutation, InsertMeshWanMutationVariables> = { mutation: InsertMeshWanDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMeshWanMutation>({ operationType: 'insert', entityName:'MeshWan', objects:objectsForInsert as (MeshWan_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMeshWan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertMeshWanObjectsFetchResult = await apolloClient.mutate<InsertMeshWanMutation, InsertMeshWanMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_MeshWan?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateMeshWanByIdQueryResult = FetchResult<UpdateMeshWanByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateMeshWanByIdHelperResultEx = UpdateMeshWanByIdQueryResult & MeshWanByIdHelperResultEx;

    async function updateMeshWanById({ apolloClient, meshWanId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, meshWanId: string, set: MeshWan_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateMeshWanByIdMutation, UpdateMeshWanByIdMutationVariables>, 'mutation'> }): Promise<UpdateMeshWanByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateMeshWanByIdMutation, UpdateMeshWanByIdMutationVariables> = { mutation: UpdateMeshWanByIdDocument, variables: { id:meshWanId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateMeshWanByIdMutation>({ operationType: 'update', entityName:'MeshWan', objects:[{ id:meshWanId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateMeshWanById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateMeshWanByIdQueryResult = await apolloClient.mutate<UpdateMeshWanByIdMutation, UpdateMeshWanByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, meshWan: mutation?.data?.update_MeshWan?.returning && mutation.data.update_MeshWan.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateMeshWanObjectsFetchResult = FetchResult<UpdateMeshWanMutation, Record<string, any>, Record<string, any>>;
    export type UpdateMeshWanObjectsHelperResultEx = UpdateMeshWanObjectsFetchResult & MeshWanObjectsHelperResultEx;

    async function updateMeshWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateMeshWanMutation, UpdateMeshWanMutationVariables>, 'mutation'> }): Promise<UpdateMeshWanObjectsHelperResultEx> {  
      const mutation:UpdateMeshWanObjectsFetchResult = await apolloClient.mutate<UpdateMeshWanMutation, UpdateMeshWanMutationVariables>({ mutation: UpdateMeshWanDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_MeshWan?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveMeshWanModelByIdQueryResult = FetchResult<RemoveMeshWanModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveMeshWanModelByIdQueryHelperResultEx = RemoveMeshWanModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeMeshWanModelById({ apolloClient, meshWanId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, meshWanId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveMeshWanModelByIdMutation, RemoveMeshWanModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveMeshWanModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveMeshWanModelByIdMutation, RemoveMeshWanModelByIdMutationVariables> = { mutation: RemoveMeshWanModelByIdDocument, variables: { id:meshWanId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveMeshWanModelByIdMutation>({ operationType: 'delete', entityName:'meshWan', objects:[{ id:meshWanId }] }); 
        if(logLevel >= 2) console.log(' --> removeMeshWanModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveMeshWanModelByIdMutation>({ operationType: 'delete', entityName:'meshWan', entityId:meshWanId }); }
      
      const mutation:RemoveMeshWanModelByIdQueryResult = await apolloClient.mutate<RemoveMeshWanModelByIdMutation, RemoveMeshWanModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_MeshWan?.affected_rows || 0 };
    }
  

    type RemoveMeshWanModelObjectsQueryResult = FetchResult<RemoveMeshWanModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveMeshWanModelObjectsQueryHelperResultEx = RemoveMeshWanModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeMeshWanModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveMeshWanModelMutation, RemoveMeshWanModelMutationVariables>, 'mutation'> }): Promise<RemoveMeshWanModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveMeshWanModelObjectsQueryResult = await apolloClient.mutate<RemoveMeshWanModelMutation, RemoveMeshWanModelMutationVariables>({ mutation: RemoveMeshWanModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_MeshWan?.affected_rows || 0 };
      }
  

    // MeshWan Fragment Helper Object
    //------------------------------------------------

    export const MeshWanFragmentGQLHelper = {
      queryById: queryMeshWanById,
      queryObjects: queryMeshWanObjects,
      watchQueryById: watchQueryMeshWanById,
      watchQueryObjects: watchQueryMeshWanObjects,
      insert: insertMeshWan,
      insertWithOnConflict: insertMeshWanWithOnConflict,
      insertObjects: insertMeshWanObjects,
      updateById: updateMeshWanById,
      updateObjects: updateMeshWanObjects
    }
  

    // MeshWan Entity Helper Object
    //------------------------------------------------

    export const MeshWanModelGQLHelper = {
      removeById: removeMeshWanModelById,
      removeObjects: removeMeshWanModelObjects
    }
  

    // Monitor HELPERS
    //------------------------------------------------

    export type MonitorByIdHelperResultEx = { monitor:MonitorFragment | null | undefined };
    export type MonitorObjectsHelperResultEx = { objects:MonitorFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryMonitorByIdApolloQueryResult = ApolloQueryResult<QueryMonitorByIdQuery>;
      export type QueryMonitorByIdApolloQueryHelperResultEx = QueryMonitorByIdApolloQueryResult & MonitorByIdHelperResultEx;

      async function queryMonitorById({ apolloClient, monitorId, options }: { apolloClient: ApolloClient<object>, monitorId: string, options?: Omit<QueryOptions<QueryMonitorByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryMonitorByIdApolloQueryHelperResultEx> {
        const query: QueryMonitorByIdApolloQueryResult = await apolloClient.query<QueryMonitorByIdQuery>({ query: QueryMonitorByIdDocument, variables: { monitorId }, ...options });
        
        return { ...query, monitor: query?.data?.Monitor_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryMonitorByIdApolloObservableQuery = ObservableQuery<QueryMonitorByIdQuery>;
      async function watchQueryMonitorById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMonitorByIdQueryVariables>, 'query'> }) : Promise<WatchQueryMonitorByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryMonitorByIdQuery>({ query: QueryMonitorByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryMonitorObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryMonitorObjectsQuery>;
      export type QueryMonitorObjectsObjectsApolloQueryResultEx = QueryMonitorObjectsObjectsApolloQueryResult & MonitorObjectsHelperResultEx;

      async function queryMonitorObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMonitorObjectsQueryVariables>, 'query'> }): Promise<QueryMonitorObjectsObjectsApolloQueryResultEx> {
        const query: QueryMonitorObjectsObjectsApolloQueryResult = await apolloClient.query<QueryMonitorObjectsQuery>({ query: QueryMonitorObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Monitor || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryMonitorObjectsApolloObservableQuery = ObservableQuery<QueryMonitorObjectsQuery>;
      async function watchQueryMonitorObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryMonitorObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryMonitorObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryMonitorObjectsQuery>({ query: QueryMonitorObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertMonitorFetchResult = FetchResult<InsertMonitorMutation, Record<string, any>, Record<string, any>>;
    export type InsertMonitorFetchHelperResultEx = InsertMonitorFetchResult & MonitorByIdHelperResultEx;

    async function insertMonitor({ apolloClient, monitor, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, monitor: Monitor_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMonitorMutation, InsertMonitorMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMonitorFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: monitor });
      const mutationOptions:MutationOptions<InsertMonitorMutation, InsertMonitorMutationVariables> = { mutation: InsertMonitorDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'monitor'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMonitorMutation>({ operationType: 'insert', entityName:'Monitor', objects:[objectForInsert as Monitor_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMonitor - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertMonitorFetchResult = await apolloClient.mutate<InsertMonitorMutation, InsertMonitorMutationVariables>(mutationOptions);
        
      return { ...mutation, monitor: mutation?.data?.insert_Monitor?.returning && mutation.data.insert_Monitor.returning[0] };
    }

    async function insertMonitorWithOnConflict({ apolloClient, monitor, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, monitor: Monitor_Insert_Input, onConflict: Monitor_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMonitorWithOnConflictMutation, InsertMonitorWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMonitorFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: monitor });
      const mutationOptions:MutationOptions<InsertMonitorWithOnConflictMutation, InsertMonitorWithOnConflictMutationVariables> = { mutation: InsertMonitorWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'monitor'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMonitorWithOnConflictMutation>({ operationType: 'insert', entityName:'Monitor', objects:[objectForInsert as Monitor_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMonitorWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertMonitorFetchResult = await apolloClient.mutate<InsertMonitorWithOnConflictMutation, InsertMonitorWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, monitor: mutation?.data?.insert_Monitor?.returning && mutation.data.insert_Monitor.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertMonitorObjectsFetchResult = FetchResult<InsertMonitorMutation, Record<string, any>, Record<string, any>>;
    export type InsertMonitorObjectsHelperResultEx = InsertMonitorObjectsFetchResult & MonitorObjectsHelperResultEx;

    async function insertMonitorObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Monitor_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertMonitorMutation, InsertMonitorMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertMonitorObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertMonitorMutation, InsertMonitorMutationVariables> = { mutation: InsertMonitorDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertMonitorMutation>({ operationType: 'insert', entityName:'Monitor', objects:objectsForInsert as (Monitor_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertMonitor - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertMonitorObjectsFetchResult = await apolloClient.mutate<InsertMonitorMutation, InsertMonitorMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Monitor?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateMonitorByIdQueryResult = FetchResult<UpdateMonitorByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateMonitorByIdHelperResultEx = UpdateMonitorByIdQueryResult & MonitorByIdHelperResultEx;

    async function updateMonitorById({ apolloClient, monitorId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, monitorId: string, set: Monitor_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateMonitorByIdMutation, UpdateMonitorByIdMutationVariables>, 'mutation'> }): Promise<UpdateMonitorByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateMonitorByIdMutation, UpdateMonitorByIdMutationVariables> = { mutation: UpdateMonitorByIdDocument, variables: { id:monitorId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateMonitorByIdMutation>({ operationType: 'update', entityName:'Monitor', objects:[{ id:monitorId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateMonitorById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateMonitorByIdQueryResult = await apolloClient.mutate<UpdateMonitorByIdMutation, UpdateMonitorByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, monitor: mutation?.data?.update_Monitor?.returning && mutation.data.update_Monitor.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateMonitorObjectsFetchResult = FetchResult<UpdateMonitorMutation, Record<string, any>, Record<string, any>>;
    export type UpdateMonitorObjectsHelperResultEx = UpdateMonitorObjectsFetchResult & MonitorObjectsHelperResultEx;

    async function updateMonitorObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateMonitorMutation, UpdateMonitorMutationVariables>, 'mutation'> }): Promise<UpdateMonitorObjectsHelperResultEx> {  
      const mutation:UpdateMonitorObjectsFetchResult = await apolloClient.mutate<UpdateMonitorMutation, UpdateMonitorMutationVariables>({ mutation: UpdateMonitorDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Monitor?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveMonitorModelByIdQueryResult = FetchResult<RemoveMonitorModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveMonitorModelByIdQueryHelperResultEx = RemoveMonitorModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeMonitorModelById({ apolloClient, monitorId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, monitorId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveMonitorModelByIdMutation, RemoveMonitorModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveMonitorModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveMonitorModelByIdMutation, RemoveMonitorModelByIdMutationVariables> = { mutation: RemoveMonitorModelByIdDocument, variables: { id:monitorId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveMonitorModelByIdMutation>({ operationType: 'delete', entityName:'monitor', objects:[{ id:monitorId }] }); 
        if(logLevel >= 2) console.log(' --> removeMonitorModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveMonitorModelByIdMutation>({ operationType: 'delete', entityName:'monitor', entityId:monitorId }); }
      
      const mutation:RemoveMonitorModelByIdQueryResult = await apolloClient.mutate<RemoveMonitorModelByIdMutation, RemoveMonitorModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Monitor?.affected_rows || 0 };
    }
  

    type RemoveMonitorModelObjectsQueryResult = FetchResult<RemoveMonitorModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveMonitorModelObjectsQueryHelperResultEx = RemoveMonitorModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeMonitorModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveMonitorModelMutation, RemoveMonitorModelMutationVariables>, 'mutation'> }): Promise<RemoveMonitorModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveMonitorModelObjectsQueryResult = await apolloClient.mutate<RemoveMonitorModelMutation, RemoveMonitorModelMutationVariables>({ mutation: RemoveMonitorModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Monitor?.affected_rows || 0 };
      }
  

    // Monitor Fragment Helper Object
    //------------------------------------------------

    export const MonitorFragmentGQLHelper = {
      queryById: queryMonitorById,
      queryObjects: queryMonitorObjects,
      watchQueryById: watchQueryMonitorById,
      watchQueryObjects: watchQueryMonitorObjects,
      insert: insertMonitor,
      insertWithOnConflict: insertMonitorWithOnConflict,
      insertObjects: insertMonitorObjects,
      updateById: updateMonitorById,
      updateObjects: updateMonitorObjects
    }
  

    // Monitor Entity Helper Object
    //------------------------------------------------

    export const MonitorModelGQLHelper = {
      removeById: removeMonitorModelById,
      removeObjects: removeMonitorModelObjects
    }
  

    // Profile HELPERS
    //------------------------------------------------

    export type ProfileByIdHelperResultEx = { profile:ProfileFragment | null | undefined };
    export type ProfileObjectsHelperResultEx = { objects:ProfileFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryProfileByIdApolloQueryResult = ApolloQueryResult<QueryProfileByIdQuery>;
      export type QueryProfileByIdApolloQueryHelperResultEx = QueryProfileByIdApolloQueryResult & ProfileByIdHelperResultEx;

      async function queryProfileById({ apolloClient, profileId, options }: { apolloClient: ApolloClient<object>, profileId: string, options?: Omit<QueryOptions<QueryProfileByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryProfileByIdApolloQueryHelperResultEx> {
        const query: QueryProfileByIdApolloQueryResult = await apolloClient.query<QueryProfileByIdQuery>({ query: QueryProfileByIdDocument, variables: { profileId }, ...options });
        
        return { ...query, profile: query?.data?.Profile_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryProfileByIdApolloObservableQuery = ObservableQuery<QueryProfileByIdQuery>;
      async function watchQueryProfileById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryProfileByIdQueryVariables>, 'query'> }) : Promise<WatchQueryProfileByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryProfileByIdQuery>({ query: QueryProfileByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryProfileObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryProfileObjectsQuery>;
      export type QueryProfileObjectsObjectsApolloQueryResultEx = QueryProfileObjectsObjectsApolloQueryResult & ProfileObjectsHelperResultEx;

      async function queryProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryProfileObjectsQueryVariables>, 'query'> }): Promise<QueryProfileObjectsObjectsApolloQueryResultEx> {
        const query: QueryProfileObjectsObjectsApolloQueryResult = await apolloClient.query<QueryProfileObjectsQuery>({ query: QueryProfileObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Profile || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryProfileObjectsApolloObservableQuery = ObservableQuery<QueryProfileObjectsQuery>;
      async function watchQueryProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryProfileObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryProfileObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryProfileObjectsQuery>({ query: QueryProfileObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertProfileFetchResult = FetchResult<InsertProfileMutation, Record<string, any>, Record<string, any>>;
    export type InsertProfileFetchHelperResultEx = InsertProfileFetchResult & ProfileByIdHelperResultEx;

    async function insertProfile({ apolloClient, profile, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, profile: Profile_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertProfileMutation, InsertProfileMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertProfileFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: profile });
      const mutationOptions:MutationOptions<InsertProfileMutation, InsertProfileMutationVariables> = { mutation: InsertProfileDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'profile'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertProfileMutation>({ operationType: 'insert', entityName:'Profile', objects:[objectForInsert as Profile_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertProfile - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertProfileFetchResult = await apolloClient.mutate<InsertProfileMutation, InsertProfileMutationVariables>(mutationOptions);
        
      return { ...mutation, profile: mutation?.data?.insert_Profile?.returning && mutation.data.insert_Profile.returning[0] };
    }

    async function insertProfileWithOnConflict({ apolloClient, profile, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, profile: Profile_Insert_Input, onConflict: Profile_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertProfileWithOnConflictMutation, InsertProfileWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertProfileFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: profile });
      const mutationOptions:MutationOptions<InsertProfileWithOnConflictMutation, InsertProfileWithOnConflictMutationVariables> = { mutation: InsertProfileWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'profile'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertProfileWithOnConflictMutation>({ operationType: 'insert', entityName:'Profile', objects:[objectForInsert as Profile_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertProfileWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertProfileFetchResult = await apolloClient.mutate<InsertProfileWithOnConflictMutation, InsertProfileWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, profile: mutation?.data?.insert_Profile?.returning && mutation.data.insert_Profile.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertProfileObjectsFetchResult = FetchResult<InsertProfileMutation, Record<string, any>, Record<string, any>>;
    export type InsertProfileObjectsHelperResultEx = InsertProfileObjectsFetchResult & ProfileObjectsHelperResultEx;

    async function insertProfileObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Profile_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertProfileMutation, InsertProfileMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertProfileObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertProfileMutation, InsertProfileMutationVariables> = { mutation: InsertProfileDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertProfileMutation>({ operationType: 'insert', entityName:'Profile', objects:objectsForInsert as (Profile_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertProfile - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertProfileObjectsFetchResult = await apolloClient.mutate<InsertProfileMutation, InsertProfileMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Profile?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateProfileByIdQueryResult = FetchResult<UpdateProfileByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateProfileByIdHelperResultEx = UpdateProfileByIdQueryResult & ProfileByIdHelperResultEx;

    async function updateProfileById({ apolloClient, profileId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, profileId: string, set: Profile_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateProfileByIdMutation, UpdateProfileByIdMutationVariables>, 'mutation'> }): Promise<UpdateProfileByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateProfileByIdMutation, UpdateProfileByIdMutationVariables> = { mutation: UpdateProfileByIdDocument, variables: { id:profileId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateProfileByIdMutation>({ operationType: 'update', entityName:'Profile', objects:[{ id:profileId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateProfileById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateProfileByIdQueryResult = await apolloClient.mutate<UpdateProfileByIdMutation, UpdateProfileByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, profile: mutation?.data?.update_Profile?.returning && mutation.data.update_Profile.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateProfileObjectsFetchResult = FetchResult<UpdateProfileMutation, Record<string, any>, Record<string, any>>;
    export type UpdateProfileObjectsHelperResultEx = UpdateProfileObjectsFetchResult & ProfileObjectsHelperResultEx;

    async function updateProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>, 'mutation'> }): Promise<UpdateProfileObjectsHelperResultEx> {  
      const mutation:UpdateProfileObjectsFetchResult = await apolloClient.mutate<UpdateProfileMutation, UpdateProfileMutationVariables>({ mutation: UpdateProfileDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Profile?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveProfileModelByIdQueryResult = FetchResult<RemoveProfileModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveProfileModelByIdQueryHelperResultEx = RemoveProfileModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeProfileModelById({ apolloClient, profileId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, profileId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveProfileModelByIdMutation, RemoveProfileModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveProfileModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveProfileModelByIdMutation, RemoveProfileModelByIdMutationVariables> = { mutation: RemoveProfileModelByIdDocument, variables: { id:profileId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveProfileModelByIdMutation>({ operationType: 'delete', entityName:'profile', objects:[{ id:profileId }] }); 
        if(logLevel >= 2) console.log(' --> removeProfileModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveProfileModelByIdMutation>({ operationType: 'delete', entityName:'profile', entityId:profileId }); }
      
      const mutation:RemoveProfileModelByIdQueryResult = await apolloClient.mutate<RemoveProfileModelByIdMutation, RemoveProfileModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Profile?.affected_rows || 0 };
    }
  

    type RemoveProfileModelObjectsQueryResult = FetchResult<RemoveProfileModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveProfileModelObjectsQueryHelperResultEx = RemoveProfileModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeProfileModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveProfileModelMutation, RemoveProfileModelMutationVariables>, 'mutation'> }): Promise<RemoveProfileModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveProfileModelObjectsQueryResult = await apolloClient.mutate<RemoveProfileModelMutation, RemoveProfileModelMutationVariables>({ mutation: RemoveProfileModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Profile?.affected_rows || 0 };
      }
  

    // Profile Fragment Helper Object
    //------------------------------------------------

    export const ProfileFragmentGQLHelper = {
      queryById: queryProfileById,
      queryObjects: queryProfileObjects,
      watchQueryById: watchQueryProfileById,
      watchQueryObjects: watchQueryProfileObjects,
      insert: insertProfile,
      insertWithOnConflict: insertProfileWithOnConflict,
      insertObjects: insertProfileObjects,
      updateById: updateProfileById,
      updateObjects: updateProfileObjects
    }
  

    // Profile Entity Helper Object
    //------------------------------------------------

    export const ProfileModelGQLHelper = {
      removeById: removeProfileModelById,
      removeObjects: removeProfileModelObjects
    }
  

    // PublicIp HELPERS
    //------------------------------------------------

    export type PublicIpByIdHelperResultEx = { publicIp:PublicIpFragment | null | undefined };
    export type PublicIpObjectsHelperResultEx = { objects:PublicIpFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryPublicIpByIdApolloQueryResult = ApolloQueryResult<QueryPublicIpByIdQuery>;
      export type QueryPublicIpByIdApolloQueryHelperResultEx = QueryPublicIpByIdApolloQueryResult & PublicIpByIdHelperResultEx;

      async function queryPublicIpById({ apolloClient, publicIpId, options }: { apolloClient: ApolloClient<object>, publicIpId: string, options?: Omit<QueryOptions<QueryPublicIpByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryPublicIpByIdApolloQueryHelperResultEx> {
        const query: QueryPublicIpByIdApolloQueryResult = await apolloClient.query<QueryPublicIpByIdQuery>({ query: QueryPublicIpByIdDocument, variables: { publicIpId }, ...options });
        
        return { ...query, publicIp: query?.data?.PublicIp_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryPublicIpByIdApolloObservableQuery = ObservableQuery<QueryPublicIpByIdQuery>;
      async function watchQueryPublicIpById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPublicIpByIdQueryVariables>, 'query'> }) : Promise<WatchQueryPublicIpByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryPublicIpByIdQuery>({ query: QueryPublicIpByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryPublicIpObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryPublicIpObjectsQuery>;
      export type QueryPublicIpObjectsObjectsApolloQueryResultEx = QueryPublicIpObjectsObjectsApolloQueryResult & PublicIpObjectsHelperResultEx;

      async function queryPublicIpObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPublicIpObjectsQueryVariables>, 'query'> }): Promise<QueryPublicIpObjectsObjectsApolloQueryResultEx> {
        const query: QueryPublicIpObjectsObjectsApolloQueryResult = await apolloClient.query<QueryPublicIpObjectsQuery>({ query: QueryPublicIpObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.PublicIp || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryPublicIpObjectsApolloObservableQuery = ObservableQuery<QueryPublicIpObjectsQuery>;
      async function watchQueryPublicIpObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryPublicIpObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryPublicIpObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryPublicIpObjectsQuery>({ query: QueryPublicIpObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertPublicIpFetchResult = FetchResult<InsertPublicIpMutation, Record<string, any>, Record<string, any>>;
    export type InsertPublicIpFetchHelperResultEx = InsertPublicIpFetchResult & PublicIpByIdHelperResultEx;

    async function insertPublicIp({ apolloClient, publicIp, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, publicIp: PublicIp_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPublicIpMutation, InsertPublicIpMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPublicIpFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: publicIp });
      const mutationOptions:MutationOptions<InsertPublicIpMutation, InsertPublicIpMutationVariables> = { mutation: InsertPublicIpDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'publicIp'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPublicIpMutation>({ operationType: 'insert', entityName:'PublicIp', objects:[objectForInsert as PublicIp_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPublicIp - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertPublicIpFetchResult = await apolloClient.mutate<InsertPublicIpMutation, InsertPublicIpMutationVariables>(mutationOptions);
        
      return { ...mutation, publicIp: mutation?.data?.insert_PublicIp?.returning && mutation.data.insert_PublicIp.returning[0] };
    }

    async function insertPublicIpWithOnConflict({ apolloClient, publicIp, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, publicIp: PublicIp_Insert_Input, onConflict: PublicIp_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPublicIpWithOnConflictMutation, InsertPublicIpWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPublicIpFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: publicIp });
      const mutationOptions:MutationOptions<InsertPublicIpWithOnConflictMutation, InsertPublicIpWithOnConflictMutationVariables> = { mutation: InsertPublicIpWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'publicIp'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPublicIpWithOnConflictMutation>({ operationType: 'insert', entityName:'PublicIp', objects:[objectForInsert as PublicIp_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPublicIpWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertPublicIpFetchResult = await apolloClient.mutate<InsertPublicIpWithOnConflictMutation, InsertPublicIpWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, publicIp: mutation?.data?.insert_PublicIp?.returning && mutation.data.insert_PublicIp.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertPublicIpObjectsFetchResult = FetchResult<InsertPublicIpMutation, Record<string, any>, Record<string, any>>;
    export type InsertPublicIpObjectsHelperResultEx = InsertPublicIpObjectsFetchResult & PublicIpObjectsHelperResultEx;

    async function insertPublicIpObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: PublicIp_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertPublicIpMutation, InsertPublicIpMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertPublicIpObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertPublicIpMutation, InsertPublicIpMutationVariables> = { mutation: InsertPublicIpDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertPublicIpMutation>({ operationType: 'insert', entityName:'PublicIp', objects:objectsForInsert as (PublicIp_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertPublicIp - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertPublicIpObjectsFetchResult = await apolloClient.mutate<InsertPublicIpMutation, InsertPublicIpMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_PublicIp?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdatePublicIpByIdQueryResult = FetchResult<UpdatePublicIpByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdatePublicIpByIdHelperResultEx = UpdatePublicIpByIdQueryResult & PublicIpByIdHelperResultEx;

    async function updatePublicIpById({ apolloClient, publicIpId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, publicIpId: string, set: PublicIp_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdatePublicIpByIdMutation, UpdatePublicIpByIdMutationVariables>, 'mutation'> }): Promise<UpdatePublicIpByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdatePublicIpByIdMutation, UpdatePublicIpByIdMutationVariables> = { mutation: UpdatePublicIpByIdDocument, variables: { id:publicIpId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdatePublicIpByIdMutation>({ operationType: 'update', entityName:'PublicIp', objects:[{ id:publicIpId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updatePublicIpById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdatePublicIpByIdQueryResult = await apolloClient.mutate<UpdatePublicIpByIdMutation, UpdatePublicIpByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, publicIp: mutation?.data?.update_PublicIp?.returning && mutation.data.update_PublicIp.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdatePublicIpObjectsFetchResult = FetchResult<UpdatePublicIpMutation, Record<string, any>, Record<string, any>>;
    export type UpdatePublicIpObjectsHelperResultEx = UpdatePublicIpObjectsFetchResult & PublicIpObjectsHelperResultEx;

    async function updatePublicIpObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdatePublicIpMutation, UpdatePublicIpMutationVariables>, 'mutation'> }): Promise<UpdatePublicIpObjectsHelperResultEx> {  
      const mutation:UpdatePublicIpObjectsFetchResult = await apolloClient.mutate<UpdatePublicIpMutation, UpdatePublicIpMutationVariables>({ mutation: UpdatePublicIpDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_PublicIp?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemovePublicIpModelByIdQueryResult = FetchResult<RemovePublicIpModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemovePublicIpModelByIdQueryHelperResultEx = RemovePublicIpModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removePublicIpModelById({ apolloClient, publicIpId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, publicIpId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemovePublicIpModelByIdMutation, RemovePublicIpModelByIdMutationVariables>, 'mutation'> }): Promise<RemovePublicIpModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemovePublicIpModelByIdMutation, RemovePublicIpModelByIdMutationVariables> = { mutation: RemovePublicIpModelByIdDocument, variables: { id:publicIpId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemovePublicIpModelByIdMutation>({ operationType: 'delete', entityName:'publicIp', objects:[{ id:publicIpId }] }); 
        if(logLevel >= 2) console.log(' --> removePublicIpModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemovePublicIpModelByIdMutation>({ operationType: 'delete', entityName:'publicIp', entityId:publicIpId }); }
      
      const mutation:RemovePublicIpModelByIdQueryResult = await apolloClient.mutate<RemovePublicIpModelByIdMutation, RemovePublicIpModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_PublicIp?.affected_rows || 0 };
    }
  

    type RemovePublicIpModelObjectsQueryResult = FetchResult<RemovePublicIpModelMutation, Record<string, any>, Record<string, any>>;
    export type RemovePublicIpModelObjectsQueryHelperResultEx = RemovePublicIpModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removePublicIpModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemovePublicIpModelMutation, RemovePublicIpModelMutationVariables>, 'mutation'> }): Promise<RemovePublicIpModelObjectsQueryHelperResultEx> {  
        const mutation:RemovePublicIpModelObjectsQueryResult = await apolloClient.mutate<RemovePublicIpModelMutation, RemovePublicIpModelMutationVariables>({ mutation: RemovePublicIpModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_PublicIp?.affected_rows || 0 };
      }
  

    // PublicIp Fragment Helper Object
    //------------------------------------------------

    export const PublicIpFragmentGQLHelper = {
      queryById: queryPublicIpById,
      queryObjects: queryPublicIpObjects,
      watchQueryById: watchQueryPublicIpById,
      watchQueryObjects: watchQueryPublicIpObjects,
      insert: insertPublicIp,
      insertWithOnConflict: insertPublicIpWithOnConflict,
      insertObjects: insertPublicIpObjects,
      updateById: updatePublicIpById,
      updateObjects: updatePublicIpObjects
    }
  

    // PublicIp Entity Helper Object
    //------------------------------------------------

    export const PublicIpModelGQLHelper = {
      removeById: removePublicIpModelById,
      removeObjects: removePublicIpModelObjects
    }
  

    // Server HELPERS
    //------------------------------------------------

    export type ServerByIdHelperResultEx = { server:ServerFragment | null | undefined };
    export type ServerObjectsHelperResultEx = { objects:ServerFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryServerByIdApolloQueryResult = ApolloQueryResult<QueryServerByIdQuery>;
      export type QueryServerByIdApolloQueryHelperResultEx = QueryServerByIdApolloQueryResult & ServerByIdHelperResultEx;

      async function queryServerById({ apolloClient, serverId, options }: { apolloClient: ApolloClient<object>, serverId: string, options?: Omit<QueryOptions<QueryServerByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryServerByIdApolloQueryHelperResultEx> {
        const query: QueryServerByIdApolloQueryResult = await apolloClient.query<QueryServerByIdQuery>({ query: QueryServerByIdDocument, variables: { serverId }, ...options });
        
        return { ...query, server: query?.data?.Server_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryServerByIdApolloObservableQuery = ObservableQuery<QueryServerByIdQuery>;
      async function watchQueryServerById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryServerByIdQueryVariables>, 'query'> }) : Promise<WatchQueryServerByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryServerByIdQuery>({ query: QueryServerByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryServerObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryServerObjectsQuery>;
      export type QueryServerObjectsObjectsApolloQueryResultEx = QueryServerObjectsObjectsApolloQueryResult & ServerObjectsHelperResultEx;

      async function queryServerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryServerObjectsQueryVariables>, 'query'> }): Promise<QueryServerObjectsObjectsApolloQueryResultEx> {
        const query: QueryServerObjectsObjectsApolloQueryResult = await apolloClient.query<QueryServerObjectsQuery>({ query: QueryServerObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Server || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryServerObjectsApolloObservableQuery = ObservableQuery<QueryServerObjectsQuery>;
      async function watchQueryServerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryServerObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryServerObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryServerObjectsQuery>({ query: QueryServerObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertServerFetchResult = FetchResult<InsertServerMutation, Record<string, any>, Record<string, any>>;
    export type InsertServerFetchHelperResultEx = InsertServerFetchResult & ServerByIdHelperResultEx;

    async function insertServer({ apolloClient, server, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, server: Server_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertServerMutation, InsertServerMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertServerFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: server });
      const mutationOptions:MutationOptions<InsertServerMutation, InsertServerMutationVariables> = { mutation: InsertServerDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'server'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertServerMutation>({ operationType: 'insert', entityName:'Server', objects:[objectForInsert as Server_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertServer - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertServerFetchResult = await apolloClient.mutate<InsertServerMutation, InsertServerMutationVariables>(mutationOptions);
        
      return { ...mutation, server: mutation?.data?.insert_Server?.returning && mutation.data.insert_Server.returning[0] };
    }

    async function insertServerWithOnConflict({ apolloClient, server, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, server: Server_Insert_Input, onConflict: Server_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertServerWithOnConflictMutation, InsertServerWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertServerFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: server });
      const mutationOptions:MutationOptions<InsertServerWithOnConflictMutation, InsertServerWithOnConflictMutationVariables> = { mutation: InsertServerWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'server'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertServerWithOnConflictMutation>({ operationType: 'insert', entityName:'Server', objects:[objectForInsert as Server_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertServerWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertServerFetchResult = await apolloClient.mutate<InsertServerWithOnConflictMutation, InsertServerWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, server: mutation?.data?.insert_Server?.returning && mutation.data.insert_Server.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertServerObjectsFetchResult = FetchResult<InsertServerMutation, Record<string, any>, Record<string, any>>;
    export type InsertServerObjectsHelperResultEx = InsertServerObjectsFetchResult & ServerObjectsHelperResultEx;

    async function insertServerObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Server_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertServerMutation, InsertServerMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertServerObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertServerMutation, InsertServerMutationVariables> = { mutation: InsertServerDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertServerMutation>({ operationType: 'insert', entityName:'Server', objects:objectsForInsert as (Server_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertServer - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertServerObjectsFetchResult = await apolloClient.mutate<InsertServerMutation, InsertServerMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Server?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateServerByIdQueryResult = FetchResult<UpdateServerByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateServerByIdHelperResultEx = UpdateServerByIdQueryResult & ServerByIdHelperResultEx;

    async function updateServerById({ apolloClient, serverId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, serverId: string, set: Server_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateServerByIdMutation, UpdateServerByIdMutationVariables>, 'mutation'> }): Promise<UpdateServerByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateServerByIdMutation, UpdateServerByIdMutationVariables> = { mutation: UpdateServerByIdDocument, variables: { id:serverId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateServerByIdMutation>({ operationType: 'update', entityName:'Server', objects:[{ id:serverId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateServerById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateServerByIdQueryResult = await apolloClient.mutate<UpdateServerByIdMutation, UpdateServerByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, server: mutation?.data?.update_Server?.returning && mutation.data.update_Server.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateServerObjectsFetchResult = FetchResult<UpdateServerMutation, Record<string, any>, Record<string, any>>;
    export type UpdateServerObjectsHelperResultEx = UpdateServerObjectsFetchResult & ServerObjectsHelperResultEx;

    async function updateServerObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateServerMutation, UpdateServerMutationVariables>, 'mutation'> }): Promise<UpdateServerObjectsHelperResultEx> {  
      const mutation:UpdateServerObjectsFetchResult = await apolloClient.mutate<UpdateServerMutation, UpdateServerMutationVariables>({ mutation: UpdateServerDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Server?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveServerModelByIdQueryResult = FetchResult<RemoveServerModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveServerModelByIdQueryHelperResultEx = RemoveServerModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeServerModelById({ apolloClient, serverId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, serverId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveServerModelByIdMutation, RemoveServerModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveServerModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveServerModelByIdMutation, RemoveServerModelByIdMutationVariables> = { mutation: RemoveServerModelByIdDocument, variables: { id:serverId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveServerModelByIdMutation>({ operationType: 'delete', entityName:'server', objects:[{ id:serverId }] }); 
        if(logLevel >= 2) console.log(' --> removeServerModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveServerModelByIdMutation>({ operationType: 'delete', entityName:'server', entityId:serverId }); }
      
      const mutation:RemoveServerModelByIdQueryResult = await apolloClient.mutate<RemoveServerModelByIdMutation, RemoveServerModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Server?.affected_rows || 0 };
    }
  

    type RemoveServerModelObjectsQueryResult = FetchResult<RemoveServerModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveServerModelObjectsQueryHelperResultEx = RemoveServerModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeServerModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveServerModelMutation, RemoveServerModelMutationVariables>, 'mutation'> }): Promise<RemoveServerModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveServerModelObjectsQueryResult = await apolloClient.mutate<RemoveServerModelMutation, RemoveServerModelMutationVariables>({ mutation: RemoveServerModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Server?.affected_rows || 0 };
      }
  

    // Server Fragment Helper Object
    //------------------------------------------------

    export const ServerFragmentGQLHelper = {
      queryById: queryServerById,
      queryObjects: queryServerObjects,
      watchQueryById: watchQueryServerById,
      watchQueryObjects: watchQueryServerObjects,
      insert: insertServer,
      insertWithOnConflict: insertServerWithOnConflict,
      insertObjects: insertServerObjects,
      updateById: updateServerById,
      updateObjects: updateServerObjects
    }
  

    // Server Entity Helper Object
    //------------------------------------------------

    export const ServerModelGQLHelper = {
      removeById: removeServerModelById,
      removeObjects: removeServerModelObjects
    }
  

    // ServerBridge HELPERS
    //------------------------------------------------

    export type ServerBridgeByIdHelperResultEx = { serverBridge:ServerBridgeFragment | null | undefined };
    export type ServerBridgeObjectsHelperResultEx = { objects:ServerBridgeFragment[] };
    
  

      // Query Fetch Objects Helper
      //
      export type QueryServerBridgeObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryServerBridgeObjectsQuery>;
      export type QueryServerBridgeObjectsObjectsApolloQueryResultEx = QueryServerBridgeObjectsObjectsApolloQueryResult & ServerBridgeObjectsHelperResultEx;

      async function queryServerBridgeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryServerBridgeObjectsQueryVariables>, 'query'> }): Promise<QueryServerBridgeObjectsObjectsApolloQueryResultEx> {
        const query: QueryServerBridgeObjectsObjectsApolloQueryResult = await apolloClient.query<QueryServerBridgeObjectsQuery>({ query: QueryServerBridgeObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.ServerBridge || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryServerBridgeObjectsApolloObservableQuery = ObservableQuery<QueryServerBridgeObjectsQuery>;
      async function watchQueryServerBridgeObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryServerBridgeObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryServerBridgeObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryServerBridgeObjectsQuery>({ query: QueryServerBridgeObjectsDocument, ...options });
      }
    

      async function removeServerBridgeModelObjects({
        apolloClient,
        options
      }: {
        apolloClient: ApolloClient<object>;
        options: Omit<MutationOptions<RemoveServerModelMutation, RemoveServerModelMutationVariables>, 'mutation'>;
      }): Promise<RemoveServerModelObjectsQueryHelperResultEx> {
        const mutation: RemoveServerModelObjectsQueryResult = await apolloClient.mutate<
          RemoveServerModelMutation,
          RemoveServerModelMutationVariables
        >({
          mutation: RemoveServerModelDocument,
          ...options
        });
      
        return {
          ...mutation,
          affected_rows: mutation?.data?.delete_Server?.affected_rows || 0
        };
      }
  

    // ServerBridge Fragment Helper Object
    //------------------------------------------------

    export const ServerBridgeFragmentGQLHelper = {
      queryObjects: queryServerBridgeObjects,
      watchQueryObjects: watchQueryServerBridgeObjects,
    }
  

    // ServerBridge Entity Helper Object
    //------------------------------------------------

    export const ServerBridgeModelGQLHelper = {
      removeObjects: removeServerBridgeModelObjects
    }
  

    // Site HELPERS
    //------------------------------------------------

    export type SiteByIdHelperResultEx = { site:SiteFragment | null | undefined };
    export type SiteObjectsHelperResultEx = { objects:SiteFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QuerySiteByIdApolloQueryResult = ApolloQueryResult<QuerySiteByIdQuery>;
      export type QuerySiteByIdApolloQueryHelperResultEx = QuerySiteByIdApolloQueryResult & SiteByIdHelperResultEx;

      async function querySiteById({ apolloClient, siteId, options }: { apolloClient: ApolloClient<object>, siteId: string, options?: Omit<QueryOptions<QuerySiteByIdQueryVariables>, 'query' | 'variables'> }): Promise<QuerySiteByIdApolloQueryHelperResultEx> {
        const query: QuerySiteByIdApolloQueryResult = await apolloClient.query<QuerySiteByIdQuery>({ query: QuerySiteByIdDocument, variables: { siteId }, ...options });
        
        return { ...query, site: query?.data?.Site_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQuerySiteByIdApolloObservableQuery = ObservableQuery<QuerySiteByIdQuery>;
      async function watchQuerySiteById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteByIdQueryVariables>, 'query'> }) : Promise<WatchQuerySiteByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteByIdQuery>({ query: QuerySiteByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QuerySiteObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySiteObjectsQuery>;
      export type QuerySiteObjectsObjectsApolloQueryResultEx = QuerySiteObjectsObjectsApolloQueryResult & SiteObjectsHelperResultEx;

      async function querySiteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteObjectsQueryVariables>, 'query'> }): Promise<QuerySiteObjectsObjectsApolloQueryResultEx> {
        const query: QuerySiteObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySiteObjectsQuery>({ query: QuerySiteObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Site || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQuerySiteObjectsApolloObservableQuery = ObservableQuery<QuerySiteObjectsQuery>;
      async function watchQuerySiteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteObjectsQueryVariables>, 'query'> }) : Promise<WatchQuerySiteObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteObjectsQuery>({ query: QuerySiteObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertSiteFetchResult = FetchResult<InsertSiteMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteFetchHelperResultEx = InsertSiteFetchResult & SiteByIdHelperResultEx;

    async function insertSite({ apolloClient, site, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, site: Site_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteMutation, InsertSiteMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: site });
      const mutationOptions:MutationOptions<InsertSiteMutation, InsertSiteMutationVariables> = { mutation: InsertSiteDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'site'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteMutation>({ operationType: 'insert', entityName:'Site', objects:[objectForInsert as Site_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSite - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteFetchResult = await apolloClient.mutate<InsertSiteMutation, InsertSiteMutationVariables>(mutationOptions);
        
      return { ...mutation, site: mutation?.data?.insert_Site?.returning && mutation.data.insert_Site.returning[0] };
    }

    async function insertSiteWithOnConflict({ apolloClient, site, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, site: Site_Insert_Input, onConflict: Site_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteWithOnConflictMutation, InsertSiteWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: site });
      const mutationOptions:MutationOptions<InsertSiteWithOnConflictMutation, InsertSiteWithOnConflictMutationVariables> = { mutation: InsertSiteWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'site'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteWithOnConflictMutation>({ operationType: 'insert', entityName:'Site', objects:[objectForInsert as Site_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteFetchResult = await apolloClient.mutate<InsertSiteWithOnConflictMutation, InsertSiteWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, site: mutation?.data?.insert_Site?.returning && mutation.data.insert_Site.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertSiteObjectsFetchResult = FetchResult<InsertSiteMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteObjectsHelperResultEx = InsertSiteObjectsFetchResult & SiteObjectsHelperResultEx;

    async function insertSiteObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Site_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteMutation, InsertSiteMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertSiteMutation, InsertSiteMutationVariables> = { mutation: InsertSiteDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteMutation>({ operationType: 'insert', entityName:'Site', objects:objectsForInsert as (Site_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSite - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertSiteObjectsFetchResult = await apolloClient.mutate<InsertSiteMutation, InsertSiteMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Site?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateSiteByIdQueryResult = FetchResult<UpdateSiteByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteByIdHelperResultEx = UpdateSiteByIdQueryResult & SiteByIdHelperResultEx;

    async function updateSiteById({ apolloClient, siteId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, siteId: string, set: Site_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>, 'mutation'> }): Promise<UpdateSiteByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables> = { mutation: UpdateSiteByIdDocument, variables: { id:siteId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSiteByIdMutation>({ operationType: 'update', entityName:'Site', objects:[{ id:siteId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateSiteById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateSiteByIdQueryResult = await apolloClient.mutate<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, site: mutation?.data?.update_Site?.returning && mutation.data.update_Site.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateSiteObjectsFetchResult = FetchResult<UpdateSiteMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteObjectsHelperResultEx = UpdateSiteObjectsFetchResult & SiteObjectsHelperResultEx;

    async function updateSiteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>, 'mutation'> }): Promise<UpdateSiteObjectsHelperResultEx> {  
      const mutation:UpdateSiteObjectsFetchResult = await apolloClient.mutate<UpdateSiteMutation, UpdateSiteMutationVariables>({ mutation: UpdateSiteDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Site?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveSiteModelByIdQueryResult = FetchResult<RemoveSiteModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteModelByIdQueryHelperResultEx = RemoveSiteModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeSiteModelById({ apolloClient, siteId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, siteId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveSiteModelByIdMutation, RemoveSiteModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveSiteModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveSiteModelByIdMutation, RemoveSiteModelByIdMutationVariables> = { mutation: RemoveSiteModelByIdDocument, variables: { id:siteId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSiteModelByIdMutation>({ operationType: 'delete', entityName:'site', objects:[{ id:siteId }] }); 
        if(logLevel >= 2) console.log(' --> removeSiteModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveSiteModelByIdMutation>({ operationType: 'delete', entityName:'site', entityId:siteId }); }
      
      const mutation:RemoveSiteModelByIdQueryResult = await apolloClient.mutate<RemoveSiteModelByIdMutation, RemoveSiteModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Site?.affected_rows || 0 };
    }
  

    type RemoveSiteModelObjectsQueryResult = FetchResult<RemoveSiteModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteModelObjectsQueryHelperResultEx = RemoveSiteModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeSiteModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveSiteModelMutation, RemoveSiteModelMutationVariables>, 'mutation'> }): Promise<RemoveSiteModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveSiteModelObjectsQueryResult = await apolloClient.mutate<RemoveSiteModelMutation, RemoveSiteModelMutationVariables>({ mutation: RemoveSiteModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Site?.affected_rows || 0 };
      }
  

    // Site Fragment Helper Object
    //------------------------------------------------

    export const SiteFragmentGQLHelper = {
      queryById: querySiteById,
      queryObjects: querySiteObjects,
      watchQueryById: watchQuerySiteById,
      watchQueryObjects: watchQuerySiteObjects,
      insert: insertSite,
      insertWithOnConflict: insertSiteWithOnConflict,
      insertObjects: insertSiteObjects,
      updateById: updateSiteById,
      updateObjects: updateSiteObjects
    }
  

    // Site Entity Helper Object
    //------------------------------------------------

    export const SiteModelGQLHelper = {
      removeById: removeSiteModelById,
      removeObjects: removeSiteModelObjects
    }
  

    // SiteContact HELPERS
    //------------------------------------------------

    export type SiteContactByIdHelperResultEx = { siteContact:SiteContactFragment | null | undefined };
    export type SiteContactObjectsHelperResultEx = { objects:SiteContactFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QuerySiteContactByIdApolloQueryResult = ApolloQueryResult<QuerySiteContactByIdQuery>;
      export type QuerySiteContactByIdApolloQueryHelperResultEx = QuerySiteContactByIdApolloQueryResult & SiteContactByIdHelperResultEx;

      async function querySiteContactById({ apolloClient, siteContactId, options }: { apolloClient: ApolloClient<object>, siteContactId: string, options?: Omit<QueryOptions<QuerySiteContactByIdQueryVariables>, 'query' | 'variables'> }): Promise<QuerySiteContactByIdApolloQueryHelperResultEx> {
        const query: QuerySiteContactByIdApolloQueryResult = await apolloClient.query<QuerySiteContactByIdQuery>({ query: QuerySiteContactByIdDocument, variables: { siteContactId }, ...options });
        
        return { ...query, siteContact: query?.data?.SiteContact_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQuerySiteContactByIdApolloObservableQuery = ObservableQuery<QuerySiteContactByIdQuery>;
      async function watchQuerySiteContactById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteContactByIdQueryVariables>, 'query'> }) : Promise<WatchQuerySiteContactByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteContactByIdQuery>({ query: QuerySiteContactByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QuerySiteContactObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySiteContactObjectsQuery>;
      export type QuerySiteContactObjectsObjectsApolloQueryResultEx = QuerySiteContactObjectsObjectsApolloQueryResult & SiteContactObjectsHelperResultEx;

      async function querySiteContactObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteContactObjectsQueryVariables>, 'query'> }): Promise<QuerySiteContactObjectsObjectsApolloQueryResultEx> {
        const query: QuerySiteContactObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySiteContactObjectsQuery>({ query: QuerySiteContactObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.SiteContact || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQuerySiteContactObjectsApolloObservableQuery = ObservableQuery<QuerySiteContactObjectsQuery>;
      async function watchQuerySiteContactObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteContactObjectsQueryVariables>, 'query'> }) : Promise<WatchQuerySiteContactObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteContactObjectsQuery>({ query: QuerySiteContactObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertSiteContactFetchResult = FetchResult<InsertSiteContactMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteContactFetchHelperResultEx = InsertSiteContactFetchResult & SiteContactByIdHelperResultEx;

    async function insertSiteContact({ apolloClient, siteContact, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteContact: SiteContact_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteContactMutation, InsertSiteContactMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteContactFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteContact });
      const mutationOptions:MutationOptions<InsertSiteContactMutation, InsertSiteContactMutationVariables> = { mutation: InsertSiteContactDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteContact'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteContactMutation>({ operationType: 'insert', entityName:'SiteContact', objects:[objectForInsert as SiteContact_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteContact - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteContactFetchResult = await apolloClient.mutate<InsertSiteContactMutation, InsertSiteContactMutationVariables>(mutationOptions);
        
      return { ...mutation, siteContact: mutation?.data?.insert_SiteContact?.returning && mutation.data.insert_SiteContact.returning[0] };
    }

    async function insertSiteContactWithOnConflict({ apolloClient, siteContact, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteContact: SiteContact_Insert_Input, onConflict: SiteContact_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteContactWithOnConflictMutation, InsertSiteContactWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteContactFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteContact });
      const mutationOptions:MutationOptions<InsertSiteContactWithOnConflictMutation, InsertSiteContactWithOnConflictMutationVariables> = { mutation: InsertSiteContactWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteContact'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteContactWithOnConflictMutation>({ operationType: 'insert', entityName:'SiteContact', objects:[objectForInsert as SiteContact_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteContactWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteContactFetchResult = await apolloClient.mutate<InsertSiteContactWithOnConflictMutation, InsertSiteContactWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, siteContact: mutation?.data?.insert_SiteContact?.returning && mutation.data.insert_SiteContact.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertSiteContactObjectsFetchResult = FetchResult<InsertSiteContactMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteContactObjectsHelperResultEx = InsertSiteContactObjectsFetchResult & SiteContactObjectsHelperResultEx;

    async function insertSiteContactObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: SiteContact_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteContactMutation, InsertSiteContactMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteContactObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertSiteContactMutation, InsertSiteContactMutationVariables> = { mutation: InsertSiteContactDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteContactMutation>({ operationType: 'insert', entityName:'SiteContact', objects:objectsForInsert as (SiteContact_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteContact - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertSiteContactObjectsFetchResult = await apolloClient.mutate<InsertSiteContactMutation, InsertSiteContactMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_SiteContact?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateSiteContactByIdQueryResult = FetchResult<UpdateSiteContactByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteContactByIdHelperResultEx = UpdateSiteContactByIdQueryResult & SiteContactByIdHelperResultEx;

    async function updateSiteContactById({ apolloClient, siteContactId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, siteContactId: string, set: SiteContact_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateSiteContactByIdMutation, UpdateSiteContactByIdMutationVariables>, 'mutation'> }): Promise<UpdateSiteContactByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateSiteContactByIdMutation, UpdateSiteContactByIdMutationVariables> = { mutation: UpdateSiteContactByIdDocument, variables: { id:siteContactId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSiteContactByIdMutation>({ operationType: 'update', entityName:'SiteContact', objects:[{ id:siteContactId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateSiteContactById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateSiteContactByIdQueryResult = await apolloClient.mutate<UpdateSiteContactByIdMutation, UpdateSiteContactByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, siteContact: mutation?.data?.update_SiteContact?.returning && mutation.data.update_SiteContact.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateSiteContactObjectsFetchResult = FetchResult<UpdateSiteContactMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteContactObjectsHelperResultEx = UpdateSiteContactObjectsFetchResult & SiteContactObjectsHelperResultEx;

    async function updateSiteContactObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateSiteContactMutation, UpdateSiteContactMutationVariables>, 'mutation'> }): Promise<UpdateSiteContactObjectsHelperResultEx> {  
      const mutation:UpdateSiteContactObjectsFetchResult = await apolloClient.mutate<UpdateSiteContactMutation, UpdateSiteContactMutationVariables>({ mutation: UpdateSiteContactDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_SiteContact?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveSiteContactModelByIdQueryResult = FetchResult<RemoveSiteContactModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteContactModelByIdQueryHelperResultEx = RemoveSiteContactModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeSiteContactModelById({ apolloClient, siteContactId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, siteContactId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveSiteContactModelByIdMutation, RemoveSiteContactModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveSiteContactModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveSiteContactModelByIdMutation, RemoveSiteContactModelByIdMutationVariables> = { mutation: RemoveSiteContactModelByIdDocument, variables: { id:siteContactId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSiteContactModelByIdMutation>({ operationType: 'delete', entityName:'siteContact', objects:[{ id:siteContactId }] }); 
        if(logLevel >= 2) console.log(' --> removeSiteContactModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveSiteContactModelByIdMutation>({ operationType: 'delete', entityName:'siteContact', entityId:siteContactId }); }
      
      const mutation:RemoveSiteContactModelByIdQueryResult = await apolloClient.mutate<RemoveSiteContactModelByIdMutation, RemoveSiteContactModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_SiteContact?.affected_rows || 0 };
    }
  

    type RemoveSiteContactModelObjectsQueryResult = FetchResult<RemoveSiteContactModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteContactModelObjectsQueryHelperResultEx = RemoveSiteContactModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeSiteContactModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveSiteContactModelMutation, RemoveSiteContactModelMutationVariables>, 'mutation'> }): Promise<RemoveSiteContactModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveSiteContactModelObjectsQueryResult = await apolloClient.mutate<RemoveSiteContactModelMutation, RemoveSiteContactModelMutationVariables>({ mutation: RemoveSiteContactModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_SiteContact?.affected_rows || 0 };
      }
  

    // SiteContact Fragment Helper Object
    //------------------------------------------------

    export const SiteContactFragmentGQLHelper = {
      queryById: querySiteContactById,
      queryObjects: querySiteContactObjects,
      watchQueryById: watchQuerySiteContactById,
      watchQueryObjects: watchQuerySiteContactObjects,
      insert: insertSiteContact,
      insertWithOnConflict: insertSiteContactWithOnConflict,
      insertObjects: insertSiteContactObjects,
      updateById: updateSiteContactById,
      updateObjects: updateSiteContactObjects
    }
  

    // SiteContact Entity Helper Object
    //------------------------------------------------

    export const SiteContactModelGQLHelper = {
      removeById: removeSiteContactModelById,
      removeObjects: removeSiteContactModelObjects
    }
  

    // SiteLan HELPERS
    //------------------------------------------------

    export type SiteLanByIdHelperResultEx = { siteLan:SiteLanFragment | null | undefined };
    export type SiteLanObjectsHelperResultEx = { objects:SiteLanFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QuerySiteLanByIdApolloQueryResult = ApolloQueryResult<QuerySiteLanByIdQuery>;
      export type QuerySiteLanByIdApolloQueryHelperResultEx = QuerySiteLanByIdApolloQueryResult & SiteLanByIdHelperResultEx;

      async function querySiteLanById({ apolloClient, siteLanId, options }: { apolloClient: ApolloClient<object>, siteLanId: string, options?: Omit<QueryOptions<QuerySiteLanByIdQueryVariables>, 'query' | 'variables'> }): Promise<QuerySiteLanByIdApolloQueryHelperResultEx> {
        const query: QuerySiteLanByIdApolloQueryResult = await apolloClient.query<QuerySiteLanByIdQuery>({ query: QuerySiteLanByIdDocument, variables: { siteLanId }, ...options });
        
        return { ...query, siteLan: query?.data?.SiteLan_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQuerySiteLanByIdApolloObservableQuery = ObservableQuery<QuerySiteLanByIdQuery>;
      async function watchQuerySiteLanById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteLanByIdQueryVariables>, 'query'> }) : Promise<WatchQuerySiteLanByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteLanByIdQuery>({ query: QuerySiteLanByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QuerySiteLanObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySiteLanObjectsQuery>;
      export type QuerySiteLanObjectsObjectsApolloQueryResultEx = QuerySiteLanObjectsObjectsApolloQueryResult & SiteLanObjectsHelperResultEx;

      async function querySiteLanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteLanObjectsQueryVariables>, 'query'> }): Promise<QuerySiteLanObjectsObjectsApolloQueryResultEx> {
        const query: QuerySiteLanObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySiteLanObjectsQuery>({ query: QuerySiteLanObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.SiteLan || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQuerySiteLanObjectsApolloObservableQuery = ObservableQuery<QuerySiteLanObjectsQuery>;
      async function watchQuerySiteLanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteLanObjectsQueryVariables>, 'query'> }) : Promise<WatchQuerySiteLanObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteLanObjectsQuery>({ query: QuerySiteLanObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertSiteLanFetchResult = FetchResult<InsertSiteLanMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteLanFetchHelperResultEx = InsertSiteLanFetchResult & SiteLanByIdHelperResultEx;

    async function insertSiteLan({ apolloClient, siteLan, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteLan: SiteLan_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteLanMutation, InsertSiteLanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteLanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteLan });
      const mutationOptions:MutationOptions<InsertSiteLanMutation, InsertSiteLanMutationVariables> = { mutation: InsertSiteLanDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteLan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteLanMutation>({ operationType: 'insert', entityName:'SiteLan', objects:[objectForInsert as SiteLan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteLan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteLanFetchResult = await apolloClient.mutate<InsertSiteLanMutation, InsertSiteLanMutationVariables>(mutationOptions);
        
      return { ...mutation, siteLan: mutation?.data?.insert_SiteLan?.returning && mutation.data.insert_SiteLan.returning[0] };
    }

    async function insertSiteLanWithOnConflict({ apolloClient, siteLan, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteLan: SiteLan_Insert_Input, onConflict: SiteLan_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteLanWithOnConflictMutation, InsertSiteLanWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteLanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteLan });
      const mutationOptions:MutationOptions<InsertSiteLanWithOnConflictMutation, InsertSiteLanWithOnConflictMutationVariables> = { mutation: InsertSiteLanWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteLan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteLanWithOnConflictMutation>({ operationType: 'insert', entityName:'SiteLan', objects:[objectForInsert as SiteLan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteLanWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteLanFetchResult = await apolloClient.mutate<InsertSiteLanWithOnConflictMutation, InsertSiteLanWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, siteLan: mutation?.data?.insert_SiteLan?.returning && mutation.data.insert_SiteLan.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertSiteLanObjectsFetchResult = FetchResult<InsertSiteLanMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteLanObjectsHelperResultEx = InsertSiteLanObjectsFetchResult & SiteLanObjectsHelperResultEx;

    async function insertSiteLanObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: SiteLan_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteLanMutation, InsertSiteLanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteLanObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertSiteLanMutation, InsertSiteLanMutationVariables> = { mutation: InsertSiteLanDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteLanMutation>({ operationType: 'insert', entityName:'SiteLan', objects:objectsForInsert as (SiteLan_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteLan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertSiteLanObjectsFetchResult = await apolloClient.mutate<InsertSiteLanMutation, InsertSiteLanMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_SiteLan?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateSiteLanByIdQueryResult = FetchResult<UpdateSiteLanByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteLanByIdHelperResultEx = UpdateSiteLanByIdQueryResult & SiteLanByIdHelperResultEx;

    async function updateSiteLanById({ apolloClient, siteLanId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, siteLanId: string, set: SiteLan_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateSiteLanByIdMutation, UpdateSiteLanByIdMutationVariables>, 'mutation'> }): Promise<UpdateSiteLanByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateSiteLanByIdMutation, UpdateSiteLanByIdMutationVariables> = { mutation: UpdateSiteLanByIdDocument, variables: { id:siteLanId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSiteLanByIdMutation>({ operationType: 'update', entityName:'SiteLan', objects:[{ id:siteLanId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateSiteLanById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateSiteLanByIdQueryResult = await apolloClient.mutate<UpdateSiteLanByIdMutation, UpdateSiteLanByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, siteLan: mutation?.data?.update_SiteLan?.returning && mutation.data.update_SiteLan.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateSiteLanObjectsFetchResult = FetchResult<UpdateSiteLanMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteLanObjectsHelperResultEx = UpdateSiteLanObjectsFetchResult & SiteLanObjectsHelperResultEx;

    async function updateSiteLanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateSiteLanMutation, UpdateSiteLanMutationVariables>, 'mutation'> }): Promise<UpdateSiteLanObjectsHelperResultEx> {  
      const mutation:UpdateSiteLanObjectsFetchResult = await apolloClient.mutate<UpdateSiteLanMutation, UpdateSiteLanMutationVariables>({ mutation: UpdateSiteLanDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_SiteLan?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveSiteLanModelByIdQueryResult = FetchResult<RemoveSiteLanModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteLanModelByIdQueryHelperResultEx = RemoveSiteLanModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeSiteLanModelById({ apolloClient, siteLanId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, siteLanId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveSiteLanModelByIdMutation, RemoveSiteLanModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveSiteLanModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveSiteLanModelByIdMutation, RemoveSiteLanModelByIdMutationVariables> = { mutation: RemoveSiteLanModelByIdDocument, variables: { id:siteLanId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSiteLanModelByIdMutation>({ operationType: 'delete', entityName:'siteLan', objects:[{ id:siteLanId }] }); 
        if(logLevel >= 2) console.log(' --> removeSiteLanModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveSiteLanModelByIdMutation>({ operationType: 'delete', entityName:'siteLan', entityId:siteLanId }); }
      
      const mutation:RemoveSiteLanModelByIdQueryResult = await apolloClient.mutate<RemoveSiteLanModelByIdMutation, RemoveSiteLanModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_SiteLan?.affected_rows || 0 };
    }
  

    type RemoveSiteLanModelObjectsQueryResult = FetchResult<RemoveSiteLanModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteLanModelObjectsQueryHelperResultEx = RemoveSiteLanModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeSiteLanModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveSiteLanModelMutation, RemoveSiteLanModelMutationVariables>, 'mutation'> }): Promise<RemoveSiteLanModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveSiteLanModelObjectsQueryResult = await apolloClient.mutate<RemoveSiteLanModelMutation, RemoveSiteLanModelMutationVariables>({ mutation: RemoveSiteLanModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_SiteLan?.affected_rows || 0 };
      }
  

    // SiteLan Fragment Helper Object
    //------------------------------------------------

    export const SiteLanFragmentGQLHelper = {
      queryById: querySiteLanById,
      queryObjects: querySiteLanObjects,
      watchQueryById: watchQuerySiteLanById,
      watchQueryObjects: watchQuerySiteLanObjects,
      insert: insertSiteLan,
      insertWithOnConflict: insertSiteLanWithOnConflict,
      insertObjects: insertSiteLanObjects,
      updateById: updateSiteLanById,
      updateObjects: updateSiteLanObjects
    }
  

    // SiteLan Entity Helper Object
    //------------------------------------------------

    export const SiteLanModelGQLHelper = {
      removeById: removeSiteLanModelById,
      removeObjects: removeSiteLanModelObjects
    }
  

    // SiteRoute HELPERS
    //------------------------------------------------

    export type SiteRouteByIdHelperResultEx = { siteRoute:SiteRouteFragment | null | undefined };
    export type SiteRouteObjectsHelperResultEx = { objects:SiteRouteFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QuerySiteRouteByIdApolloQueryResult = ApolloQueryResult<QuerySiteRouteByIdQuery>;
      export type QuerySiteRouteByIdApolloQueryHelperResultEx = QuerySiteRouteByIdApolloQueryResult & SiteRouteByIdHelperResultEx;

      async function querySiteRouteById({ apolloClient, siteRouteId, options }: { apolloClient: ApolloClient<object>, siteRouteId: string, options?: Omit<QueryOptions<QuerySiteRouteByIdQueryVariables>, 'query' | 'variables'> }): Promise<QuerySiteRouteByIdApolloQueryHelperResultEx> {
        const query: QuerySiteRouteByIdApolloQueryResult = await apolloClient.query<QuerySiteRouteByIdQuery>({ query: QuerySiteRouteByIdDocument, variables: { siteRouteId }, ...options });
        
        return { ...query, siteRoute: query?.data?.SiteRoute_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQuerySiteRouteByIdApolloObservableQuery = ObservableQuery<QuerySiteRouteByIdQuery>;
      async function watchQuerySiteRouteById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteRouteByIdQueryVariables>, 'query'> }) : Promise<WatchQuerySiteRouteByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteRouteByIdQuery>({ query: QuerySiteRouteByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QuerySiteRouteObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySiteRouteObjectsQuery>;
      export type QuerySiteRouteObjectsObjectsApolloQueryResultEx = QuerySiteRouteObjectsObjectsApolloQueryResult & SiteRouteObjectsHelperResultEx;

      async function querySiteRouteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteRouteObjectsQueryVariables>, 'query'> }): Promise<QuerySiteRouteObjectsObjectsApolloQueryResultEx> {
        const query: QuerySiteRouteObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySiteRouteObjectsQuery>({ query: QuerySiteRouteObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.SiteRoute || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQuerySiteRouteObjectsApolloObservableQuery = ObservableQuery<QuerySiteRouteObjectsQuery>;
      async function watchQuerySiteRouteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QuerySiteRouteObjectsQueryVariables>, 'query'> }) : Promise<WatchQuerySiteRouteObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QuerySiteRouteObjectsQuery>({ query: QuerySiteRouteObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertSiteRouteFetchResult = FetchResult<InsertSiteRouteMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteRouteFetchHelperResultEx = InsertSiteRouteFetchResult & SiteRouteByIdHelperResultEx;

    async function insertSiteRoute({ apolloClient, siteRoute, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteRoute: SiteRoute_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteRouteMutation, InsertSiteRouteMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteRouteFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteRoute });
      const mutationOptions:MutationOptions<InsertSiteRouteMutation, InsertSiteRouteMutationVariables> = { mutation: InsertSiteRouteDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteRoute'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteRouteMutation>({ operationType: 'insert', entityName:'SiteRoute', objects:[objectForInsert as SiteRoute_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteRoute - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteRouteFetchResult = await apolloClient.mutate<InsertSiteRouteMutation, InsertSiteRouteMutationVariables>(mutationOptions);
        
      return { ...mutation, siteRoute: mutation?.data?.insert_SiteRoute?.returning && mutation.data.insert_SiteRoute.returning[0] };
    }

    async function insertSiteRouteWithOnConflict({ apolloClient, siteRoute, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, siteRoute: SiteRoute_Insert_Input, onConflict: SiteRoute_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteRouteWithOnConflictMutation, InsertSiteRouteWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteRouteFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: siteRoute });
      const mutationOptions:MutationOptions<InsertSiteRouteWithOnConflictMutation, InsertSiteRouteWithOnConflictMutationVariables> = { mutation: InsertSiteRouteWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'siteRoute'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteRouteWithOnConflictMutation>({ operationType: 'insert', entityName:'SiteRoute', objects:[objectForInsert as SiteRoute_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteRouteWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertSiteRouteFetchResult = await apolloClient.mutate<InsertSiteRouteWithOnConflictMutation, InsertSiteRouteWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, siteRoute: mutation?.data?.insert_SiteRoute?.returning && mutation.data.insert_SiteRoute.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertSiteRouteObjectsFetchResult = FetchResult<InsertSiteRouteMutation, Record<string, any>, Record<string, any>>;
    export type InsertSiteRouteObjectsHelperResultEx = InsertSiteRouteObjectsFetchResult & SiteRouteObjectsHelperResultEx;

    async function insertSiteRouteObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: SiteRoute_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertSiteRouteMutation, InsertSiteRouteMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertSiteRouteObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertSiteRouteMutation, InsertSiteRouteMutationVariables> = { mutation: InsertSiteRouteDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSiteRouteMutation>({ operationType: 'insert', entityName:'SiteRoute', objects:objectsForInsert as (SiteRoute_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertSiteRoute - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertSiteRouteObjectsFetchResult = await apolloClient.mutate<InsertSiteRouteMutation, InsertSiteRouteMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_SiteRoute?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateSiteRouteByIdQueryResult = FetchResult<UpdateSiteRouteByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteRouteByIdHelperResultEx = UpdateSiteRouteByIdQueryResult & SiteRouteByIdHelperResultEx;

    async function updateSiteRouteById({ apolloClient, siteRouteId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, siteRouteId: string, set: SiteRoute_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateSiteRouteByIdMutation, UpdateSiteRouteByIdMutationVariables>, 'mutation'> }): Promise<UpdateSiteRouteByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateSiteRouteByIdMutation, UpdateSiteRouteByIdMutationVariables> = { mutation: UpdateSiteRouteByIdDocument, variables: { id:siteRouteId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSiteRouteByIdMutation>({ operationType: 'update', entityName:'SiteRoute', objects:[{ id:siteRouteId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateSiteRouteById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateSiteRouteByIdQueryResult = await apolloClient.mutate<UpdateSiteRouteByIdMutation, UpdateSiteRouteByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, siteRoute: mutation?.data?.update_SiteRoute?.returning && mutation.data.update_SiteRoute.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateSiteRouteObjectsFetchResult = FetchResult<UpdateSiteRouteMutation, Record<string, any>, Record<string, any>>;
    export type UpdateSiteRouteObjectsHelperResultEx = UpdateSiteRouteObjectsFetchResult & SiteRouteObjectsHelperResultEx;

    async function updateSiteRouteObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateSiteRouteMutation, UpdateSiteRouteMutationVariables>, 'mutation'> }): Promise<UpdateSiteRouteObjectsHelperResultEx> {  
      const mutation:UpdateSiteRouteObjectsFetchResult = await apolloClient.mutate<UpdateSiteRouteMutation, UpdateSiteRouteMutationVariables>({ mutation: UpdateSiteRouteDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_SiteRoute?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveSiteRouteModelByIdQueryResult = FetchResult<RemoveSiteRouteModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteRouteModelByIdQueryHelperResultEx = RemoveSiteRouteModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeSiteRouteModelById({ apolloClient, siteRouteId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, siteRouteId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveSiteRouteModelByIdMutation, RemoveSiteRouteModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveSiteRouteModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveSiteRouteModelByIdMutation, RemoveSiteRouteModelByIdMutationVariables> = { mutation: RemoveSiteRouteModelByIdDocument, variables: { id:siteRouteId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSiteRouteModelByIdMutation>({ operationType: 'delete', entityName:'siteRoute', objects:[{ id:siteRouteId }] }); 
        if(logLevel >= 2) console.log(' --> removeSiteRouteModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveSiteRouteModelByIdMutation>({ operationType: 'delete', entityName:'siteRoute', entityId:siteRouteId }); }
      
      const mutation:RemoveSiteRouteModelByIdQueryResult = await apolloClient.mutate<RemoveSiteRouteModelByIdMutation, RemoveSiteRouteModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_SiteRoute?.affected_rows || 0 };
    }
  

    type RemoveSiteRouteModelObjectsQueryResult = FetchResult<RemoveSiteRouteModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveSiteRouteModelObjectsQueryHelperResultEx = RemoveSiteRouteModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeSiteRouteModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveSiteRouteModelMutation, RemoveSiteRouteModelMutationVariables>, 'mutation'> }): Promise<RemoveSiteRouteModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveSiteRouteModelObjectsQueryResult = await apolloClient.mutate<RemoveSiteRouteModelMutation, RemoveSiteRouteModelMutationVariables>({ mutation: RemoveSiteRouteModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_SiteRoute?.affected_rows || 0 };
      }
  

    // SiteRoute Fragment Helper Object
    //------------------------------------------------

    export const SiteRouteFragmentGQLHelper = {
      queryById: querySiteRouteById,
      queryObjects: querySiteRouteObjects,
      watchQueryById: watchQuerySiteRouteById,
      watchQueryObjects: watchQuerySiteRouteObjects,
      insert: insertSiteRoute,
      insertWithOnConflict: insertSiteRouteWithOnConflict,
      insertObjects: insertSiteRouteObjects,
      updateById: updateSiteRouteById,
      updateObjects: updateSiteRouteObjects
    }
  

    // SiteRoute Entity Helper Object
    //------------------------------------------------

    export const SiteRouteModelGQLHelper = {
      removeById: removeSiteRouteModelById,
      removeObjects: removeSiteRouteModelObjects
    }
  

    // Tunnel HELPERS
    //------------------------------------------------

    export type TunnelByIdHelperResultEx = { tunnel:TunnelFragment | null | undefined };
    export type TunnelObjectsHelperResultEx = { objects:TunnelFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryTunnelByIdApolloQueryResult = ApolloQueryResult<QueryTunnelByIdQuery>;
      export type QueryTunnelByIdApolloQueryHelperResultEx = QueryTunnelByIdApolloQueryResult & TunnelByIdHelperResultEx;

      async function queryTunnelById({ apolloClient, tunnelId, options }: { apolloClient: ApolloClient<object>, tunnelId: string, options?: Omit<QueryOptions<QueryTunnelByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryTunnelByIdApolloQueryHelperResultEx> {
        const query: QueryTunnelByIdApolloQueryResult = await apolloClient.query<QueryTunnelByIdQuery>({ query: QueryTunnelByIdDocument, variables: { tunnelId }, ...options });
        
        return { ...query, tunnel: query?.data?.Tunnel_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryTunnelByIdApolloObservableQuery = ObservableQuery<QueryTunnelByIdQuery>;
      async function watchQueryTunnelById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelByIdQueryVariables>, 'query'> }) : Promise<WatchQueryTunnelByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryTunnelByIdQuery>({ query: QueryTunnelByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryTunnelObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryTunnelObjectsQuery>;
      export type QueryTunnelObjectsObjectsApolloQueryResultEx = QueryTunnelObjectsObjectsApolloQueryResult & TunnelObjectsHelperResultEx;

      async function queryTunnelObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelObjectsQueryVariables>, 'query'> }): Promise<QueryTunnelObjectsObjectsApolloQueryResultEx> {
        const query: QueryTunnelObjectsObjectsApolloQueryResult = await apolloClient.query<QueryTunnelObjectsQuery>({ query: QueryTunnelObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Tunnel || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryTunnelObjectsApolloObservableQuery = ObservableQuery<QueryTunnelObjectsQuery>;
      async function watchQueryTunnelObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryTunnelObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryTunnelObjectsQuery>({ query: QueryTunnelObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertTunnelFetchResult = FetchResult<InsertTunnelMutation, Record<string, any>, Record<string, any>>;
    export type InsertTunnelFetchHelperResultEx = InsertTunnelFetchResult & TunnelByIdHelperResultEx;

    async function insertTunnel({ apolloClient, tunnel, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, tunnel: Tunnel_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelMutation, InsertTunnelMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: tunnel });
      const mutationOptions:MutationOptions<InsertTunnelMutation, InsertTunnelMutationVariables> = { mutation: InsertTunnelDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'tunnel'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelMutation>({ operationType: 'insert', entityName:'Tunnel', objects:[objectForInsert as Tunnel_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnel - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertTunnelFetchResult = await apolloClient.mutate<InsertTunnelMutation, InsertTunnelMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnel: mutation?.data?.insert_Tunnel?.returning && mutation.data.insert_Tunnel.returning[0] };
    }

    async function insertTunnelWithOnConflict({ apolloClient, tunnel, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, tunnel: Tunnel_Insert_Input, onConflict: Tunnel_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelWithOnConflictMutation, InsertTunnelWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: tunnel });
      const mutationOptions:MutationOptions<InsertTunnelWithOnConflictMutation, InsertTunnelWithOnConflictMutationVariables> = { mutation: InsertTunnelWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'tunnel'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelWithOnConflictMutation>({ operationType: 'insert', entityName:'Tunnel', objects:[objectForInsert as Tunnel_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnelWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertTunnelFetchResult = await apolloClient.mutate<InsertTunnelWithOnConflictMutation, InsertTunnelWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnel: mutation?.data?.insert_Tunnel?.returning && mutation.data.insert_Tunnel.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertTunnelObjectsFetchResult = FetchResult<InsertTunnelMutation, Record<string, any>, Record<string, any>>;
    export type InsertTunnelObjectsHelperResultEx = InsertTunnelObjectsFetchResult & TunnelObjectsHelperResultEx;

    async function insertTunnelObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Tunnel_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelMutation, InsertTunnelMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertTunnelMutation, InsertTunnelMutationVariables> = { mutation: InsertTunnelDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelMutation>({ operationType: 'insert', entityName:'Tunnel', objects:objectsForInsert as (Tunnel_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnel - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertTunnelObjectsFetchResult = await apolloClient.mutate<InsertTunnelMutation, InsertTunnelMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Tunnel?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateTunnelByIdQueryResult = FetchResult<UpdateTunnelByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateTunnelByIdHelperResultEx = UpdateTunnelByIdQueryResult & TunnelByIdHelperResultEx;

    async function updateTunnelById({ apolloClient, tunnelId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, tunnelId: string, set: Tunnel_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateTunnelByIdMutation, UpdateTunnelByIdMutationVariables>, 'mutation'> }): Promise<UpdateTunnelByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateTunnelByIdMutation, UpdateTunnelByIdMutationVariables> = { mutation: UpdateTunnelByIdDocument, variables: { id:tunnelId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateTunnelByIdMutation>({ operationType: 'update', entityName:'Tunnel', objects:[{ id:tunnelId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateTunnelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateTunnelByIdQueryResult = await apolloClient.mutate<UpdateTunnelByIdMutation, UpdateTunnelByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnel: mutation?.data?.update_Tunnel?.returning && mutation.data.update_Tunnel.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateTunnelObjectsFetchResult = FetchResult<UpdateTunnelMutation, Record<string, any>, Record<string, any>>;
    export type UpdateTunnelObjectsHelperResultEx = UpdateTunnelObjectsFetchResult & TunnelObjectsHelperResultEx;

    async function updateTunnelObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateTunnelMutation, UpdateTunnelMutationVariables>, 'mutation'> }): Promise<UpdateTunnelObjectsHelperResultEx> {  
      const mutation:UpdateTunnelObjectsFetchResult = await apolloClient.mutate<UpdateTunnelMutation, UpdateTunnelMutationVariables>({ mutation: UpdateTunnelDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Tunnel?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveTunnelModelByIdQueryResult = FetchResult<RemoveTunnelModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveTunnelModelByIdQueryHelperResultEx = RemoveTunnelModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeTunnelModelById({ apolloClient, tunnelId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, tunnelId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveTunnelModelByIdMutation, RemoveTunnelModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveTunnelModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveTunnelModelByIdMutation, RemoveTunnelModelByIdMutationVariables> = { mutation: RemoveTunnelModelByIdDocument, variables: { id:tunnelId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveTunnelModelByIdMutation>({ operationType: 'delete', entityName:'tunnel', objects:[{ id:tunnelId }] }); 
        if(logLevel >= 2) console.log(' --> removeTunnelModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveTunnelModelByIdMutation>({ operationType: 'delete', entityName:'tunnel', entityId:tunnelId }); }
      
      const mutation:RemoveTunnelModelByIdQueryResult = await apolloClient.mutate<RemoveTunnelModelByIdMutation, RemoveTunnelModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Tunnel?.affected_rows || 0 };
    }
  

    type RemoveTunnelModelObjectsQueryResult = FetchResult<RemoveTunnelModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveTunnelModelObjectsQueryHelperResultEx = RemoveTunnelModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeTunnelModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveTunnelModelMutation, RemoveTunnelModelMutationVariables>, 'mutation'> }): Promise<RemoveTunnelModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveTunnelModelObjectsQueryResult = await apolloClient.mutate<RemoveTunnelModelMutation, RemoveTunnelModelMutationVariables>({ mutation: RemoveTunnelModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Tunnel?.affected_rows || 0 };
      }
  

    // Tunnel Fragment Helper Object
    //------------------------------------------------

    export const TunnelFragmentGQLHelper = {
      queryById: queryTunnelById,
      queryObjects: queryTunnelObjects,
      watchQueryById: watchQueryTunnelById,
      watchQueryObjects: watchQueryTunnelObjects,
      insert: insertTunnel,
      insertWithOnConflict: insertTunnelWithOnConflict,
      insertObjects: insertTunnelObjects,
      updateById: updateTunnelById,
      updateObjects: updateTunnelObjects
    }
  

    // Tunnel Entity Helper Object
    //------------------------------------------------

    export const TunnelModelGQLHelper = {
      removeById: removeTunnelModelById,
      removeObjects: removeTunnelModelObjects
    }
  

    // TunnelProfile HELPERS
    //------------------------------------------------

    export type TunnelProfileByIdHelperResultEx = { tunnelProfile:TunnelProfileFragment | null | undefined };
    export type TunnelProfileObjectsHelperResultEx = { objects:TunnelProfileFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryTunnelProfileByIdApolloQueryResult = ApolloQueryResult<QueryTunnelProfileByIdQuery>;
      export type QueryTunnelProfileByIdApolloQueryHelperResultEx = QueryTunnelProfileByIdApolloQueryResult & TunnelProfileByIdHelperResultEx;

      async function queryTunnelProfileById({ apolloClient, tunnelProfileId, options }: { apolloClient: ApolloClient<object>, tunnelProfileId: string, options?: Omit<QueryOptions<QueryTunnelProfileByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryTunnelProfileByIdApolloQueryHelperResultEx> {
        const query: QueryTunnelProfileByIdApolloQueryResult = await apolloClient.query<QueryTunnelProfileByIdQuery>({ query: QueryTunnelProfileByIdDocument, variables: { tunnelProfileId }, ...options });
        
        return { ...query, tunnelProfile: query?.data?.TunnelProfile_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryTunnelProfileByIdApolloObservableQuery = ObservableQuery<QueryTunnelProfileByIdQuery>;
      async function watchQueryTunnelProfileById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelProfileByIdQueryVariables>, 'query'> }) : Promise<WatchQueryTunnelProfileByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryTunnelProfileByIdQuery>({ query: QueryTunnelProfileByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryTunnelProfileObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryTunnelProfileObjectsQuery>;
      export type QueryTunnelProfileObjectsObjectsApolloQueryResultEx = QueryTunnelProfileObjectsObjectsApolloQueryResult & TunnelProfileObjectsHelperResultEx;

      async function queryTunnelProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelProfileObjectsQueryVariables>, 'query'> }): Promise<QueryTunnelProfileObjectsObjectsApolloQueryResultEx> {
        const query: QueryTunnelProfileObjectsObjectsApolloQueryResult = await apolloClient.query<QueryTunnelProfileObjectsQuery>({ query: QueryTunnelProfileObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.TunnelProfile || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryTunnelProfileObjectsApolloObservableQuery = ObservableQuery<QueryTunnelProfileObjectsQuery>;
      async function watchQueryTunnelProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryTunnelProfileObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryTunnelProfileObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryTunnelProfileObjectsQuery>({ query: QueryTunnelProfileObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertTunnelProfileFetchResult = FetchResult<InsertTunnelProfileMutation, Record<string, any>, Record<string, any>>;
    export type InsertTunnelProfileFetchHelperResultEx = InsertTunnelProfileFetchResult & TunnelProfileByIdHelperResultEx;

    async function insertTunnelProfile({ apolloClient, tunnelProfile, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, tunnelProfile: TunnelProfile_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelProfileFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: tunnelProfile });
      const mutationOptions:MutationOptions<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables> = { mutation: InsertTunnelProfileDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'tunnelProfile'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelProfileMutation>({ operationType: 'insert', entityName:'TunnelProfile', objects:[objectForInsert as TunnelProfile_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnelProfile - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertTunnelProfileFetchResult = await apolloClient.mutate<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnelProfile: mutation?.data?.insert_TunnelProfile?.returning && mutation.data.insert_TunnelProfile.returning[0] };
    }

    async function insertTunnelProfileWithOnConflict({ apolloClient, tunnelProfile, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, tunnelProfile: TunnelProfile_Insert_Input, onConflict: TunnelProfile_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelProfileWithOnConflictMutation, InsertTunnelProfileWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelProfileFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: tunnelProfile });
      const mutationOptions:MutationOptions<InsertTunnelProfileWithOnConflictMutation, InsertTunnelProfileWithOnConflictMutationVariables> = { mutation: InsertTunnelProfileWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'tunnelProfile'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelProfileWithOnConflictMutation>({ operationType: 'insert', entityName:'TunnelProfile', objects:[objectForInsert as TunnelProfile_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnelProfileWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertTunnelProfileFetchResult = await apolloClient.mutate<InsertTunnelProfileWithOnConflictMutation, InsertTunnelProfileWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnelProfile: mutation?.data?.insert_TunnelProfile?.returning && mutation.data.insert_TunnelProfile.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertTunnelProfileObjectsFetchResult = FetchResult<InsertTunnelProfileMutation, Record<string, any>, Record<string, any>>;
    export type InsertTunnelProfileObjectsHelperResultEx = InsertTunnelProfileObjectsFetchResult & TunnelProfileObjectsHelperResultEx;

    async function insertTunnelProfileObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: TunnelProfile_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertTunnelProfileObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables> = { mutation: InsertTunnelProfileDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertTunnelProfileMutation>({ operationType: 'insert', entityName:'TunnelProfile', objects:objectsForInsert as (TunnelProfile_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertTunnelProfile - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertTunnelProfileObjectsFetchResult = await apolloClient.mutate<InsertTunnelProfileMutation, InsertTunnelProfileMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_TunnelProfile?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateTunnelProfileByIdQueryResult = FetchResult<UpdateTunnelProfileByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateTunnelProfileByIdHelperResultEx = UpdateTunnelProfileByIdQueryResult & TunnelProfileByIdHelperResultEx;

    async function updateTunnelProfileById({ apolloClient, tunnelProfileId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, tunnelProfileId: string, set: TunnelProfile_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateTunnelProfileByIdMutation, UpdateTunnelProfileByIdMutationVariables>, 'mutation'> }): Promise<UpdateTunnelProfileByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateTunnelProfileByIdMutation, UpdateTunnelProfileByIdMutationVariables> = { mutation: UpdateTunnelProfileByIdDocument, variables: { id:tunnelProfileId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateTunnelProfileByIdMutation>({ operationType: 'update', entityName:'TunnelProfile', objects:[{ id:tunnelProfileId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateTunnelProfileById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateTunnelProfileByIdQueryResult = await apolloClient.mutate<UpdateTunnelProfileByIdMutation, UpdateTunnelProfileByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, tunnelProfile: mutation?.data?.update_TunnelProfile?.returning && mutation.data.update_TunnelProfile.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateTunnelProfileObjectsFetchResult = FetchResult<UpdateTunnelProfileMutation, Record<string, any>, Record<string, any>>;
    export type UpdateTunnelProfileObjectsHelperResultEx = UpdateTunnelProfileObjectsFetchResult & TunnelProfileObjectsHelperResultEx;

    async function updateTunnelProfileObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateTunnelProfileMutation, UpdateTunnelProfileMutationVariables>, 'mutation'> }): Promise<UpdateTunnelProfileObjectsHelperResultEx> {  
      const mutation:UpdateTunnelProfileObjectsFetchResult = await apolloClient.mutate<UpdateTunnelProfileMutation, UpdateTunnelProfileMutationVariables>({ mutation: UpdateTunnelProfileDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_TunnelProfile?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveTunnelProfileModelByIdQueryResult = FetchResult<RemoveTunnelProfileModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveTunnelProfileModelByIdQueryHelperResultEx = RemoveTunnelProfileModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeTunnelProfileModelById({ apolloClient, tunnelProfileId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, tunnelProfileId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveTunnelProfileModelByIdMutation, RemoveTunnelProfileModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveTunnelProfileModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveTunnelProfileModelByIdMutation, RemoveTunnelProfileModelByIdMutationVariables> = { mutation: RemoveTunnelProfileModelByIdDocument, variables: { id:tunnelProfileId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveTunnelProfileModelByIdMutation>({ operationType: 'delete', entityName:'tunnelProfile', objects:[{ id:tunnelProfileId }] }); 
        if(logLevel >= 2) console.log(' --> removeTunnelProfileModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveTunnelProfileModelByIdMutation>({ operationType: 'delete', entityName:'tunnelProfile', entityId:tunnelProfileId }); }
      
      const mutation:RemoveTunnelProfileModelByIdQueryResult = await apolloClient.mutate<RemoveTunnelProfileModelByIdMutation, RemoveTunnelProfileModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_TunnelProfile?.affected_rows || 0 };
    }
  

    type RemoveTunnelProfileModelObjectsQueryResult = FetchResult<RemoveTunnelProfileModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveTunnelProfileModelObjectsQueryHelperResultEx = RemoveTunnelProfileModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeTunnelProfileModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveTunnelProfileModelMutation, RemoveTunnelProfileModelMutationVariables>, 'mutation'> }): Promise<RemoveTunnelProfileModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveTunnelProfileModelObjectsQueryResult = await apolloClient.mutate<RemoveTunnelProfileModelMutation, RemoveTunnelProfileModelMutationVariables>({ mutation: RemoveTunnelProfileModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_TunnelProfile?.affected_rows || 0 };
      }
  

    // TunnelProfile Fragment Helper Object
    //------------------------------------------------

    export const TunnelProfileFragmentGQLHelper = {
      queryById: queryTunnelProfileById,
      queryObjects: queryTunnelProfileObjects,
      watchQueryById: watchQueryTunnelProfileById,
      watchQueryObjects: watchQueryTunnelProfileObjects,
      insert: insertTunnelProfile,
      insertWithOnConflict: insertTunnelProfileWithOnConflict,
      insertObjects: insertTunnelProfileObjects,
      updateById: updateTunnelProfileById,
      updateObjects: updateTunnelProfileObjects
    }
  

    // TunnelProfile Entity Helper Object
    //------------------------------------------------

    export const TunnelProfileModelGQLHelper = {
      removeById: removeTunnelProfileModelById,
      removeObjects: removeTunnelProfileModelObjects
    }
  

    // User HELPERS
    //------------------------------------------------

    export type UserByIdHelperResultEx = { user:UserFragment | null | undefined };
    export type UserObjectsHelperResultEx = { objects:UserFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryUserByIdApolloQueryResult = ApolloQueryResult<QueryUserByIdQuery>;
      export type QueryUserByIdApolloQueryHelperResultEx = QueryUserByIdApolloQueryResult & UserByIdHelperResultEx;

      async function queryUserById({ apolloClient, userId, options }: { apolloClient: ApolloClient<object>, userId: string, options?: Omit<QueryOptions<QueryUserByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryUserByIdApolloQueryHelperResultEx> {
        const query: QueryUserByIdApolloQueryResult = await apolloClient.query<QueryUserByIdQuery>({ query: QueryUserByIdDocument, variables: { userId }, ...options });
        
        return { ...query, user: query?.data?.User_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryUserByIdApolloObservableQuery = ObservableQuery<QueryUserByIdQuery>;
      async function watchQueryUserById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserByIdQueryVariables>, 'query'> }) : Promise<WatchQueryUserByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserByIdQuery>({ query: QueryUserByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryUserObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryUserObjectsQuery>;
      export type QueryUserObjectsObjectsApolloQueryResultEx = QueryUserObjectsObjectsApolloQueryResult & UserObjectsHelperResultEx;

      async function queryUserObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserObjectsQueryVariables>, 'query'> }): Promise<QueryUserObjectsObjectsApolloQueryResultEx> {
        const query: QueryUserObjectsObjectsApolloQueryResult = await apolloClient.query<QueryUserObjectsQuery>({ query: QueryUserObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.User || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryUserObjectsApolloObservableQuery = ObservableQuery<QueryUserObjectsQuery>;
      async function watchQueryUserObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryUserObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserObjectsQuery>({ query: QueryUserObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertUserFetchResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserFetchHelperResultEx = InsertUserFetchResult & UserByIdHelperResultEx;

    async function insertUser({ apolloClient, user, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, user: User_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: user });
      const mutationOptions:MutationOptions<InsertUserMutation, InsertUserMutationVariables> = { mutation: InsertUserDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserMutation>({ operationType: 'insert', entityName:'User', objects:[objectForInsert as User_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUser - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserFetchResult = await apolloClient.mutate<InsertUserMutation, InsertUserMutationVariables>(mutationOptions);
        
      return { ...mutation, user: mutation?.data?.insert_User?.returning && mutation.data.insert_User.returning[0] };
    }

    async function insertUserWithOnConflict({ apolloClient, user, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, user: User_Insert_Input, onConflict: User_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: user });
      const mutationOptions:MutationOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables> = { mutation: InsertUserWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserWithOnConflictMutation>({ operationType: 'insert', entityName:'User', objects:[objectForInsert as User_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserFetchResult = await apolloClient.mutate<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, user: mutation?.data?.insert_User?.returning && mutation.data.insert_User.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertUserObjectsFetchResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserObjectsHelperResultEx = InsertUserObjectsFetchResult & UserObjectsHelperResultEx;

    async function insertUserObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: User_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertUserMutation, InsertUserMutationVariables> = { mutation: InsertUserDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserMutation>({ operationType: 'insert', entityName:'User', objects:objectsForInsert as (User_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUser - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertUserObjectsFetchResult = await apolloClient.mutate<InsertUserMutation, InsertUserMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_User?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateUserByIdQueryResult = FetchResult<UpdateUserByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserByIdHelperResultEx = UpdateUserByIdQueryResult & UserByIdHelperResultEx;

    async function updateUserById({ apolloClient, userId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, userId: string, set: User_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>, 'mutation'> }): Promise<UpdateUserByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables> = { mutation: UpdateUserByIdDocument, variables: { id:userId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserByIdMutation>({ operationType: 'update', entityName:'User', objects:[{ id:userId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateUserById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateUserByIdQueryResult = await apolloClient.mutate<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, user: mutation?.data?.update_User?.returning && mutation.data.update_User.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateUserObjectsFetchResult = FetchResult<UpdateUserMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserObjectsHelperResultEx = UpdateUserObjectsFetchResult & UserObjectsHelperResultEx;

    async function updateUserObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'> }): Promise<UpdateUserObjectsHelperResultEx> {  
      const mutation:UpdateUserObjectsFetchResult = await apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({ mutation: UpdateUserDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_User?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveUserModelByIdQueryResult = FetchResult<RemoveUserModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserModelByIdQueryHelperResultEx = RemoveUserModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeUserModelById({ apolloClient, userId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, userId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveUserModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables> = { mutation: RemoveUserModelByIdDocument, variables: { id:userId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserModelByIdMutation>({ operationType: 'delete', entityName:'user', objects:[{ id:userId }] }); 
        if(logLevel >= 2) console.log(' --> removeUserModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserModelByIdMutation>({ operationType: 'delete', entityName:'user', entityId:userId }); }
      
      const mutation:RemoveUserModelByIdQueryResult = await apolloClient.mutate<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_User?.affected_rows || 0 };
    }
  

    type RemoveUserModelObjectsQueryResult = FetchResult<RemoveUserModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserModelObjectsQueryHelperResultEx = RemoveUserModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeUserModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveUserModelMutation, RemoveUserModelMutationVariables>, 'mutation'> }): Promise<RemoveUserModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveUserModelObjectsQueryResult = await apolloClient.mutate<RemoveUserModelMutation, RemoveUserModelMutationVariables>({ mutation: RemoveUserModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_User?.affected_rows || 0 };
      }
  

    // User Fragment Helper Object
    //------------------------------------------------

    export const UserFragmentGQLHelper = {
      queryById: queryUserById,
      queryObjects: queryUserObjects,
      watchQueryById: watchQueryUserById,
      watchQueryObjects: watchQueryUserObjects,
      insert: insertUser,
      insertWithOnConflict: insertUserWithOnConflict,
      insertObjects: insertUserObjects,
      updateById: updateUserById,
      updateObjects: updateUserObjects
    }
  

    // User Entity Helper Object
    //------------------------------------------------

    export const UserModelGQLHelper = {
      removeById: removeUserModelById,
      removeObjects: removeUserModelObjects
    }
  

    // UserHistory HELPERS
    //------------------------------------------------

    export type UserHistoryByIdHelperResultEx = { userHistory:UserHistoryFragment | null | undefined };
    export type UserHistoryObjectsHelperResultEx = { objects:UserHistoryFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryUserHistoryByIdApolloQueryResult = ApolloQueryResult<QueryUserHistoryByIdQuery>;
      export type QueryUserHistoryByIdApolloQueryHelperResultEx = QueryUserHistoryByIdApolloQueryResult & UserHistoryByIdHelperResultEx;

      async function queryUserHistoryById({ apolloClient, userHistoryId, options }: { apolloClient: ApolloClient<object>, userHistoryId: string, options?: Omit<QueryOptions<QueryUserHistoryByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryUserHistoryByIdApolloQueryHelperResultEx> {
        const query: QueryUserHistoryByIdApolloQueryResult = await apolloClient.query<QueryUserHistoryByIdQuery>({ query: QueryUserHistoryByIdDocument, variables: { userHistoryId }, ...options });
        
        return { ...query, userHistory: query?.data?.UserHistory_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryUserHistoryByIdApolloObservableQuery = ObservableQuery<QueryUserHistoryByIdQuery>;
      async function watchQueryUserHistoryById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserHistoryByIdQueryVariables>, 'query'> }) : Promise<WatchQueryUserHistoryByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserHistoryByIdQuery>({ query: QueryUserHistoryByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryUserHistoryObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryUserHistoryObjectsQuery>;
      export type QueryUserHistoryObjectsObjectsApolloQueryResultEx = QueryUserHistoryObjectsObjectsApolloQueryResult & UserHistoryObjectsHelperResultEx;

      async function queryUserHistoryObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserHistoryObjectsQueryVariables>, 'query'> }): Promise<QueryUserHistoryObjectsObjectsApolloQueryResultEx> {
        const query: QueryUserHistoryObjectsObjectsApolloQueryResult = await apolloClient.query<QueryUserHistoryObjectsQuery>({ query: QueryUserHistoryObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.UserHistory || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryUserHistoryObjectsApolloObservableQuery = ObservableQuery<QueryUserHistoryObjectsQuery>;
      async function watchQueryUserHistoryObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserHistoryObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryUserHistoryObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserHistoryObjectsQuery>({ query: QueryUserHistoryObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertUserHistoryFetchResult = FetchResult<InsertUserHistoryMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserHistoryFetchHelperResultEx = InsertUserHistoryFetchResult & UserHistoryByIdHelperResultEx;

    async function insertUserHistory({ apolloClient, userHistory, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, userHistory: UserHistory_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserHistoryMutation, InsertUserHistoryMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserHistoryFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: userHistory });
      const mutationOptions:MutationOptions<InsertUserHistoryMutation, InsertUserHistoryMutationVariables> = { mutation: InsertUserHistoryDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userHistory'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserHistoryMutation>({ operationType: 'insert', entityName:'UserHistory', objects:[objectForInsert as UserHistory_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserHistory - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserHistoryFetchResult = await apolloClient.mutate<InsertUserHistoryMutation, InsertUserHistoryMutationVariables>(mutationOptions);
        
      return { ...mutation, userHistory: mutation?.data?.insert_UserHistory?.returning && mutation.data.insert_UserHistory.returning[0] };
    }

    async function insertUserHistoryWithOnConflict({ apolloClient, userHistory, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, userHistory: UserHistory_Insert_Input, onConflict: UserHistory_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserHistoryWithOnConflictMutation, InsertUserHistoryWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserHistoryFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: userHistory });
      const mutationOptions:MutationOptions<InsertUserHistoryWithOnConflictMutation, InsertUserHistoryWithOnConflictMutationVariables> = { mutation: InsertUserHistoryWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userHistory'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserHistoryWithOnConflictMutation>({ operationType: 'insert', entityName:'UserHistory', objects:[objectForInsert as UserHistory_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserHistoryWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserHistoryFetchResult = await apolloClient.mutate<InsertUserHistoryWithOnConflictMutation, InsertUserHistoryWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, userHistory: mutation?.data?.insert_UserHistory?.returning && mutation.data.insert_UserHistory.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertUserHistoryObjectsFetchResult = FetchResult<InsertUserHistoryMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserHistoryObjectsHelperResultEx = InsertUserHistoryObjectsFetchResult & UserHistoryObjectsHelperResultEx;

    async function insertUserHistoryObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: UserHistory_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserHistoryMutation, InsertUserHistoryMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserHistoryObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertUserHistoryMutation, InsertUserHistoryMutationVariables> = { mutation: InsertUserHistoryDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserHistoryMutation>({ operationType: 'insert', entityName:'UserHistory', objects:objectsForInsert as (UserHistory_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserHistory - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertUserHistoryObjectsFetchResult = await apolloClient.mutate<InsertUserHistoryMutation, InsertUserHistoryMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_UserHistory?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateUserHistoryByIdQueryResult = FetchResult<UpdateUserHistoryByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserHistoryByIdHelperResultEx = UpdateUserHistoryByIdQueryResult & UserHistoryByIdHelperResultEx;

    async function updateUserHistoryById({ apolloClient, userHistoryId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, userHistoryId: string, set: UserHistory_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateUserHistoryByIdMutation, UpdateUserHistoryByIdMutationVariables>, 'mutation'> }): Promise<UpdateUserHistoryByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateUserHistoryByIdMutation, UpdateUserHistoryByIdMutationVariables> = { mutation: UpdateUserHistoryByIdDocument, variables: { id:userHistoryId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserHistoryByIdMutation>({ operationType: 'update', entityName:'UserHistory', objects:[{ id:userHistoryId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateUserHistoryById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateUserHistoryByIdQueryResult = await apolloClient.mutate<UpdateUserHistoryByIdMutation, UpdateUserHistoryByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, userHistory: mutation?.data?.update_UserHistory?.returning && mutation.data.update_UserHistory.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateUserHistoryObjectsFetchResult = FetchResult<UpdateUserHistoryMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserHistoryObjectsHelperResultEx = UpdateUserHistoryObjectsFetchResult & UserHistoryObjectsHelperResultEx;

    async function updateUserHistoryObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateUserHistoryMutation, UpdateUserHistoryMutationVariables>, 'mutation'> }): Promise<UpdateUserHistoryObjectsHelperResultEx> {  
      const mutation:UpdateUserHistoryObjectsFetchResult = await apolloClient.mutate<UpdateUserHistoryMutation, UpdateUserHistoryMutationVariables>({ mutation: UpdateUserHistoryDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_UserHistory?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveUserHistoryModelByIdQueryResult = FetchResult<RemoveUserHistoryModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserHistoryModelByIdQueryHelperResultEx = RemoveUserHistoryModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeUserHistoryModelById({ apolloClient, userHistoryId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, userHistoryId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveUserHistoryModelByIdMutation, RemoveUserHistoryModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveUserHistoryModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveUserHistoryModelByIdMutation, RemoveUserHistoryModelByIdMutationVariables> = { mutation: RemoveUserHistoryModelByIdDocument, variables: { id:userHistoryId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserHistoryModelByIdMutation>({ operationType: 'delete', entityName:'userHistory', objects:[{ id:userHistoryId }] }); 
        if(logLevel >= 2) console.log(' --> removeUserHistoryModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserHistoryModelByIdMutation>({ operationType: 'delete', entityName:'userHistory', entityId:userHistoryId }); }
      
      const mutation:RemoveUserHistoryModelByIdQueryResult = await apolloClient.mutate<RemoveUserHistoryModelByIdMutation, RemoveUserHistoryModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_UserHistory?.affected_rows || 0 };
    }
  

    type RemoveUserHistoryModelObjectsQueryResult = FetchResult<RemoveUserHistoryModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserHistoryModelObjectsQueryHelperResultEx = RemoveUserHistoryModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeUserHistoryModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveUserHistoryModelMutation, RemoveUserHistoryModelMutationVariables>, 'mutation'> }): Promise<RemoveUserHistoryModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveUserHistoryModelObjectsQueryResult = await apolloClient.mutate<RemoveUserHistoryModelMutation, RemoveUserHistoryModelMutationVariables>({ mutation: RemoveUserHistoryModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_UserHistory?.affected_rows || 0 };
      }
  

    // UserHistory Fragment Helper Object
    //------------------------------------------------

    export const UserHistoryFragmentGQLHelper = {
      queryById: queryUserHistoryById,
      queryObjects: queryUserHistoryObjects,
      watchQueryById: watchQueryUserHistoryById,
      watchQueryObjects: watchQueryUserHistoryObjects,
      insert: insertUserHistory,
      insertWithOnConflict: insertUserHistoryWithOnConflict,
      insertObjects: insertUserHistoryObjects,
      updateById: updateUserHistoryById,
      updateObjects: updateUserHistoryObjects
    }
  

    // UserHistory Entity Helper Object
    //------------------------------------------------

    export const UserHistoryModelGQLHelper = {
      removeById: removeUserHistoryModelById,
      removeObjects: removeUserHistoryModelObjects
    }
  

    // UserRole HELPERS
    //------------------------------------------------

    export type UserRoleByIdHelperResultEx = { userRole:UserRoleFragment | null | undefined };
    export type UserRoleObjectsHelperResultEx = { objects:UserRoleFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryUserRoleByIdApolloQueryResult = ApolloQueryResult<QueryUserRoleByIdQuery>;
      export type QueryUserRoleByIdApolloQueryHelperResultEx = QueryUserRoleByIdApolloQueryResult & UserRoleByIdHelperResultEx;

      async function queryUserRoleById({ apolloClient, userRoleId, options }: { apolloClient: ApolloClient<object>, userRoleId: string, options?: Omit<QueryOptions<QueryUserRoleByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryUserRoleByIdApolloQueryHelperResultEx> {
        const query: QueryUserRoleByIdApolloQueryResult = await apolloClient.query<QueryUserRoleByIdQuery>({ query: QueryUserRoleByIdDocument, variables: { userRoleId }, ...options });
        
        return { ...query, userRole: query?.data?.UserRole_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryUserRoleByIdApolloObservableQuery = ObservableQuery<QueryUserRoleByIdQuery>;
      async function watchQueryUserRoleById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserRoleByIdQueryVariables>, 'query'> }) : Promise<WatchQueryUserRoleByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserRoleByIdQuery>({ query: QueryUserRoleByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryUserRoleObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryUserRoleObjectsQuery>;
      export type QueryUserRoleObjectsObjectsApolloQueryResultEx = QueryUserRoleObjectsObjectsApolloQueryResult & UserRoleObjectsHelperResultEx;

      async function queryUserRoleObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserRoleObjectsQueryVariables>, 'query'> }): Promise<QueryUserRoleObjectsObjectsApolloQueryResultEx> {
        const query: QueryUserRoleObjectsObjectsApolloQueryResult = await apolloClient.query<QueryUserRoleObjectsQuery>({ query: QueryUserRoleObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.UserRole || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryUserRoleObjectsApolloObservableQuery = ObservableQuery<QueryUserRoleObjectsQuery>;
      async function watchQueryUserRoleObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryUserRoleObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryUserRoleObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryUserRoleObjectsQuery>({ query: QueryUserRoleObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertUserRoleFetchResult = FetchResult<InsertUserRoleMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserRoleFetchHelperResultEx = InsertUserRoleFetchResult & UserRoleByIdHelperResultEx;

    async function insertUserRole({ apolloClient, userRole, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, userRole: UserRole_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserRoleMutation, InsertUserRoleMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserRoleFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: userRole });
      const mutationOptions:MutationOptions<InsertUserRoleMutation, InsertUserRoleMutationVariables> = { mutation: InsertUserRoleDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userRole'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserRoleMutation>({ operationType: 'insert', entityName:'UserRole', objects:[objectForInsert as UserRole_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserRole - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserRoleFetchResult = await apolloClient.mutate<InsertUserRoleMutation, InsertUserRoleMutationVariables>(mutationOptions);
        
      return { ...mutation, userRole: mutation?.data?.insert_UserRole?.returning && mutation.data.insert_UserRole.returning[0] };
    }

    async function insertUserRoleWithOnConflict({ apolloClient, userRole, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, userRole: UserRole_Insert_Input, onConflict: UserRole_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserRoleWithOnConflictMutation, InsertUserRoleWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserRoleFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: userRole });
      const mutationOptions:MutationOptions<InsertUserRoleWithOnConflictMutation, InsertUserRoleWithOnConflictMutationVariables> = { mutation: InsertUserRoleWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userRole'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserRoleWithOnConflictMutation>({ operationType: 'insert', entityName:'UserRole', objects:[objectForInsert as UserRole_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserRoleWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertUserRoleFetchResult = await apolloClient.mutate<InsertUserRoleWithOnConflictMutation, InsertUserRoleWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, userRole: mutation?.data?.insert_UserRole?.returning && mutation.data.insert_UserRole.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertUserRoleObjectsFetchResult = FetchResult<InsertUserRoleMutation, Record<string, any>, Record<string, any>>;
    export type InsertUserRoleObjectsHelperResultEx = InsertUserRoleObjectsFetchResult & UserRoleObjectsHelperResultEx;

    async function insertUserRoleObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: UserRole_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertUserRoleMutation, InsertUserRoleMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertUserRoleObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertUserRoleMutation, InsertUserRoleMutationVariables> = { mutation: InsertUserRoleDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserRoleMutation>({ operationType: 'insert', entityName:'UserRole', objects:objectsForInsert as (UserRole_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertUserRole - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertUserRoleObjectsFetchResult = await apolloClient.mutate<InsertUserRoleMutation, InsertUserRoleMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_UserRole?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateUserRoleByIdQueryResult = FetchResult<UpdateUserRoleByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserRoleByIdHelperResultEx = UpdateUserRoleByIdQueryResult & UserRoleByIdHelperResultEx;

    async function updateUserRoleById({ apolloClient, userRoleId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, userRoleId: string, set: UserRole_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateUserRoleByIdMutation, UpdateUserRoleByIdMutationVariables>, 'mutation'> }): Promise<UpdateUserRoleByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateUserRoleByIdMutation, UpdateUserRoleByIdMutationVariables> = { mutation: UpdateUserRoleByIdDocument, variables: { id:userRoleId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserRoleByIdMutation>({ operationType: 'update', entityName:'UserRole', objects:[{ id:userRoleId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateUserRoleById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateUserRoleByIdQueryResult = await apolloClient.mutate<UpdateUserRoleByIdMutation, UpdateUserRoleByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, userRole: mutation?.data?.update_UserRole?.returning && mutation.data.update_UserRole.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateUserRoleObjectsFetchResult = FetchResult<UpdateUserRoleMutation, Record<string, any>, Record<string, any>>;
    export type UpdateUserRoleObjectsHelperResultEx = UpdateUserRoleObjectsFetchResult & UserRoleObjectsHelperResultEx;

    async function updateUserRoleObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>, 'mutation'> }): Promise<UpdateUserRoleObjectsHelperResultEx> {  
      const mutation:UpdateUserRoleObjectsFetchResult = await apolloClient.mutate<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>({ mutation: UpdateUserRoleDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_UserRole?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveUserRoleModelByIdQueryResult = FetchResult<RemoveUserRoleModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserRoleModelByIdQueryHelperResultEx = RemoveUserRoleModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeUserRoleModelById({ apolloClient, userRoleId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, userRoleId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveUserRoleModelByIdMutation, RemoveUserRoleModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveUserRoleModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveUserRoleModelByIdMutation, RemoveUserRoleModelByIdMutationVariables> = { mutation: RemoveUserRoleModelByIdDocument, variables: { id:userRoleId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserRoleModelByIdMutation>({ operationType: 'delete', entityName:'userRole', objects:[{ id:userRoleId }] }); 
        if(logLevel >= 2) console.log(' --> removeUserRoleModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserRoleModelByIdMutation>({ operationType: 'delete', entityName:'userRole', entityId:userRoleId }); }
      
      const mutation:RemoveUserRoleModelByIdQueryResult = await apolloClient.mutate<RemoveUserRoleModelByIdMutation, RemoveUserRoleModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_UserRole?.affected_rows || 0 };
    }
  

    type RemoveUserRoleModelObjectsQueryResult = FetchResult<RemoveUserRoleModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveUserRoleModelObjectsQueryHelperResultEx = RemoveUserRoleModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeUserRoleModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveUserRoleModelMutation, RemoveUserRoleModelMutationVariables>, 'mutation'> }): Promise<RemoveUserRoleModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveUserRoleModelObjectsQueryResult = await apolloClient.mutate<RemoveUserRoleModelMutation, RemoveUserRoleModelMutationVariables>({ mutation: RemoveUserRoleModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_UserRole?.affected_rows || 0 };
      }
  

    // UserRole Fragment Helper Object
    //------------------------------------------------

    export const UserRoleFragmentGQLHelper = {
      queryById: queryUserRoleById,
      queryObjects: queryUserRoleObjects,
      watchQueryById: watchQueryUserRoleById,
      watchQueryObjects: watchQueryUserRoleObjects,
      insert: insertUserRole,
      insertWithOnConflict: insertUserRoleWithOnConflict,
      insertObjects: insertUserRoleObjects,
      updateById: updateUserRoleById,
      updateObjects: updateUserRoleObjects
    }
  

    // UserRole Entity Helper Object
    //------------------------------------------------

    export const UserRoleModelGQLHelper = {
      removeById: removeUserRoleModelById,
      removeObjects: removeUserRoleModelObjects
    }
  

    // Wan HELPERS
    //------------------------------------------------

    export type WanByIdHelperResultEx = { wan:WanFragment | null | undefined };
    export type WanObjectsHelperResultEx = { objects:WanFragment[] };
    
  

      // Query Fetch Helper
      //
      export type QueryWanByIdApolloQueryResult = ApolloQueryResult<QueryWanByIdQuery>;
      export type QueryWanByIdApolloQueryHelperResultEx = QueryWanByIdApolloQueryResult & WanByIdHelperResultEx;

      async function queryWanById({ apolloClient, wanId, options }: { apolloClient: ApolloClient<object>, wanId: string, options?: Omit<QueryOptions<QueryWanByIdQueryVariables>, 'query' | 'variables'> }): Promise<QueryWanByIdApolloQueryHelperResultEx> {
        const query: QueryWanByIdApolloQueryResult = await apolloClient.query<QueryWanByIdQuery>({ query: QueryWanByIdDocument, variables: { wanId }, ...options });
        
        return { ...query, wan: query?.data?.Wan_by_pk }
      }

      // Query Watch ById Helper
      //
      export type WatchQueryWanByIdApolloObservableQuery = ObservableQuery<QueryWanByIdQuery>;
      async function watchQueryWanById({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryWanByIdQueryVariables>, 'query'> }) : Promise<WatchQueryWanByIdApolloObservableQuery> {
        return apolloClient.watchQuery<QueryWanByIdQuery>({ query: QueryWanByIdDocument, ...options });
      }
    

      // Query Fetch Objects Helper
      //
      export type QueryWanObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryWanObjectsQuery>;
      export type QueryWanObjectsObjectsApolloQueryResultEx = QueryWanObjectsObjectsApolloQueryResult & WanObjectsHelperResultEx;

      async function queryWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryWanObjectsQueryVariables>, 'query'> }): Promise<QueryWanObjectsObjectsApolloQueryResultEx> {
        const query: QueryWanObjectsObjectsApolloQueryResult = await apolloClient.query<QueryWanObjectsQuery>({ query: QueryWanObjectsDocument, ...options });
        
        return { ...query, objects: query?.data?.Wan || [] }
      }

      // Query Watch Objects Helper
      //
      export type WatchQueryWanObjectsApolloObservableQuery = ObservableQuery<QueryWanObjectsQuery>;
      async function watchQueryWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<QueryOptions<QueryWanObjectsQueryVariables>, 'query'> }) : Promise<WatchQueryWanObjectsApolloObservableQuery> {
        return apolloClient.watchQuery<QueryWanObjectsQuery>({ query: QueryWanObjectsDocument, ...options });
      }
    

    // Insert Helper
    //
    type InsertWanFetchResult = FetchResult<InsertWanMutation, Record<string, any>, Record<string, any>>;
    export type InsertWanFetchHelperResultEx = InsertWanFetchResult & WanByIdHelperResultEx;

    async function insertWan({ apolloClient, wan, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, wan: Wan_Insert_Input, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertWanMutation, InsertWanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertWanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: wan });
      const mutationOptions:MutationOptions<InsertWanMutation, InsertWanMutationVariables> = { mutation: InsertWanDocument, variables: { objects: [objectForInsert] }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'wan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertWanMutation>({ operationType: 'insert', entityName:'Wan', objects:[objectForInsert as Wan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertWan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertWanFetchResult = await apolloClient.mutate<InsertWanMutation, InsertWanMutationVariables>(mutationOptions);
        
      return { ...mutation, wan: mutation?.data?.insert_Wan?.returning && mutation.data.insert_Wan.returning[0] };
    }

    async function insertWanWithOnConflict({ apolloClient, wan, onConflict, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, wan: Wan_Insert_Input, onConflict: Wan_On_Conflict, autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertWanWithOnConflictMutation, InsertWanWithOnConflictMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertWanFetchHelperResultEx> {
      const objectForInsert = stripInsertInputClientFields({ input: wan });
      const mutationOptions:MutationOptions<InsertWanWithOnConflictMutation, InsertWanWithOnConflictMutationVariables> = { mutation: InsertWanWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'wan'`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertWanWithOnConflictMutation>({ operationType: 'insert', entityName:'Wan', objects:[objectForInsert as Wan_Insert_Input & ObjectWithId], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertWanWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation:InsertWanFetchResult = await apolloClient.mutate<InsertWanWithOnConflictMutation, InsertWanWithOnConflictMutationVariables>(mutationOptions);
        
      return { ...mutation, wan: mutation?.data?.insert_Wan?.returning && mutation.data.insert_Wan.returning[0] };
    }



  

    // Insert Objects Helper
    //
    type InsertWanObjectsFetchResult = FetchResult<InsertWanMutation, Record<string, any>, Record<string, any>>;
    export type InsertWanObjectsHelperResultEx = InsertWanObjectsFetchResult & WanObjectsHelperResultEx;

    async function insertWanObjects({ apolloClient, objects, autoOptimisticResponse, fieldMap, options } :{ apolloClient: ApolloClient<object>, objects: Wan_Insert_Input[], autoOptimisticResponse?:boolean, fieldMap?: FieldMap, options?: Omit<MutationOptions<InsertWanMutation, InsertWanMutationVariables>, 'mutation' | 'variables'> }): Promise<InsertWanObjectsHelperResultEx> {
      const objectsForInsert = objects.map(objectItem => stripInsertInputClientFields({ input: objectItem }));
      const mutationOptions:MutationOptions<InsertWanMutation, InsertWanMutationVariables> = { mutation: InsertWanDocument, variables: { objects: objectsForInsert }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        if(objectsForInsert.find(objectItem => !objectItem.id)) throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`); 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertWanMutation>({ operationType: 'insert', entityName:'Wan', objects:objectsForInsert as (Wan_Insert_Input & ObjectWithId)[], fieldMap }); 
        if(logLevel >= 2) console.log(' --> insertWan - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      
      const mutation: InsertWanObjectsFetchResult = await apolloClient.mutate<InsertWanMutation, InsertWanMutationVariables>(mutationOptions);
        
      return { ...mutation, objects: mutation?.data?.insert_Wan?.returning || [] };
    }
  

    // Update Helper
    //
    type UpdateWanByIdQueryResult = FetchResult<UpdateWanByIdMutation, Record<string, any>, Record<string, any>>;
    export type UpdateWanByIdHelperResultEx = UpdateWanByIdQueryResult & WanByIdHelperResultEx;

    async function updateWanById({ apolloClient, wanId, set, autoOptimisticResponse, options }: { apolloClient: ApolloClient<object>, wanId: string, set: Wan_Set_Input, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<UpdateWanByIdMutation, UpdateWanByIdMutationVariables>, 'mutation'> }): Promise<UpdateWanByIdHelperResultEx> {
      const mutationOptions:MutationOptions<UpdateWanByIdMutation, UpdateWanByIdMutationVariables> = { mutation: UpdateWanByIdDocument, variables: { id:wanId, set }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateWanByIdMutation>({ operationType: 'update', entityName:'Wan', objects:[{ id:wanId, ...set }] }); 
        if(logLevel >= 2) console.log(' --> updateWanById - optimisticResponse:', mutationOptions.optimisticResponse);
      }

      const mutation:UpdateWanByIdQueryResult = await apolloClient.mutate<UpdateWanByIdMutation, UpdateWanByIdMutationVariables>(mutationOptions);
        
      return { ...mutation, wan: mutation?.data?.update_Wan?.returning && mutation.data.update_Wan.returning[0] };
    }
  

    // Update Objects Helper
    //
    type UpdateWanObjectsFetchResult = FetchResult<UpdateWanMutation, Record<string, any>, Record<string, any>>;
    export type UpdateWanObjectsHelperResultEx = UpdateWanObjectsFetchResult & WanObjectsHelperResultEx;

    async function updateWanObjects({ apolloClient, options }: { apolloClient: ApolloClient<object>, options: Omit<MutationOptions<UpdateWanMutation, UpdateWanMutationVariables>, 'mutation'> }): Promise<UpdateWanObjectsHelperResultEx> {  
      const mutation:UpdateWanObjectsFetchResult = await apolloClient.mutate<UpdateWanMutation, UpdateWanMutationVariables>({ mutation: UpdateWanDocument, ...options } );
        
      return { ...mutation, objects:mutation?.data?.update_Wan?.returning || [] };
    }
  
  

    // Delete Helper
    //

    type RemoveWanModelByIdQueryResult = FetchResult<RemoveWanModelByIdMutation, Record<string, any>, Record<string, any>>;
    export type RemoveWanModelByIdQueryHelperResultEx = RemoveWanModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;
  
    async function removeWanModelById({ apolloClient, wanId, autoOptimisticResponse, options } :{ apolloClient: ApolloClient<object>, wanId: string, autoOptimisticResponse?:boolean, options?: Omit<MutationOptions<RemoveWanModelByIdMutation, RemoveWanModelByIdMutationVariables>, 'mutation'> }): Promise<RemoveWanModelByIdQueryHelperResultEx> {
      const mutationOptions:MutationOptions<RemoveWanModelByIdMutation, RemoveWanModelByIdMutationVariables> = { mutation: RemoveWanModelByIdDocument, variables: { id:wanId, }, ...options };
      if(autoOptimisticResponse && (!options || !options.optimisticResponse)){ 
        mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveWanModelByIdMutation>({ operationType: 'delete', entityName:'wan', objects:[{ id:wanId }] }); 
        if(logLevel >= 2) console.log(' --> removeWanModelById - optimisticResponse:', mutationOptions.optimisticResponse);
      }
      if((!options || !options.update)){ mutationOptions.update = generateUpdateFunctionForMutation<RemoveWanModelByIdMutation>({ operationType: 'delete', entityName:'wan', entityId:wanId }); }
      
      const mutation:RemoveWanModelByIdQueryResult = await apolloClient.mutate<RemoveWanModelByIdMutation, RemoveWanModelByIdMutationVariables>(mutationOptions);
    
      return { ...mutation, affected_rows: mutation?.data?.delete_Wan?.affected_rows || 0 };
    }
  

    type RemoveWanModelObjectsQueryResult = FetchResult<RemoveWanModelMutation, Record<string, any>, Record<string, any>>;
    export type RemoveWanModelObjectsQueryHelperResultEx = RemoveWanModelObjectsQueryResult & RemoveEntitiesQueryHelperResultEx;  
  
    async function removeWanModelObjects({ apolloClient, options }:{ apolloClient: ApolloClient<object>, options: Omit<MutationOptions<RemoveWanModelMutation, RemoveWanModelMutationVariables>, 'mutation'> }): Promise<RemoveWanModelObjectsQueryHelperResultEx> {  
        const mutation:RemoveWanModelObjectsQueryResult = await apolloClient.mutate<RemoveWanModelMutation, RemoveWanModelMutationVariables>({ mutation: RemoveWanModelDocument, ...options } );
          
        return { ...mutation, affected_rows: mutation?.data?.delete_Wan?.affected_rows || 0 };
      }
  

    // Wan Fragment Helper Object
    //------------------------------------------------

    export const WanFragmentGQLHelper = {
      queryById: queryWanById,
      queryObjects: queryWanObjects,
      watchQueryById: watchQueryWanById,
      watchQueryObjects: watchQueryWanObjects,
      insert: insertWan,
      insertWithOnConflict: insertWanWithOnConflict,
      insertObjects: insertWanObjects,
      updateById: updateWanById,
      updateObjects: updateWanObjects
    }
  

    // Wan Entity Helper Object
    //------------------------------------------------

    export const WanModelGQLHelper = {
      removeById: removeWanModelById,
      removeObjects: removeWanModelObjects
    }
  

    // COMBINED HELPER OBJECT
    //------------------------------------------------
    export const GQLHelpers = {
      Fragments: {
        Carrier: CarrierFragmentGQLHelper,
        ClientCompany: ClientCompanyFragmentGQLHelper,
        Gateway: GatewayFragmentGQLHelper,
        Partner: PartnerFragmentGQLHelper,
        Quicklink: QuicklinkFragmentGQLHelper,
        Firmware: FirmwareFragmentGQLHelper,
        GatewayBridge: GatewayBridgeFragmentGQLHelper,
        GatewaySubnet: GatewaySubnetFragmentGQLHelper,
        LanNode: LanNodeFragmentGQLHelper,
        Link: LinkFragmentGQLHelper,
        LinkType: LinkTypeFragmentGQLHelper,
        MeshWan: MeshWanFragmentGQLHelper,
        Monitor: MonitorFragmentGQLHelper,
        Profile: ProfileFragmentGQLHelper,
        PublicIp: PublicIpFragmentGQLHelper,
        Server: ServerFragmentGQLHelper,
        ServerBridge: ServerBridgeFragmentGQLHelper,
        Site: SiteFragmentGQLHelper,
        SiteContact: SiteContactFragmentGQLHelper,
        SiteLan: SiteLanFragmentGQLHelper,
        SiteRoute: SiteRouteFragmentGQLHelper,
        Tunnel: TunnelFragmentGQLHelper,
        TunnelProfile: TunnelProfileFragmentGQLHelper,
        User: UserFragmentGQLHelper,
        UserHistory: UserHistoryFragmentGQLHelper,
        UserRole: UserRoleFragmentGQLHelper,
        Wan: WanFragmentGQLHelper
      },
      Models: {
        Carrier: CarrierModelGQLHelper,
        ClientCompany: ClientCompanyModelGQLHelper,
        Gateway: GatewayModelGQLHelper,
        Partner: PartnerModelGQLHelper,
        Quicklink: QuicklinkModelGQLHelper,
        Firmware: FirmwareModelGQLHelper,
        GatewayBridge: GatewayBridgeModelGQLHelper,
        GatewaySubnet: GatewaySubnetModelGQLHelper,
        LanNode: LanNodeModelGQLHelper,
        Link: LinkModelGQLHelper,
        LinkType: LinkTypeModelGQLHelper,
        MeshWan: MeshWanModelGQLHelper,
        Monitor: MonitorModelGQLHelper,
        Profile: ProfileModelGQLHelper,
        PublicIp: PublicIpModelGQLHelper,
        Server: ServerModelGQLHelper,
        Site: SiteModelGQLHelper,
        SiteContact: SiteContactModelGQLHelper,
        SiteLan: SiteLanModelGQLHelper,
        SiteRoute: SiteRouteModelGQLHelper,
        Tunnel: TunnelModelGQLHelper,
        TunnelProfile: TunnelProfileModelGQLHelper,
        User: UserModelGQLHelper,
        UserHistory: UserHistoryModelGQLHelper,
        UserRole: UserRoleModelGQLHelper,
        Wan: WanModelGQLHelper
      }
    }
  