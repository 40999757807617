import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalVariant,
  Spinner,
  Title,
} from '@patternfly/react-core';
import { Spacer } from '@app/components/Spacer';
import { sortable } from '@patternfly/react-table';
import { useWanContext } from '../../contexts/wan.context';
import {
  selectControllerById,
  selectServerById,
  selectWanById,
  siteSelectors,
} from '../../common/configuration/configuration.selectors';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import { push } from 'connected-react-router';
import {
  BullhornIcon,
  DomainIcon,
  LockIcon,
  ServiceCatalogIcon,
} from '@patternfly/react-icons';
import { IconHeading } from '@app/components/IconHeading';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import { InfoSection } from '@app/components/InfoSection';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { actionCreators } from '@app/common/configuration/configuration.reducer';
import { Link } from 'react-router-dom';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import './Wan.css';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Loader from '@app/components/Loader';
import { MdAccountTree } from 'react-icons/md';

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const WanView: React.FC = () => {
  const dispatch = useDispatch();
  const { id, isLoading, isFailed, isSuccess } = useWanContext();
  const [isRebootConfirmOpen, setRebootConfirmOpen] = React.useState<boolean>(false);
  const wan = useSelector(selectWanById(id));
  const sites = useSelector(siteSelectors.selectAll).filter((site) => site.wanId === id);
  const activeController = useSelector(selectControllerById(wan?.activeGatewayId));
  const standbyController = useSelector(selectControllerById(wan?.standbyGatewayId));
  const activeGateway = useSelector(selectServerById(wan?.activeServerId));
  const standbyGateway = useSelector(selectServerById(wan?.standbyServerId));
  const encryptedState = wan?.isEncrypted ? 'Enabled' : 'Disabled';
  const [aclProfiles, setAclProfiles] = React.useState([]);
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;

  React.useEffect(() => {
    (async function () {
      const aclProfiles = await ACLProfile.getAssignedACLs(id);
      setAclProfiles(aclProfiles);
    })();
  }, []);

  const getSiteSubnetColumns = () => {
    return [
      { title: 'Address' },
      { title: 'LAN Subnet', transforms: [sortable] },
      { title: 'Accessible IPs' },
      { title: 'Addresses' },
    ];
  };

  const editWan = React.useCallback(() => {
    dispatch(push(`/wan/edit/${id}`));
  }, [id]);

  const getControllerInfo = React.useCallback(() => {
    return [
      { label: 'Active Controller', value: activeController?.name },
      { label: 'Standby Controller', value: standbyController?.name ?? 'N/A' },
    ];
  }, [activeController, standbyController]);

  const getGatewayInfo = React.useCallback(() => {
    return [
      { label: 'Active Tunnel Gateway', value: activeGateway?.ip4Address },
      { label: 'Standby Tunnel Gateway', value: standbyGateway?.ip4Address ?? 'N/A' },
    ];
  }, [activeGateway, standbyGateway]);

  const getAclProfileInfo = () => {
    if (aclProfiles?.length > 0) {
      return aclProfiles.map(profile => {
        let aclProfile = `/aclprofile/${profile.id}`;
        return { label: <Link to={aclProfile}>{profile.name}</Link>, value: profile.description };
      });
    }
    return [];
  };

  const rebootGatewaysInWan = React.useCallback(() => {
    sites.forEach((site) => {
      const serverIds = [
        site.Tunnels?.[0]?.Server?.id,
        site.Tunnels?.[1]?.Server?.id,
        activeGateway?.id,
        standbyGateway?.id,
      ].filter((x) => x !== undefined);

      dispatch(actionCreators.serverRebootRequest(serverIds));
    });
    setRebootConfirmOpen(false);
  }, [dispatch, sites, setRebootConfirmOpen]);

  const getSiteSubnets = React.useCallback(() => {
    return sites.map((site) => {
      const lan = site.SiteLans?.[0];
      return {
        cells: [
          { title: <Link to={{ pathname: '/site/' + site.id }}>{site.address1}</Link> },
          { title: `${lan?.lanSegmentIp4}${lan?.lanCidrIp4}` },
          { title: site.ipAccessLevel || 'All' },
          { title: site.ipAccessLevel === 'Specific' ? site.accessibleIps : '' },
        ],
      };
    });
  }, [sites]);

  const aclProfileInfo = getAclProfileInfo();

  if (isLoading) {
    return <Loader />;
  }

  if (isFailed) {
    return <ErrorView title="Failed to load WAN" message="🤖 Failed to load WAN" />;
  }

  if (isSuccess && !wan) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <Page
        tag="div"
        className={`cr-page px-3 pt-2 wans-list ${theme}`}
        title=""
        breadcrumbs={[{ name: 'WANs', active: true }]}
      >
        <Row>
          <Col lg={5} md={5} sm={12} xs={12}>
            <div className="p-3 h-100 sites-card-wrapper d-flex position-relative">
              <Row className="w-100">
                <Col lg={8} md={8} sm={12} xs={12}>
                  <Title headingLevel="h1" size="lg">
                    {wan?.name}
                  </Title>
                  <div className="w-100 mt-2 mb-2 font-12">
                   {wan?.description}
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>         
                  <div className="d-flex flex-column">
                    <Button className="text-white w-100 mb-2 ethica-button-green" onClick={editWan}>Edit</Button>
                    <Button className="text-white w-100 mb-2 ethica-button-green" onClick={(e) => setRebootConfirmOpen(!isRebootConfirmOpen)}>Reboot Gateway</Button>
                  </div>         
                </Col> 
              </Row>
            </div>
          </Col>

          <Col lg={7} md={7} sm={12} xs={12}>
            <div className="p-3 h-100 sites-card-wrapper d-flex position-relative font-12">
              <Row className="w-100">
                <Col> 
                  <Card className="h-100">
                    <CardHeader>                
                      <IconHeading
                        icon={<MdAccountTree className="icon-medium" />}
                        heading="Controllers"
                      />
                    </CardHeader>
                    <CardBody>
                        <InfoSection keyValuePairs={getControllerInfo()} showHeading={false} />
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Card className="h-100">
                    <CardHeader>
                      <IconHeading icon={<DomainIcon className="icon-medium" />} heading="Tunnel Gateway" />
                    </CardHeader>
                    <CardBody>
                      <InfoSection keyValuePairs={getGatewayInfo()} showHeading={false} />
                    </CardBody>
                  </Card>               
                </Col>

                <Col>    
                  <Card className="h-100">           
                    <CardHeader> 
                      <IconHeading
                          icon={<LockIcon className="icon-medium" />}
                          heading="Encryption"
                          subHeading={encryptedState}
                        />
                    </CardHeader>
                    <CardBody>
                      <p>If enabled, all tunnel traffic will be encrypted at 128-bit using a 256-bit key</p>
                    </CardBody>
                  </Card>   
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="p-3 h-100 sites-card-wrapper d-flex position-relative">
              <Card className="h-100">
                <CardHeader>
                  Security Settings
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                      <Spinner size="lg" />
                  ) : (
                    <Card>
                      <CardHeader>
                        <IconHeading
                          icon={<ServiceCatalogIcon className="icon-medium" />}
                          heading="ACL Profiles"
                          subHeading={aclProfileInfo?.length > 0 ? 'Enabled' : 'Disabled'}
                        />
                      </CardHeader>
                      <CardBody className="font-12">
                        {(aclProfileInfo?.length > 0) ? (
                          <div>
                            <InfoSection keyValuePairs={aclProfileInfo} showHeading={false} />
                          </div>
                        ) : (
                          <div>No ACL Profiles defined</div>
                        )}
                      </CardBody>
                    </Card>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col>
            <div className="p-3 h-100 sites-card-wrapper d-flex position-relative">
              <Card className="h-100">
                <CardHeader>                
                  Sites
                </CardHeader>
                <CardBody className="font-12">
                  {getSiteSubnets().length > 0 ? (
                    <PatternflyTable columns={getSiteSubnetColumns} data={getSiteSubnets()} />
                  ) : (
                    <div>No Sites configured!</div>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Modal
          title="Confirm Reboot"
          titleIconVariant={BullhornIcon}
          variant={ModalVariant.small}
          isOpen={isRebootConfirmOpen}
          onClose={() => setRebootConfirmOpen(!isRebootConfirmOpen)}
          actions={[
            <Button key="confirm" variant="primary" onClick={rebootGatewaysInWan}>
              Yes
            </Button>,
            <Button
              key="cancel"
              variant="link"
              onClick={(e) => {
                setRebootConfirmOpen(false);
              }}
            >
              Cancel
            </Button>,
          ]}
        >
          <p>Are you sure you want to reboot all Gateways?</p>
          <p>Please note that all Sites in this WAN will be affected by this action.</p>
        </Modal>
      </Page>
    </React.Fragment>
  );
};
