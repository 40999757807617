import axios, { AxiosError } from 'axios';
import headers from './api-gateway-headers';
import UserSession from '@app/common/user-session';

export default {
  async get(path) {
    try {
      let response = await axios.get(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        { headers: this.getHeaders() }
      );

      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw new Error(response.statusText || "Unknown error occurred");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || "Request failed");
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  },

  async patch(path, payload) {
    try {
      let response = await axios.patch(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        payload,
        { headers: this.getHeaders() }
      );

      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw new Error(response.statusText || "Unknown error occurred");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || "Request failed");
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  },

  async post(path, payload) {
    try {
      let response = await axios.post(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        payload,
        { headers: this.getHeaders() }
      );

      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw new Error(response.statusText || "Unknown error occurred");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || "Request failed");
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  },

  async put(path, payload) {
    try {
      let response = await axios.put(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        payload,
        { headers: this.getHeaders() }
      );

      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw new Error(response.statusText || "Unknown error occurred");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || "Request failed");
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  },

  async delete(path, payload) {
    try {
      let response = await axios.delete(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + path,
        { headers: this.getHeaders(), data: payload }
      );

      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        throw new Error(response.statusText || "Unknown error occurred");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || "Request failed");
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  },

  getHeaders() {
    let requestHeaders = headers.getApiKeyHeaders();

    if (UserSession.getToken()) {
      requestHeaders = headers.getAuthHeaders();
    }

    return requestHeaders;
  },
};
