import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import Site from '@app/api/site/site.actions';
import { Spinner } from '@patternfly/react-core';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { sortable, cellWidth } from '@patternfly/react-table';
import { truncateString } from '@app/utils/utils';
import { sortBy } from '@app/lib/functions';
import { PageSection, Title, Modal, ModalVariant } from "@patternfly/react-core";
import { BullhornIcon } from '@patternfly/react-icons';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { Column, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Updater, useReactTable } from '@tanstack/react-table';
import { MdAddCircle, MdArrowDownward, MdArrowUpward, MdCircle, MdUnfoldMore } from 'react-icons/md';
import Loader from '@app/components/Loader';

type ColumnFiltersState = ColumnFilter[];
interface ColumnFilter {
  id: string;
  value: unknown;
}

interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ACLProfileList: React.FunctionComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [aclProfiles, setAclProfiles] = React.useState([]);
  const [aclProfilesData, setAclProfilesData] = React.useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isErrorOpen, setIsErrorOpen] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [deleteError, setDeleteError] = React.useState("");
  const { theme } = React.useContext(ThemeContext) as ThemeContextType;
  const [expandedRows, setExpandedRows] = React.useState({});

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const statuses = [
    "#85ba3b",
    "#e93a54",
    "#000"
  ];

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * statuses.length);
    return statuses[randomNumber];
  }

  React.useEffect(() => {
    (async function() {
      try {
        const acls = await ACLProfile.getAll();
        const sites = await Site.getAll();
        const sorted = sortBy(acls, 'name');
        let aclProfileRows = [];
        let aclProfileDataRows = [];

        if (sorted) {
          aclProfileRows = sorted.map(item => {
            return aclProfileFromItem(sorted, item, sites);
          });
          aclProfileDataRows = sorted.map(item => {
            return aclProfileDataFromItem(sorted, item, sites);
          });
        }

        setAclProfiles(aclProfileRows);
        setAclProfilesData(aclProfileDataRows);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const columns = React.useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <>
          <strong>
            <Link to={`${basePath}/aclprofile/${row.original.id}`} className="acl-name">
              {row.original.name}
            </Link>
          </strong>
        </>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'sitesCount',
      header: 'Sites',
    },
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      header: 'Actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <Link to={`${basePath}/aclProfile/edit/${row.original.id}`}>
            <Button className="ethica-button-green" size="sm me-2">Edit</Button>
          </Link>
          <Button className="ethica-button-black" size="sm me-2">Delete</Button>
        </>
      ),
    },
  ], []);

  const table = useReactTable({
    data: aclProfilesData,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  const getColumns = () => {
    return [
      { title: 'Name', transforms: [sortable] },
      { title: 'Description' },
      { title: 'Sites', transforms: [sortable] },
      { title: 'ID', props: { className: 'pf-m-hidden pf-m-hidden-on-md' }},
      { title: 'Actions', transforms: [cellWidth(10)] }
    ];
  }

  const getColumnIndex = (columnName) => {
    const columns = getColumns();
    for (var i = 0; i < columns.length; i++) {
      if (columns[i]['title'] === columnName) {
        return i;
      }
    }
    return -1;
  }

  const editACLProfile = (event, rowId, rowData) => {
    const aclProfileId = rowData['id']['title'];
    history.push("/aclProfile/edit/" + aclProfileId);
  }

  const deleteACLProfile = async (event, rowId, rowData) => {
    const aclProfileId = rowData['id']['title'];
    setIsConfirmOpen(!isConfirmOpen);
    setActionId(aclProfileId);
  }

  const deleteRow = async () => {
    const aclProfile = await ACLProfile.get(actionId);

    if (aclProfile.assignments?.length > 0) {
      setDeleteError("This ACL Profile has Sites or WANs assigned. You must unassign this from all Sites and WANs before it can be deleted.")
      setIsConfirmOpen(false);
      setIsErrorOpen(true);
    }
    else {
      const result = await ACLProfile.delete(actionId);

      if (result?.data?.request_id) {
        const aclProfileIdIndex = getColumnIndex('ID');
        const updated = aclProfiles.filter(a => a.cells[aclProfileIdIndex].title !== actionId);

        setAclProfiles(updated);
        setLoading(false);
        setIsConfirmOpen(false);
        setIsErrorOpen(false);
        setActionId("");
        setPage(1);
      }
      else {
        setDeleteError("There was an error deleting the ACL Profile, or you are not permitted to perform this action")
        setIsConfirmOpen(false);
        setIsErrorOpen(true);
      }
    }
  }

  const handleConfirmToggle = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const handleErrorToggle = () => {
    setIsErrorOpen(!isErrorOpen);
  };

  const aclProfileFromItem = (acls, item, allSites) => {
    let sitesCount = 0;

    acls.map(acl => {
      if (acl.id === item.id && acl.assignments) {
        let sites = acl.assignments.filter(a => a.resource_type === 'site');
        let wans = acl.assignments.filter(a => a.resource_type === 'wan');

        if (sites?.length > 0) {
          sitesCount += sites.length;
        }
        if (wans?.length > 0) {
          wans.map(wan => {
            const wanSites = allSites.filter(site => site.wanId === wan.resource_id);
            const siteCount = wanSites ? wanSites.length : 0;
            sitesCount += siteCount;
          });
        }
      }
    });

    return {
      cells: [
        { title: <Link to={{ pathname: `${basePath}/aclprofile/` + item.id }}>{item.name}</Link> },
        { title: truncateString(item.description, 50) },
        { title: sitesCount },
        { title: item.id, props: { className: 'pf-m-hidden pf-m-hidden-on-md' }}
      ]
    }
  }

  const aclProfileDataFromItem = (acls, item, allSites) => {
    let sitesCount = 0;
    let sitesData = {};

    acls.map(acl => {
      if (acl.id === item.id && acl.assignments) {
        let sites = acl.assignments.filter(a => a.resource_type === 'site');
        let wans = acl.assignments.filter(a => a.resource_type === 'wan');

        if (sites?.length > 0) {
          sitesCount += sites.length;
          sitesData = sites;
        }
        if (wans?.length > 0) {
          wans.map(wan => {
            const wanSites = allSites.filter(site => site.wanId === wan.resource_id);
            const siteCount = wanSites ? wanSites.length : 0;
            sitesCount += siteCount;
          });
        }
      }
    });

    return {
      name: item.name,
      description: truncateString(item.description, 50),
      sitesCount: sitesCount,
      sites: sitesData,
      id: item.id
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>ACL Profiles</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Page
          tag="div"
          className={`cr-page px-3 pt-2 acl-list h-100 ${theme}`}
          title=""
          breadcrumbs={[{ name: 'ACL Profiles', active: true }]}
        >
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="acl-card-wrapper">
                  <Card>
                    <CardBody>
                      {loading ?
                        <Spinner size="lg" />
                      : aclProfiles.length === 0 ? <div>No ACL Profiles found!</div> :
                        <>
                          <table className="acl-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                              {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                  {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan}>
                                      {header.isPlaceholder ? null : (
                                        <div className={`d-flex  align-items-center ${header.column.getCanFilter() ? 'justify-content-start' : 'justify-content-center'}`}>
                                          <div
                                            {...{
                                              className: header.column.getCanSort()
                                                ? 'select-none'
                                                : '',
                                              onClick: header.column.getToggleSortingHandler(),
                                            }}
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}

                                            {header.column.getCanSort() && (
                                              {
                                                asc: <MdArrowUpward className="ms-2" />,
                                                desc: <MdArrowDownward className="ms-2" />,
                                              }[header.column.getIsSorted() as string] ?? (
                                                <MdUnfoldMore className="ms-2 opacity-50" />
                                              )
                                            )}
                                          </div>
                                          {header.column.getCanFilter() ? (
                                            <div>
                                              <Filter column={header.column} />
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {table.getRowModel().rows.map(row => (
                                <React.Fragment key={row.id}>
                                    <tr>
                                    {row.getVisibleCells().map(cell => (
                                      <td key={cell.id} className={cell.column.getCanFilter() ? '' : 'text-center'}>
                                        {row.original.sites && row.original.sites.length > 0 && cell.column.columnDef.header === 'Name' ? (
                                          <>
                                            <button onClick={() => toggleRow(row.id)} className="table-arrow">
                                              {expandedRows[row.id] ? '▼' : '▶'}
                                            </button>
                                            <strong><a href={`/wan/${row.original.id}`} className="acl-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                          </>
                                        ) : (
                                          <span className="no-arrow d-flex flex-wrap-nowrap w-100">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                                        )}
                                      </td>
                                    ))}
                                  </tr>

                                  {expandedRows[row.id] && (
                                    <>
                                      <tr>
                                        <td className="fw-bold">Type</td>
                                        <td className="fw-bold">ID</td>
                                        <td className="text-center fw-bold">Status</td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      {row.original.sites && row.original.sites.length > 0 && row.original.sites.map((site, index) => (
                                        <tr key={index} className="expanded-row">
                                          <td>Site</td>
                                          <td>{site.id}</td>
                                          <td className="text-center">
                                            <MdCircle color={generateRandomNumber()} />
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      )
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>

                          {/* Пагинация */}
                          <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                            <div className="me-2">
                              <span className="flex items-center gap-1">
                                Go to page:
                                <input
                                  type="number"
                                  min="1"
                                  max={table.getPageCount()}
                                  defaultValue={table.getState().pagination.pageIndex + 1}
                                  onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                  }}
                                  className="border p-1 rounded w-16"
                                />
                              </span>
                            </div>
                            <div>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.setPageIndex(0)}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  {'<<'}
                                </button>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.previousPage()}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  {'<'}
                                </button>
                                <span className="flex items-center me-1">
                                  <strong>
                                    {table.getState().pagination.pageIndex + 1} of{' '}
                                    {table.getPageCount()}
                                  </strong>
                                </span>
                                <button
                                  className="border rounded p-1 me-1"
                                  onClick={() => table.nextPage()}
                                  disabled={!table.getCanNextPage()}
                                >
                                  {'>'}
                                </button>
                                <button
                                  className="border rounded p-1 me-2"
                                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                  disabled={!table.getCanNextPage()}
                                >
                                  {'>>'}
                                </button>
                              </div>
                              <div>
                                <select
                                  className="form-select"
                                  value={table.getState().pagination.pageSize}
                                  onChange={e => {
                                    table.setPageSize(Number(e.target.value))
                                  }}
                                >
                                  {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                      Show {pageSize}
                                    </option>
                                  ))}
                                </select>
                              </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <Link to={`${basePath}/aclprofile/new`}>
                              <Button className="ethica-button-green" size="sm"><MdAddCircle className="me-1 ms-auto"/>New ACL</Button>
                            </Link>
                          </div>
                        </>
                      }
                      </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </>

          <Modal
            title="Confirm Action"
            titleIconVariant={BullhornIcon}
            variant={ModalVariant.small}
            isOpen={isConfirmOpen}
            onClose={handleConfirmToggle}
            actions={[
              <Button key="confirm" variant="primary" onClick={deleteRow}>Yes</Button>,
              <Button key="cancel" variant="link" onClick={handleConfirmToggle}>Cancel</Button>
            ]}
          >
            <p>Are you sure you want to delete this ACL Profile?</p>
            <p><b>{actionId}</b></p>
          </Modal>

          <Modal
            title="Error"
            titleIconVariant={BullhornIcon}
            variant={ModalVariant.small}
            isOpen={isErrorOpen}
            onClose={handleErrorToggle}
            actions={[
              <Button key="cancel" variant="primary" onClick={handleErrorToggle}>OK</Button>
            ]}
          >
            {deleteError}
          </Modal>
        </Page>
      )}
    </React.Fragment>
  );
}

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();
  //@ts-ignore
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
