import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    // Skip metrics requests
    return { sites: { metrics: {} } };
    
    /*
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get('/sites/metrics');
      return result.data;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
    */
  },

  async getInfo() {
    const state = store.getState();
    const partnerId = UserSession.getPartnerId();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${partnerId}`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },
}
