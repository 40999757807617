import ApiGateway from '../api-gateway/api-gateway';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll(userId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/users/${userId}/tokens`);
      return result.data.tokens || [];
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(userId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/users/${userId}/tokens`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async delete(userId, tokenId) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.delete(`/users/${userId}/tokens/${tokenId}/`, {});
      return result;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  },

  async updateStatus(userId, apiKeyId, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session?.expiration ?? 0;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/users/${userId}/tokens/${apiKeyId}/status`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
      return null;
    }
  }
}