import * as React from 'react';
import { OutlinedClockIcon } from '@patternfly/react-icons';
import { Select, SelectOption, SelectVariant } from "@patternfly/react-core";
import './TimePicker.css';

class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    
    const times = [
      {id: 1, time: "12:00 am"}, {id: 2, time: "12:30 am"}, {id: 3, time: "1:00 am"}, {id: 4, time: "1:30 am"}, {id: 5, time: "2:00 am"},
      {id: 6, time: "2:30 am"}, {id: 7, time: "3:00 am"}, {id: 8, time: "3:30 am"}, {id: 9, time: "4:00 am"}, {id: 10, time: "4:30 am"},
      {id: 11, time: "5:00 am"}, {id: 12, time: "5:30 am"}, {id: 13, time: "6:00 am"}, {id: 14, time: "6:30 am"}, {id: 15, time: "7:00 am"},
      {id: 16, time: "7:30 am"}, {id: 17, time: "8:00 am"}, {id: 18, time: "8:30 am"}, {id: 19, time: "9:00 am"}, {id: 20, time: "9:30 am"},
      {id: 21, time: "10:00 am"}, {id: 22, time: "10:30 am"}, {id: 23, time: "11:00 am"}, {id: 24, time: "11:30 am"}, {id: 25, time: "12:00 pm"},
      {id: 26, time: "12:30 pm"}, {id: 27, time: "1:00 pm"}, {id: 28, time: "1:30 pm"}, {id: 29, time: "2:00 pm"}, {id: 30, time: "2:30 pm"},
      {id: 31, time: "3:00 pm"}, {id: 32, time: "3:30 pm"}, {id: 33, time: "4:00 pm"}, {id: 34, time: "4:30 pm"}, {id: 35, time: "5:00 pm"},
      {id: 36, time: "5:30 pm"}, {id: 37, time: "6:00 pm"}, {id: 38, time: "6:30 pm"}, {id: 39, time: "7:00 pm"}, {id: 40, time: "7:30 pm"},
      {id: 41, time: "8:00 pm"}, {id: 42, time: "8:30 pm"}, {id: 43, time: "9:00 pm"}, {id: 44, time: "9:30 pm"}, {id: 45, time: "10:00 pm"},
      {id: 46, time: "10:30 pm"}, {id: 47, time: "11:00 pm"}, {id: 48, time: "11:30 pm"}
    ];
  
    this.state = {
      //@ts-ignore 
      id: this.props.id,
      //@ts-ignore 
      isEnabled: this.props.isEnabled || true,
      //@ts-ignore 
      validated: this.props.validated,
      times: times,
      //@ts-ignore 
      time: this.props.selection,
      isSelectOpen: false
    }
  }

  getTimeOptions = () => {
    //@ts-ignore 
    return this.state.times.map(time => {
      return <SelectOption key={time.id} id={time.id} value={time.time} />
    });
  }

  onToggle = (isOpen) => {
    this.setState(() => ({ 
      isSelectOpen: isOpen
    }));
  }

  onSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {

    }
    else {
      this.setState(() => ({ 
        time: selection,
        isSelectOpen: false 
      }));
    }
    //@ts-ignore 
    this.props.onSelect(event, selection, isPlaceholder);
  }

  render() {
    //@ts-ignore 
    const { id, times, time, isEnabled, validated, isSelectOpen } = this.state;
    const timesOptions = this.getTimeOptions();
    
    return (
      <div>
        <table className="timepicker-table">
          <tbody>
            <tr>
              <td className="timepicker-select">
                <Select
                  id={id} isDisabled={!isEnabled} variant={SelectVariant.single} placeholderText="hh:mm"
                  //@ts-ignore 
                  aria-label="Select Time" validated={validated} onToggle={(e) => this.onToggle(e, "time", id)}
                  //@ts-ignore 
                  onSelect={(event, selection, isPlaceholder) => this.onSelect(event, selection, isPlaceholder, "time", id)}
                  selections={time || ""} isOpen={isSelectOpen}
                >
                  {timesOptions}
                </Select>
              </td>              
              <td>
                <div className="timepicker-icon">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <OutlinedClockIcon className="icon-small icon-shift" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export { TimePicker };